import React, {
  ReactNode, useContext, useEffect, useRef,
} from 'react';
import { Card } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import './ExpandableCard.scss';
import { translations } from '../../Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { useAnimateHeight } from '../../../lib/useAnimateHeight';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons';

interface ExpandableCardProps {
  bordered?: boolean;
  loading?: boolean;
  title: string | ReactNode;
  children: ReactNode | ReactNode[] | any;
  cardOpen?: boolean;
  category: string;
  onToggled?: (category: string, open: boolean) => void;
  summary: ReactNode;
  hideSummaryWhenOpen?: boolean;
}

export function ExpandableCard(props: ExpandableCardProps) {
  const { tl } = useContext(LanguageContext);
  const { children, cardOpen, onToggled, summary, hideSummaryWhenOpen, ...cardProps } = props;

  const cardRef = useRef(null);

  const { toggleHeight, expanded }: { toggleHeight: any; expanded: boolean } = useAnimateHeight({
    animatedComponentRef: cardRef,
    initialClosed: true,
  });

  useEffect(() => {
    if (onToggled) {
      onToggled(props.category, expanded);
    }
  }, [expanded]);

  useEffect(() => {
    if (cardOpen !== null && typeof cardOpen !== 'undefined' && cardOpen !== expanded) {
      // workaround to only bulk-expand without too much custom logic
      toggleHeight();
    }
  }, [cardOpen]);

  const renderExpandButton = (): ReactNode => (
    <Button className="expand-card-button" type="text" onClick={toggleHeight} icon={expanded ? <Icon component={ICONS.collapse} /> : <Icon component={ICONS.expand} />}>
      {expanded ? tl(translations.pages.accountsOverview.collapse) : tl(translations.pages.accountsOverview.expand)}
    </Button>
  );

  const noBookings = <div className="empty-bookings">{tl(translations.pages.accountsOverview.noBookings)}</div>;
  const displayData = (children?.props && Object.keys(children?.props).length !== 0) || (Array.isArray(children) && children[0]?.props && Object.keys(children[0]?.props).length !== 0) ? children : noBookings;
  return (
    <div className="expandable-card">
      <div className="expand-button">{renderExpandButton()}</div>
      <Card className="antd-expandable-card" {...cardProps}>
        <div className="animated-card-body" ref={cardRef}>
          <div className="animated-content-container">{displayData}</div>
        </div>
        {hideSummaryWhenOpen && expanded ? <div /> : <div className={`summary-row-wrapper ${expanded ? 'expanded' : ''}`}>{summary}</div>}
      </Card>
    </div>
  );
}

ExpandableCard.defaultProps = {
  bordered: true,
  loading: false,
  cardOpen: false,
  onToggled: () => { },
  hideSummaryWhenOpen: true,
};
