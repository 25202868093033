import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import { Skeleton, Tooltip } from 'antd';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import Amount from 'storybook-components/typography/Amount/Amount';
import { formatCurrency } from 'lib/Utils';
import { InfoIcon } from 'storybook-components/InfoIcon/InfoIcon';
import { Link } from 'react-router-dom';

export const useBankAccountListColumns = (showNewBankAccountListColumns: boolean) => {
  const { tl } = useContext(LanguageContext);

  if (!showNewBankAccountListColumns) {
    return [
      {
        title: tl(translations.pages.property.table.headers.name),
        dataKey: 'name',
        render: (_, record) => (
          <Tooltip title={`${record.name || '—'}\n${getPropertyCellSubtitleInList(record, 'administrationType')}`}>
            {/* without this div the Tooltip doesn't work */}
            <div>
              <CellWithTwoLines
                firstElement={record.name || '—'}
                secondElement={getPropertyCellSubtitleInList(record, 'administrationType')}
              />
            </div>
          </Tooltip>
        ),
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.administrator),
        dataKey: 'supervisorName',
        sortable: false,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.calculatedHouseMoneyBalance),
        dataKey: 'calculatedHouseMoneyBalance',
        sortable: false,
        format: FORMAT.CURRENCY,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.houseMoneyBalance),
        dataKey: 'houseMoneyBalance',
        sortable: false,
        format: FORMAT.CURRENCY,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.calculatedReserveMoneyBalance),
        dataKey: 'calculatedReserveMoneyBalance',
        sortable: false,
        format: FORMAT.CURRENCY,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.reserveMoneyBalance),
        dataKey: 'reserveMoneyBalance',
        sortable: false,
        format: FORMAT.CURRENCY,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.calculatedTotalBalance),
        dataKey: 'calculatedTotalBalance',
        sortable: false,
        format: FORMAT.CURRENCY,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.totalBalance),
        dataKey: 'totalBalance',
        sortable: false,
        format: FORMAT.CURRENCY,
      },
    ];
  }

  return [
    {
      title: tl(translations.pages.property.table.headers.name),
      dataKey: 'name',
      render: (_, record) => (
        <Tooltip title={`${record.name || '—'}\n${getPropertyCellSubtitleInList(record, 'administrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.name || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'administrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.administrator),
      dataKey: 'supervisorName',
      sortable: false,
    },
    {
      title: (
        <>
          {tl(translations.pages.bankAccount.table.headers.bankBalance)}
          <InfoIcon
            popupText={tl(translations.pages.bankAccount.table.headers.bankBalanceInfo)}
            size="small"
          />
        </>
      ),
      titleString: tl(translations.pages.bankAccount.table.headers.bankBalance),
      dataKey: 'totalBankBalance',
      sortable: false,
      className: 'column-align-right',
      render: (value, record) => (
        <Link
          to={record.transactionsUrl}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
          title={tl(translations.pages.bankAccount.table.headers.bankBalanceHover)}
        >
          <CellWithTwoLines
            firstElement={<Amount>{formatCurrency(value)}</Amount>}
            secondElement={record.maxBankBalanceDateString}
          />
        </Link>
      ),
    },
    {
      title: (
        <>
          {tl(translations.pages.bankAccount.table.headers.accountingBalance)}
          <InfoIcon
            popupText={tl(translations.pages.bankAccount.table.headers.accountingBalanceInfo)}
            size="small"
          />
        </>
      ),
      titleString: tl(translations.pages.bankAccount.table.headers.accountingBalance),
      dataKey: 'sumOfPropertyAccountBalances',
      sortable: false,
      className: 'column-align-right',
      render: (value, record) => (
        <Skeleton
          loading={value === undefined}
          active
          title={false}
          paragraph={{ rows: 1, width: '10rem' }}
        >
          <Link
            to={record.accountUrl}
            target="_blank"
            rel="noreferrer"
            onClick={e => e.stopPropagation()}
            title={`${tl(translations.pages.bankAccount.table.headers.accountingBalanceHover)} ${record.accountCode}`}
          >
            <Amount>{formatCurrency(value)}</Amount>
          </Link>
        </Skeleton>
      ),
    },
  ];
};
