import {
  AllocationControllerApi, AllocationDto, BankTransactionControllerApi, ExchangeDtoTypeEnum, ExtendedExchangeProjectionTypeEnum, GetAllocationGroupsUsingGETOrderEnum, GetAllocationGroupsUsingGETTransactionStatusesEnum, ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum, BankTransactionProjectionDtoTransactionPaymentStatusEnum, SliceOfAllocationGroupDto, GetAllocationsUsingGETStatesEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';
import { useContext } from 'react';
import { ExchangeListAndPreviousAllocationValuesContext, ServiceCompanyAllocationUpdatersContext, SetPostingTextsContext } from './ServiceCompanyAllocationContext';

export const useServiceCompanyAllocationProposals = () => {
  const exchangeListAndPreviousAllocationValuesContext = useContext(ExchangeListAndPreviousAllocationValuesContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);
  const setPostingTexts = useContext(SetPostingTextsContext);

  if (exchangeListAndPreviousAllocationValuesContext === undefined || updatersContext === undefined || setPostingTexts === undefined) {
    throw new Error('useServiceCompanyAllocationProposals must be used within a ServiceCompanyAllocationContextProvider');
  }

  const { setExchangeList } = exchangeListAndPreviousAllocationValuesContext;
  const {
    setAllocationAmounts,
    setAllocationLaborCostValues,
    setSelectInputValues,
    setAllocationVatEligibilityValues,
  } = updatersContext;

  const { apiConfiguration } = useContext(AuthContext);
  const bankTransactionControllerApi = new BankTransactionControllerApi(apiConfiguration('accounting'));
  const allocationControllerApi = new AllocationControllerApi(apiConfiguration('accounting'));

  const addProposals = (allocations: AllocationDto[], selectedTransactions: ExtendedBankTransaction[]) => {
    const allocData = allocations.map((a) => {
      const {
        amount,
        laborCost,
        accountCode,
        laborCostType,
        vatEligibilityPercentage,
      } = a.exchange?.counterpartInstruction?.[0]?.categorisationInstructions?.[0] ?? {};
      return ({
        proposedXcKey: nanoid(),
        amount,
        laborCost,
        accountCode,
        laborCostType,
        vatEligibilityPercentage,
        propertyHrId: a.exchange?.propertyHrId,
        ...a,
      });
    });

    setAllocationAmounts(prev => ([...prev, ...(allocData.map(a => ({
      key: a.proposedXcKey,
      currentAmount: Math.abs(a.amount ?? 0),
      exchangeType: ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED,
    })) ?? [])]));

    setAllocationLaborCostValues(prev => ([...prev, ...(allocData.map(a => ({
      key: a.proposedXcKey,
      laborCostAmount: a.laborCost,
      laborCostType: (a.laborCostType as unknown) as ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
    })) ?? [])]));

    setSelectInputValues(prev => ([...prev, ...(allocData.map(a => ({
      key: a.proposedXcKey,
      accountCode: [a.accountCode ?? ''],
      counterpartContactId: a.exchange?.serviceCompanyId ?? a.exchange?.unitContractId,
      vatPercentage: a.exchange?.counterpartInstruction?.[0]?.categorisationInstructions?.[0]?.vatPercentage,
      propertyHrId: a.propertyHrId,
    })) ?? [])]));

    setPostingTexts(prev => ([...prev, ...(allocData.map(a => ({
      postingTexts: selectedTransactions.map(tx => ({ key: a.proposedXcKey, transactionId: tx.bankTransactionId, postingText: tx.purpose || '' })),
    })).flatMap(pt => pt.postingTexts) ?? [])]));

    setAllocationVatEligibilityValues(prev => ([...prev, ...(allocData.map(a => ({
      key: a.proposedXcKey,
      vatEligibilityPercentage: a.vatEligibilityPercentage,
    })) ?? [])]));

    setExchangeList(prev => prev.load(([...prev.data, ...(allocData.map(a => ({
      key: a.proposedXcKey,
      type: ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED,
      propertyHrId: a.propertyHrId,
    })))])));
  };

  const fetchProposals = (selectedTransactions: ExtendedBankTransaction[]) => {
    if (selectedTransactions[0] === undefined || selectedTransactions[0].transactionPaymentStatus !== BankTransactionProjectionDtoTransactionPaymentStatusEnum.UNASSIGNED) {
      return;
    }
    bankTransactionControllerApi.getAllocationGroupsUsingGET({
      amount: selectedTransactions[0].amount,
      propertyIban: selectedTransactions[0].propertyIban,
      counterpartIban: selectedTransactions[0].counterpartIban,
      transactionStatuses: GetAllocationGroupsUsingGETTransactionStatusesEnum.ASSIGNED,
      page: 0,
      size: 1,
      sort: 'updated',
      order: GetAllocationGroupsUsingGETOrderEnum.DESC,
    })
      .then((response: SliceOfAllocationGroupDto) => {
        if (response.content?.[0]?.transactions?.[0].bankTransactionId) {
          allocationControllerApi.getAllocationsUsingGET({
            transactionIds: [response.content?.[0].transactions[0].bankTransactionId],
            states: [GetAllocationsUsingGETStatesEnum.ALLOCATED] as unknown as GetAllocationsUsingGETStatesEnum,
          })
            .then((allocations : AllocationDto[]) => {
              // show proposals only if every allocation is transaction based
              if (_.isEmpty(allocations.filter(a => a.exchange?.type !== ExchangeDtoTypeEnum.TRANSACTION_BASED))) {
                addProposals(allocations, selectedTransactions);
              }
            })
            .catch((e) => {
              console.error('Failed to load proposals', e);
            });
        }
      })
      .catch((e) => {
        console.error('Failed to load proposals', e);
      });
  };

  return {
    fetchProposals,
  };
};
