import Icon from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, {
  useContext, useMemo, useState,
} from 'react';
import { ICONS } from '../../../../components/icons';
import CheckboxInput from '../../../Inputs/CheckboxInput/CheckboxInput';
import { Column, DataIndex } from '../../SmartTable';
import './ColumnSelector.scss';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../../Translation/translations';

interface ColumnSelectorProps {
  columns: Column[],
  changeColumnVisibility: (key: DataIndex) => void
}

export default function ColumnSelector(props: ColumnSelectorProps): JSX.Element {
  const { columns, changeColumnVisibility } = props;
  const [visible, setVisible] = useState(false);
  const { tl } = useContext(LanguageContext);

  const onSelectAll = () => {
    columns
      .flatMap((column: Column) => (column.children ? column.children : column))
      .forEach((col: Column) => {
        if (col.visible === allChecked && col.dataKey && !col.disabled) changeColumnVisibility(col.dataKey.toString());
      });
  };

  const allChecked = useMemo(() => {
    try {
      return columns
        .flatMap((column: Column) => (column.children ? column.children : column))
        .filter(column => !column.visible && !column.alwaysVisible).length <= 0;
    } catch {
      return true;
    }
  }, [columns]);

  const someChecked = useMemo(() => {
    try {
      return columns
        .flatMap((column: Column) => (column.children ? column.children : column))
        .filter(column => column.visible).length > 0;
    } catch {
      return true;
    }
  }, [columns]);

  const columnsMenu = (): JSX.Element => {
    if (columns) {
      return (
        <Menu>
          <Menu.Item key="all">
            <CheckboxInput
              className="all-selector"
              label={tl(translations.elements.smartTable.all)}
              value={allChecked}
              onChange={onSelectAll}
              indeterminate={!allChecked && someChecked}
            />
          </Menu.Item>
          {columns.flatMap((column: Column) => (column.children ? column.children : column))
            .filter((column: Column) => !column.alwaysVisible && column.titleString && column.dataKey)
            .map((column: Column) => (
              <Menu.Item key={`${column.dataKey}`}>
                <CheckboxInput
                  disabled={column.disabled}
                  label={`${column.titleString ? column.titleString : ''}`}
                  value={typeof column.visible !== 'undefined' ? column.visible : true}
                  onChange={() => changeColumnVisibility(column.dataKey!)}
                />
              </Menu.Item>
            ))}
        </Menu>
      );
    }
    return (<></>);
  };

  return (
    <div className="ColumnSelector">
      <Dropdown
        overlay={columnsMenu}
        overlayClassName="column-selector-dropdown"
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={setVisible}
        visible={visible}
      >
        <Icon component={ICONS.menuDots} />
      </Dropdown>
    </div>
  );
}
