import React, { useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import './DropMenu.scss';
import { ICONS } from '../icons';

export interface DropMenuProps {
  loading?: boolean
  options?: MenuOption[]
  disabled?: boolean,
  className?: string,
  dropdownVisibleClassName?: string,
  placement?: 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter',
  children?: React.ReactNode,
  'data-testid'?: string,
}

export interface MenuOption {
  label: string,
  onClick: (e?: React.SyntheticEvent) => void,
  className?: string,
  disabled?: boolean,
  tooltipText?: string | React.ReactNode,
  'data-testid'?: string,
}

export const DropMenu: React.FC<DropMenuProps> = (props) => {
  const {
    loading, options, disabled, children, className, dropdownVisibleClassName, placement,
  } = props;
  const [menuVisible, setMenuVisible] = useState(false);

  const menu = useMemo(() => (
    <Menu>
      {options ? options.map(option => (option.tooltipText !== undefined
        ? (
          <Tooltip title={option.tooltipText}>
            <Menu.Item
              key={option.label}
              // only "disable" via styling, otherwise tooltip won't be visible
              className={`drop-menu-option-item ${option.className} ${option.disabled ? 'disabled' : ''}`}
              onClick={(e) => {
                if (!option.disabled) {
                  e.domEvent.stopPropagation();
                  option.onClick();
                }
                setMenuVisible(false);
              }}
              data-testid={option['data-testid']}
            >
              {option.label}
            </Menu.Item>
          </Tooltip>
        ) : (
          <Menu.Item
            key={option.label}
            className={`drop-menu-option-item ${option.className}`}
            onClick={(e) => { e.domEvent.stopPropagation(); option.onClick(); setMenuVisible(false); }}
            disabled={option.disabled}
            data-testid={option['data-testid']}
          >
            {option.label}
          </Menu.Item>
        ))) : undefined}
    </Menu>
  ), [options]);

  return (
    <Dropdown
      className="drop-menu"
      overlay={menu}
      placement={placement || 'bottomRight'}
      trigger={['click']}
      disabled={disabled}
      onVisibleChange={v => setMenuVisible(v)}
    >
      <Button
        type="ghost"
        onClick={(e) => { e.stopPropagation(); }}
        className={`drop-menu-button ${className} ${menuVisible ? dropdownVisibleClassName : ''}`}
        loading={loading}
        data-testid={props['data-testid']}
      >
        {children || <Icon component={ICONS.menuDots} />}
      </Button>
    </Dropdown>
  );
};
