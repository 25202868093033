import { LanguageContext } from 'contexts/LanguageContext';
import { getUserPropertyAccess, isAuditingUser } from 'lib/userPermissionUtils';
import { AnalyticsGroup, useAnalyticsDomainDataList } from 'pages/Analytics/analyticsDomainDataList';
import { useContext, useEffect, useMemo } from 'react';
import { useAuditingGlobalFilterActions, useAuditingGlobalFilterLoadedData, useAuditingGlobalFilters } from 'services/AuditingGlobalFilterStore/selectorsAuditingGlobalFilter';
import { SharedDocuments, Timeframe } from 'services/AuditingGlobalFilterStore/interfacesAuditingGlobalFilter';
import { isEmpty } from 'lodash';
import { PropertyDisplayDto, PropertyDisplayDtoAdministrationTypeEnum, PropertyLegacyControllerApi } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { showNotification } from 'lib/Notification';
import { GlobalSearchContext } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { getDocumentListRoute } from 'pages/Document/routes';
import { getAccountSheetRoute } from 'pages/Account/routes';
import { DocumentLegacyDtoSourceTypeEnum } from 'api/document';
import { translations } from './translations';

const getNavigationTileItemsForAllAccesUser = (analyticsDomainData: AnalyticsGroup[]) => [
  {
    icon: 'property',
    key: 'propertyData',
    url: '/properties',
  },
  {
    icon: 'accounting',
    key: 'accounting',
    url: '/invoice',
  },
  {
    icon: 'meetings',
    key: 'meetings',
    url: '/owners-meeting/invitation',
  },
  {
    icon: 'contacts',
    key: 'contacts',
    url: '/persons',
  },
  {
    icon: 'documents',
    key: 'documents',
    url: '/documents',
  },
  {
    icon: 'analytics',
    key: 'analytics',
    url: analyticsDomainData ? `${analyticsDomainData[0].pages[0].path}` : '/analytics',
    buttonProps: {
      disabled: !analyticsDomainData,
    },
    // tooltip: 'comingSoon',
  },
  {
    icon: 'communication',
    key: 'messages',
    url: '/messages',
  },
  {
    icon: 'settings',
    key: 'settings',
    url: '/watermarks',
  },
];

const getNavigationTileItemsForAuditingUser = (sharedDocuments: SharedDocuments[], propertyId: number, timeframe: Timeframe | undefined) =>
  sharedDocuments?.map(({ sourceType, sourceIds }) => ({
    icon: 'documents',
    key: formatNavigationKay(sourceType),
    url: getDocumentListRoute({ sourceType, sourceIds, propertyId, timeframe }),
  }));

const formatNavigationKay = (str: string) => str.toLowerCase().replace(/_/g, '');

const getSharedDocuments = (globalFiltersSharedDocuments: SharedDocuments[], property: PropertyDisplayDto) => {
  if (!isEmpty(globalFiltersSharedDocuments)) {
    return globalFiltersSharedDocuments;
  }

  if (property?.administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG) {
    return [{ sourceType: DocumentLegacyDtoSourceTypeEnum.INVOICE }, { sourceType: DocumentLegacyDtoSourceTypeEnum.HOUSE_MONEY_SETTLEMENT }, { sourceType: DocumentLegacyDtoSourceTypeEnum.ECONOMIC_PLAN }, { sourceType: DocumentLegacyDtoSourceTypeEnum.HEATING_COST_DISTRIBUTION }, { sourceType: DocumentLegacyDtoSourceTypeEnum.SPECIAL_CONTRIBUTION }] as SharedDocuments[];
  }

  return [
    {
      sourceType: DocumentLegacyDtoSourceTypeEnum.INVOICE,
    },
    {
      sourceType: DocumentLegacyDtoSourceTypeEnum.PROFIT_AND_LOSS,
    },
  ] as SharedDocuments[];
};

export const useDashboard = () => {
  const { tl } = useContext(LanguageContext);
  const globalFilters = useAuditingGlobalFilters();
  const { analyticsDomainData } = useAnalyticsDomainDataList();
  const loadedData = useAuditingGlobalFilterLoadedData();
  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const { onChangeLoadedData } = useAuditingGlobalFilterActions();
  const { setHideGlobalSearch } = useContext(GlobalSearchContext);

  useEffect(() => {
    if (isAuditingUser()) {
      setHideGlobalSearch(true);
    }

    return () => {
      setHideGlobalSearch(false);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(getUserPropertyAccess()) && isEmpty(loadedData?.properties)) {
      onGetLoadedProperty();
    }
  }, [loadedData?.properties]);

  const onGetLoadedProperty = () => {
    propertyControllerApi
      .getPropertyDisplayByIdUsingGET({
        propertyId: globalFilters?.propertyIds[0],
      })
      .then((response) => {
        onChangeLoadedData('properties', [response]);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          key: 'loadPropertyError',
          message: tl(translations.notifications.propertyLoadError),
          type: 'error',
        });
      });
  };

  const navigationSections = useMemo(() => {
    if (!isAuditingUser()) {
      return [
        {
          id: Math.random(),
          items: getNavigationTileItemsForAllAccesUser(analyticsDomainData),
          showLanguageSwitcher: true,
          salutation: `${tl(translations.salutation)}!`,
        },
      ];
    }

    const sharedDocuments = getSharedDocuments(globalFilters.sharedDocuments, loadedData.properties[0]);

    return [
      {
        id: Math.random(),
        items: [
          {
            icon: 'accounting',
            key: 'accountsheet',
            url: getAccountSheetRoute({
              startDate: globalFilters?.timeframe?.startDate,
              endDate: globalFilters?.timeframe?.endDate,
              propertyHrId: loadedData.properties[0]?.propertyHrId,
            }),
          },
        ],
        showLanguageSwitcher: false,
        salutation: `${tl(translations.salutation)}! ${loadedData.properties[0]?.propertyIdInternal} · ${loadedData.properties[0]?.name}`,
      },
      {
        id: Math.random(),
        items: getNavigationTileItemsForAuditingUser(sharedDocuments, loadedData.properties[0]?.id, globalFilters?.timeframe),
        showLanguageSwitcher: false,
        salutation: tl(translations.navigationTiles.documents),
      },
    ];
  }, [globalFilters, loadedData.properties, analyticsDomainData, tl]);

  return { navigationSections };
};
