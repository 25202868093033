import React, { useContext, useMemo } from 'react';
import Icon from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import { PropertyListContext } from 'contexts/PropertyListContext';
import {
  PropertyDisplayDtoAdministrationTypeEnum,
  PropertyDisplayDtoVatRelevanceEnum,
} from 'api/accounting';
import { isAuditingUser } from 'lib/userPermissionUtils';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import Amount from 'storybook-components/typography/Amount/Amount';
import { formatCurrency } from 'lib/Utils';
import { DOCUMENT_EDITOR_PATH_BY_SOURCE } from 'pages/Document/routes';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../../elements/Translation/translations';
import { ICONS } from '../../../components/icons';
import { Column, FORMAT } from '../../../elements/SmartTable/SmartTable';

export const UsePostingListColumns = (): Column[] => {
  const { tl } = useContext(LanguageContext);
  const { headers } = translations.pages.accountSheet.table;
  const location = useLocation();
  const history = useHistory();

  const { selectedProperty } = useContext(PropertyListContext);

  const hasAuditingPermissions = isAuditingUser();
  const isMVPropertyType = [PropertyDisplayDtoAdministrationTypeEnum.MV, PropertyDisplayDtoAdministrationTypeEnum.SEV_MV].includes(selectedProperty?.data.administrationType);

  const openInvoice = (e: any, invoiceHrId: string, invoiceId: number) => {
    if (hasAuditingPermissions) {
      history.push(`${location.pathname}${DOCUMENT_EDITOR_PATH_BY_SOURCE}?sourceType=INVOICE&sourceId=${invoiceId}`);
    } else {
      history.push(`${location.pathname}/invoice/edit/${invoiceHrId}`);
    }
    e.stopPropagation();
  };

  const columns = useMemo(() => {
    const bookingColumns = [
      {
        title: tl(headers.date),
        format: FORMAT.DATE,
        defaultWidth: '10%',
        dataKey: 'postDate',
        sortable: true,
      },
      {
        title: tl(headers.bookingText),
        dataKey: 'bookingText',
        sortable: false,
        defaultWidth: '25%',
      },
      {
        title: tl(headers.counterAccount),
        dataKey: 'counterAccounts',
        sortable: false,
        defaultWidth: '20%',
        render: (_, record) => (
          <>
            {!!record.counterAccounts &&
              record.counterAccounts.split(',').map((account) => (
                <div key={account.toString()}>
                  {account.toString()}
                  <br />
                </div>
              ))}
          </>
        ),
      },
      {
        title: tl(headers.bookingAmount),
        format: FORMAT.CURRENCY,
        defaultWidth: 155,
        dataKey: 'balancedAmount',
        defaultVisible: hasAuditingPermissions,
        sortable: false,
      },
      {
        title: tl(headers.debit),
        format: FORMAT.CURRENCY,
        dataKey: 'debit',
        sortable: false,
        defaultWidth: '10%',
        defaultVisible: !hasAuditingPermissions,
      },
      {
        title: tl(headers.credit),
        format: FORMAT.CURRENCY,
        dataKey: 'credit',
        sortable: false,
        defaultWidth: '10%',
        defaultVisible: !hasAuditingPermissions,
      },
      {
        title: tl(headers.laborCost),
        dataKey: 'laborCost',
        defaultVisible: hasAuditingPermissions,
        sortable: false,
        defaultWidth: '10%',
        className: 'column-align-right',
        render: (_, record) => (record.laborCost ? <CellWithTwoLines firstElement={<Amount>{formatCurrency(record.laborCost, '')}</Amount>} secondElement={tl(translations.pages.accountSheet.table.laborCostTypes[record.laborCostType])} /> : null),
      },
      {
        title: tl(headers.vat),
        format: FORMAT.CURRENCY,
        defaultVisible: hasAuditingPermissions && isMVPropertyType,
        sortable: false,
        defaultWidth: 155,
        render: (_, record) => <CellWithTwoLines firstElement={<Amount>{formatCurrency(record.vatAmount)}</Amount>} secondElement={`${record.vatPercentage ?? 0}%`} />,
      },
    ];

    if ([PropertyDisplayDtoVatRelevanceEnum.PARTIALLY_RELEVANT, PropertyDisplayDtoVatRelevanceEnum.FULLY_RELEVANT].includes(selectedProperty.data?.vatRelevance)) {
      bookingColumns.push({
        title: tl(headers.vatEligibility),
        defaultVisible: hasAuditingPermissions && isMVPropertyType,
        sortable: false,
        format: FORMAT.CURRENCY,
        defaultWidth: 155,
        render: (_, record) => <CellWithTwoLines firstElement={<Amount>{formatCurrency(record.vatEligibilityAmount)}</Amount>} secondElement={`${record.vatEligibilityPercentage ?? 0}%`} />,
      } as any);
    }

    const tempColumns = [
      {
        title: `${tl(headers.account)}`,
        dataKey: 'accountCode',
        sortable: false,
        defaultWidth: '8%',
        disabled: true,
      },
      {
        title: `${tl(headers.invoice)}`,
        children: [
          {
            title: `${tl(headers.invoice)}`,
            defaultWidth: '5%',
            dataKey: 'invoiceHrId',
            sortable: false,
            render: (text: any, row: any) => {
              if (row.invoiceHrId) {
                return (
                  <Icon
                    className="invoice-icon"
                    onClick={(e) => {
                      openInvoice(e, row.invoiceHrId, row.invoiceId);
                    }}
                    component={ICONS.file}
                  />
                );
              }
              return null;
            },
          },
          {
            title: tl(headers.invoiceDate),
            format: FORMAT.DATE,
            defaultWidth: '10%',
            dataKey: 'invoiceDate',
            defaultVisible: false,
            sortable: false,
          },
          {
            title: tl(headers.invoiceAmount),
            format: FORMAT.CURRENCY,
            defaultWidth: 155,
            dataKey: 'invoiceAmount',
            defaultVisible: false,
            sortable: false,
          },
          {
            title: tl(headers.invoiceState),
            defaultWidth: 100,
            dataKey: 'state',
            defaultVisible: false,
            sortable: false,
          },
          {
            title: tl(headers.creditor),
            defaultWidth: '15%',
            dataKey: 'creditor',
            defaultVisible: false,
            sortable: false,
          },
        ],
      },
      {
        title: tl(headers.booking),
        defaultWidth: '55%',
        children: bookingColumns,
      },
      {
        title: tl(headers.balance),
        format: FORMAT.CURRENCY,
        dataKey: 'runningBalance',
        sortable: false,
        defaultWidth: '10%',
      },
    ];

    return tempColumns;
  }, [selectedProperty.data?.vatRelevance]);

  return columns;
};
