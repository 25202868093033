import { Tag } from 'antd';
import React, { useContext } from 'react';
import { UnitDistributionSetDtoDistributionModeEnum, SpecialContributionDetailsDtoTypeEnum } from '../../../../../../api/accounting';
import { translations } from '../../../../translations';
import CurrencyInputWithPrefix from '../../../../../../elements/Inputs/CurrencyInputWithPrefix/CurrencyInputWithPrefix';
import SelectInput from '../../../../../../elements/Inputs/SelectInput/SelectInput';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';

export function AmountAndDistributionModeSelector(
  setAmountToDistribute: (amountToDistribute: number) => void,
  onChangeDistributionMode: (newDistributionMode: UnitDistributionSetDtoDistributionModeEnum) => void,
  distributionMode?: UnitDistributionSetDtoDistributionModeEnum,
  type?: SpecialContributionDetailsDtoTypeEnum,
  totalAmount: number = 0,
  disabled: boolean = false,
) {
  const { tl } = useContext(LanguageContext);
  const distributionModeOptions = Object.keys(UnitDistributionSetDtoDistributionModeEnum)
    .filter((dm) => dm !== UnitDistributionSetDtoDistributionModeEnum.HEATING_COST && dm !== UnitDistributionSetDtoDistributionModeEnum.LEGACY_HEATING_COST)
    .map((mode) => ({
      // @ts-ignore
      value: UnitDistributionSetDtoDistributionModeEnum[mode],
      label: tl(translations.specialContribution.section3.optionsDk[mode]),
    }));

  return {
    sectionId: 'contributionOrDistributionSection',
    title: tl(translations.specialContribution.section3.subsections.distributionTitle),
    content: (
      <div className="section-distribution-contribution">
        <div className="item">
          <CurrencyInputWithPrefix
            onChange={setAmountToDistribute}
            value={totalAmount}
            inputClassName=""
            prefixComponent={(
              <Tag className={(type && `prefix-${type}`) || 'prefix-CONTRIBUTION'}>
                {(type && tl(translations.specialContribution.section3.subsections[type])) || tl(translations.specialContribution.section3.subsections.CONTRIBUTION)}
              </Tag>
            )}
            label={tl(translations.specialContribution.section3.subsections.inputs.totalAmountLabel)}
            required
            disabled={disabled}
            autoFormat={false}
          />

        </div>
        <div className="item">
          <SelectInput
            label={tl(translations.specialContribution.section3.subsections.inputs.distributionKeyLabel)}
            options={distributionModeOptions}
            onChange={onChangeDistributionMode}
            value={distributionMode}
            required
            disabled={disabled}
          />
        </div>
      </div>
    ),
  };
}
