import { Col, Row } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { SPACE_SIZES } from 'lib/constants';
import ModalWithSubtitle from 'storybook-components/modals/ModalWithSubtitle/ModalWithSubtitle';
import DateInput from 'elements/Inputs/DateInput/DateInput';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import RangeInput from 'elements/Inputs/RangeInput/RangeInput';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from 'lib/Utils';
import SelectInputWithLink from 'storybook-components/inputs/select/SelectorWithLink/SelectorWithLink';
import Button from 'elements/Buttons/Button/Button';
import './ModalShareAccountingData.scss';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import { accountsOverviewTranslations } from '../../translations';
import { FinancialDataAccessOptions, useModalShareAccountingData } from './useModalShareAccountingData';

type Props = {
  visible: boolean;
  onCancel: () => void;
};

const ModalShareAccountingData = ({ visible, onCancel }: Props) => {
  const { tl } = useContext(LanguageContext);
  const {
    linkExpirationDate, setLinkExpirationDate, financialDataAccessOptions, financialDataAccess, setFinancialDataAccess, timeframeForSharedData, setTimeframeForSharedData, sharedDocumentsOptions, onChangeSourceDocuments, selectedSourceDocuments, onCopyLink, onGenerateTokenAndSendMail, areButtonsDisabled, isLimitedAccess, isCopied, loading, resetState, minDateLinkEpiration, maxDateLinkEpiration,
  } = useModalShareAccountingData(visible);

  return (
    <ModalWithSubtitle
      visible={visible}
      destroyOnClose
      title={tl(accountsOverviewTranslations.shareAccountingDataModal.title)}
      subtitle={tl(accountsOverviewTranslations.shareAccountingDataModal.subtitle)}
      onCancel={() => {
        onCancel();
        resetState();
      }}
      cancelButtonProps={{ className: 'Button' }}
      cancelText={tl(accountsOverviewTranslations.shareAccountingDataModal.cancelButton)}
      okButtonProps={{ hidden: true }}
    >
      <Row gutter={[SPACE_SIZES.medium, SPACE_SIZES.medium]} className="ModalShareAccountingData">
        <Col span={24}>
          <DateInput
            className="custom-required"
            label={tl(accountsOverviewTranslations.shareAccountingDataModal.linkExpirationDate)}
            disabled={false}
            value={linkExpirationDate}
            onChange={setLinkExpirationDate}
            infoText={tl(accountsOverviewTranslations.shareAccountingDataModal.linkExpirationDateTooltip)}
            hardMinDate={minDateLinkEpiration}
            hardMaxDate={maxDateLinkEpiration}
          />
        </Col>
        <Col span={24}>
          <SelectInput
            onChange={value => setFinancialDataAccess(value as FinancialDataAccessOptions)}
            options={financialDataAccessOptions}
            value={financialDataAccess}
            label={tl(accountsOverviewTranslations.shareAccountingDataModal.financialDataAccess)}
            infoText={tl(accountsOverviewTranslations.shareAccountingDataModal.financialDataAccessTooltip)}
          />
        </Col>

        {isLimitedAccess && (
          <>
            <Col span={24}>
              <RangeInput value={(timeframeForSharedData as unknown) as [Moment, Moment]} onChange={setTimeframeForSharedData} label={tl(accountsOverviewTranslations.shareAccountingDataModal.timeframeForSharedDate.title)} infoText={tl(accountsOverviewTranslations.shareAccountingDataModal.timeframeForSharedDate.tooltip)} format={DATE_FORMAT} />
            </Col>
            <Col span={24}>
              <SelectInputWithLink showSearch allowMultiple className="Select custom-required" label={tl(accountsOverviewTranslations.shareAccountingDataModal.sharedDocuments.title)} infoText={tl(accountsOverviewTranslations.shareAccountingDataModal.sharedDocuments.tooltip)} onChange={onChangeSourceDocuments} value={selectedSourceDocuments} options={sharedDocumentsOptions} />
            </Col>
          </>
        )}
        <Col span={24} className="button-group">
          <Button type="default" onClick={onGenerateTokenAndSendMail} loading={loading} className="large-button" disabled={areButtonsDisabled} icon={<Icon component={ICONS.sendEmail} />}>
            {tl(accountsOverviewTranslations.shareAccountingDataModal.sendEmailButton)}
          </Button>
          <Button type="default" onClick={onCopyLink} loading={loading} className="large-button" disabled={areButtonsDisabled} icon={!isCopied ? <Icon component={ICONS.copyLink} /> : <Icon component={ICONS.copied} />}>
            {!isCopied ? tl(accountsOverviewTranslations.shareAccountingDataModal.copyLinkButton) : tl(accountsOverviewTranslations.shareAccountingDataModal.copiedButton)}
          </Button>
        </Col>
      </Row>
    </ModalWithSubtitle>
  );
};

export default ModalShareAccountingData;
