import React, { SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router';
import './BurgerMenu.scss';
import { BurgerMenuItem } from 'services/useSiteMap/interfaces';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import { HelpButton } from 'components/HelpButton/HelpButton';
import Heading from 'storybook-components/typography/Heading/Heading';
import { isAuditingUser } from 'lib/userPermissionUtils';

export const BurgerMenu: React.FC<{ open: boolean; setOpen: () => void }> = ({ open, setOpen }) => {
  const { burgerMenuItems } = useSiteMap();
  const isAuditing = isAuditingUser();
  const menuItemsToRender = isAuditing ? [burgerMenuItems[burgerMenuItems.length - 1]] : burgerMenuItems;
  return (
    <div className={`burger-menu ${open ? 'open' : ''}`}>
      <div className="container-wrapper">
        <div className="container">
          <div className="burger-menu-content">
            {menuItemsToRender.map((item) => (
              <BurgerMenuSection item={item} key={item.key} toggleMenu={setOpen} />
            ))}
          </div>
        </div>
      </div>
      {!isAuditing && <HelpButton />}
    </div>
  );
};

const BurgerMenuSection: React.FC<{ item: BurgerMenuItem, toggleMenu: () => void }> = ({ item, toggleMenu }) => {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);

  const toggleMenuPoint: () => void = () => {
    if (item.action) {
      item.action();
    } else {
      setOpen(prevValue => !prevValue);
    }
  };

  const onNavigate: (e: SyntheticEvent, url: string) => void = (e, url) => {
    e.preventDefault();
    toggleMenu();
    toggleMenuPoint();
    history.push(url);
  };

  return (
    <div className={`burger-section ${item.key} ${open ? 'open' : ''}`}>
      <button
        onClick={toggleMenuPoint}
        className={`section-button ${item.key}`}
      >
        <Heading level={2} className="section-button-title">
          {item.title}
        </Heading>
      </button>
      <div className={`section-content ${open ? 'open' : ''}`}>
        {item.subMenuPoints.map(subMenuPoint => (
          <div className="sub-menu-point col-xs-3" key={subMenuPoint.key}>
            <div className="sub-menu-point-content">
              <div className="category-label">
                {subMenuPoint.title}
              </div>
              {subMenuPoint.navItems
                .filter(navItem => !navItem.hidden)
                .map(navItem => (
                  <a
                    className="nav-item"
                    href={navItem.url}
                    key={navItem.key}
                    onClick={e => onNavigate(e, navItem.url)}
                  >
                    {navItem.title}
                  </a>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
