import React, {
  useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { ColumnType, Key, TableRowSelection } from 'antd/lib/table/interface';
import { GetComponentProps } from 'rc-table/lib/interface';
import Amount from 'storybook-components/typography/Amount/Amount';
import { Table } from 'antd';
import SmartTableHeader from './components/SmartTableHeader/SmartTableHeader';
import { Action } from './data';
import {
  Column, DataIndex, ExpandedRowRender, FORMAT, InfiniteScrollerProps, LAST_COLUMN_WIDTH, SmartTableProps,
} from './SmartTable';
import {
  formatCurrency, formatDate, formatPercentage, loadFromLocalStorage, saveToLocalStorage,
} from '../../lib/Utils';
import ActionsDropdown from './components/OptionsMenu/ActionsDropdown';

export interface SmartTableSortProps {
  field: string,
  order: 1 | -1,
  onSortChange: (dataKey: string) => void
}


export interface UseSmartTableProps {
  tableName: string
  className?: string
  columns: (Column)[]
  dataSource: any[]
  propSort?: SmartTableSortProps
  onHeaderClick?: (dataKey: DataIndex) => void
  contentLoading: boolean
  onRow?: GetComponentProps<any>
  showHeader?: boolean
  infiniteScrollerProps?: InfiniteScrollerProps
  expandedRowRender?: ExpandedRowRender
  onExpandedRowsChange?: (expandedRowKeys: Key[]) => void
  expandRowByClick?: boolean
  rowKey: string,
  rowClassName?: (record: any, index: number) => string,
  verticallyScrollable?: boolean
  maxHeight?: number
  actionsMenu?: Action[]
  supportBatchActions?: boolean,
  showOnlyCheckboxes?: boolean,
  expandable?: boolean,
  hideColumnSelector?: boolean,
  virtualize?: boolean,
  hideActionBar?: boolean,
  onRowSelected?: (record: any, selected: boolean, selectedRows: any[]) => void,
  onAllRowsSelected?: (selected: boolean, selectedRows: any[]) => void,
  shouldDisableCheckbox?: (record: any, selectedRows: any[]) => boolean,
  showHeaderCheckbox?: boolean,
  parentHeight?: number,
  parentComponent?: Element | null,
  emptyResultsComponent?: JSX.Element,
  selectedRowKeys?: any[],
  onChangeSelectedRowKeys?: (keys: any[], record?: any, selected?: boolean) => void,
  expandedRowKeys?: string[] | number[],
  onChangeExpandedRowKeys?: React.Dispatch<React.SetStateAction<string[] | number[]>>,
  getCheckboxProps?: TableRowSelection<any>['getCheckboxProps'],
}


/**
 * The shenanigans with Table.EXPAND_COLUMN and Table.SELECTION_COLUMN are needed because
 * antd exports these two columns as a constant object and internally checks for object equality,
 * so if the reference changes (e.g. we `.map` over it and return a new object), then the table
 * will not recognize it as this special column and it will not work.
 */


// @ts-ignore
export default function useSmartTable(props: UseSmartTableProps): SmartTableProps {
  const {
    tableName,
    className,
    dataSource,
    propSort,
    onHeaderClick,
    contentLoading,
    onRow,
    infiniteScrollerProps,
    expandedRowRender,
    expandRowByClick,
    rowKey,
    maxHeight,
    actionsMenu,
    supportBatchActions,
    showOnlyCheckboxes,
    hideColumnSelector,
    virtualize,
    hideActionBar,
    parentHeight,
    parentComponent,
    emptyResultsComponent,
    shouldDisableCheckbox,
    showHeaderCheckbox,
    onRowSelected,
    onAllRowsSelected,
    getCheckboxProps,
    selectedRowKeys: selectedRowKeysProp,
    onChangeSelectedRowKeys: onChangeSelectedRowKeysProp,
    expandedRowKeys: expandedRowKeysProp,
    onChangeExpandedRowKeys: setExpandedRowKeysProp,
  } = props;
  const showHeader = typeof props.showHeader !== 'undefined' ? props.showHeader : true;


  const [columns, setColumns] = useState<Column[]>([]);

  const handleResize = (dataKey: DataIndex) => (e: MouseEvent, { size }: any) => {
    setColumns((oldColumns: Column[]) => overrideColumnPropsForDataKey(dataKey, oldColumns, (c: Column) => ({ ...c, width: size.width })));
  };

  const overrideColumnPropsForDataKey = (dataKey: DataIndex, cols: Column[], applyFn: (c: Column) => Column): Column[] => cols.map((column) => {
    if ([Table.EXPAND_COLUMN, Table.SELECTION_COLUMN].includes(column)) return column;

    if (column.children) {
      return {
        ...column,
        children: overrideColumnPropsForDataKey(dataKey, column.children, applyFn),
      };
    }

    if (column.dataKey?.toString() === dataKey.toString()) return { ...applyFn(column) };

    return column;
  });

  const isColumnVisible = (columnsVisibility: any, column: Column) => {
    if (column.visible !== undefined) {
      return column.visible;
    }
    const localStorageColumnVisibility = columnsVisibility.find(col => col.columnName === column.dataKey);
    if (localStorageColumnVisibility !== undefined) {
      return localStorageColumnVisibility.visibleColumn;
    }
    return column.defaultVisible ?? true;
  };

  const initializeDefaultValues = (cols: Column[]): Column[] => {
    const columnsVisibility = loadFromLocalStorage(`${tableName}ColumnsVisibility`, 'array');
    const defaultValues = cols.map((column, index) => {
      if ([Table.EXPAND_COLUMN, Table.SELECTION_COLUMN].includes(column)) return column;

      if (column.children) {
        column.children = [...initializeDefaultValues(column.children)];
      }

      const sortable = !!propSort && (column.sortable === undefined || column.sortable) && column.dataKey;
      let sort;
      if (propSort && column.dataKey) {
        const selected = propSort.field.toString() === column.dataKey.toString();
        sort = {
          directionAsc: propSort.order === 1,
          selected,
        };
      }

      return {
        ...column,
        titleString: column.titleString ?? column.title?.toString(),
        title: showHeader && !!column.title ? (
          <SmartTableHeader
            title={column.title ?? column.titleString}
            sort={sortable ? sort : undefined}
            onClick={() => {
              if (column.dataKey) {
                if (sortable && !!propSort) {
                  let sortField = column.dataKey.toString().replace(',', '.');
                  if (sortField.indexOf('translated') !== -1) {
                    sortField = sortField.replace('translated', '');
                    sortField = sortField.charAt(0).toLowerCase() + sortField.slice(1);
                  }
                  propSort.onSortChange(sortField);
                }
                if (onHeaderClick) {
                  onHeaderClick(column.dataKey);
                }
              }
            }}
          />
        ) : '',
        key: column.dataKey ? column.dataKey.toString() + index : index,
        visible: isColumnVisible(columnsVisibility, column),
        width: column.defaultWidth,
        className: ` ${column.className} ${column.format ? column.format.toString().toLowerCase() : ''} `,
        dataIndex: column.dataKey,
        onHeaderCell: (c: Column) => ({
          width: c.width,
          onResize: c.dataKey ? handleResize(c.dataKey) : undefined,
        }),
        render: [FORMAT.CURRENCY, FORMAT.CURRENCY_DASH, FORMAT.NUMBER, FORMAT.PERCENTAGE].includes(column.format)
          // @ts-ignore
          ? (...args) => <Amount>{column.render ? column.render(...args) : args[0]}</Amount>
          : column.render,
      };
    });
    return defaultValues;
  };

  useEffect(() => {
    setColumns((old) => {
      if (old.length === 0) {
        return initializeDefaultValues(props.columns);
      }
      return initializeDefaultValues(old);
    });
  }, [propSort?.order, propSort?.field]);

  const addUniqueKey = (dataSrc: any[], parentIndex?: string | number) => dataSrc.map((data: any, index: number) => {
    const newObject = {
      key: parentIndex !== undefined ? `${parentIndex}-${index}` : index,
      ...data,
    };
    return newObject;
  });

  const changeColumnVisibility = (key: DataIndex, visible?: boolean) => {
    setColumns((oldColumns) => {
      const newColumns: Column[] = overrideColumnPropsForDataKey(key, oldColumns, (c: Column) => ({ ...c, visible: visible ?? !c.visible }));
      const currentColumn = newColumns.find(newCol => newCol.dataKey === key);
      const localStorageColumns = loadFromLocalStorage(`${tableName}ColumnsVisibility`, 'array');
      const currentLocalStorageColumn = localStorageColumns.find(col => col.columnName === key);
      if (currentLocalStorageColumn) {
        currentLocalStorageColumn.visibleColumn = !currentColumn || currentColumn.visible;
      } else {
        localStorageColumns.push({ columnName: key, visibleColumn: !currentColumn || currentColumn.visible });
      }
      saveToLocalStorage(`${tableName}ColumnsVisibility`, localStorageColumns);
      return newColumns;
    });
  };

  const formatValue = (value: any, format: FORMAT | undefined) => {
    switch (format) {
      case FORMAT.CURRENCY:
        return formatCurrency(value);
      case FORMAT.PERCENTAGE:
        return formatPercentage(value);
      case FORMAT.DATE:
        return formatDate(value);
      case FORMAT.CURRENCY_DASH:
        return value ? formatCurrency(value) : '-';
      case FORMAT.NUMBER:
        return value?.toFixed(2) || '-';
      default:
        return value;
    }
  };

  const formatValues = (data: any[], dataKey: DataIndex, format: FORMAT | undefined) => data.map((object: any) => {
    const newObject = { ...object };
    newObject[dataKey.toString()] = formatValue(newObject[dataKey.toString()], format);
    if (!_.isEmpty(newObject.children)) {
      newObject.children = formatValues(newObject.children, dataKey, format);
    }
    return newObject;
  });

  const formatDataSource = (dataSrc: any[]) => {
    let result = dataSrc;
    columns
      .flatMap((column: Column) => (column.children ? column.children : column))
      .filter((column: Column) => column.visible && column.format !== undefined && column.dataKey)
      .forEach((column: Column) => {
        result = formatValues(result, column.dataKey!, column.format);
      });
    return result;
  };

  const verticallyScrollable = useMemo(() => (props.verticallyScrollable !== undefined ? props.verticallyScrollable : true), [props.verticallyScrollable]);

  const [formattedDateSource, setFormattedDateSource] = useState<any[]>([]);
  useEffect(() => setFormattedDateSource(addUniqueKey(formatDataSource(dataSource))), [dataSource, columns]);


  const [expandedRowKeysInternal, setExpandedRowKeysInternal] = useState<string[] | number[]>([]);

  const [internalSelectedRowKeys, setInternalSelectedRowKeys] = useState<Key[]>([]);
  /**
   * TODO explanation comment
   */
  const selectedRowKeys = selectedRowKeysProp ?? internalSelectedRowKeys;
  const setSelectedRowKeys = onChangeSelectedRowKeysProp ?? setInternalSelectedRowKeys;

  const selectedRecords = useMemo(() => selectedRowKeys?.flatMap((key) => {
    const result = [];
    for (let i = 0; i < formattedDateSource.length; i += 1) {
      const item = formattedDateSource[i];
      if (item[rowKey] === key) {
        result.push(item);
      }
      if (item.children !== undefined) {
        const childWithMatchingKey = item.children.find(child => child[rowKey] === key);
        if (childWithMatchingKey !== undefined) {
          result.push(childWithMatchingKey);
        }
      }
    }
    return result;
  }) ?? [], [selectedRowKeys, formattedDateSource]);

  const rowSelection = useMemo(() => {
    if (supportBatchActions) {
      return {
        // TODO find out why this isn't working
        columnWidth: 56,
        hideSelectAll: !showHeaderCheckbox,
        selectedRowKeys,
        onSelect: (record: any, selected: boolean, allSelectedRows: any[]) => {
          allSelectedRows = allSelectedRows.filter(r => !!r); // sometimes contains undefined elements
          setSelectedRowKeys(allSelectedRows.map(r => r[rowKey]), record, selected);
          if (onRowSelected) {
            onRowSelected(record, selected, allSelectedRows);
          }
        },
        onSelectAll: (selected: boolean, allSelectedRows: any[]) => {
          allSelectedRows = allSelectedRows.filter(r => !!r); // sometimes contains undefined elements
          setSelectedRowKeys(allSelectedRows.map(r => r[rowKey]));
          if (onAllRowsSelected) {
            onAllRowsSelected(selected, allSelectedRows);
          }
        },
        getCheckboxProps: (record: any) => ({
          disabled: shouldDisableCheckbox ? shouldDisableCheckbox(record, selectedRecords) : false,
          className: `batch-checkbox ${selectedRecords?.length > 0 ? 'visible' : ''}`,
          ...(getCheckboxProps ? getCheckboxProps(record) : {}),
        }),
      };
    }
    return undefined;
    // selectedRowKeys doesn't have to be a dependency because selectedRecords already depends on it
  }, [supportBatchActions, selectedRecords, getCheckboxProps]);

  const onUnselectAll = () => {
    setSelectedRowKeys([]);
  };


  const [visibleColumns, setVisibleColumns] = useState<Array<ColumnType<any>>>([]);

  useEffect(() => {
    const visCol: ColumnType<any>[] = columns
      .map(column => (
        [Table.EXPAND_COLUMN, Table.SELECTION_COLUMN].includes(column)
          ? column
          : {
            ...column,
            children: column.children?.filter(c => c.visible),
          }
      ))
      .filter(column => (
        [Table.EXPAND_COLUMN, Table.SELECTION_COLUMN].includes(column)
        || (!column.children && column.visible)
        || (column.children && column.children.length! > 0)
      ))
      .map((column, index: number) => (
        [Table.EXPAND_COLUMN, Table.SELECTION_COLUMN].includes(column)
          ? column
          : {
            ...column,
            className: `${column.className}${column.children && index % 2 === 1 ? ' background-darker' : ''}`,
            children: column.children?.map(child => ({ ...child, className: `${child.className}${index % 2 === 1 ? ' background-darker' : ''}` })),
          }
      ));

    if (!hideColumnSelector) {
      // add options menu
      visCol.push({
        title: '',
        // expandable rows and fixed columns are not compatible
        width: LAST_COLUMN_WIDTH,
        className: 'option-cell',
        align: 'center',
        render: (text: string, record: any) => (
          <ActionsDropdown
            record={record}
            actions={actionsMenu || undefined}
            tableVerticallyScrollable={verticallyScrollable}
          />
        ),
      });
    }
    setVisibleColumns(visCol);
  }, [columns]);

  const dataSourceHasChildren = dataSource.some(record => record.children !== undefined);

  const rowClassName = (record: any, index: number) => {
    let className = props.rowClassName ? props.rowClassName(record, index) : '';
    if (onRow && onRow(record, index).onClick) {
      className += ' cursor-pointer';
    }
    if (dataSourceHasChildren && !record.children && selectedRecords?.length === 0) {
      className += ' expandable-indent';
    }
    return className;
  };


  const onExpandRow = (expanded: boolean, record: any) => {
    (setExpandedRowKeysProp ?? setExpandedRowKeysInternal)((oldList: string[] | number[]) => {
      const newList = oldList.slice();
      if (expanded) {
        // @ts-ignore
        newList.push(record[rowKey]);
      } else {
        // @ts-ignore
        const idx = _.findIndex(newList, k => _.isEqual(k, record[rowKey]));
        if (idx !== -1) {
          newList.splice(idx, 1);
        }
      }
      return newList;
    });
  };

  return {
    rowSelection,
    tableName,
    className,
    visibleColumns,
    dataSource: formattedDateSource,
    columns,
    changeColumnVisibility,
    onRow,
    contentLoading,
    showHeader,
    infiniteScrollerProps,
    expandedRowRender,
    expandedRowKeys: expandedRowKeysProp ?? expandedRowKeysInternal,
    setExpandedRowKeys: setExpandedRowKeysProp ?? setExpandedRowKeysInternal,
    expandRowByClick,
    onExpand: onExpandRow,
    verticallyScrollable: verticallyScrollable !== undefined ? verticallyScrollable : true,
    maxHeight,
    rowKey,
    rowClassName,
    actionsMenu,
    onUnselectAll,
    selectedRecords,
    setSelectedRowKeys: setInternalSelectedRowKeys,
    showOnlyCheckboxes,
    hideColumnSelector,
    virtualize: virtualize !== undefined ? virtualize : true,
    hideActionBar,
    parentHeight,
    parentComponent,
    emptyResultsComponent,
  };
}
