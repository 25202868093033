export const translations: any = {
  salutation: {
    en: 'Welcome',
    de: 'Willkommen',
  },
  navigationTiles: {
    comingSoon: {
      en: 'This feature will be released soon',
      de: 'Dieses Feature wird bald zur Verfügung stehen',
    },
    propertyData: {
      de: 'Objektdaten',
      en: 'Property Data',
    },
    accounting: {
      en: 'Accounting',
      de: 'Buchhaltung',
    },
    meetings: {
      en: 'Meetings',
      de: 'Versammlungen',
    },
    analytics: {
      en: 'Analytics',
      de: 'Analytics',
    },
    contacts: {
      en: 'Contacts',
      de: 'Kontakte',
    },
    communication: {
      en: 'Communication',
      de: 'Kommunikation',
    },
    messages: {
      en: 'Messages',
      de: 'Nachrichten',
    },
    documents: {
      en: 'Documents',
      de: 'Dokumente',
    },
    settings: {
      en: 'Settings',
      de: 'Einstellungen',
    },
    economicplan: {
      en: 'Economic plan',
      de: 'Wirtschaftsplan',
    },
    housemoneysettlement: {
      en: 'Housemoney Settlement',
      de: 'Hausgelabrechnung',
    },
    heatingcostdistribution: {
      en: 'Heating Cost Report',
      de: 'Wärmekostenabrechnung',
    },
    invoice: {
      en: 'Invoices',
      de: 'Rechnungen',
    },
    accountsheet: {
      en: 'Account Sheets',
      de: 'Kontensalden',
    },
    specialcontribution: {
      en: 'Special Contribution',
      de: 'Sonderumlage',
    },
    profitandloss: {
      en: 'Profit and Loss',
      de: 'Eigentümerbericht',
    },
  },
  notifications: {
    propertyLoadError: {
      en: 'Failed to load properties.',
      de: 'Objekten konnten nicht geladen werden.',
    },
  },
};
