import { AuthContext } from 'contexts/AuthContext';
import { getEnvVar } from 'lib/getEnvVar';
import { useFeatureSettings } from 'lib/useFeatureSettings';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

interface TallyEnvVar {
  formId: string;
  popupOptions?: PopupOptions;
}

// Declare Tally on the Window interface
declare global {
  interface Window {
    Tally?: {
      openPopup: (formId: string, options: PopupOptions) => void;
    };
  }
}

type PopupOptions = {
  key?: string;
  layout?: 'default' | 'modal';
  width?: number;
  alignLeft?: boolean;
  hideTitle?: boolean;
  overlay?: boolean;
  emoji?: {
    text: string;
    animation: 'none' | 'wave' | 'tada' | 'heart-beat' | 'spin' | 'flash' | 'bounce' | 'rubber-band' | 'head-shake';
  };
  autoClose?: number;
  showOnce?: boolean;
  doNotShowAfterSubmit?: boolean;
  customFormUrl?: string;
  hiddenFields?: {
    [key: string]: any,
  };
  onOpen?: () => void;
  onClose?: () => void;
  onPageView?: (page: number) => void;
  onSubmit?: (payload: any) => void;
};


const POPUP_OPTIONS: PopupOptions = {
  autoClose: 2000,
  showOnce: false,
  doNotShowAfterSubmit: true,
};


export const useSurveyNPS = () => {
  const [showedThisSession, setShowedThisSession] = useState(false);

  const { surveyFormEnabled } = useFeatureSettings();

  const location = useLocation();
  const authContext = useContext(AuthContext);
  // @ts-ignore
  const { domain, email, tokenType } = authContext?.data || {};


  const tallySurvey = getEnvVar('tallySurvey') as TallyEnvVar;
  const {
    formId,
    popupOptions: popupOptionOverrides,
  } = tallySurvey ?? {};

  useEffect(() => {
    if (
      (!surveyFormEnabled || !formId) // check if the feature is enabled and if there's a survey
      || (!domain || !email) // wait until auth context is ready
      || showedThisSession // don't show it every time they go to the dashboard
      || location.pathname !== '/dashboard' // only show on the dashboard
      || tokenType === 'ACCESS_TOKEN' // don't show for auditing users
    ) {
      return;
    }

    if (!window.Tally) {
      console.warn('No Tally object found');
      return;
    }


    window.Tally.openPopup(formId, {
      ...POPUP_OPTIONS,
      hiddenFields: {
        domain,
        email,
      },
      ...(popupOptionOverrides || {}),
    });

    setShowedThisSession(true);
  }, [surveyFormEnabled, domain, email, location.pathname, formId, popupOptionOverrides]);
};
