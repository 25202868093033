import { DocumentDtoSourceTypeEnum } from 'api/public';
import { FinancialDataAccessOptions } from './components/ModalShareAccountingData/useModalShareAccountingData';

export const accountsOverviewTranslations = {
  title: {
    en: 'Accounts Overview',
    de: 'Sachkonten',
  },
  expandAll: {
    en: 'Expand all',
    de: 'Alle Aufklappen',
  },
  collapseAll: {
    en: 'Collapse all',
    de: 'Alle zuklappen',
  },
  expand: {
    en: 'Expand',
    de: 'Aufklappen',
  },
  close: {
    en: 'Close',
    de: 'Zuklappen',
  },
  noBookings: {
    en: 'No bookings',
    de: 'Keine Buchungen',
  },
  book: {
    en: 'Book',
    de: 'Buchen',
  },
  navigationBar: {
    groups: {
      overview: {
        en: 'Overview',
        de: 'Übersicht',
      },
    },
    houseMoneySettlement: {
      en: 'Incomes and Expenses',
      de: 'Einnahmen und Ausgaben',
    },
    wealthStatus: {
      en: 'Reserve funds and Results',
      de: 'Rücklagen und Ergebnisse',
    },
    debitorsCreditors: {
      en: 'Debitors/Creditors',
      de: 'Debitoren/Kreditoren',
    },
  },
  cards: {
    categories: {
      houseMoneySettlement: {
        en: 'Incomes and Expenses',
        de: 'Einnahmen und Ausgaben',
      },
      wealthStatus: {
        en: 'Reserve funds and Results',
        de: 'Rücklagen und Ergebnisse',
      },
      debitorsCreditors: {
        en: 'Debitors / Creditors',
        de: 'Debitoren / Kreditoren',
      },
    },
    total: {
      en: 'Total',
      de: 'Gesamt',
    },
    subCategories: {
      revenue: {
        en: 'Income',
        de: 'Einnahmen',
      },
      expense: {
        en: 'Expenses',
        de: 'Ausgaben',
      },
      liquidity: {
        en: 'Liquidity',
        de: 'Flüssige Mittel, Aktive RAP',
      },
      liability: {
        en: 'Liabilities',
        de: 'Verbindlichkeiten',
      },
      capital: {
        en: 'Capital',
        de: 'Rücklagen & Abrechnungsspitze',
      },
      openingStatus: {
        en: 'Opening Status',
        de: 'Eröffnungsbestände',
      },
      debitors: {
        en: 'Debitors',
        de: 'Debitoren',
      },
      creditors: {
        en: 'Creditors',
        de: 'Kreditoren',
      },
    },
  },
  dataExportModal: {
    labels: {
      of: {
        en: 'Of',
        de: 'Von',
      },
      timePeriod: {
        en: 'Time Period',
        de: 'Zeitspanne',
      },
      format: {
        en: 'Format',
        de: 'Format',
      },
      account: {
        en: 'Account(Multiple selection)',
        de: 'Sachkonto(Mehrfachauswahl)',
      },
      dateStart: {
        en: 'Date Start',
        de: 'Datum Start',
      },
      dateEnd: {
        en: 'Date End',
        de: 'Datum Ende',
      },
    },
    selectInputOfOptions: {
      allAccounts: {
        en: 'All Accounts',
        de: 'Alle Konten',
      },
      individualAccounts: {
        en: 'Individual account(s)',
        de: 'Einzelne Konten',
      },
    },
    selectInputTimePeriodOptions: {
      currentEconomicYear: {
        en: 'Current Economic Year',
        de: 'Aktuelles Wirtschaftsjahr',
      },
      previousYear: {
        en: 'Previous Year',
        de: 'Vorheriges Wirtschaftsjahr',
      },
      custom: {
        en: 'Custom',
        de: 'Benutzerdefiniert',
      },
    },
  },
  table: {
    groupHeaders: {
      current: {
        en: 'Current Year',
        de: 'Laufendes Jahr',
      },
      comparison: {
        en: 'Comparison',
        de: 'Vergleich',
      },
      difference: {
        en: 'Difference in %',
        de: 'Abweichung in %',
      },
    },
    headers: {
      code: {
        en: 'Account Nr',
        de: 'Kontonummer',
      },
      name: {
        en: 'Account Title',
        de: 'Kontobezeichnung',
      },
      currentYear: {
        en: 'Current year',
        de: 'Laufendes Jahr',
      },
      debitSum: {
        en: 'Debit',
        de: 'Soll',
      },
      creditSum: {
        en: 'Credit',
        de: 'Haben',
      },
      balance: {
        en: 'Balance',
        de: 'Saldo',
      },
      prevBalance: {
        en: 'Prev. Year',
        de: 'Vorjahr',
      },
    },
  },
  shareAccountingDataModal: {
    title: {
      en: 'Share accounting data',
      de: 'Buchhaltungsdaten teilen',
    },
    cancelButton: {
      en: 'Close',
      de: 'Zuklappen',
    },
    subtitle: {
      en: 'Provide all accounting information for the audit process in an online portal with a secure link.',
      de: 'Stellen Sie alle Buchhaltungsinformationen für den Prüfungsprozess in einem Online-Portal mit einem sicheren Link bereit.',
    },
    linkExpirationDate: {
      en: 'Link expiration date',
      de: 'Ablaufdatum des Links',
    },
    linkExpirationDateTooltip: {
      en: 'Due to security considerations the link must expire after a certain period. By default access expires after 30 days and may not be longer than 60 days.',
      de: 'Aus Sicherheitsgründen muss der Link nach einer bestimmten Zeit ablaufen. Standardmäßig verfällt der Zugriff nach 30 Tagen und darf nicht länger als 60 Tage verbleiben.',
    },
    financialDataAccess: {
      en: 'Read-only access to accounting data',
      de: 'Lesezugriff auf Buchhaltungsdaten',
    },
    financialDataAccessTooltip: {
      en: 'Select if you wish to limit the access of the user to a specific timeframe and specific documents.',
      de: 'Wählen Sie, ob Sie den Zugriff des Nutzers auf bestimmten Zeitraum und bestimmte Dokumente begrenzen wollen.',
    },
    financialDataAccessOptions: {
      [FinancialDataAccessOptions.UNLIMITED]: {
        title: {
          en: 'Unlimited',
          de: 'Unbegrenzt',
        },
        details: {
          en: 'The user has read-only access to all the bookings and documents.',
          de: 'Der Nutzer hat Lesezugriff auf alle Buchungen und Dokumente.',
        },
      },
      [FinancialDataAccessOptions.LIMITED]: {
        title: {
          en: 'Limited',
          de: 'Begrenzt',
        },
        details: {
          en: 'The user has read-only access only to accounting data of a selected time period and selected documents.',
          de: 'Der Nutzer hat Lesezugriff nur auf bestimmten Buchhaltungsdaten aus einem Zeitraum und nur ausgewählte Dokumente.',
        },
      },
    },
    timeframeForSharedDate: {
      title: {
        en: 'Timerange of bookings',
        de: 'Zeitraum der Buchungen',
      },
      tooltip: {
        en: 'The users will be limited to viewing bookings within this timeframe.',
        de: 'Der Nutzer wird auf Buchungen innerhalb dieses Zeitraums begrenzt.',
      },
    },
    sharedDocuments: {
      title: {
        en: 'Shared documents',
        de: 'Geteilte Dokumente',
      },
      tooltip: {
        en: 'Only selected documents will be shared and the invocies belonging to the bookings. Currently only documents from impower processes can be selected.',
        de: 'Nur gewählte Dokumente werden geteilt und die Rechnungen der Buchungen. Derzeit sind nur Dokumente aus Impower-Prozessen auswählbar.',
      },
    },
    copyLinkButton: {
      en: 'Copy link',
      de: 'Link kopieren',
    },
    copiedButton: {
      en: 'Copied',
      de: 'Kopiert',
    },
    sendEmailButton: {
      en: 'Send email',
      de: 'E-Mail senden',
    },
  },
  shareButton: {
    en: 'Share',
    de: 'Teilen',
  },
  notifications: {
    error: {
      [DocumentDtoSourceTypeEnum.HEATING_COST_DISTRIBUTION]: {
        en: 'Failed to load wkas',
        de: 'WKA konnte nicht geladen werden.',
      },
      [DocumentDtoSourceTypeEnum.HOUSE_MONEY_SETTLEMENT]: {
        en: 'Failed to load house money settlement years',
        de: 'Fehler beim Laden des Hausgeldabrechnungsjahrs',
      },
      [DocumentDtoSourceTypeEnum.ECONOMIC_PLAN]: {
        en: 'Failed to load economic plans.',
        de: 'Fehler beim Laden der Wirtschaftspläne.',
      },
      [DocumentDtoSourceTypeEnum.SPECIAL_CONTRIBUTION]: {
        en: 'Failed to load Special Contributions.',
        de: 'Fehler beim Laden der Sonderbeiträge.',
      },
    },
    generateTokenError: {
      en: 'Failed to generate the link. Please check your input and try again.',
      de: 'Fehler beim Generieren des Links. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.',
    },
    copyLinkError: {
      en: 'Failed to copy the link.',
      de: 'Fehler beim Kopieren des Links.',
    },
    sendEmailError: {
      en: 'Failed to send the email.',
      de: 'Fehler beim Senden der E-Mail.',
    },
  },
};
