import { useContext, useState } from 'react';
import FileSaver from 'file-saver';
import { DocumentCreateDtoOcrStateEnum, DocumentCreateDtoTagCodeEnum, DocumentLegacyControllerApi } from 'api/document';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import backend, { endpointUrls } from '../../../backend_api';
import { extractDocumentIdFromUrl, extractFileName } from '../../../lib/Utils';
import { showNotification } from '../../../lib/Notification';
import { AuthContext } from '../../../contexts/AuthContext';

export const SERIAL_LETTER = 'SERIAL_LETTER';

export function useFileUpload(initialValue: string, documentSourceType?: string, ocrStateParam?: DocumentCreateDtoOcrStateEnum) {
  const [value, setValue] = useState(initialValue);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document'));

  const { tl } = useContext(LanguageContext);

  function onUploadError(error: any) {
    setUploading(false);
    let description = tl(translations.elements.fileUpload.uploadError.description);
    if (error?.detail === 'WRONG DIMENSIONS') {
      description += tl(translations.elements.fileUpload.uploadError.wrongDimensions);
    }
    if (error?.detail === 'UNSUPPORTED_MEDIA_TYPE') {
      description += tl(translations.elements.fileUpload.uploadError.unsupportedFormat);
    }
    showNotification({
      key: 'fileUploadError',
      message: tl(translations.elements.fileUpload.uploadError.message),
      description,
      type: 'error',
    });
  }

  const extractDocumentId = (response: any) => {
    if (response && response !== '') {
      return response.id;
    }
    return undefined;
  };


  const onDeleteFile = () => setValue('');
  const onChange = (event: any, onSuccess: (name: string, documentId?: number) => void, propertyId?: number,
    documentTag?: DocumentCreateDtoTagCodeEnum, unitContractId?: number, economicYear?: number) => {
    if (event.target.files && event.target.files[0]) {
      // remove extension from filename
      const fileName = event.target.files[0].name;
      const fileReader = new FileReader();
      fileReader.addEventListener('error', (error) => {
        onUploadError(error);
      });

      fileReader.addEventListener('load', (e: any) => {
        fetch(e.target.result)
          .then((resp) => {
            resp.blob()
              .then((blob) => {
                const additionalData = {
                  sourceType: documentSourceType,
                  propertyId,
                  economicYear,
                  name: fileName,
                  tagCode: documentTag,
                  contractId: unitContractId,
                  ocrState: ocrStateParam || DocumentCreateDtoOcrStateEnum.NO_OCR,
                };

                let url = `${endpointUrls.DOCUMENT}/upload`;

                if (SERIAL_LETTER === documentSourceType) {
                  url = `${endpointUrls.DOCUMENT}/upload-for-letter`;
                }

                const f = new File([blob], fileName, { type: blob.type });

                backend.postFile(url, f, additionalData)
                  .then((docResp) => {
                    setValue(docResp.url);
                    setUploading(false);
                    onSuccess(docResp.url, extractDocumentId(docResp));
                  })
                  .catch((error) => {
                    onUploadError(error);
                  });
              })
              .catch((error) => {
                onUploadError(error);
              });
          })
          .catch((error) => {
            onUploadError(error);
          });
      });
      setUploading(true);
      fileReader.readAsDataURL(event.target.files[0]);
    }
  };
  const onDownload = (filePath: string, fileName?: string) => {
    backend.getFile(filePath)
      .then(async (resp: any) => {
        const blob = new Blob([resp], { type: 'application/pdf' });
        if (fileName !== undefined) {
          FileSaver.saveAs(blob, fileName);
          return;
        }
        let name = extractFileName(filePath);
        if (!name) {
          const documentId = extractDocumentIdFromUrl(filePath);
          if (documentId) {
            const { name: documentName } = await documentControllerApi.getDocumentByIdUsingGET({ documentId });
            name = documentName;
          }
        }
        if (!name.includes('.pdf')) {
          if (name.substr(-1) === '.') name += 'pdf';
          else name += '.pdf';
        }
        FileSaver.saveAs(blob, name);
      })
      .catch(() => {
        showNotification({
          key: 'fileDownloadError',
          message: tl(translations.elements.fileUpload.downloadError.message),
          type: 'error',
        });
      });
  };
  const onDisplayModal = () => setShowModal(true);
  const onHideModal = () => setShowModal(false);
  return {
    value, uploading, showModal, onChange, onDeleteFile, onDownload, onDisplayModal, onHideModal,
  };
}
