import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Icon from '@ant-design/icons';
import { Table } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import './AccountsOverview.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import { PropertyListContext } from 'contexts/PropertyListContext';
import {
  ISO_DATE_FORMAT,
  compareAccountCodes,
  formatCurrency, formatPercentage, getCurrentEconomicYear, getDateRangeForEconomicYear, renameKeyRecursively,
  round2dec,
} from 'lib/Utils';
import { AccountBalance, AccountsBalanceContext } from 'contexts/AccountsBalanceContext';
import { ExpandableCardsList } from 'elements/CustomElements/ExpandableCardsList/ExpandableCardsList';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import SmartTable from 'elements/SmartTable/SmartTable';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import moment from 'moment';
import * as H from 'history';
import { ICONS } from 'components/icons';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import Amount from 'storybook-components/typography/Amount/Amount';
import { useCurrentOverlayInfo } from 'components/OverlayRoute/services/useCurrentOverlayInfo';
import { isEmpty } from 'lodash';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import Page from 'storybook-components/layout/Page/Page';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { isAuditingUser } from 'lib/userPermissionUtils';
import { GlobalSearchContext } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { useAuditingGlobalFilterIsDateRangeLimited } from 'services/AuditingGlobalFilterStore/selectorsAuditingGlobalFilter';
import { useDomainConfiguration } from 'lib/useDomainConfiguration';
import { UseAccountsOverviewColumns } from './UseAccountsOverviewColumns';
import { getAccountCategories } from './AccountUtils';

import { useAutoOpenUnitAccountRow } from './services/useAutoOpenUnitAccountRow';
import { useAccountOverviewDataExportModal } from './components/useAccountOverviewDataExportModal';
import { AccountOverviewDataExportBody } from './components/AccountOverviewDataExportBody';
import RangeInput from '../../../elements/Inputs/RangeInput/RangeInput';
import { accountsOverviewTranslations } from './translations';
import ModalShareAccountingData from './components/ModalShareAccountingData/ModalShareAccountingData';

export interface TableViewData {
  accountCode: string;
  accountName: string;
  accountType: string;
  parentAccountCode?: string;
  debitSum: number;
  creditSum: number;
  balance: number;
  prevBalance: number;
  prevPercent?: number; // TODO: Remove optional once they are available
  economicPlanPercent?: number;
  economicPlanBalance?: number;
  children: any;
}

const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';

export function AccountsOverview() {
  const { tl } = useContext(LanguageContext);
  const location: H.Location<{ scrollToUnitAccountCode?: number }> = useLocation();
  const { auditingEnabled } = useDomainConfiguration();

  const {
    selectedProperty, setSelectedPropertyHrId, currentEconomicYear, previousEconomicYear,
  } = useContext(PropertyListContext);
  const {
    accountsBalances, resetFilter, filterState, setRangeValues, getAccountBalancesForAllAccountTypes,
  } = useContext(AccountsBalanceContext);

  const { setHideGlobalSearch } = useContext(GlobalSearchContext);
  const isDateRangeLimited = useAuditingGlobalFilterIsDateRangeLimited();

  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  const { dateRange } = filterState;
  const { search } = useLocation();
  const { economicYearStart } = selectedProperty.data || {};
  const { isOverlayOnTop } = useCurrentOverlayInfo();
  const [showShareModal, setShowShareModal] = useState(false);

  const urlSearchParams = new URLSearchParams(search);
  const history = useHistory();
  useAutoOpenUnitAccountRow({
    accountCode: location.state?.scrollToUnitAccountCode,
    loaded: accountsBalances.loaded && selectedProperty.loaded,
  });

  /**
   * Processed accounts data for views
   *
   * of the format:
   * {
   *  category: [
   *  {
   *    subCategory: [tableData]
   *  },
   * {
   * ...
   * }
   * ],
   * {..},
   * }
   */
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (propertyHrId) {
      setSelectedPropertyHrId(propertyHrId);
    }
    return () => {
      resetFilter();
      setSelectedPropertyHrId(undefined);
    };
  }, [propertyHrId]);

  useEffect(() => {
    if (isOverlayOnTop && isAuditingUser()) {
      setHideGlobalSearch(true);
    }
  }, [isOverlayOnTop]);

  useEffect(() => {
    const currentYear = filterState.dateRange[0]?.year() ? filterState.dateRange[0]?.year() : new Date().getFullYear();
    if (accountsBalances.loaded) {
      let newTableData: any = buildTableData(accountsBalances.data[currentYear], accountsBalances.data[currentYear - 1]);
      newTableData = renameKeyRecursively(newTableData, 'subAccounts', 'children');
      newTableData = categorizeAccounts(newTableData);
      setTableData(newTableData);
    }
  }, [accountsBalances]);

  useEffect(() => {
    if (isOverlayOnTop) {
      const startDateParam = urlSearchParams.get('startDate');
      const endDateParam = urlSearchParams.get('endDate');

      const economicYear = urlSearchParams.get('economicYear');
      const adjustedEconomicYear = economicYear ? getDateRangeForEconomicYear(economicYearStart, parseInt(economicYear, 10)) : getCurrentEconomicYear(economicYearStart);

      const economicYearStartDate = moment(adjustedEconomicYear.economicYearStart);
      const economicYearEndDate = moment(adjustedEconomicYear.economicYearEnd);

      // set the default value if the search params are undefined
      if (isEmpty(startDateParam) && isEmpty(endDateParam)) {
        setRangeValues([economicYearStartDate, economicYearEndDate]);
        return;
      }

      let startDate = moment(startDateParam);
      let endDate = moment(endDateParam);

      if (!filterState.dateRange?.[0] && !filterState.dateRange?.[1]) {
        if (!startDate.isValid()) {
          startDate = economicYearStartDate;
        }
        if (!endDate.isValid()) {
          endDate = economicYearEndDate;
        }

        setRangeValues([startDate, endDate]);
      }
    }
  }, [isOverlayOnTop]);
  /**
   *
   * @param tb Single array of table data
   * @returns arrays with the accounts sorted into categories
   */
  const categorizeAccounts = (tb: TableViewData[]): any => {
    const accountsByCategories: any = {};
    tb.forEach((element: TableViewData) => {
      // Skip over legacy accounts as they cannot be categorized
      if (element.accountCode && element.accountCode.indexOf('L') > -1) return;
      const category = getAccountCategories(element.accountCode);
      if (!category) {
        throw `Element cannot be categorized: ${element.accountCode}`;
      }

      if (accountsByCategories[category.mainCategory]) {
        if (accountsByCategories[category.mainCategory][category.subCategory]) {
          accountsByCategories[category.mainCategory][category.subCategory].push(element);
        } else {
          accountsByCategories[category.mainCategory][category.subCategory] = [element];
        }
      } else {
        accountsByCategories[category.mainCategory] = {};
        accountsByCategories[category.mainCategory][category.subCategory] = [element];
      }
    });
    return accountsByCategories;
  };

  /**
   * For each account in currentYear, add the balance from previous year
   * @param currentYear Accountbalances for current year
   * @param previousYear Accountbalances for previous year
   */
  const buildTableData = (currentYear: AccountBalance[] | undefined, previousYear: AccountBalance[] | undefined) => {
    if (!currentYear || !previousYear || currentYear.length !== previousYear.length) {
      return [];
    }

    const mergedArray: any[] = [];
    for (let i: number = 0; i < currentYear.length; i += 1) {
      if (currentYear[i].accountCode !== previousYear[i].accountCode) {
        throw `Accounts are not sorted consistently: ${JSON.stringify(currentYear[i])}, " --- ", ${JSON.stringify(previousYear[i])}`;
      }

      // Skip accounts that start with "6" or "8" and have both creditSum and debitSum as 0, as these accounts are not relevant for the report.
      if ((currentYear[i].accountCode.startsWith('6') || currentYear[i].accountCode.startsWith('8'))
        && currentYear[i].creditSum === 0 && currentYear[i].debitSum === 0
        && previousYear[i].balance === 0
        && isAuditingUser()) {
        continue;
      }

      let subAccountResults = null;
      if (currentYear[i].subAccounts) {
        subAccountResults = buildTableData(currentYear[i].subAccounts, previousYear[i].subAccounts);
      }

      mergedArray.push({
        ...currentYear[i],
        subAccounts: subAccountResults,
        debitSum: Math.abs(currentYear[i].debitSum),
        creditSum: Math.abs(currentYear[i].creditSum),
        prevBalance: previousYear[i].balance,
        prevDebit: Math.abs(previousYear[i].debitSum),
        prevCredit: Math.abs(previousYear[i].creditSum),
        prevPercent: previousYear[i].balance ? round2dec(((currentYear[i].balance - previousYear[i].balance) * 100) / previousYear[i].balance) : 0,
      });
    }
    if (mergedArray && mergedArray.length) {
      mergedArray.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode));
    }

    return mergedArray;
  };

  const reduceAccountSummary = (accumulator: any, currentValue: any) => {
    // balance = credit - debit because if income (mostly credit) > expense (mostly debit) it means the property is in profit
    // both credit and debit are "stored" as a positive amount
    const balance = round2dec(accumulator.balance + currentValue.creditSum - currentValue.debitSum);
    const prevBalance = round2dec(accumulator.prevBalance + currentValue.prevCredit - currentValue.prevDebit);

    return ({
      debitSum: accumulator.debitSum + currentValue.debitSum,
      creditSum: accumulator.creditSum + currentValue.creditSum,
      balance,
      prevBalance,
    });
  };

  const accountSummary = (category: any) => (tableData[category]
    ? [Object.values(tableData[category])].flat(2).reduce(reduceAccountSummary, {
      debitSum: 0,
      creditSum: 0,
      balance: 0,
      prevBalance: 0,
    })
    : {
      debitSum: 0,
      creditSum: 0,
      balance: 0,
      prevBalance: 0,
    });

  const expandableCardChildren = (category: any) => {
    if (tableData[category]) {
      return Object.keys(tableData[category]).map((subcategory: any) => (
        <div className="category-tables" key={`${subcategory}-card`}>
          <div className="table-title">{tl(accountsOverviewTranslations.cards.subCategories[subcategory])}</div>
          <OverviewCardTable subcategory={subcategory} key={subcategory} loading={accountsBalances.loading} tableData={tableData[category][subcategory]} />
        </div>
      ));
    }
    return <div />;
  };
  const expandableCardGroups = [
    {
      groupName: tl(accountsOverviewTranslations.navigationBar.groups.overview),
      expandableCards: [
        {
          loading: accountsBalances.loading,
          title: tl(accountsOverviewTranslations.cards.categories.houseMoneySettlement),
          navigationTitle: tl(accountsOverviewTranslations.navigationBar.houseMoneySettlement),
          children: expandableCardChildren('houseMoneySettlement'),
          category: 'houseMoneySettlement',
          summary: <OverviewHeader accountsSummary={accountSummary('houseMoneySettlement')} />,
          summaryOnBottom: false,
        },
        {
          loading: accountsBalances.loading,
          title: tl(accountsOverviewTranslations.cards.categories.wealthStatus),
          navigationTitle: tl(accountsOverviewTranslations.navigationBar.wealthStatus),
          children: expandableCardChildren('wealthStatus'),
          category: 'wealthStatus',
          summary: <OverviewHeader accountsSummary={accountSummary('wealthStatus')} />,
          summaryOnBottom: false,
        },
        {
          loading: accountsBalances.loading,
          title: tl(accountsOverviewTranslations.cards.categories.debitorsCreditors),
          navigationTitle: tl(accountsOverviewTranslations.navigationBar.debitorsCreditors),
          children: expandableCardChildren('debitorsCreditors'),
          category: 'debitorsCreditors',
          summary: <OverviewHeader accountsSummary={accountSummary('debitorsCreditors')} />,
          summaryOnBottom: false,
        },
      ],
    },
  ];

  const dataExportModalProps = useAccountOverviewDataExportModal({
    currentEconomicYear,
    previousEconomicYear,
    startDateProp: dateRange[0],
    endDateProp: dateRange[1],
  });

  const {
    setStartDate, setEndDate, showModal, onClickDownload, onClickPrint,
  } = dataExportModalProps;

  useEffect(() => {
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  }, [dateRange]);

  useEffect(() => {
    if (filterState.dateRange && filterState.dateRange[0] && filterState.dateRange[1] && selectedProperty.data?.propertyHrId && selectedProperty.data?.propertyHrId === propertyHrId) {
      getAccountBalancesForAllAccountTypes(new Date(moment(filterState.dateRange![0]).format(REQUEST_DATE_FORMAT)), new Date(moment(filterState.dateRange![1]).format(REQUEST_DATE_FORMAT)));
    }
  }, [filterState.dateRange?.[0], filterState.dateRange?.[1], selectedProperty.data?.propertyHrId]);

  const accountOverviewDataExportModalProps = useMemo(
    () => ({
      currentEconomicYear,
      previousEconomicYear,
      accountsBalances,
      dataExportModalProps,
      onClickDownload: () => {
        onClickDownload(propertyHrId, filterState.includeCorrectional);
      },
      onClickPrint: () => {
        onClickPrint(propertyHrId, filterState.includeCorrectional);
      },
    }),
    [currentEconomicYear, previousEconomicYear, accountsBalances, dataExportModalProps, dateRange, propertyHrId, filterState.includeCorrectional],
  );

  const downloadButtonDisabled = accountsBalances.loading || accountsBalances.error || selectedProperty.loading || selectedProperty.error;

  const headerButtons = (
    <div className="header-buttons">
      <Button className="download-button" disabled={downloadButtonDisabled} onClick={showModal}>
        <Icon component={ICONS.download} />
      </Button>
      {!isAuditingUser() && (
        <>
          {auditingEnabled && <Button onClick={() => setShowShareModal(true)}>{tl(accountsOverviewTranslations.shareButton)}</Button>}
          <Button className="book-button" type="primary" onClick={() => history.push(`${location.pathname}/booking/create`)}>
            {tl(accountsOverviewTranslations.book)}
          </Button>
        </>
      )}
    </div>
  );

  return (
    <Page className="sachkonten">
      <PageHeader title={tl(accountsOverviewTranslations.title)} subtitle={<PropertyInfo property={selectedProperty.data} />} rightSideComponent={headerButtons} />
      <PageContent className="overlay-container">
        {accountsBalances.loading || selectedProperty.loading ? <LoadingScreen /> : <ExpandableCardsList expandableCardGroups={expandableCardGroups} headerChildren={<RangeInput label="" className="input-small" onChange={setRangeValues} value={dateRange} disabled={isDateRangeLimited} />} />}
        <AccountOverviewDataExportBody {...accountOverviewDataExportModalProps} />
        {auditingEnabled && <ModalShareAccountingData visible={showShareModal} onCancel={() => setShowShareModal(false)} />}
      </PageContent>
    </Page>
  );
}

function OverviewCardTable({ tableData, loading, subcategory }: { tableData: TableViewData[], loading: boolean, subcategory: string }) {
  const location = useLocation();
  const history = useHistory();
  const { search } = useLocation();

  /**
   * Replaces empty list children with null for proper display in tree
   * @param children
   */
  const accountChildrenOrNull = (children: any) => (children?.length > 0 ? children.map((child: any) => (
    {
      ...child,
      children: accountChildrenOrNull(child.children),
    }
  )) : null);

  const dataSource = useMemo(() => tableData.map(account => ({
    ...account,
    children: accountChildrenOrNull(account.children),
  })), [tableData]);

  const urlSearchParams = new URLSearchParams(search);
  const startDateParam = moment(urlSearchParams.get('startDate')).format(ISO_DATE_FORMAT);
  const endDateParam = moment(urlSearchParams.get('endDate')).format(ISO_DATE_FORMAT);

  const expensesTable = useSmartTable({
    tableName: subcategory,
    onRow: (record: any) => ({
      onClick: (e) => {
        e.stopPropagation();
        const navigationPath = `/account/${encodeURIComponent(record.accountCode)}`;
        const navigationSearchQuery = `?startDate=${startDateParam}&endDate=${endDateParam}`;

        if (location.pathname.includes(navigationPath) && location.search.includes(navigationSearchQuery)) {
          return;
        }
        history.push(`${location.pathname}${navigationPath}${navigationSearchQuery}`);
      },
    }),
    columns: UseAccountsOverviewColumns(),
    dataSource,
    contentLoading: loading,
    verticallyScrollable: false,
    virtualize: false,
    rowKey: 'accountCode',
  });
  return <SmartTable withBorder {...expensesTable} />;
}

function OverviewHeader(props: any) {
  const { accountsSummary } = props;
  const [headerData, setHeaderData] = useState({});

  const { tl } = useContext(LanguageContext);

  useEffect(() => {
    const newHeaderData = {
      ...Object.keys(accountsSummary).reduce((acc: any, key: any) => {
        acc[key] = formatCurrency(accountsSummary[key]);
        return acc;
      }, {}),
      creditSum: formatCurrency(accountsSummary.creditSum),
      prevBalancePercent: formatPercentage(accountsSummary.prevBalance ? ((accountsSummary.balance - accountsSummary.prevBalance) * 100) / accountsSummary.prevBalance : 0),
      economicPlan: formatCurrency(0),
      economicPlanPercent: formatPercentage(0),
    };
    setHeaderData(newHeaderData);
  }, [accountsSummary]);

  const columns = [
    {
      title: tl(accountsOverviewTranslations.table.headers.debitSum),
      dataIndex: 'debitSum',
      key: 'debitSum',
      width: '20%',
      render: value => <Amount>{value}</Amount>,
    },
    {
      title: tl(accountsOverviewTranslations.table.headers.creditSum),
      dataIndex: 'creditSum',
      key: 'creditSum',
      width: '20%',
      render: value => <Amount>{value}</Amount>,
    },
    {
      title: tl(accountsOverviewTranslations.table.headers.balance),
      dataIndex: 'balance',
      key: 'balance',
      width: '20%',
      render: value => <Amount>{value}</Amount>,
    },
    {
      title: tl(accountsOverviewTranslations.table.headers.prevBalance),
      dataIndex: 'prevBalance',
      key: 'prevBalance',
      width: '20%',
      render: value => <Amount>{value}</Amount>,
    },
    {
      title: tl(accountsOverviewTranslations.table.headers.prevBalance),
      dataIndex: 'prevBalancePercent',
      key: 'prevBalance-comparison',
      width: '20%',
      render: value => <Amount>{value}</Amount>,
    },
  ];
  return (
    <div className="summary-row">
      <div className="total">{tl(accountsOverviewTranslations.cards.total)}</div>
      <Table className="account-overview-header-table" columns={columns} dataSource={[headerData]} pagination={false} rowKey={() => '1'} />
    </div>
  );
}
