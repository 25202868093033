import Heading from 'storybook-components/typography/Heading/Heading';
import './TitleAndSubtitle.scss';

interface TitleAndSubtitleProps {
    title: string;
    subtitle?: string;
  }

const TitleAndSubtitle: React.FC<TitleAndSubtitleProps> = ({ title, subtitle }) => (
  <div>
    <Heading level={1} className="page-title">
      {title}
    </Heading>
    {subtitle && (
      <Heading level={2} className="page-subtitle">
        {subtitle}
      </Heading>
    )}
  </div>
);

export default TitleAndSubtitle;
