import React, { useContext } from 'react';
import SimplePdfViewer from 'elements/PdfViewer/SimplePdfViewer';
import { LanguageContext } from 'contexts/LanguageContext';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';
import { translations } from 'pages/ProfitAndLossReport/translations';
import Page from 'storybook-components/layout/Page/Page';
import ErrorMessage from 'storybook-components/feedback/ErrorScreen/ErrorMessages/ErrorMessage';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import ProfitAndLossReportViewHeader from './components/ProfitAndLossReportHeader/ProfitAndLossReportViewHeader';
import ProfitAndLossReportContextProvider from './serivces/ProfitAndLossReportViewContext';
import { useInitializeProfitAndLossReportView } from './serivces/useInitializeProfitAndLossReportView';
import './ProfitAndLossReportView.scss';
import { useProfitAndLossReportView } from './serivces/useProfitAndLossReportView';


const ProfitAndLossReportView = (): JSX.Element => {
  const { tl } = useContext(LanguageContext);
  const { isGenerating, generationFailed } = useInitializeProfitAndLossReportView();

  const { url, filename } = useProfitAndLossReportView();

  if (generationFailed) {
    return (
      <Page className="ProfitAndLossReportView">
        <ProfitAndLossReportViewHeader />
        <ErrorMessage message={(
          <AlertArticleLink
            label={tl(translations.notifications.generationErrorMessage)}
            content={tl(translations.notifications.contactSupport)}
            url={articleUrls.contactSupport}
          />
        )}
        />
      </Page>
    );
  }

  return (
    <Page className="ProfitAndLossReportView">
      <ProfitAndLossReportViewHeader />
      {isGenerating
        ? <LoadingScreen title={tl(translations.notifications.generatingReportLoadingMessage.title)} subtitle={tl(translations.notifications.generatingReportLoadingMessage.subtitle)} />
        : <SimplePdfViewer url={url} filename={filename} renderTextLayer disableCache/>}
    </Page>
  );
};

const ProfitAndLossReportViewWithProvider = () => (
  <ProfitAndLossReportContextProvider>
    <ProfitAndLossReportView />
  </ProfitAndLossReportContextProvider>
);

export default ProfitAndLossReportViewWithProvider;
