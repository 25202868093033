import { produce } from 'immer';
import { StoreApi } from 'zustand';
import { PropertyDisplayDto } from 'api/accounting';
import { AuditingGlobalFiltersStore } from './interfacesAuditingGlobalFilter';
import { initialGlobalFilter } from './storeAuditingGlobalFilter';

/* not declared with the rest of interfaces because these are just helper types to avoid long lines */
type Set = StoreApi<AuditingGlobalFiltersStore>['setState'];

export const onChangeGlobalFilters = <K extends keyof AuditingGlobalFiltersStore['globalFilters']>(set: Set, key: K, value: any) =>
  set((state) =>
    produce(state, (draft) => {
      /* Set the value for the key */
      draft.globalFilters[key] = value;
    }),
  );

export const onInitializeGlobalFilters = (set: Set, queryParamFilter: string, propertyIds: number[]) =>
  set((state) =>
    produce(state, (draft) => {
      try {
        // Decode Base64 filter
        const decodedFilters = queryParamFilter ? JSON.parse(atob(queryParamFilter)) : {};

        draft.globalFilters = {
          propertyIds,
          ...decodedFilters,
        };

        // Persist in localStorage
        localStorage.setItem('globalFilters', JSON.stringify(draft.globalFilters));
      } catch (error) {
        console.warn('Failed to decode queryParamFilter', queryParamFilter, error);
      }
    }),
  );

export const onChangeLoadedData = <K extends keyof AuditingGlobalFiltersStore['loadedData']>(set: Set, key: K, properties: PropertyDisplayDto[]) =>
  set((state) =>
    produce(state, (draft) => {
      draft.loadedData[key] = properties;
    }),
  );

export const onClearStore = (set: Set) =>
  set((state) =>
    produce(state, (draft) => {
      draft.loadedData.properties = [];
      draft.globalFilters = initialGlobalFilter;
      localStorage.setItem('globalFilters', JSON.stringify({}));
    }),
  );
