import {
  DocumentCreateDtoOcrStateEnum, DocumentCreateDtoSourceTypeEnum, DocumentProjectionDto, DocumentProjectionDtoSourceTypeEnum,
} from 'api/document';
import { DocumentDto, DocumentDtoSourceTypeEnum } from 'api/public';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { LanguageContext } from 'contexts/LanguageContext';
import { useFileUpload } from 'elements/Inputs/FileUpload/UseFileUpload';
import { Action } from 'elements/SmartTable/data';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { isNil } from 'lodash';
import { FileUploadButton } from 'pages/Import/ImportEditor/components/FileUploadButton/FileUploadButton';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import { isAuditingUser } from 'lib/userPermissionUtils';
import { getDocumentEditorRoute } from '../routes';
import './DocumentList.scss';
import {
  DocumentListContextProvider,
  DocumentListEntry,
} from './services/DocumentListContext';
import useDocumentList from './services/useDocumentList';
import { useDocumentListColumns } from './services/useDocumentListColumns';
import { useDocumentsListFilters } from './services/useDocumentsListFilter';
import { useDocumentsListSort } from './services/useDocumentsListSort';
import { translations } from './translations';

const DocumentList = (): JSX.Element => {
  const { tl } = useContext(LanguageContext);
  const history = useHistory();

  const { onChange } = useFileUpload(
    '',
    DocumentCreateDtoSourceTypeEnum.OTHER,
    DocumentCreateDtoOcrStateEnum.OCR_REQUESTED,
  );
  const {
    documentList,
    onLoadDocumentList,
    onDownloadSingleDocument,
    onDownloadZip,
    onDeleteDocument,
  } = useDocumentList();
  const { sortField, sortOrder, onChangeSort } = useDocumentsListSort();
  const {
    filters,
    filterState,
    onSetDefaultFilterFromQueryParams,
    onChangeFilterState,
  } = useDocumentsListFilters();

  const { columns } = useDocumentListColumns();

  useGlobalSearch({
    key: 'documents',
    filterProps: {
      availableFilters: filters,
      setFilter: onChangeFilterState,
    },
    queryParamAsFilter: {
      filterState,
      onSetDefaultFilterFromQueryParams,
    },
  });

  const actionsMenu: Action[] = [
    {
      label: tl(translations.actions.openSource),
      onAction: (record: DocumentListEntry) => {
        window.open(record.sourceProcessUrl, '_blank');
      },
      actionSupported: (record: DocumentListEntry) => !isAuditingUser() && (!isNil(record.sourceId) || DocumentProjectionDtoSourceTypeEnum.PROFIT_AND_LOSS === record.sourceType),
    },
    {
      label: tl(translations.actions.download),
      onAction: (record: DocumentDto) => {
        onDownloadSingleDocument(record.id!);
      },
      onBatchAction: (records: DocumentDto[]) => {
        const documentIds: number[] = records.map((record) => record.id!);
        onDownloadZip(documentIds);
      },
      actionSupported: (record: DocumentDto) => !isNil(record.id),
    },
    {
      label: tl(translations.actions.delete),
      onAction: (record: DocumentDto) => {
        onDeleteDocument(record.id);
      },
      actionSupported: (record: DocumentDto) => isNil(record?.sourceType),
    },
  ];

  const documentTable = useSmartTable({
    rowKey: 'id',
    tableName: 'DocumentsListTable',
    columns,
    dataSource: documentList.data,
    contentLoading: documentList.loading,
    infiniteScrollerProps: {
      hasMoreData: !documentList.lastPage,
      loadMoreData: onLoadDocumentList,
    },
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) =>
        onChangeSort(dataKey as keyof DocumentDto),
    },
    onRow: (record) => ({
      onClick: () => {
        if (DocumentDtoSourceTypeEnum.BANK_ORDER !== record?.sourceType) {
          history.push(getDocumentEditorRoute(record.id));
        }
      },
    }),
    supportBatchActions: true,
    actionsMenu,
  });

  const headerRightSideComponent = (
    <>
      {!isAuditingUser() && (
        <FileUploadButton
          type="primary"
          label={tl(translations.header.rightSideComponent.uploadButton)}
          onChange={(e) =>
            onChange(e, (_, documentId?: number) => {
              history.push(`${getDocumentEditorRoute(documentId)}?editMode=true`);
            })
          }
          accept="application/pdf"
          showIcon={false}
        />
      )}
    </>
  );

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'documents-overview',
    navItems: subcategorySwitcherItems.documents,
  };

  return (
    <div className="DocumentList page">
      <MainPageHeader
        rightSideComponent={headerRightSideComponent}
        subcategorySwitcherProps={subcategorySwitcherProps}
      />
      <SmartTable {...documentTable} />
    </div>
  );
};


const DocumentListWithProvider = () => (
  <DocumentListContextProvider>
    <DocumentList />
  </DocumentListContextProvider>
);

export default DocumentListWithProvider;
