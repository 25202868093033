import { useContext } from 'react';
import { DECIMAL_MATCHER } from 'lib/Utils';
import { Filter } from '../../../../components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import useDefaultPropertyFilters from '../../../../components/Header/components/data/useDefaultPropertyFilters';
import { translations } from '../../translations';
import { GetAllocationGroupsUsingGETDirectionEnum, BankTransactionProjectionDtoTransactionPaymentStatusEnum } from '../../../../api/accounting';
import usePropertyIbanAndCounterpartIbanFilters from './usePropertyIbanAndCounterpartIbanFilters';
import { usePropertyAdministrationTypeFilterOption } from 'services/usePropertyAdministrationTypeFilterOption/usePropertyAdministrationTypeFilterOption';


export default function useBankTransactionFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);
  const administrationTypeFilterOption = usePropertyAdministrationTypeFilterOption();

  const stateOptions = Object.values(BankTransactionProjectionDtoTransactionPaymentStatusEnum).map(value => ({
    label: tl(translations.statuses[value]),
    value,
  }));

  const directions = [
    {
      label: tl(translations.directions.outgoing),
      value: GetAllocationGroupsUsingGETDirectionEnum.OUTGOING,
    },
    {
      label: tl(translations.directions.incoming),
      value: GetAllocationGroupsUsingGETDirectionEnum.INCOMING,
    },
  ];
  return [
    {
      type: 'enum',
      name: tl(translations.table.status),
      key: 'transactionStatuses',
      options: stateOptions,
      multiValue: true,
    },
    administrationTypeFilterOption,
    {
      type: 'text',
      name: tl(translations.table.internalNr),
      key: 'propertyIdInternal',
    },
    {
      type: 'text',
      name: tl(translations.table.name),
      key: 'propertyName',
    },
    {
      type: 'text',
      name: tl(translations.table.accountantName),
      key: 'accountantName',
    },
    {
      type: 'text',
      name: tl(translations.table.statement),
      key: 'statement',
    },
    {
      type: 'text',
      name: tl(translations.table.administrator),
      key: 'supervisorName',
    },
    {
      type: 'decimal',
      name: `Min ${tl(translations.table.amount)}`,
      matcher: DECIMAL_MATCHER,
      key: 'minAmount',
    },
    {
      type: 'decimal',
      name: `Max ${tl(translations.table.amount)}`,
      matcher: DECIMAL_MATCHER,
      key: 'maxAmount',
    },
    {
      type: 'decimal',
      name: tl(translations.table.amount),
      matcher: DECIMAL_MATCHER,
      key: 'amount',
    },
    {
      type: 'enum',
      name: tl(translations.searchBar.filter.direction),
      key: 'direction',
      options: directions,
      multiValue: false,
    },
    {
      type: 'date',
      name: tl(translations.searchBar.filter.fromDate),
      key: 'fromDate',
    },
    {
      type: 'date',
      name: tl(translations.searchBar.filter.untilDate),
      key: 'untilDate',
    },
    ...usePropertyIbanAndCounterpartIbanFilters(),
  ];
}
