import React, {
  createRef, ReactNode, useContext, useEffect, useState,
} from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { GetComponentProps, AlignType, RenderExpandIconProps } from 'rc-table/lib/interface';
import { Key, TableRowSelection } from 'antd/lib/table/interface';
import { useVT } from 'virtualizedtableforantd4';
import InfiniteScroller from '../InfiniteScroller/InfiniteScroller';
import './SmartTable.scss';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../Translation/translations';
import ResizeableColumn from '../../storybook-components/table/ResizableColumn/ResizableColumn';
import LegacyActionBar from '../../components/LegacyActionBar/LegacyActionBar';
import { Action } from './data';
import ColumnSelector from './components/ColumnSelector/ColumnSelector';
import { StickyLastRow } from './components/StickyRow/StickyLastRow';
import { StickyFirstRow } from './components/StickyRow/StickyFirstRow';
import { StickyRowDataType } from './components/StickyRow/StickyRowInterface';
import TableContentLoadingIndicator from './components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { RowExpandIcon } from './components/RowExpandIcon/RowExpandIcon';
import useEvent from '../../lib/useEvent';

export type ExpandedRowRender = (record: any, index: number, indent: number, expanded: boolean) => React.ReactNode;
export declare type DataIndex = string | number | readonly (string | number)[];

export enum FORMAT { CURRENCY = 'CURRENCY', PERCENTAGE = 'PERCENTAGE', DATE = 'DATE', CURRENCY_DASH = 'CURRENCY_DASH', NUMBER = 'NUMBER' }

export const ROW_HEIGHT = 50;
export const LAST_COLUMN_WIDTH = 50;
export const FIRST_COLUMN_WIDTH = 100;

export interface InfiniteScrollerProps {
  loadMoreData: () => void,
  hasMoreData: boolean
}

export interface Column {
  titleString?: string
  title?: string | ReactNode
  dataKey?: DataIndex
  alwaysVisible?: boolean
  sortable?: boolean
  onClick?: () => void
  // fixed?: 'right' | 'left'
  className?: string
  colSpan?: number
  width?: number | string | undefined
  defaultWidth?: number | string
  render?: (text: any, row: any, index: any) => any,
  visible?: boolean,
  // defaultVisible will take this value in case no other value is specified. This value will be overwritten as soon as there is something set in localStorage
  defaultVisible?: boolean,
  groupTitle?: string,
  children?: Column[],
  format?: FORMAT,
  disabled?: boolean,
  align?: AlignType,
}

export interface GroupHeader {
  title: string,
  className?: string,
  width: string | number | undefined
}

export interface SmartTableProps {
  key?: string,
  tableName: string,
  className?: string,
  dataSource: any[],
  withBorder?: boolean,
  rowKey: string,
  rowClassName?: (record: any, index: number) => string,
  visibleColumns: ColumnProps<any>[]
  columns?: Column[]
  hideColumnSelector?: boolean,
  changeColumnVisibility?: (key: DataIndex, visible?: boolean) => void,
  onRow?: GetComponentProps<any>
  contentLoading: boolean
  showHeader: boolean
  infiniteScrollerProps?: InfiniteScrollerProps
  expandedRowRender?: ExpandedRowRender
  onExpandedRowsChange?: (expandedRowKeys: Key[]) => void,
  expandedRowKeys?: string[] | number[],
  setExpandedRowKeys?: Function,
  onExpand?: (expanded: boolean, record: any) => void
  verticallyScrollable: boolean,
  maxHeight?: number,
  expandIconColumnIndex?: number,
  expandRowByClick?: boolean,
  actionsMenu?: Action[]
  onUnselectAll?: () => void,
  selectedRecords?: any[],
  setSelectedRowKeys?: Function,
  selectRow?: Function,
  showOnlyCheckboxes?: boolean,
  dataSourceChildren?: any[],
  virtualize?: boolean,
  hideActionBar?: boolean,
  heightOfElementsBelowTable?: number,
  stickyFirstRowData?: StickyRowDataType,
  stickyLastRowData?: StickyRowDataType,
  parentHeight?: number,
  parentComponent?: Element | null,
  emptyResultsComponent?: JSX.Element,
  rowSelection?: TableRowSelection<any>,
}


const getColumnsWidthRecursively = (columns: ColumnProps<any>[]): number => columns.flatMap(c => (c.children ? c.children : c))
  .reduce((widthSum, col) => widthSum + (col.width ? parseInt(`${col.width}`, 10) : 0), 0);

export default function SmartTable(props: SmartTableProps): JSX.Element {
  const {
    key,
    tableName,
    className: classNameProp,
    dataSource,
    stickyFirstRowData,
    stickyLastRowData,
    rowKey,
    rowClassName,
    visibleColumns,
    columns,
    hideColumnSelector,
    changeColumnVisibility,
    onRow,
    contentLoading,
    showHeader,
    infiniteScrollerProps,
    expandedRowRender,
    expandedRowKeys,
    onExpand,
    verticallyScrollable,
    maxHeight,
    expandIconColumnIndex,
    expandRowByClick,
    actionsMenu,
    onUnselectAll,
    selectedRecords,
    showOnlyCheckboxes,
    hideActionBar,
    heightOfElementsBelowTable,
    parentHeight,
    parentComponent,
    emptyResultsComponent,
    rowSelection,
    virtualize,
    withBorder,
  } = props;
  const { tl } = useContext(LanguageContext);

  const smartTableRef = createRef<HTMLDivElement>();
  const contentElement: any = document.querySelector(`.${tableName} .ant-table-body`) || {};

  const components = {
    header: {
      cell: ResizeableColumn,
    },
  };


  const addInfiniteScroller = ({ loadMoreData, hasMoreData }: InfiniteScrollerProps, children: any): JSX.Element => (
    <InfiniteScroller
      loadMore={loadMoreData}
      hasMore={hasMoreData}
      threshold={200}
      loading={contentLoading}
      contentElement={contentElement}
    >
      {children}
    </InfiniteScroller>
  );

  const [nestedHeader, setNestedHeader] = useState(false);
  useEffect(() => (setNestedHeader(!!visibleColumns.find(c => c.children))), [visibleColumns]);


  const calcTableHeight = () => {
    let verticalPosition: number = 0;
    if (smartTableRef.current) {
      verticalPosition = parentComponent ? smartTableRef.current.getBoundingClientRect().top - parentComponent.getBoundingClientRect().top
        : smartTableRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
    }

    if (maxHeight) return maxHeight;

    const headerHeight = 37;
    const containerHeight = parentHeight || document.body.getBoundingClientRect().height;
    let th: number = containerHeight - verticalPosition - 3;
    th = heightOfElementsBelowTable ? th - heightOfElementsBelowTable : th;
    th = stickyLastRowData && !nestedHeader ? th - 40 : th;
    th = showHeader ? th - headerHeight : th;
    th = nestedHeader ? th - headerHeight : th;

    return th;
  };

  const [tableHeight, setTableHeight] = useState<number>(calcTableHeight());
  const [scrollOnTop, setScrollOnTop] = useState<boolean>(true);

  useEffect(() => {
    setTableHeight(calcTableHeight());
  }, [parentHeight, maxHeight, showHeader, nestedHeader, heightOfElementsBelowTable, scrollOnTop, tableName]);

  // TODO Maybe we should get a property heightOfElementsABOVETable and not randomly recalculate it when scrolled to/from top
  useEvent('scroll', contentElement, (e) => {
    if (e.target.scrollTop === 0 && !scrollOnTop) {
      setTimeout(() => setScrollOnTop(true), 270);
    } else if (e.target.scrollTop !== 0 && scrollOnTop) {
      setTimeout(() => setScrollOnTop(false), 270);
    }
  });

  const [
    VT,
    setComponents,
  ] = useVT(() => ({ scroll: { y: tableHeight } }), []);

  // set header component for virtualized table
  useEffect(() => {
    setComponents({
      header: {
        cell: ResizeableColumn,
      },
    });
  }, [setComponents]);

  const emptyTableWithCustomComponent = dataSource.length === 0 && !contentLoading && emptyResultsComponent;
  const renderTable = (): JSX.Element => {
    if (!visibleColumns || !contentElement) return (<></>);
    const tableWidth = contentElement ? contentElement.clientWidth : 0;
    const horizontallyScrollable = Math.floor(getColumnsWidthRecursively(visibleColumns)) > tableWidth;

    const containerClassName = `${classNameProp ?? ''
      } ${stickyFirstRowData || stickyLastRowData ? 'with-sticky-row' : ''
      } ${stickyFirstRowData ? 'with-sticky-first-row' : ''
      } ${withBorder ? 'with-border' : ''
      }`;

    // the classname `SmartTableInner` is used to differentiate between SmartTable styling and
    // native antd table styling in `_table.scss`
    const tableClassName = `SmartTableInner ${horizontallyScrollable ? '' : 'horizontally-not-scrollable '
      }${verticallyScrollable ? '' : 'vertically-not-scrollable'
      }`;

    return (
      <div className={containerClassName}>

        <StickyFirstRow stickyRowData={stickyFirstRowData} contentElement={contentElement} tableName={tableName} visibleColumns={visibleColumns} loading={contentLoading} nestedHeader={nestedHeader} />

        <Table
          key={key}
          locale={{ emptyText: (<></>) }}
          className={tableClassName}
          components={dataSource.length && virtualize ? VT : components}
          dataSource={dataSource}
          rowKey={rowKey}
          rowClassName={rowClassName}
          columns={visibleColumns}
          onRow={onRow}
          pagination={false}
          loading={contentLoading ? { indicator: <TableContentLoadingIndicator /> } : false}
          scroll={{
            y: verticallyScrollable ? tableHeight : 'max-content',
          }}
          showHeader={showHeader && !emptyTableWithCustomComponent}
          expandable={{
            expandedRowRender,
            expandedRowKeys,
            expandRowByClick,
            defaultExpandAllRows: false,
            expandIcon: (prps: RenderExpandIconProps<any>) => (prps.record.children || expandedRowRender ? <RowExpandIcon needIndentSpaced={dataSource.some(record => record.children !== undefined)} {...prps} /> : undefined),
            expandIconColumnIndex,
            onExpand,
          }}
          rowSelection={rowSelection}
        />
        {columns && changeColumnVisibility && showHeader && !hideColumnSelector && !emptyTableWithCustomComponent
          && (
            <div className="column-selector-wrapper">
              <ColumnSelector columns={columns} changeColumnVisibility={changeColumnVisibility} />
            </div>
          )}
        {dataSource.length === 0 && !contentLoading
          && (emptyResultsComponent || <div className="empty-table-marker">{tl(translations.elements.smartTable.noData)}</div>)}

        <StickyLastRow stickyRowData={stickyLastRowData} tableName={tableName} visibleColumns={visibleColumns} contentElement={contentElement} loading={contentLoading} />
      </div>
    );
  };

  return (
    <div
      id={tableName}
      className={`SmartTable ${tableName} ${!verticallyScrollable ? 'hidden-scroll' : ''} ${showHeader ? 'header-visible' : 'header-hidden'}`}
      ref={smartTableRef}
    >
      {!showOnlyCheckboxes && !hideActionBar && selectedRecords && selectedRecords.length > 0
        && (
          <LegacyActionBar
            actions={actionsMenu || []}
            onUnselectAll={() => {
              if (onUnselectAll) onUnselectAll();
            }}
            records={selectedRecords || []}
          />
        )
      }
      {infiniteScrollerProps
        ? addInfiniteScroller(infiniteScrollerProps, renderTable())
        : renderTable()}
    </div>
  );
}

SmartTable.defaultProps = {
  key: undefined,
  expandRowByClick: true,
  expandedRowKeys: [],
  onExpand: () => { },
  withBorder: false,
};
