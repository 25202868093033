import { MessageCreateDtoLetterDispatchTypeEnum, UnitContractProjectionDtoTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import {
  MessageSendingContext, useMessageSendingPropertyContext, useRecipientContext, useRecipientUpdaterContext,
} from 'pages/MessageSendingPage/services/MessageSendingContext';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext } from 'react';
import { ValidateStatuses } from 'lib/Utils';
import _ from 'lodash';

export const useDistributionModeCell = (key: string) => {
  const { tl } = useContext(LanguageContext);
  const messageSendingContext = useContext(MessageSendingContext);
  const recipientContext = useRecipientContext('useDistributionModeCell');
  const recipientUpdaterContext = useRecipientUpdaterContext('useDistributionModeCell');
  const propertyContext = useMessageSendingPropertyContext('useDistributionModeCell');

  if (messageSendingContext === undefined) {
    throw new Error('useDistributionModeCell must be used within a MessageSendingContextProvider');
  }


  const {
    basedOnPreference, warnings, setWarnings,
  } = messageSendingContext;

  const { propertyConnections } = propertyContext;

  const { recipientDistributions } = recipientContext;
  const { setRecipientDistributions } = recipientUpdaterContext;

  const index = recipientDistributions.findIndex(recipient => recipient.key === key);
  const value = recipientDistributions[index]?.distributionType;


  const onChange = (val: MessageCreateDtoLetterDispatchTypeEnum[]) => {
    setRecipientDistributions(prev => prev.map((recipient) => {
      if (recipient.key === key) return { ...recipient, distributionType: val };
      return recipient;
    }));

    // remove warning on change
    setWarnings(prev => ({
      ...prev,
      contactsWithMissingDispatch: prev.contactsWithMissingDispatch?.filter(c => c !== recipientDistributions[index]?.contactId),
    }));
  };

  const selectPlacement: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight' = index === recipientDistributions.length - 1 ? 'topRight' : 'bottomRight';

  const epostDisabled = propertyConnections?.filter(pc => pc.propertyId === recipientDistributions[index]?.propertyId
    && pc.epostEnabled && pc.epostCredentialsValid).length === 0;

  const activePortalsOfCurrentContractProperty = propertyConnections?.find(pc => pc.propertyId === recipientDistributions[index]?.propertyId)?.activePortals?.map(ap => ap.appName);

  const portalDisabled = activePortalsOfCurrentContractProperty?.length === 0
    || (recipientDistributions[index]?.contractType === UnitContractProjectionDtoTypeEnum.PROPERTY_OWNER
      && (activePortalsOfCurrentContractProperty?.includes('facilioo') || activePortalsOfCurrentContractProperty?.includes('etg24')));

  const options: SelectOption[] = [
    {
      value: MessageCreateDtoLetterDispatchTypeEnum.EPOST,
      label: tl(messageSendingTranslations.distributionOptions.epost),
      disabled: epostDisabled,
    },
    {
      value: MessageCreateDtoLetterDispatchTypeEnum.PORTAL,
      label: tl(messageSendingTranslations.distributionOptions.portal),
      disabled: portalDisabled,
    },
    {
      value: MessageCreateDtoLetterDispatchTypeEnum.MANUAL,
      label: tl(messageSendingTranslations.distributionOptions.manual),
    },
  ];

  const validationError = warnings.contactsWithMissingDispatch?.findIndex(c => c === recipientDistributions[index]?.contactId) > -1 ? { state: ValidateStatuses[2] } : undefined;

  return {
    options,
    onChange,
    value,
    disabled: basedOnPreference,
    validationError,
    selectPlacement,
  };
};
