import { MessageCreateDtoLetterDispatchTypeEnum, UnitContractProjectionDtoTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import _, { uniq } from 'lodash';
import {
  MessageSendingContext, useMessageSendingPropertyContext, useRecipientContext, useRecipientUpdaterContext,
} from 'pages/MessageSendingPage/services/MessageSendingContext';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

const PORTAL_TYPE = MessageCreateDtoLetterDispatchTypeEnum.PORTAL;
const EPOST_TYPE = MessageCreateDtoLetterDispatchTypeEnum.EPOST;
const MANUAL_TYPE = MessageCreateDtoLetterDispatchTypeEnum.MANUAL;


export const useDistributionModeRow = () => {
  const { tl } = useContext(LanguageContext);

  const messageSendingContext = useContext(MessageSendingContext);
  const recipientContext = useRecipientContext('useDistributionModeRow');
  const recipientUpdaterContext = useRecipientUpdaterContext('useDistributionModeRow');
  const propertyContext = useMessageSendingPropertyContext('useDistribtuionModeRow');

  if (messageSendingContext === undefined) {
    throw new Error('useDistributionModeRow must be used within a MessageSendingContextProvider');
  }


  const {
    basedOnPreference,
    setBasedOnPreference,
  } = messageSendingContext;

  const { recipientDistributions, contactsLoading } = recipientContext;
  const { setRecipientDistributions } = recipientUpdaterContext;
  const { properties, propertyConnections } = propertyContext;

  const disabled = contactsLoading;


  const dispatchOptions = [
    {
      value: true,
      label: tl(messageSendingTranslations.preference),
    }, {
      value: false,
      label: tl(messageSendingTranslations.selection),
    },
  ];

  const onChangeRadioButton = (val: boolean) => {
    setBasedOnPreference(val);
    if (val) {
      setRecipientDistributions(prev => prev.map(c => ({
        ...c,
        distributionType: c.contactPreferences?.length > 0 ? c.contactPreferences : c.distributionType,
      })));
    }
  };

  const onChangeDistributionMode = (val: MessageCreateDtoLetterDispatchTypeEnum) => {
    setRecipientDistributions((prev) => {
      const nrMatches = prev.reduce((acc, curr) => ({
        [PORTAL_TYPE]: acc[PORTAL_TYPE] + (curr.distributionType?.includes(PORTAL_TYPE) ? 1 : 0),
        [EPOST_TYPE]: acc[EPOST_TYPE] + (curr.distributionType?.includes(EPOST_TYPE) ? 1 : 0),
        [MANUAL_TYPE]: acc[MANUAL_TYPE] + (curr.distributionType?.includes(MANUAL_TYPE) ? 1 : 0),
      }),
        {
          [PORTAL_TYPE]: 0,
          [EPOST_TYPE]: 0,
          [MANUAL_TYPE]: 0,
        });


      const nrOfRecipientsNotApplicable = val === MessageCreateDtoLetterDispatchTypeEnum.PORTAL ? prev.filter((rec) => {
        const activePortalsOfCurrentContractProperty = propertyConnections?.find(pc => pc.propertyId === rec?.propertyId)?.activePortals;
        return rec.contractType === UnitContractProjectionDtoTypeEnum.PROPERTY_OWNER
          && (activePortalsOfCurrentContractProperty?.map(ap => ap.appName).includes('facilioo') || activePortalsOfCurrentContractProperty?.map(ap => ap.appName).includes('etg24'));
      }).length : 0;

      // IF none were selected OR only some were selected THEN select all
      if (nrMatches[val] === 0 || nrMatches[val] < (prev.length - nrOfRecipientsNotApplicable)) {
        return prev.map((rec) => {
          const activePortalsOfCurrentContractProperty = propertyConnections?.find(pc => pc.propertyId === rec?.propertyId)?.activePortals?.map(ap => ap.appName);
          const shouldApply = val !== MessageCreateDtoLetterDispatchTypeEnum.PORTAL
            || !(rec.contractType === UnitContractProjectionDtoTypeEnum.PROPERTY_OWNER
              && (activePortalsOfCurrentContractProperty?.includes('facilioo') || activePortalsOfCurrentContractProperty?.includes('etg24')));

          return ({
            ...rec,
            distributionType: shouldApply ? uniq([...(rec.distributionType || []), val]) : rec.distributionType,
          });
        });
      }

      // ELSE deselect all
      return prev.map(rec => ({
        ...rec,
        distributionType: rec.distributionType.filter(dt => dt !== val),
      }));
    });
  };

  const portalNr = recipientDistributions.filter(c => c.distributionType?.includes(MessageCreateDtoLetterDispatchTypeEnum.PORTAL)).length;
  const epostNr = recipientDistributions.filter(c => c.distributionType?.includes(MessageCreateDtoLetterDispatchTypeEnum.EPOST)).length;
  const manualNr = recipientDistributions.filter(c => c.distributionType?.includes(MessageCreateDtoLetterDispatchTypeEnum.MANUAL)).length;
  const portalCheckboxValue = !basedOnPreference && portalNr === recipientDistributions.length;
  const epostCheckboxValue = !basedOnPreference && epostNr === recipientDistributions.length;
  const manualCheckboxValue = !basedOnPreference && manualNr === recipientDistributions.length;
  const portalCheckboxIndeterminate = !basedOnPreference && portalNr > 0 && portalNr < recipientDistributions.length;
  const epostCheckboxIndeterminate = !basedOnPreference && epostNr > 0 && epostNr < recipientDistributions.length;
  const manualCheckboxIndeterminate = !basedOnPreference && manualNr > 0 && manualNr < recipientDistributions.length;

  const epostDisabled = propertyConnections?.filter(pc => pc.epostEnabled && pc.epostCredentialsValid).length === 0;
  const invalidEpostCredentials = propertyConnections?.filter(pc => pc.epostEnabled && !pc.epostCredentialsValid).length !== 0;
  const portalDisabled = propertyConnections?.filter(pc => !_.isEmpty(pc.activePortals)).length === 0 || recipientDistributions.filter((rec) => {
    const activePortalsOfCurrentContractProperty = propertyConnections?.find(pc => pc.propertyId === rec?.propertyId)?.activePortals;
    return (rec.contractType === UnitContractProjectionDtoTypeEnum.PROPERTY_OWNER
      && (activePortalsOfCurrentContractProperty?.map(ap => ap.appName).includes('facilioo') || activePortalsOfCurrentContractProperty?.map(ap => ap.appName).includes('etg24')));
  }).length === recipientDistributions.length;

  const getPortalTooltipText = () => {
    if (portalDisabled) {
      return (
        <Link target="_blank" to={{ pathname: 'https://support.docs.impower.de/servicedesk/customer/kb/view/2497511425' }}>
          {tl(messageSendingTranslations.portalDisabledtooltipText)}
        </Link>
      );
    }
    if (propertyConnections?.filter(pc => _.isEmpty(pc.activePortals)).length !== 0) {
      const propertyInternalIds = propertyConnections?.filter(pc => !_.isEmpty(pc.activePortals)).map(pc => properties.data?.find(p => p.id === pc.propertyId)?.propertyIdInternal).join(', ');
      return tl(messageSendingTranslations.differentSettingTooltipText)(propertyInternalIds);
    }
    return undefined;
  };

  const getEpostTooltipText = () => {
    if (epostDisabled) {
      if (invalidEpostCredentials) return tl(messageSendingTranslations.epostCredentialsInvalidText);
      return (
        <Link target="_blank" to={{ pathname: 'https://support.docs.impower.de/wiki/spaces/IS/pages/2140504069/Alles+rund+um+die+E-Post+Anbindung?parentProduct=JSM-Portal&parentProductContentContainerId=10005&initialAllowedFeatures=disable-login-flow.disable-share&locale=en-US#Erstellen-und-Anbinden-eines-E-Post-Kontos' }}>
          {tl(messageSendingTranslations.epostDisabledTooltipText)}
        </Link>
      );
    }
    if (propertyConnections?.filter(pc => !pc.epostEnabled).length !== 0) {
      const propertyInternalIds = propertyConnections?.filter(pc => pc.epostEnabled).map(pc => properties.data?.find(p => p.id === pc.propertyId)?.propertyIdInternal).join(', ');
      return tl(messageSendingTranslations.differentSettingTooltipText)(propertyInternalIds);
    }
    return undefined;
  };

  const portalTooltipText = getPortalTooltipText();
  const epostTooltipText = getEpostTooltipText();

  return {
    options: dispatchOptions,
    onChangeRadioButton,
    value: basedOnPreference,
    onChangeDistributionMode,
    portalCheckboxValue,
    portalCheckboxIndeterminate,
    epostCheckboxValue,
    epostCheckboxIndeterminate,
    manualCheckboxValue,
    manualCheckboxIndeterminate,
    disabled,
    epostDisabled,
    portalDisabled,
    portalTooltipText,
    epostTooltipText,
  };
};
