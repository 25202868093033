import { renderToString } from 'react-dom/server';
import DunningAnnexAccountBalanceTable from 'pages/OrderOpenBalances/DunningAnnexDocument/DunningAnnexAccountBalanceTable/DunningAnnexAccountBalanceTable ';
import { formatCurrency } from 'lib/Utils';
import { ExchangeDto } from 'api/accounting';
import { MainDetails } from 'storybook-components/documents/MainDetails/MainDetails';
import TitleAndSubtitle from 'storybook-components/documents/TitleAndSubtitle/TitleAndSubtitle';
import { orderOpenBalancesTranslations } from 'pages/OrderOpenBalances/translations';
import { HtmlAndDetailsForContract } from '../components/OrderOpenBalancesViewHeaderButtons/intrefaces';
import { ReturnTypeOfData } from '../components/OrderOpenBalancesViewHeaderButtons/useOrderOpenBalancesViewButtons';

export const useDunningAnnexHtmlGenerator = ({
  data, language, exchanges,
}: {data:ReturnTypeOfData, language: any, exchanges: ExchangeDto[]}) => {
  const tl = (obj) => {
    try {
      return obj[language];
    } catch (error) {
      return 'tl-err';
    }
  };


  const sectionsSeparateForEachContract: HtmlAndDetailsForContract[] = data.reduce((list, props) => {
    const {
      dunningAnnexAccountBalanceTableProps,
      contractDetails,
    } = props;

    const sections = (
      <div id="DunningAnnexDocument">
        <TitleAndSubtitle
          title={tl(orderOpenBalancesTranslations.dunningAnnexDocumentCover.title)}
        />
        <MainDetails
          tl={tl}
          details={{
            property: contractDetails?.propertyInternalId && contractDetails?.propertyName ? `${contractDetails?.propertyInternalId} | ${contractDetails?.propertyName}` : '',
            unit: contractDetails?.unitType && contractDetails?.unitNrSharingDeclaration
              ? `${contractDetails?.unitNrSharingDeclaration} | ${tl(orderOpenBalancesTranslations.dunningAnnexDocumentCover.unitTypes[contractDetails?.unitType])}` : '',
          }}
        />
        <DunningAnnexAccountBalanceTable tl={tl} useDunningAnnexAccountBalanceTable={dunningAnnexAccountBalanceTableProps} />
      </div>
    );

    const sectionsForContract: HtmlAndDetailsForContract = {
      propertyId: contractDetails.propertyId,
      propertyHrId: contractDetails.propertyHrId,
      propertyName: contractDetails.propertyName,
      propertyAccountHolder: contractDetails.propertyAccountHolder,
      contractId: contractDetails.contractId,
      contractName: contractDetails.rootAccountName,
      openAmount: formatCurrency(contractDetails.openAmount, '0,00', false),
      // openAmount + dunning fee
      totalBalance: formatCurrency(contractDetails.openAmount + (contractDetails.dunningFee?.dunningFeeNet ?? 0) + (contractDetails.dunningFee?.vatAmount ?? 0), '0,00', false),
      templateName: contractDetails.dunningLevel.dunningLevel,
      unitId: contractDetails.unitId,
      unitNrSharingDeclaration: contractDetails.unitNrSharingDeclaration,
      unitType: contractDetails.unitType,
      propertyIban: contractDetails.propertyIban,
      managementCompanyName: contractDetails.managementCompanyName,
      creditorId: contractDetails.creditorId,
      dunningFee: contractDetails.dunningFee,
      ibanContract: contractDetails.ibanContract,
      propertyManager: contractDetails.propertyManager,
      contractHasMandate: contractDetails.contractHasMandate,
      mandateId: contractDetails.mandateId,
      propertyAccountant: contractDetails.propertyAccountant,
      propertyInternalId: contractDetails.propertyInternalId,
      balanceUntilDate: contractDetails.balanceUntilDate,
      xcId: exchanges.find(xc => xc.unitContractId === contractDetails.contractId).id,
      html: renderToString(sections),
      contract: contractDetails.contract,
    };
    list.push(sectionsForContract);
    return list;
  }, []);

  return { separateHtmlForEachContractWithContractDetails: sectionsSeparateForEachContract };
};
