import { PropsWithChildren, createContext, useState } from 'react';
import { StoreApi, createStore } from 'zustand';
import { AuditingGlobalFiltersStore, GlobalFilter } from './interfacesAuditingGlobalFilter';
import { onChangeGlobalFilters, onChangeLoadedData, onClearStore, onInitializeGlobalFilters } from './actionsAuditingGlobalFilter';


export const initialGlobalFilter = {
  propertyIds: [],
  timeframe: {
    startDate: undefined,
    endDate: undefined,
  },
  sharedDocuments: null,
} as GlobalFilter;

/**
 * In the html generator we initialize it with the values from the store when they click finalize.
 */
const createAuditingGlobalFilterStore = () =>
  createStore<AuditingGlobalFiltersStore>()((set) => ({
    globalFilters: initialGlobalFilter,
    loadedData: {
      properties: [],
    },
    actions: {
      onChangeGlobalFilters: (...args) => onChangeGlobalFilters(set, ...args),
      onInitializeGlobalFilters: (...args) => onInitializeGlobalFilters(set, ...args),
      onChangeLoadedData: (...args) => onChangeLoadedData(set, ...args),
      onClearStore: () => onClearStore(set),
    },
  }));


export const AuditingGlobalFilterContext = createContext<StoreApi<AuditingGlobalFiltersStore> | null>(
  null,
);

export const AuditingGlobalFilterStoreProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [store] = useState(() => createAuditingGlobalFilterStore());

  return (
    <AuditingGlobalFilterContext.Provider value={store}>
      {children}
    </AuditingGlobalFilterContext.Provider>
  );
};
