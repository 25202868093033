export const translations = {
  property: {
    en: 'Property-Nr. | Property name',
    de: 'Objekt-Nr. | Bezeichnung',
  },
  unit: {
    en: 'Unit-Nr. | Unit type',
    de: 'Einheits-Nr. | Kategorie',
  },
  meetingRoom: {
    en: 'Meeting room',
    de: 'Versammlungsort',
  },
  meetingLocation: {
    en: 'Meeting location',
    de: 'Versammlungsraum',
  },
  meetingDate: {
    en: 'Date',
    de: 'Datum',
  },
};
