export const executeInParallelBatch = async (allData: any[], batchSize: number = 10, handler: (el: any) => Promise<void>): Promise<void> => {
  for (let batchIdx = 0; batchIdx < Math.ceil(allData.length / batchSize); batchIdx++) {
    const batchData = allData.slice(batchIdx * batchSize, (batchIdx + 1) * batchSize);
    await Promise.all(batchData.map(async (el) => {
      try {
        await handler(el);
      } catch (error) {
        console.error('Error processing element:', el, error);
        // Optionally, you can handle the error or continue processing
      }
    }));
  }
};
