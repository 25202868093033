import _ from 'lodash';
import {
  useHistory,
} from 'react-router';

import { useBankTransactionListContext, useBankTransactionListDrawerContext } from 'pages/BankTransactions/services/BankTransactionListContext';
import { DefaultDataInterface } from 'lib/data';
import { useSelectedTransactions } from 'pages/BankTransactions/services/useSelectedTransactions';
import { translations } from 'pages/BankTransactions/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import {
  ExtendedAllocationGroup,
  ExtendedBankTransaction,
} from '../../interfaces';
import {
  BankTransactionProjectionDtoTransactionPaymentStatusEnum,
  GetAllocationGroupsUsingGETTransactionStatusesEnum,
} from '../../../../api/accounting';


export default function useTransactionList() {
  const { tl } = useContext(LanguageContext);

  const {
    filterState,
    bankTransactionListState,
    setBankTransactionListState,
  } = useBankTransactionListContext('useTransactionList');

  const {
    setAllocationVisible,
  } = useBankTransactionListDrawerContext('useTransactionList');

  const {
    onChangeSelectedRowKeys,
  } = useSelectedTransactions();

  const history = useHistory();

  const updateTransactionStateAndRemainingAmount = (
    removeFromList: boolean,
    originalGroupId: number,
    groupIdForAllocatedTransactions: number,
    selectedTransactionIds: number[],
    status: string,
    remainingAmount: number,
    allocatedInvoiceIds?: number[],
    unallocatedInvoiceIds?: number[],
  ) => {
    setBankTransactionListState((oldList: DefaultDataInterface<ExtendedAllocationGroup[]>) => {
      const newList: ExtendedAllocationGroup[] = _.cloneDeep(oldList.data) || [];
      const groupIdx = _.findIndex(newList, (t: ExtendedAllocationGroup) => _.isEqual(t.allocationGroupId, originalGroupId));

      if (groupIdx === -1) return oldList.load(newList);


      const shouldRemoveTransactionsFromList = ((filterState.transactionStatuses?.length ?? 0) > 0
          && !filterState.transactionStatuses?.includes(status as GetAllocationGroupsUsingGETTransactionStatusesEnum)
          && status !== GetAllocationGroupsUsingGETTransactionStatusesEnum.PARTIALLY_BOOKED);


      const updateAllocationGroupIdInUrlIfNecessary = () => {
        if (originalGroupId !== groupIdForAllocatedTransactions && !removeFromList) {
          setTimeout(() => {
            // setTimeout used to put this to the end of the event loop until after state has been set
            history.replace(`/bank-transactions/${groupIdForAllocatedTransactions}`, { navigatedFromApp: true, updateUrlOnCurrentOverlay: true });
          }, 0);
        }
      };

      if (shouldRemoveTransactionsFromList && removeFromList) {
        if (_.isEmpty(newList[groupIdx].children) || selectedTransactionIds.length === newList[groupIdx].children?.length) {
          // the whole group needs to be removed from list
          newList.splice(groupIdx, 1);
        } else {
          newList[groupIdx].children = newList[groupIdx].children?.filter(tx => !selectedTransactionIds.includes(tx.bankTransactionId!));
        }

        if (newList.length === 0) {
          setTimeout(() => {
            history.replace('/bank-transactions', { navigatedFromApp: false, reloadFirstPage: false });
            setAllocationVisible(false);
            onChangeSelectedRowKeys([]);
            // might need to remove breadcrumb
          }, 0);
        }

        return oldList.load(newList);
      }

      const indexOfAllocatedTransactionsGroup = newList.findIndex(tx => tx.allocationGroupId === originalGroupId);

      if (_.isEmpty(newList[groupIdx].children)) {
        // only one tx in group
        updateRow(newList[groupIdx], groupIdForAllocatedTransactions, status, remainingAmount, allocatedInvoiceIds, unallocatedInvoiceIds);
        if (indexOfAllocatedTransactionsGroup !== -1 && indexOfAllocatedTransactionsGroup !== groupIdx) {
          mergeGroup(newList, indexOfAllocatedTransactionsGroup, newList[groupIdx], groupIdx);
        }

        onChangeSelectedRowKeys(newList[indexOfAllocatedTransactionsGroup].children
          ? [...newList[indexOfAllocatedTransactionsGroup].children?.map(r => r.rowKey)]
          : [newList[indexOfAllocatedTransactionsGroup].rowKey]);

        updateAllocationGroupIdInUrlIfNecessary();
        return oldList.load(newList);
      }

      if (selectedTransactionIds.length === newList[groupIdx].children?.length) {
        // every transactions from the group is selected
        updateRow(newList[groupIdx], groupIdForAllocatedTransactions, status, remainingAmount, allocatedInvoiceIds, unallocatedInvoiceIds);
        selectedTransactionIds.forEach((txId) => {
          const txIdx = _.findIndex(newList[groupIdx].children, tx => _.isEqual(tx.bankTransactionId, txId));
          if (txIdx !== -1) {
            updateRow(newList[groupIdx].children![txIdx], groupIdForAllocatedTransactions, status, remainingAmount, allocatedInvoiceIds, unallocatedInvoiceIds);
          }
        });

        if (indexOfAllocatedTransactionsGroup !== -1 && indexOfAllocatedTransactionsGroup !== groupIdx) {
          mergeGroup(newList, indexOfAllocatedTransactionsGroup, newList[groupIdx], groupIdx);
        }

        onChangeSelectedRowKeys(newList[indexOfAllocatedTransactionsGroup].children
          ? [...newList[indexOfAllocatedTransactionsGroup].children?.map(r => r.rowKey)]
          : [newList[indexOfAllocatedTransactionsGroup].rowKey]);


        updateAllocationGroupIdInUrlIfNecessary();
        return oldList.load(newList);
      }

      // more txs in group, some of them were NOT selected => add new group
      splitGroup(newList, groupIdx, originalGroupId, groupIdForAllocatedTransactions, selectedTransactionIds, status, remainingAmount);

      const newGroupId = groupIdForAllocatedTransactions;
      const indexToExclude = newList[groupIdx].allocationGroupId === newGroupId ? groupIdx : groupIdx + 1;
      const duplicatedIndex = newList.findIndex((tx, index) => tx.allocationGroupId === newGroupId && index !== indexToExclude);

      if (duplicatedIndex > -1 && indexToExclude !== duplicatedIndex) {
        mergeGroup(newList, indexToExclude, newList[duplicatedIndex], duplicatedIndex);
        onChangeSelectedRowKeys(newList[indexToExclude].children
          ? [...newList[indexToExclude].children?.map(r => r.rowKey)]
          : [newList[indexToExclude].rowKey]);
      } else {
        onChangeSelectedRowKeys(newList[groupIdx].children
          ? [...newList[groupIdx].children?.map(r => r.rowKey)]
          : [newList[groupIdx].rowKey]);
      }


      updateAllocationGroupIdInUrlIfNecessary();
      return oldList.load(newList);
    });
  };

  const mergeGroup = (
    txList: ExtendedAllocationGroup[],
    indexOfTargetGroup: number,
    sourceGroup: ExtendedAllocationGroup,
    indexOfSourceGroup: number,
  ) => {
    if ((txList[indexOfTargetGroup].children?.length ?? 0) > 0) {
      // if target group has children then push the source group there
      if ((sourceGroup.children?.length ?? 0) > 0) {
        txList[indexOfTargetGroup].children!.push(...(sourceGroup.children!));
      } else {
        txList[indexOfTargetGroup].children!.push(sourceGroup);
      }

      // remove source group
      txList.splice(indexOfSourceGroup, 1);
      return;
    }

    // create children, add the original tx group to children and push source group to children
    if ((sourceGroup.children?.length ?? 0) > 0) {
      txList[indexOfTargetGroup].children = [{ ...txList[indexOfTargetGroup] }, ...(sourceGroup.children!)];
    } else {
      txList[indexOfTargetGroup].children = [{ ...txList[indexOfTargetGroup] }, sourceGroup];
    }

    // remove source group
    txList.splice(indexOfSourceGroup, 1);
  };

  const splitGroup = (txList: ExtendedAllocationGroup[], groupIdx: number, groupIdForUnllocatedTransactions: number, groupIdForAllocatedTransactions: number, selectedTransactionIds: number[], status: string, remainingAmount: number) => {
    const unallocatedChildren = txList[groupIdx].children?.filter(tx => !selectedTransactionIds.includes(tx.bankTransactionId!)) ?? [];
    const groupWithUnallocatedTxs: ExtendedAllocationGroup = { children: unallocatedChildren, ...unallocatedChildren[0] };
    updateRow(groupWithUnallocatedTxs, groupIdForUnllocatedTransactions, groupWithUnallocatedTxs.transactionPaymentStatus!, groupWithUnallocatedTxs.remainingAmount!);
    groupWithUnallocatedTxs.children!.forEach(child => updateRow(child, groupIdForUnllocatedTransactions, groupWithUnallocatedTxs.transactionPaymentStatus!, groupWithUnallocatedTxs.remainingAmount!));

    const allocatedChildren = txList[groupIdx].children?.filter(tx => selectedTransactionIds.includes(tx.bankTransactionId!)) ?? [];
    const groupWithAllocatedTxs: ExtendedAllocationGroup = { children: allocatedChildren, ...allocatedChildren[0] };
    updateRow(groupWithAllocatedTxs, groupIdForAllocatedTransactions, status, remainingAmount);
    groupWithAllocatedTxs.children!.forEach(child => updateRow(child, groupIdForAllocatedTransactions, status, remainingAmount));

    txList[groupIdx] = groupWithAllocatedTxs;
    txList.splice(groupIdx + 1, 0, groupWithUnallocatedTxs);
  };

  const updateRow = (row: ExtendedAllocationGroup | ExtendedBankTransaction, newAllocationGroupId: number, status: string, remainingAmount: number, allocatedInvoiceIds?: number[], unallocatedInvoiceIds?: number[]) => {
    row.transactionPaymentStatus = status as BankTransactionProjectionDtoTransactionPaymentStatusEnum;
    row.remainingAmount = remainingAmount;
    row.allocationGroupId = newAllocationGroupId;
    row.translatedStatus = tl(translations.statuses[status]) || '';

    row.allocatedInvoices = [
      ...(row.allocatedInvoices?.filter(inv => !unallocatedInvoiceIds?.includes(inv.invoiceId)) ?? []),
      ...(allocatedInvoiceIds?.map(invoiceId => ({ invoiceId })) ?? []),
    ];

    if (row.children) {
      row.rowKey = `${row.children?.map(c => c.bankTransactionId)?.join('-')}`;
    } else {
      row.rowKey = `${row.bankTransactionId}`;
    }
  };


  return {
    bankTransactionListState,
    updateTransactionStateAndRemainingAmount,
    updateRow,
  };
}
