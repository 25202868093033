import { useContext, useEffect } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { BankTransactionControllerApi, GetAllocationGroupsUsingGETTransactionStatusesEnum } from 'api/accounting';
import { isNil } from 'lodash';
import { useOpenTransactionNumberContext } from 'pages/OrderOpenBalances/OrderOpenBalancesList/services/OrderOpenBalancesContext';

const statuses = [
  GetAllocationGroupsUsingGETTransactionStatusesEnum.UNASSIGNED,
  GetAllocationGroupsUsingGETTransactionStatusesEnum.PARTIALLY_BOOKED,
];

export const getOpenTransactionUrl = (propertyIdInternal: string) => `/bank-transactions?propertyIdInternal=${propertyIdInternal}&transactionStatuses=${encodeURIComponent(JSON.stringify(statuses))}`;

export const useOpenTransactionsCell = (propertyId: number) => {
  const { apiConfiguration } = useContext(AuthContext);
  const { openTransactionNumbers, setOpenTransactionNumbers } = useOpenTransactionNumberContext('useOpenTransactionsCell');
  const bankTransactionControllerApi = new BankTransactionControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    if (propertyId && isNil(openTransactionNumbers[propertyId])) onLoadBankTransaction();
  }, [propertyId]);

  const onLoadBankTransaction = () => {
    bankTransactionControllerApi.countBankTransactionsUsingGET({
      // @ts-ignore
      transactionPaymentStatuses: [GetAllocationGroupsUsingGETTransactionStatusesEnum.UNASSIGNED, GetAllocationGroupsUsingGETTransactionStatusesEnum.PARTIALLY_BOOKED],
      propertyId,
    }).then((r) => {
      setOpenTransactionNumbers(prev => ({ ...prev, [propertyId]: r }));
    });
  };

  return {
    numberOfOpenTransactions: openTransactionNumbers[propertyId],
  };
};
