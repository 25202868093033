import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import './AccountSheetHeader.scss';
import Button from 'elements/Buttons/Button/Button';
import moment from 'moment';
import { PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../../elements/Translation/translations';
import { AccountListContext } from '../../../contexts/AccountListContext';
import { getAccountCategories } from '../../Account/AccountsOverview/AccountUtils';
import { useAccountDistributionKey } from '../../../services/useAccountDistributionKey';
import {  isAuditingUser } from 'lib/userPermissionUtils';


export default function AccountSheetHeader(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { setSelectedAccountIdentifiers, selectedAccount } = useContext(AccountListContext);
  const { selectedProperty } = useContext(PropertyListContext);
  const { header } = translations.pages.accountSheet;
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  let { accountCode } = useParams<{ accountCode: string }>();
  const location = useLocation();
  const history = useHistory();
  accountCode = removeLettersFromAccountCode(decodeURIComponent(accountCode));

  const propertyAdministrationType = selectedProperty.data?.administrationType;

  const { accountDistributionKey, loadAccountDistributionKey, isAccountDistributable } = useAccountDistributionKey({
    propertyId: selectedProperty.data?.id,
    accountCode: typeof selectedAccount.code !== 'undefined' ? selectedAccount.code : '',
    year: moment().get('year').toString(),
    leaf: selectedAccount?.leaf,
  });


  useEffect(() => {
    if (accountCode === selectedAccount.code && !location.pathname.includes('distribution-keys-details') && isAccountDistributable(selectedAccount.code, selectedAccount.leaf)) {
      loadAccountDistributionKey();
    }
  }, [location, selectedAccount]);

  useEffect(() => {
    setSelectedAccountIdentifiers({ propertyHrId, accountCode });
  }, [propertyHrId, accountCode]);

  const { code, name } = selectedAccount;
  const accountDetails = getAccountCategories(code);
  let mainCategory = '';
  let subCategory = '';
  if (accountDetails) {
    mainCategory = tl(translations.accounts.mainCategory[accountDetails.mainCategory]);
    subCategory = tl(translations.accounts.subCategory[accountDetails.subCategory]);
  }

  const {
    houseMoneySettlement, economicPlan, apply, unitDistributions,
  } = accountDistributionKey || {};
  const allUnitsEconomicPlan = unitDistributions && unitDistributions.reduce((flag: boolean, key: any) => flag && key.economicPlan, true);
  const allUnitsHouseMoneySettlement = unitDistributions && unitDistributions.reduce((flag: boolean, key: any) => flag && key.houseMoneySettlement, true);

  const onClickDistributionKeys = () => {
    history.push(`${location.pathname}/${moment().get('year')}/distribution-keys-details`);
  };

  function removeLettersFromAccountCode(accCode?: string): string {
    accCode = accCode?.split('/invoice')[0];
    const regExp = /[a-zA-Z,-]/g;
    if (!!accCode && accCode!.match(regExp)) {
      accCode = accCode!.replace(regExp, '').slice(0, -1);
    }
    return accCode as string;
  }

  return (
    <div className="AccountSheetHeader">
      <div className="column">
        <div className="detail-group">
          <div className="label">{tl(header.number)}</div>
          <div className="value semi-bold">{code || ''}</div>
        </div>
        <div className="detail-group">
          <div className="label">{tl(header.name)}</div>
          <div className="value semi-bold">{name}</div>
        </div>
        <div className="detail-group">
          <div className="label">{tl(header.category)}</div>
          <div className="value semi-bold">{mainCategory}</div>
        </div>
      </div>
      {propertyAdministrationType !== PropertyLegacyDtoAdministrationTypeEnum.MV && propertyAdministrationType !== PropertyLegacyDtoAdministrationTypeEnum.SEV && propertyAdministrationType !== PropertyLegacyDtoAdministrationTypeEnum.SEV_MV ? (
        <div className="column">
          <div className="detail-group">
            <div className="label">{tl(header.type)}</div>
            <div className="value medium semi-bold">{subCategory}</div>
          </div>
          <div className="detail-group">
            <div className="label">{tl(header.distributionKeyHMS)}</div>
            {selectedAccount.leaf && houseMoneySettlement ? (
              <Button type="link" disabled={isAuditingUser()} onClick={onClickDistributionKeys} className="distr-key-button value medium semi-bold">
                {tl(translations.enums.distributionMode[houseMoneySettlement])}
              </Button>
            ) : (
              <div className="distr-key-placeholder">-</div>
            )}
          </div>
          <div className="detail-group">
            <div className="label">{tl(header.distributionKeyEP)}</div>
            {selectedAccount.leaf && economicPlan ? (
              <Button type="link" disabled={isAuditingUser()} onClick={onClickDistributionKeys} className="distr-key-button value medium semi-bold">
                {tl(translations.enums.distributionMode[economicPlan])}
              </Button>
            ) : (
              <div className="distr-key-placeholder">-</div>
            )}
          </div>
        </div>
      ) : (
        <div className="column">
          <div className="detail-group">
            <div className="label">{tl(header.type)}</div>
            <div className="value medium semi-bold">{subCategory}</div>
          </div>
          <div className="detail-group">
            <div className="label">{tl(header.allocable)}</div>
            {selectedAccount.leaf && typeof apply !== 'undefined' ? <div className="value medium semi-bold">{apply ? tl(translations.elements.radioGroup.yes) : tl(translations.elements.radioGroup.no)}</div> : <div className="distr-key-placeholder">-</div>}
          </div>
        </div>
      )}
      {![PropertyLegacyDtoAdministrationTypeEnum.MV, PropertyLegacyDtoAdministrationTypeEnum.SEV, PropertyLegacyDtoAdministrationTypeEnum.SEV_MV].includes(propertyAdministrationType) && (
        <div className="column">
          <div className="detail-group">
            <div className="label">{tl(header.allocable)}</div>
            {selectedAccount.leaf && typeof apply !== 'undefined' ? <div className="value medium semi-bold">{apply ? tl(translations.elements.radioGroup.yes) : tl(translations.elements.radioGroup.no)}</div> : <div className="distr-key-placeholder">-</div>}
          </div>
          <div className="detail-group">
            <div className="label">{tl(header.detailsHMS)}</div>
            <div className="value medium">{allUnitsHouseMoneySettlement ? tl(header.allUnits) : tl(header.notAllUnits)}</div>
          </div>
          <div className="detail-group">
            <div className="label">{tl(header.detailsEP)}</div>
            <div className="value medium">{allUnitsEconomicPlan ? tl(header.allUnits) : tl(header.notAllUnits)}</div>
          </div>
        </div>
      )}
      <div />
    </div>
  );
}
