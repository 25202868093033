import React, { useContext, useEffect } from 'react';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import DateInput from 'elements/Inputs/DateInput/DateInput';
import { DataExportModal } from 'elements/Modals/DataExportModal/DataExportModal';
import './AccountOverviewDataExportBody.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import CheckboxInput from 'elements/Inputs/CheckboxInput/CheckboxInput';
import { useAuditingGlobalFilterIsDateRangeLimited } from 'services/AuditingGlobalFilterStore/selectorsAuditingGlobalFilter';

export enum TIME_PERIOD_TYPE {
  CURRENT_ECONOMIC_YEAR = 'Current Economic Year',
  PREVIOUS_YEAR = 'Previous Year',
  CUSTOM = 'Custom',
}

export enum FILE_TYPE {
  PDF = 'PDF',
  EXCEL = 'Excel',
}

export enum DOWNLOAD_ALL_ACCOUNTS {
  TRUE = 'true',
  FALSE = 'false',
}

export const AccountOverviewDataExportBody = ({ accountsBalances, dataExportModalProps, onClickDownload, onClickPrint }: { accountsBalances: any; dataExportModalProps: any; onClickDownload: Function; onClickPrint: Function }) => {
  const { tl } = useContext(LanguageContext);
  const isDateRangeLimited = useAuditingGlobalFilterIsDateRangeLimited();
  const { pdfFormat, setPdfFormat, setOptions, setAllAccountsCode, downloadAllAccounts, setDownloadAllAccountsValue, setSelectedAccounts, selectedAccounts, options, setTimePeriod, timePeriodType, startDate, endDate, setStartDate, setEndDate, excelFormat, setExcelFormat } = dataExportModalProps;
  const placeholder = '';

  const getOption = (account: any) => ({
    value: account.accountCode,
    label: `${account.accountCode} ${account.accountName}`,
  });

  const filterOption = (input: any, option: any) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const setOptionsForChildren = (optionsList: any, account: any) => {
    const options =
      account.subAccounts?.length > 0
        ? account.subAccounts.map((child: any) => {
            const option = getOption(child);
            optionsList.push(option);
            setOptionsForChildren(optionsList, child);
            return optionsList;
          })
        : optionsList;
    return options;
  };

  const setOptionsExportModal = (dataSource: any) => {
    const options: any = [];
    const optionsRootAccounts: any = [];
    dataSource?.map((account: any) => {
      const option = getOption(account);
      options.push(option);
      optionsRootAccounts.push(option);
      setOptionsForChildren(options, account);
    });
    setOptions(options);
    const allAccounts = optionsRootAccounts.map((option: any) => option.value);
    setAllAccountsCode(allAccounts);
  };

  useEffect(() => {
    const currentYear = new Date(startDate).getFullYear() || new Date().getFullYear();
    if (accountsBalances.loaded) {
      setOptionsExportModal(accountsBalances.data[currentYear]);
    }
  }, [accountsBalances]);

  const body = (
    <div key="accountOverview" className="container">
      <div className="input-section">
        <SelectInput
          label={tl(translations.pages.accountsOverview.dataExportModal.labels.of)}
          required
          onChange={setDownloadAllAccountsValue}
          value={downloadAllAccounts}
          options={[
            {
              value: DOWNLOAD_ALL_ACCOUNTS.TRUE,
              label: tl(translations.pages.accountsOverview.dataExportModal.selectInputOfOptions.allAccounts),
            },
            {
              value: DOWNLOAD_ALL_ACCOUNTS.FALSE,
              label: tl(translations.pages.accountsOverview.dataExportModal.selectInputOfOptions.individualAccounts),
            },
          ]}
          placeholder={placeholder}
          size="small"
        />
      </div>
      {downloadAllAccounts === DOWNLOAD_ALL_ACCOUNTS.FALSE && (
        <div className="input-section">
          <SelectInput label={tl(translations.pages.accountsOverview.dataExportModal.labels.account)} mode="multiple" onChange={setSelectedAccounts} value={selectedAccounts} options={options} showSearch filterOption={filterOption} placeholder={placeholder} />
        </div>
      )}
      <div className="input-section">
        <SelectInput
          label={tl(translations.pages.accountsOverview.dataExportModal.labels.timePeriod)}
          onChange={setTimePeriod}
          value={timePeriodType}
          options={[
            {
              value: TIME_PERIOD_TYPE.CURRENT_ECONOMIC_YEAR,
              label: tl(translations.pages.accountsOverview.dataExportModal.selectInputTimePeriodOptions.currentEconomicYear),
            },
            {
              value: TIME_PERIOD_TYPE.PREVIOUS_YEAR,
              label: tl(translations.pages.accountsOverview.dataExportModal.selectInputTimePeriodOptions.previousYear),
            },
            {
              value: TIME_PERIOD_TYPE.CUSTOM,
              label: tl(translations.pages.accountsOverview.dataExportModal.selectInputTimePeriodOptions.custom),
            },
          ]}
          size="small"
          showSearch
          required
          placeholder={placeholder}
          disabled={isDateRangeLimited}
        />
      </div>
      {timePeriodType === 'Custom' && (
        <div className="section-row">
          <DateInput value={startDate} onChange={(value) => setStartDate(value)} label={tl(translations.pages.accountsOverview.dataExportModal.labels.dateStart)} className="input" required disabled={isDateRangeLimited} />
          <DateInput value={endDate} onChange={(value) => setEndDate(value)} label={tl(translations.pages.accountsOverview.dataExportModal.labels.dateEnd)} className="input" required disabled={isDateRangeLimited} />
        </div>
      )}

      <div className="input-section">
        <div className="format-label">{tl(translations.pages.accountsOverview.dataExportModal.labels.format)}</div>
        <div className="format-section">
          <CheckboxInput onChange={() => setPdfFormat(!pdfFormat)} value={pdfFormat} label={FILE_TYPE.PDF} />
          <CheckboxInput onChange={() => setExcelFormat(!excelFormat)} value={excelFormat} label={FILE_TYPE.EXCEL} />
        </div>
      </div>
    </div>
  );

  return (
    <DataExportModal
      {...dataExportModalProps}
      onClickDownload={(value: boolean) => {
        onClickDownload(value);
      }}
      onClickPrint={() => {
        onClickPrint();
      }}
    >
      {body}
    </DataExportModal>
  );
};
