import { isEmpty } from 'lodash';

export enum Permissions {
  PERM_ACCOUNT_READ = 'PERM_ACCOUNT_READ',
  PERM_ACCOUNT_MANAGE = 'PERM_ACCOUNT_MANAGE',
  PERM_CONN_READ = 'PERM_CONN_READ',
  PERM_CONN_MANAGE = 'PERM_CONN_MANAGE',
  PERM_CONN_UPDATE = 'PERM_CONN_UPDATE',
  PERM_DOCUMENT_DOWNLOAD = 'PERM_DOCUMENT_DOWNLOAD',
  PERM_DOCUMENT_MANAGE = 'PERM_DOCUMENT_MANAGE',
  PERM_USER_READ = 'PERM_USER_READ',
  PERM_USER_MANAGE = 'PERM_USER_MANAGE',
  PERM_SUPPORT = 'PERM_SUPPORT',
}

export const getUserPermissions = () => {
  const auth = JSON.parse(localStorage?.auth || '{}');
  const token = auth?.token || '';
  if (!token) return [];
  const payload = atob(token.split('.')[1] || '');
  const permissions = JSON.parse(payload)?.permissions;
  return permissions?.split(',') || [];
};

export const getUserPropertyAccess = () => {
  const auth = JSON.parse(localStorage?.auth || '{}');
  const token = auth?.token || '';
  if (!token) return [];
  const payload = atob(token.split('.')[1] || '');
  return JSON.parse(payload)?.propertyIds || [];
};

export const checkUserHasPermission = (permission: Permissions) => getUserPermissions().indexOf(permission) !== -1;

export const isAuditingUser = () => checkUserHasPermission(Permissions.PERM_ACCOUNT_READ) && checkUserHasPermission(Permissions.PERM_DOCUMENT_DOWNLOAD) && !isEmpty(getUserPropertyAccess());

