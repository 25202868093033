import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  EconomicPlanControllerApi,
  EconomicPlanCreateDto,
  EconomicPlanCreateDtoTypeEnum,
  EconomicPlanHouseMoneyControllerApi,
  FindFilteredPropertiesUsingGETAdministrationTypesEnum,
  FindFilteredPropertiesUsingGETRequest,
  HouseMoneyAmountV2Dto,
  HouseMoneyDto,
  PropertyDisplayDto,
  PropertyDisplayDtoPropertyStateEnum,
  PropertyLegacyControllerApi,
  PropertyUpdateDto,
  ReserveAccountDraftDto,
  UnitLegacyControllerApi,
  UnitLegacyDto,
} from 'api/accounting';
import backend, { endpointUrls } from 'backend_api';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import _ from 'lodash';

import { AuthContext } from '../../../../contexts/AuthContext';
import { IEPProgress } from '../interfaces';
import { loadCsv } from '../services/loadCsv';
import { executeInParallelBatch } from '../utils/executeInParallelBatch';
import { UnitEPData } from './interfaces';
import { translations } from './translations';

const EP_TITLE = 'Importierter Wirtschaftsplan';
const EP_START_DATE = '2024-01-01';

const mapRowToUnitEPData = (csvRow: any): UnitEPData => ({
  propertyId: parseInt(csvRow.propertyId, 10),
  propertyExternalId: csvRow.propertyExternalId,
  unitId: parseInt(csvRow.unitId, 10),
  unitExternalId: csvRow.unitExternalId,
  amountHouseMoney: csvRow.amountHouseMoney && parseFloat(csvRow.amountHouseMoney.replace('.', '').replace(',', '.')),
  reserveFunds: [
    {
      reserveFundName: csvRow.reserveFundName1,
      amount: csvRow.reserveFundAmount1 && parseFloat(csvRow.reserveFundAmount1.replace('.', '').replace(',', '.')),
    },
    {
      reserveFundName: csvRow.reserveFundName2,
      amount: csvRow.reserveFundAmount2 && parseFloat(csvRow.reserveFundAmount2.replace('.', '').replace(',', '.')),
    },
    {
      reserveFundName: csvRow.reserveFundName3,
      amount: csvRow.reserveFundAmount3 && parseFloat(csvRow.reserveFundAmount3.replace('.', '').replace(',', '.')),
    },
    {
      reserveFundName: csvRow.reserveFundName4,
      amount: csvRow.reserveFundAmount4 && parseFloat(csvRow.reserveFundAmount4.replace('.', '').replace(',', '.')),
    },
    {
      reserveFundName: csvRow.reserveFundName5,
      amount: csvRow.reserveFundAmount5 && parseFloat(csvRow.reserveFundAmount5.replace('.', '').replace(',', '.')),
    },
    {
      reserveFundName: csvRow.reserveFundName6,
      amount: csvRow.reserveFundAmount6 && parseFloat(csvRow.reserveFundAmount6.replace('.', '').replace(',', '.')),
    },
    {
      reserveFundName: csvRow.reserveFundName7,
      amount: csvRow.reserveFundAmount7 && parseFloat(csvRow.reserveFundAmount7.replace('.', '').replace(',', '.')),
    },
    {
      reserveFundName: csvRow.reserveFundName8,
      amount: csvRow.reserveFundAmount8 && parseFloat(csvRow.reserveFundAmount8.replace('.', '').replace(',', '.')),
    },
    {
      reserveFundName: csvRow.reserveFundName9,
      amount: csvRow.reserveFundAmount9 && parseFloat(csvRow.reserveFundAmount9.replace('.', '').replace(',', '.')),
    },
    {
      reserveFundName: csvRow.reserveFundName10,
      amount: csvRow.reserveFundAmount10 && parseFloat(csvRow.reserveFundAmount10.replace('.', '').replace(',', '.')),
    }],
});

export const useEconomicPlansImportCsv = () => {
  const [unitsEPData, setUnitsEPData] = useState<UnitEPData[]>([]);
  const [dataValidationIssues, setDataValidationIssues] = useState<any[]>([]);
  const [properties, setProperties] = useState<PropertyDisplayDto[]>([]);
  const [epProgress, setEpProgress] = useState<IEPProgress>({ started: false, finished: false, epsImported: 0 });
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));
  const unitControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));
  const economicPlanHouseMoneyControllerApi = new EconomicPlanHouseMoneyControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    const filter: FindFilteredPropertiesUsingGETRequest = {
      page: 0,
      size: 10000,
      administrationTypes: FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG,
      excludeFields: ['bankAccounts', 'managementCompany'],
    };

    propertyControllerApi.findFilteredPropertiesUsingGET(filter)
      .then((response) => {
        setProperties(response.content);
      })
      .catch(() => {
        showNotification({
          key: 'loadPropertyListError',
          message: tl(translations.notifications.propertyLoadError),
          type: 'error',
        });
      });
  }, []);

  const loadUnitEPDataFromCsvRows = (csvRows: any[]) => {
    const readUnitEPData: UnitEPData[] = [];
    const validationIssues: any[] = [];
    csvRows.forEach((csvRow) => {
      const unitEPData = mapRowToUnitEPData(csvRow);


      readUnitEPData.push(unitEPData);
      // either property Id or external id must be present
      if (!unitEPData.propertyId && !unitEPData.propertyExternalId) {
        validationIssues.push({ message: tl(translations.validations.issues.property), row: csvRow._row - 1 });
      }
      if (unitEPData.propertyId && properties.map(p => p.id).indexOf(unitEPData.propertyId) < 0) {
        validationIssues.push({ message: tl(translations.validations.issues.property), row: csvRow._row - 1 });
      }
      if (unitEPData.propertyExternalId && properties.map(p => p.externalId).indexOf(unitEPData.propertyExternalId) < 0) {
        validationIssues.push({ message: tl(translations.validations.issues.property), row: csvRow._row - 1 });
      }
      // either unit id or external id must be present
      if (!unitEPData.unitExternalId && !unitEPData.unitId) {
        validationIssues.push({ message: tl(translations.validations.issues.unit), row: csvRow._row - 1 });
      }
      unitEPData.reserveFunds.forEach((fund, index) => {
        if ((!fund.reserveFundName && typeof fund.amount === 'number') || (fund.reserveFundName && typeof fund.amount !== 'number')) {
          validationIssues.push({ message: `${tl(translations.validations.issues.reserve)} ${index}`, row: csvRow._row - 1 });
        }
      });
    });
    setDataValidationIssues(validationIssues);
    if (validationIssues.length) {
      setUnitsEPData([]);
    } else {
      setUnitsEPData(readUnitEPData);
    }
  };

  const loadCsvFile = (event) => {
    loadCsv(event.target.files, 0)
      .then(res => loadUnitEPDataFromCsvRows(res))
      .catch((err) => {
        showNotification({ message: tl(translations.validations.errors) });
        console.error('Error while loading csv', err);
      });
  };

  const propertyEpData = useMemo(() => {
    const internalIds = unitsEPData.map(u => u.propertyId).every(id => !!id);
    const map = new Map();
    unitsEPData.forEach((data) => {
      const property = internalIds ? map.get(data.propertyId) : map.get(data.propertyExternalId);
      const units = property ? property.units : [];
      const reserveAccounts = property ? property.reserveAccounts : new Set();
      const amounts = data.reserveFunds
        .filter(rf => !!rf.amount && !!rf.reserveFundName)
        .map(rf => ({
          name: rf.reserveFundName,
          amount: rf.amount,
        }));
      if (data.amountHouseMoney) {
        amounts.push({
          name: 'house',
          amount: data.amountHouseMoney,
        });
      }
      units.push({
        unitExternalId: data.unitExternalId,
        unitId: data.unitId,
        amounts,
      });
      data.reserveFunds
        .map(acc => acc.reserveFundName)
        .filter(name => !!name)
        .forEach(name => reserveAccounts.add(name));
      map.set(internalIds ? data.propertyId : data.propertyExternalId, { units, reserveAccounts });
    });

    if (internalIds) {
      return Array.from(map.keys()).map(propId => ({
        ...map.get(propId),
        propertyId: propId,
      }));
    }
    return Array.from(map.keys()).map(propId => ({
      ...map.get(propId),
      propertyExternalId: propId,
    }));
  }, [unitsEPData]);


  const importEPs = async () => {
    setEpProgress({
      started: true,
      finished: false,
      epsImported: 0,
    });
    try {
      await importAllEPs();
    } catch (e) {
      console.error('Import stopped', e, e.response);
      showNotification({
        key: 'importValidation-error',
        duration: 0,
        message: tl(translations.notifications.error.title),
        description: tl(translations.notifications.error.description),
        type: 'error',
      });
      setEpProgress(prev => ({ ...prev, finished: false }));
    }
  };

  const importAllEPs = async () => {
    const internalIds = propertyEpData.map(u => u.propertyId).every(id => !!id);
    await executeInParallelBatch(propertyEpData, 1, async (epData) => {
      // find existing property
      const propertyDto: PropertyDisplayDto = internalIds
        ? properties.filter(p => p.id === epData.propertyId)[0]
        : properties.filter(p => p.externalId === epData.propertyExternalId)[0];

      const fullPropertyDto = await propertyControllerApi.getPropertyUsingGET({ propertyHrId: propertyDto.propertyHrId });

      // get units of that property
      const units: UnitLegacyDto[] = await unitControllerApi.getPropertyUnitsUsingGET1({ propertyId: propertyDto.id, isOwnedByWeg: false });

      // update property with reserve accounts
      let updatedProperty;
      // if property is READY add new reserve accounts, if DRAFT replace them
      const newReserveAccountDrafts = Array.from(epData.reserveAccounts).map((name: string) => ({ name } as ReserveAccountDraftDto));
      const reserveAccountDrafts = propertyDto.propertyState === PropertyDisplayDtoPropertyStateEnum.DRAFT
        ? newReserveAccountDrafts
        : fullPropertyDto.reserveAccountDrafts.concat(newReserveAccountDrafts.filter(ad => fullPropertyDto.reserveAccountDrafts.map(rad => rad.name).indexOf(ad.name) < 0));
      try {
        const propertyUpdateDto = {
          ...fullPropertyDto.propertyAddress,
          ...(fullPropertyDto as unknown as PropertyUpdateDto),
          reserveAccountDrafts,
        };
        updatedProperty = await backend.put(`${endpointUrls.PROPERTY}`, propertyUpdateDto);
      } catch (validationResponse) {
        if (validationResponse.status === 400) {
          updatedProperty = validationResponse.savedEntity;
        } else {
          throw validationResponse;
        }
      }
      const savedReserveAccounts: ReserveAccountDraftDto[] = updatedProperty.reserveAccountDrafts;

      // create economic plan
      const epCreateDto: EconomicPlanCreateDto = {
        propertyId: updatedProperty.id,
        type: EconomicPlanCreateDtoTypeEnum.UNIT_FIGURES,
        title: EP_TITLE,
        startDate: EP_START_DATE,
      };
      const createdEconomicPlan = await economicPlanControllerApi.createEconomicPlanUsingPOST({ dto: epCreateDto });

      const emptyHouseMoneyAmounts: HouseMoneyDto[] = await economicPlanHouseMoneyControllerApi.getHouseMoneyAmountsUsingGET({ economicPlanId: createdEconomicPlan.id });

      const houseMoneyDtoList: HouseMoneyDto[] = epData.units.map((unit) => {
        const foundUnits = units.filter(u => (internalIds ? u.id === unit.unitId : u.externalId === unit.unitExternalId));
        if (foundUnits.length !== 1) {
          throw new Error(`Unit with Id not found:${internalIds ? unit.unitId : unit.unitExternalId}`);
        }
        const foundUnit = foundUnits[0];
        const foundEmptyAmounts = emptyHouseMoneyAmounts.filter(ea => ea.unitId === foundUnit.id);
        if (foundEmptyAmounts.length !== 1) {
          throw new Error(`Amounts for unit with Id not found:${internalIds ? unit.unitId : unit.unitExternalId}`);
        }
        const houseMoneyAmounts: HouseMoneyAmountV2Dto[] = unit.amounts.map((value) => {
          const reserveFundId = value.name === 'house' ? null : savedReserveAccounts.filter(rfa => rfa.name === value.name)[0].id;
          const houseMoneyAmountId = reserveFundId === null
            ? foundEmptyAmounts[0].houseMoneyAmounts.find(hma => !hma.reserveFundId).id
            : foundEmptyAmounts[0].houseMoneyAmounts.find(hma => hma.reserveFundId === reserveFundId).id;
          return ({
            reserveFundId,
            amount: value.amount,
            id: houseMoneyAmountId,
          });
        });

        return ({
          unitId: foundUnit.id,
          houseMoneyAmounts,
        });
      });

      // save house money values on economic plan
      await economicPlanHouseMoneyControllerApi.updateHouseMoneyAmountsUsingPATCH({ economicPlanId: createdEconomicPlan.id, houseMoneyDtoList });

      setEpProgress(prev => ({ ...prev, epsImported: prev.epsImported + 1 }));
    });
  };

  return {
    propertiesLoaded: properties.length > 0,
    propertyList: properties,
    propertyEpData,
    loadUnitEPDataFromCsvRows,
    unitsEPData,
    dataValidationIssues,
    loadCsvFile,
    importEPs,
    epProgress,
  };
};
