import './ColorDot.scss';

import React from 'react';

export enum DotColorEnum {
   YELLOW = 'YELLOW',
   GREEN = 'GREEN',
   RED = 'RED',
   BLUE = 'BLUE',
   PURPLE = 'PURPLE',
   BLACK = 'BLACK',
   CYAN = 'CYAN',
   CREAM = 'CREAM',
   GRAY = 'GRAY',
   DUST = 'DUST',
}

interface ColorDotProps {
  color: DotColorEnum,
  children?: React.ReactNode,
  'data-testid'?:string,
}


export const ColorDot = ({ color, children, 'data-testid': datatestid }: ColorDotProps) => (
  <div className="ColorDot" data-testid={datatestid}>
    <span data-testid={datatestid} className={`dot ${color?.toLowerCase?.()}`} />
    {children}
  </div>
);


export default ColorDot;


ColorDot.defaultProps = {
  children: undefined,
  'data-testid': undefined,
};
