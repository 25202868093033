import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { Modal } from 'antd';
import { AuthContext } from 'contexts/AuthContext';
import { WkaControllerApi } from 'api/accounting';
import { showNotification } from 'lib/Notification';
import { translations } from '../list/translations';
import { CustomButtonProps } from 'elements/Buttons/Button/Button';

export const useDeleteWka = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const wkaControllerApi = new WkaControllerApi(apiConfiguration('accounting'));

  const onClickDelete = (hcdReportId: number, onSuccess: () => void) => {
    Modal.confirm({
      title: tl(translations.wkaList.actions.title),
      content: tl(translations.wkaList.actions.description)(),
      okText: tl(translations.wkaList.actions.delete),
      cancelText: tl(translations.wkaList.actions.cancel),
      okButtonProps: { className: 'Button', danger: true, 'data-testid': 'delete-wka-confirmation' } as CustomButtonProps,
      cancelButtonProps: { className: 'Button' },
      onOk: () => onDelete(hcdReportId, onSuccess),
    });
  };

  const onDelete = (hcdReportId: number, onSuccess: () => void) => wkaControllerApi.deleteWkaUsingDELETE({ wkaId: hcdReportId })
    .then(() => {
      showNotification({
        key: 'deleteWka',
        message: tl(translations.wkaList.deleteSuccessfully),
        type: 'success',
      });
      onSuccess();
    })
    .catch((e) => {
      console.error(e);

      showNotification({
        key: 'loadWkaListError',
        message: tl(translations.wkaList.deleteFailed.message),
        type: 'error',
      });
    });

  return { onClickDelete };
};
