import { useContext } from 'react';
import moment from 'moment';
import { EconomicPlanDtoStatusEnum, EconomicPlanDtoTypeEnum } from '../../../../../api/accounting';
import { EconomicPlanContext } from '../../../services/EconomicPlanContext';
import { PropertyListContext } from '../../../../../contexts/PropertyListContext';
import { useSimpleDirtModal } from '../../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

/**
 * Hook for managing economic year and property data.
 * We need property + economic plan data to compile economic year start + economic year end.
 * Once economic plan has a start date, we can separate this hook into two hooks: useUnitFiguresEconomicPlan & useSelectedProperty
 */
export const useEconomicPlanData = () => {
  const { setEconomicPlan, economicPlan } = useContext(EconomicPlanContext);

  const { selectedProperty } = useContext(PropertyListContext);

  const { addDirt } = useSimpleDirtModal();

  const setStartDate = (date: moment.Moment) => {
    const startDate = date?.startOf('month')?.format('YYYY-MM-DD');
    setEconomicPlan((prevState) => {
      addDirt();
      return economicPlan.load({
        ...prevState.data,
        startDate,
      });
    });
  };

  const setEndDate = (date: moment.Moment) => {
    const endDate = date?.endOf('month')?.format('YYYY-MM-DD');
    setEconomicPlan((prevState) => {
      addDirt();
      return economicPlan.load({
        ...prevState.data,
        endDate,
      });
    });
  };

  const setTitle = (title: string) => {
    setEconomicPlan((prevState) => {
      if (prevState.data?.title === title) {
        return prevState;
      }
      addDirt();
      return economicPlan.load({
        ...prevState.data,
        title,
      });
    });
  };

  const isAccountBasedEp = economicPlan.data?.type === EconomicPlanDtoTypeEnum.ACCOUNT_DISTRIBUTION;
  return {
    title: economicPlan.data?.title,
    setTitle,
    startDate: economicPlan?.data?.startDate ? moment(economicPlan.data?.startDate) : undefined,
    setStartDate,
    endDate: economicPlan?.data?.endDate ? moment(economicPlan.data?.endDate) : undefined,
    setEndDate,
    economicPlanState: economicPlan.data?.status || EconomicPlanDtoStatusEnum.DRAFT,
    selectedPropertyId: selectedProperty?.data?.id,
    isWKASelectorDisplayed: isAccountBasedEp,
    loading: economicPlan.loading,
  };
};
