import { useContext } from 'react';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import Amount from 'storybook-components/typography/Amount/Amount';
import { formatCurrency } from 'lib/Utils';
import { Skeleton } from 'antd';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../../../elements/Translation/translations';
import { BankAccountInfo } from '../UseBankAccountList';

export const useBankAccountTableColumns = (showNewBankAccountListColumns: boolean) => {
  const { tl } = useContext(LanguageContext);

  if (!showNewBankAccountListColumns) {
    return [
      {
        title: tl(translations.pages.bankAccount.table.headers.accountType),
        dataKey: 'type',
        sortable: false,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.accountName),
        dataKey: 'name',
        sortable: false,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.accountIban),
        dataKey: 'iban',
        sortable: false,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.calculatedAccountBalance),
        dataKey: 'calculatedBalance',
        sortable: false,
        format: FORMAT.CURRENCY,
      },
      {
        title: tl(translations.pages.bankAccount.table.headers.actualAccountBalance),
        dataKey: 'balance',
        sortable: false,
        format: FORMAT.CURRENCY,
      },
    ];
  }

  return [
    {
      title: tl(translations.pages.bankAccount.table.headers.accountType),
      dataKey: 'type',
      sortable: false,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.account),
      dataKey: 'name',
      sortable: false,
      render: (value, record) => (
        <CellWithTwoLines
          firstElement={value}
          secondElement={record.iban}
        />
      ),
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.bankBalance),
      dataKey: 'balance',
      sortable: false,
      className: 'column-align-right',
      render: (value, record) => (
        <Link
          to={record.transactionsUrl}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
          title={tl(translations.pages.bankAccount.table.headers.bankBalanceHover)}
        >
          <CellWithTwoLines
            firstElement={<Amount>{formatCurrency(value)}</Amount>}
            secondElement={record.balanceDateString}
          />
        </Link>
      ),
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.accountingBalance),
      titleString: tl(translations.pages.bankAccount.table.headers.accountingBalance),
      dataKey: 'accountingBalance',
      sortable: false,
      className: 'column-align-right',
      render: (value, record: BankAccountInfo) => (
        <Skeleton
          loading={value === undefined}
          active
          title={false}
          paragraph={{ rows: 1, width: '10rem' }}
        >
          <Link
            to={record.accountUrl}
            target="_blank"
            rel="noreferrer"
            onClick={e => e.stopPropagation()}
            title={`${tl(translations.pages.bankAccount.table.headers.accountingBalanceHover)} ${record.accountCode}`}
          >
            <CellWithTwoLines
              firstElement={<Amount>{formatCurrency(value)}</Amount>}
              secondElement={`${tl(translations.pages.bankAccount.table.headers.account)} ${record.accountCode}`}
            />
          </Link>
        </Skeleton>
      ),
    },
  ];
};
