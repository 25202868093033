import {
  useContext,
  useState,
} from 'react';

import { useBoolean } from 'ahooks';
import backend, { endpointUrls } from 'backend_api';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import FileSaver from 'file-saver';
import { showNotification } from 'lib/Notification';
import { useDomainConfiguration } from 'lib/useDomainConfiguration';
import { round2dec } from 'lib/Utils';
import moment from 'moment';

import {
  PropertyBankAccountInsufficientFundsDto,
  SepaExecutionSummary,
} from '../../../api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from '../../../lib/data';

export const useDownloadEbics: () => [(filters: any, excludedIbans: string[], successCallback: () => void) => void, (paymentIds: number[], excludedIbans: string[], successCallback: () => void) => void, boolean,
  (filters: any, successCallback: (ibans: string[]) => void) => void, (paymentIds: number[], successCallback: (ibans: string[]) => void) => void, DefaultDataInterface<any>] = () => {
    const [loading, { setTrue: startLoading, setFalse: stopLoading }] = useBoolean(false);
    const [downloadData, setDownloadData] = useState(DEFAULT_DATA<SepaExecutionSummary>({}));

    const { groupPayments } = useDomainConfiguration();

    const { tl } = useContext(LanguageContext);

    // TODO: Need filter interface
    const onDownloadAllAsEbics = (filters: any, excludedIbans: string[], successCallback: () => void) => {
      startLoading();
      const filename = `${moment().format('YYYY-MM-DD')}-SEPA-Zahlung-${downloadData.data.totalNumberOfPayments}-${downloadData.data.totalAmount}EUR.xml`;
      const filter = { ...filters };
      // the backend expects a list so we transform it ad-hoc
      if (filter.propertyIdInternalList && typeof filter.propertyIdInternalList === 'string') {
        filter.propertyIdInternalList = filter.propertyIdInternalList.split(',');
      }
      backend.getFileUsingPost(`${endpointUrls.SEPA_PAYMENTS}/export-all-ebics`, {
        filter,
        ibansToExclude: excludedIbans,
        groupPayments,
      })
        .then((response: any) => {
          successCallback();
          const blob: any = new Blob([response], { type: 'application/xml' });
          FileSaver.saveAs(blob, filename);
          stopLoading();
        })
        .catch((e) => {
          console.error(e);
          if (e.status === 400) {
            showNotification({
              key: 'fileDownloadError',
              message: tl(translations.pages.payment.downloadWrongSEPA.message),
              description: tl(translations.pages.payment.downloadWrongSEPA.description),
              type: 'error',
            });
          } else {
            showNotification({
              key: 'fileDownloadError',
              message: tl(translations.elements.fileUpload.downloadError.message),
              type: 'error',
            });
          }
          stopLoading();
        });
    };

    const onDownloadEbicsFileForPaymentIdList = (paymentIds: number[], excludedIbans: string[], successCallback: () => void) => {
      startLoading();
      const filename = getFileName(excludedIbans);
      backend.getFileUsingPost(`${endpointUrls.SEPA_PAYMENTS}/export-ebics`, {
        paymentIds,
        ibansToExclude: excludedIbans,
        groupPayments,
      })
        .then((response: any) => {
          successCallback();
          const blob: any = new Blob([response], { type: 'application/xml' });
          FileSaver.saveAs(blob, filename);
          stopLoading();
        })
        .catch((e) => {
          console.error(e);
          if (e.status === 400) {
            showNotification({
              key: 'fileDownloadError',
              message: tl(translations.pages.payment.downloadWrongSEPA.message),
              description: tl(translations.pages.payment.downloadWrongSEPA.description),
              type: 'error',
            });
          } else {
            showNotification({
              key: 'fileDownloadError',
              message: tl(translations.elements.fileUpload.downloadError.message),
              type: 'error',
            });
          }
          stopLoading();
        });
    };

    const getFileName = (excludedIbans: string[]) => {
      const excludedNumberOfTransactions = downloadData.data.insufficientFundsProperties.filter((prp: PropertyBankAccountInsufficientFundsDto) => excludedIbans.includes(prp.iban)).reduce((acc, curr) => acc + curr.totalNumberOfTransactions, 0);
      const excludedTotalAmount = downloadData.data.insufficientFundsProperties.filter((prp: PropertyBankAccountInsufficientFundsDto) => excludedIbans.includes(prp.iban)).reduce((acc, curr) => acc + curr.totalAmountOfTransactions, 0);

      const totalSum = round2dec(downloadData.data.totalAmount - excludedTotalAmount);
      const totalNumberOfPayments = downloadData.data.totalNumberOfPayments - excludedNumberOfTransactions;

      const filename = `${moment().format('YYYY-MM-DD')}-SEPA-Zahlung-${totalNumberOfPayments}-${totalSum}EUR.xml`;
      return filename;
    };

    const onLoadEbicsDownloadDataByPaymentIds = (paymentIds: number[], successCallback: (ibans: string[]) => void) => {
      setDownloadData(prevState => prevState.startLoading());
      backend.get(`${endpointUrls.PAYMENT}/ebics-download-data-by-filter`, { orderIds: paymentIds })
        .then((response: any) => {
          setDownloadData(prevState => prevState.load(response));
          let ibans = [];
          try {
            ibans = response.insufficientFundsProperties.map((prp: PropertyBankAccountInsufficientFundsDto) => prp.iban);
          } catch (e) {
            // NOOP;
          }
          successCallback(ibans);
        })
        .catch((e) => {
          console.error(e);
          if (e.status === 422) {
            showNotification({
              key: 'fileDownloadError',
              message: tl(translations.notifications.paymentListContext.loadDownloadDataError.message),
              description: tl(translations.notifications.paymentListContext.loadDownloadDataError.description),
              type: 'error',
            });
          } else {
            showNotification({
              key: 'fileDownloadError',
              message: tl(translations.notifications.paymentListContext.loadDownloadDataError.message),
              type: 'error',
            });
          }
          setDownloadData(prevState => prevState.failed());
        });
    };

    const onLoadEbicsDownloadDataByFilters = (filters: any, successCallback: (ibans: string[]) => void) => {
      const filter = { ...filters };
      if (filter.propertyIdInternalList && typeof filter.propertyIdInternalList === 'string') {
        filter.propertyIdInternalList = filter.propertyIdInternalList.split(',');
      }
      setDownloadData(prevState => prevState.startLoading());
      backend.get(`${endpointUrls.PAYMENT}/ebics-download-data-by-filter`, { ...filter })
        .then((response: any) => {
          setDownloadData(prevState => prevState.load(response));
          let ibans = [];
          try {
            ibans = response.insufficientFundsProperties.map((prp: PropertyBankAccountInsufficientFundsDto) => prp.iban);
          } catch (e) {
            // NOOP;
          }
          successCallback(ibans);
        })
        .catch((e) => {
          console.error(e);
          if (e.status === 422) {
            showNotification({
              key: 'fileDownloadError',
              message: tl(translations.notifications.paymentListContext.loadDownloadDataError.message),
              description: tl(translations.notifications.paymentListContext.loadDownloadDataError.description),
              type: 'error',
            });
          } else {
            showNotification({
              key: 'fileDownloadError',
              message: tl(translations.notifications.paymentListContext.loadDownloadDataError.message),
              type: 'error',
            });
          }
          setDownloadData(prevState => prevState.failed());
        });
    };

    return [onDownloadAllAsEbics, onDownloadEbicsFileForPaymentIdList, loading, onLoadEbicsDownloadDataByFilters, onLoadEbicsDownloadDataByPaymentIds, downloadData];
  };
