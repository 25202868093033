import { useEffect } from 'react';
import './BankAccountList.scss';
import useDefaultPropertyFilters from 'components/Header/components/data/useDefaultPropertyFilters';
import MainPageHeader from 'storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from 'services/useSiteMap/useSiteMap';
import { useGlobalSearch } from 'components/Header/components/SearchBar/services/useGlobalSearch';
import { useParams } from 'react-router';
import { useFeatureSettings } from 'lib/useFeatureSettings';
import { DEFAULT_FILTER_BANK_ACCOUNT_LIST, useBankAccountList } from './UseBankAccountList';
import { BankAccountListTable } from './components/BankAccountListTable';

export default function BankAccountList(): JSX.Element {
  const bankAccountListContext = useBankAccountList();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();
  useGlobalSearch({
    key: 'bankAccounts',
    filterProps: {
      availableFilters: useDefaultPropertyFilters(),
      setFilter: (key: string, value: any) => bankAccountListContext.updateFilterState({ [key]: value || undefined }),
    },
    queryParamAsFilter: {
      filterState: bankAccountListContext.filterState,
      defaultFilters: DEFAULT_FILTER_BANK_ACCOUNT_LIST,
      onSetDefaultFilterFromQueryParams: bankAccountListContext.updateFilterState,
    },
  });

  const { showNewBankAccountListColumns } = useFeatureSettings();

  useEffect(() => () => {
    bankAccountListContext.onClearBankAccountList();
    bankAccountListContext.onClearFilterState();
  }, []);

  useEffect(() => {
    if (propertyHrId) {
      bankAccountListContext.updateFilterState({ propertyHrId });
    }
  }, [propertyHrId]);

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'bankAccounts',
    navItems: subcategorySwitcherItems.accounting,
  };

  return (
    <div className="BankAccountList page">
      <MainPageHeader subcategorySwitcherProps={subcategorySwitcherProps} />
      {/* It's important to have BankAccountListTable as a separate component so we can give it a `key`
          It's important to give it a key so we force re-mount
          It's important to force a re-mount because the smartTable doesn't care that the `columns` changed */}
      <BankAccountListTable bankAccountListContext={bankAccountListContext} showNewBankAccountListColumns={showNewBankAccountListColumns} key={showNewBankAccountListColumns?.toString()} />
    </div>
  );
}
