export const translations: any = {
  specialContribution: {
    validations: {
      general: {
        en: 'There are validation errors',
        de: 'Es sind Validierungsfehler aufgetreten',
      },
      mandatory: {
        en: 'Field is required',
        de: 'Feld ist erforderlich',
      },
      NOT_NULL: {
        en: 'Field is required',
        de: 'Feld ist erforderlich',
      },
      dueDate: {
        invalid: {
          en: 'Due date needs to be after resolution record date',
          de: 'Fälligkeitsdatum muss nach Beschlussdatum liegen',
        },
        mandatory: {
          en: 'Due date is required',
          de: 'Fälligkeitsdatum ist erforderlich',
        },
      },
      postingAccountCode: {
        mandatory: {
          en: 'Posting account is required',
          de: 'Buchungskonto ist erforderlich',
        },
        invalid: {
          en: 'Posting account is invalid',
          de: 'Buchungskonto ist ungültig',
        },
      },
      bankAccountId: {
        mandatory: {
          en: 'Bank account is required',
          de: 'Bankkonto ist erforderlich',
        },
        invalid: {
          en: 'Bank account is invalid',
          de: 'Bankkonto ist ungültig',
        },
      },
      'units.activeUnitContract': {
        notNull: {
          en: 'There are units without active contracts',
          de: 'Es gibt Einheiten ohne aktive Verträge',
        },
        invalid: {
          en: 'Bank account is invalid',
          de: 'Bankkonto ist ungültig',
        },
      },
      resolutionDate: {
        mandatory: {
          en: 'Resolution record date is required',
          de: 'Beschlussdatum ist erforderlich',
        },
        invalid: {
          en: 'Resolution record date is invalid',
          de: 'Beschlussdatum ist ungültig',
        },
      },
      statement: {
        mandatory: {
          en: 'Statement is required',
          de: 'Verwendungszweck ist erforderlich',
        },
        invalid: {
          en: 'Statement is invalid',
          de: 'Verwendungszweck ist ungültig',
        },
      },
      totalAmount: {
        mandatory: {
          en: 'Total amount is required',
          de: 'Gesamtbetrag ist erforderlich',
        },
        invalid: {
          en: 'Total amount is not equal to sum of unit amounts',
          de: 'Der Gesamtbetrag stimmt nicht mit der Einheitssumme überein',
        },
      },
      transactionsExecutionAllowed: {
        mandatory: {
          en: 'Transaction execution allowance option is required',
          de: 'Abwicklung der Transaktionen muss festgelegt werden',
        },
      },
      unitPaymentPreference: {
        notEmpty: {
          en: 'List of owner distributions cannot be empty',
          de: 'Abwicklung der Zahlungen darf nicht leer sein',
        },
      },
      property: {
        offboarded: {
          // legacy term not changed due to the cost of updating all support articles & videos
          en: 'Special Contributions can only be exeucted for properties in state Bookable',
          de: 'Sonderumlagen können nur für Objekte im Status "Bereit zur Verbuchung" generiert werden',
        },
      },
      distributionSet: {
        invalid: {
          en: 'Distribution Set is invalid',
          de: 'Es gibt ungültige Verteilungswerte',
        },
      },
    },
    executeSpecialContributionError: {
      en: 'Special Contribution could not be executed.',
      de: 'Sonderumlage konnte nicht ausgeführt werden.',
    },
    executeSpecialContributionSuccess: {
      en: 'Special Contribution executed successfully.',
      de: 'Sonderumlage erfolgreich ausgeführt.',
    },
    revertToDraftSpecialContributionSuccess: {
      en: 'Special Contribution successfully reverted to DRAFT.',
      de: 'Sonderumlage erfolgreich auf Entwurf zurückgesetzt.',
    },
    revertToDraftSpecialContributionError: {
      en: 'Special Contribution could not be reverted to DRAFT.',
      de: 'Sonderumlage konnte nicht auf Entwurf zurückgesetzt werden.',
    },
    deleteSpecialContributionSuccess: {
      en: 'Special Contribution successfully deleted.',
      de: 'Sonderumlage erfolgreich gelöscht.',
    },
    downloadSpecialContributionError: {
      en: 'Download failed.',
      de: 'Download fehlgeschlagen.',
    },
    loadDocumentsError: {
      en: 'Failed to load documents.',
      de: 'Dokumente konnte nicht geladen werden.',
    },
    deleteSpecialContributionError: {
      en: 'Special Contribution could not be deleted.',
      de: 'Sonderumlage konnte nicht gelöscht werden.',
    },
    fetchSpecialContributionError: {
      en: 'Special Contribution could not be retrieved.',
      de: 'Sonderumlage konnte nicht geladen werden.',
    },
    saveSpecialContributionError: {
      en: 'Special Contribution could not be saved.',
      de: 'Sonderumlage konnte nicht gespeichert werden.',
    },
    saveSpecialContributionSuccess: {
      en: 'Special Contribution saved successfully.',
      de: 'Sonderumlage erfolgreich gespeichert.',
    },
    fetchPostingAccountsError: {
      en: 'Something went wrong during retrieval of Posting Accounts.',
      de: 'Es ist ein Fehler beim Abruf der Konten aufgetreten.',
    },
    fetchBankAccountsError: {
      en: 'Something went wrong during retrieval of Bank Accounts.',
      de: 'Es ist ein Fehler beim Abruf der Bankkonten aufgetreten.',
    },
    regenerateDocumentSuccess: {
      message: {
        en: 'Document Generation has started.',
        de: 'Dokumentengenerierung wurde gestartet.',
      },
      description: {
        en: 'It will be created in a few minutes. During this time, you can close this page.',
        de: 'Dies kann ein paar Minuten dauern. In dieser Zeit können Sie die Seite verlassen.',
      },
    },
    regenerateDocumentError: {
      en: 'Document Generation cannot be started due to validation errors.',
      de: 'Die Dokumenterstellung kann aufgrund von Validierungsfehlern nicht gestartet werden.',
    },
    generationErrorMessage: {
      en: 'Document generation failed. Please try to recreate or',
      de: 'Dokumenten konnten nicht erstellt werden. Bitte erstellen Sie die Abrechnung neu oder',
    },
    contactSupport: {
      en: 'contact support.',
      de: 'kontaktieren Sie unser Support Team.',
    },
    addButton: {
      en: 'Add',
      de: 'Hinzufügen',
    },
    downloadButton: {
      en: 'Download',
      de: 'Herunterladen',
    },
    regenerateButton: {
      en: 'Generate Document',
      de: 'Dokument erstellen',
    },
    deleteButton: {
      en: 'Delete',
      de: 'Löschen',
    },
    closeButton: {
      en: 'Close',
      de: 'Schließen',
    },
    executeButton: {
      en: 'Execute',
      de: 'Ausführen',
    },
    send: {
      en: 'Preview and send',
      de: 'Vorschau & Versenden',
    },
    checkMessages: {
      en: 'View messages',
      de: 'Nachrichten ansehen',
    },
    confirmationModal: {
      title: {
        en: 'Execute special contribution',
        de: 'Sonderumlage ausführen',
      },
      content: {
        en: 'Bookings and bank orders will be generated. Are you sure you want to proceed?',
        de: 'Buchungen und Bankaufträge werden erstellt. Sind Sie sicher, dass Sie fortfahren möchten?',
      },
      onCancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      onOk: {
        en: 'Execute',
        de: 'Ausführen',
      },
    },
    messagesGeneratingTooltip: {
      en: 'Messages are currently generated for this process.',
      de: 'Es werden aktuell Nachrichten für diesen Prozess generiert.',
    },
    creationHeader: {
      specialContribution: {
        en: 'The Special Contribution',
        de: 'Die Sonderumlage',
      },
      title: {
        en: 'Special Contribution',
        de: 'Sonderumlage',
      },
      state: {
        DRAFT: {
          en: 'Draft',
          de: 'Entwurf',
        },
        ERROR: {
          en: 'Failed',
          de: 'Fehlgeschlagen',
        },
        DONE: {
          en: 'Done',
          de: 'Erledigt',
        },
      },
    },
    section: {
      generalDataSection: {
        title: {
          en: 'General Data',
          de: 'Allgemein',
        },
        selectProperty: {
          en: 'Property',
          de: 'Objekt',
        },
        watermark: {
          en: 'Watermark',
          de: 'Briefpapier',
        },
        signingPerson: {
          en: 'Signing person',
          de: 'Person für das Anschreiben',
        },
      },
      executionDetailsSection: {
        title: {
          en: 'Execution Details',
          de: 'Details zur Ausführung',
        },
        type: {
          subtitle: {
            en: 'Type',
            de: 'Art',
          },
          contribution: {
            en: 'Contribution',
            de: 'Einzug',
          },
          distribution: {
            en: 'Distribution',
            de: 'Ausschüttung',
          },
        },
        postingAccount: {
          en: 'Posting Account',
          de: 'Buchungskonto',
        },
        bankAccount: {
          en: 'Bank Account',
          de: 'Bankkonto',
        },
        resolutionRecordDate: {
          en: 'Resolution Record Date',
          de: 'Beschlussdatum',
        },
        dueDate: {
          en: 'Due Date',
          de: 'Fälligkeitsdatum',
        },
        statement: {
          en: 'Statement (Booking text)',
          de: 'Verwendungszweck (Buchungstext)',
        },
        note: {
          en: 'Note',
          de: 'Notiz',
        },
      },
    },
    section3: {
      title: {
        en: 'Owner Distribution',
        de: 'Eigentümer Verteilung',
      },
      optionsDk: {
        MEA: {
          en: 'MEA',
          de: 'MEA',
        },
        UNITS: {
          en: 'Units',
          de: 'Einheiten',
        },
        PERSONS: {
          en: 'Persons',
          de: 'Personen',
        },
        HEATING_AREA: {
          en: 'Heating area',
          de: 'Heizfläche',
        },
        AREA: {
          en: 'Area',
          de: 'Fläche',
        },
        INDIVIDUAL: {
          en: 'Individual',
          de: 'Individuell',
        },
        DISTRIBUTION_VALUE: {
          en: 'Distribution value',
          de: 'Verteilungswert',
        },
        HEATING_COST: {
          en: 'Heating cost',
          de: 'lt. Wärmekostenabrechnung',
        },
        DIRECT_COST: {
          en: 'Direct cost',
          de: 'Direktkosten',
        },
      },
      subsections: {
        distributionTitle: {
          en: 'Distribution',
          de: 'Verteilung',
        },
        CONTRIBUTION: {
          en: 'Contribution',
          de: 'Einzug',
        },
        DISTRIBUTION: {
          en: 'Distribution',
          de: 'Verteilung',
        },
        unitSelectionTitle: {
          en: 'Unit Selection',
          de: 'Auswahl der Einheiten',
        },
        transactionExecutionTitle: {
          en: 'Transaction execution',
          de: ' Ausführung der Transaktionen',
        },
        inputs: {
          totalAmountLabel: {
            en: 'Total Amount',
            de: 'Gesamtbetrag',
          },
          distributionKeyLabel: {
            en: 'Distribution Key Logic',
            de: 'Verteilerschlüssel',
          },
        },
      },
      summaryRow: {
        labelForBaseDistribution: {
          en: 'Total amount is split',
          de: 'Der Gesamtbetrag wurde verteilt',
        },
        rest: {
          en: 'Rest:',
          de: 'Restbetrag:',
        },
        exceededAmount: {
          en: 'Exceeded amount:',
          de: 'Überschrittener Betrag:',
        },
      },
      informationBar: {
        total: {
          en: 'TOTAL',
          de: 'GESAMT',
        },
        excluded: {
          en: 'EXCLUDED',
          de: 'AUSGESCHLOSSEN',
        },
        included: {
          en: 'INCLUDED',
          de: 'EINGESCHLOSSEN',
        },
        processingOptions: {
          CONTRIBUTION: {
            SELF_MANAGED: {
              en: 'Self-Payer',
              de: 'Selbstzahler',
            },
            SYSTEM_MANAGED: {
              en: 'Direct Debit',
              de: 'Lastschriften',
            },
          },
          DISTRIBUTION: {
            SELF_MANAGED: {
              en: 'No bank info',
              de: 'Keine Bankinformation',
            },
            SYSTEM_MANAGED: {
              en: 'Bank info available',
              de: 'Bankinformation verfügbar',
            },
          },
        },
      },
      transactionExecution: {
        question: {
          en: 'Do you want to execute transactions?',
          de: 'Wollen Sie die Transaktionen über die Plattform abwickeln?',
        },
        details: {
          en: 'By default it will always book.',
          de: 'Buchungen werden standardmäßig ausgeführt.',
        },
        radioGroup: {
          yes: {
            en: 'Yes',
            de: 'Ja',
          },
          no: {
            en: 'No',
            de: 'Nein',
          },
        },
      },
      unitSelection: {
        header: {
          unitId: {
            en: 'Unit-id',
            de: 'Nr.',
          },
          unitType: {
            en: 'Category',
            de: 'Kategorie',
          },
          owner: {
            en: 'Owner',
            de: 'Eigentümer',
          },
          amountToDistribute: {
            en: 'Amount to distribute',
            de: 'Zu verteilender Betrag',
          },
          dkShare: {
            en: 'Dk-share',
            de: 'Verteilerschlüssel Anteil',
          },
          processing: {
            en: 'Processing',
            de: 'Abwicklung',
          },
        },
        processingOptions: {
          CONTRIBUTION: {
            SELF_MANAGED: {
              en: 'Self-Payer',
              de: 'Selbstzahler',
            },
            SYSTEM_MANAGED: {
              en: 'Direct Debit',
              de: 'Lastschrift',
            },
          },
          DISTRIBUTION: {
            SELF_MANAGED: {
              en: 'Manually',
              de: 'Manuell',
            },
            SYSTEM_MANAGED: {
              en: 'Payment run',
              de: 'Zahlungslauf',
            },
          },
        },
      },
      fetchUnitWithOwnerNameError: {
        en: 'Something went wrong during retrieval of Units with Owner Name data.',
        de: 'Beim Abrufen von Einheiten mit Besitzernamen ist etwas schief gelaufen.',
      },
      fetchUnitDistributionRatiosError: {
        en: 'Something went wrong during retrieval of Units distribution data.',
        de: 'Beim Abrufen von Verteilungswerte der Einheiten ist etwas schief gelaufen.',
      },
      fetchUnitAmounts: {
        en: 'An error occured. Please refresh the page. If this error continues to appear, please reach out to us.',
        de: 'Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite erneut. Tritt der Fehler wiederholt auf, wenden Sie sich bitte an uns.',
      },
      unitTypes: {
        APARTMENT: {
          en: 'Apartment',
          de: 'Wohnung',
        },
        COMMERCIAL: {
          en: 'Commercial',
          de: 'Gewerbe',
        },
        PARKING: {
          en: 'Parking',
          de: 'Parkmöglichkeit',
        },
        OTHER: {
          en: 'Other',
          de: 'Sonstiges',
        },
      },
    },
    table: {
      header: {
        status: {
          en: 'Status',
          de: 'Status',
        },
        propertyId: {
          en: 'Property-id',
          de: ' Objekt Nr.',
        },
        propertyName: {
          en: 'Property Name',
          de: 'Objektbezeichnung',
        },
        amount: {
          en: 'Amount',
          de: 'Betrag',
        },
        dueDate: {
          en: 'Due Date',
          de: 'Fälligkeitsdatum',
        },
        statement: {
          en: 'Statement',
          de: 'Verwendungszweck',
        },
        type: {
          en: 'Type',
          de: 'Art',
        },
      },
      amount: {
        en: 'Amount',
        de: 'Betrag',
      },
    },
    loadSpecialContributionListError: {
      message: {
        en: 'Resource loading error.',
        de: 'Fehler beim Laden der Ressource.',
      },
      description: {
        en: 'Cannot load special contributions.',
        de: 'Sonderbeiträge können nicht geladen werden.',
      },
    },
    loadSpecialContributionError: {
      message: {
        en: 'Resource loading error.',
        de: 'Fehler beim Laden der Ressource.',
      },
      description: {
        en: 'Cannot load special contribution.',
        de: 'Sonderbeitrag kann nicht geladen werden.',
      },
    },
    filters: {
      fromDate: {
        en: 'Date from',
        de: 'Datum von',
      },
      untilDate: {
        en: 'Date until',
        de: 'Datum bis',
      },
      statement: {
        en: 'Statement (Booking text)',
        de: 'Verwendungszweck (Buchungstext)',
      },
    },
    statuses: {
      DRAFT: {
        en: 'Draft',
        de: 'Entwurf',
      },
      DONE: {
        en: 'Booked',
        de: 'Erledigt',
      },
      ERROR: {
        en: 'Failed',
        de: 'Fehlgeschlagen',
      },
    },
    types: {
      CONTRIBUTION: {
        en: 'Contribution',
        de: 'Umlage',
      },
      DISTRIBUTION: {
        en: 'Distribution',
        de: 'Ausschüttung',
      },
    },
    filterLabels: {
      ALL: {
        en: 'All',
        de: 'Alle',
      },
      OPEN: {
        en: 'Open',
        de: 'Offen',
      },
      DONE: {
        en: 'Done',
        de: 'Ausgeführt',
      },
    },
  },
};
