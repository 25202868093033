import { GetAllocationGroupsUsingGETTransactionStatusesEnum } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, {
  createContext,
  Key,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ExtendedAllocationGroup, ExtendedBankTransaction, TransactionFilter } from '../interfaces';

export const DEFAULT_FILTER_STATE = {
  transactionStatuses: [GetAllocationGroupsUsingGETTransactionStatusesEnum.UNASSIGNED,
    GetAllocationGroupsUsingGETTransactionStatusesEnum.PARTIALLY_BOOKED],
};
export type SetSelectedRowKeysFunctionType = (rowKeys: Key[]) => void;

const defaultSetSelectedRowKeys: SetSelectedRowKeysFunctionType = (rowKeys: Key[]) => {
  console.warn('No function defined, rowKeys were selected: ', rowKeys);
};

export const BankTransactionListContext = createContext<{
    bankTransactionListState: DefaultDataInterface<ExtendedAllocationGroup[]>;
    setBankTransactionListState: React.Dispatch<React.SetStateAction<DefaultDataInterface<ExtendedAllocationGroup[]>>>;
    filterState: TransactionFilter;
    setFilterState: React.Dispatch<React.SetStateAction<TransactionFilter>>;
    initialFilterUpdate: boolean;
    setInitialFilterUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    sortState: { field: string, order: 1 | -1 };
    setSortState: React.Dispatch<React.SetStateAction<{ field: string, order: 1 | -1 }>>;
  } | undefined >(undefined);

export const useBankTransactionListContext = (usageName: string) => {
  const bankTransactionListContext = useContext(BankTransactionListContext);

  if (bankTransactionListContext === undefined) {
    throw new Error(`${usageName} must be used within a BankTransactionListContextProvider`);
  }

  return bankTransactionListContext;
};

export const BankTransactionListDrawerContext = createContext<{
    allocationVisible: boolean;
    setAllocationVisible: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTransactions: ExtendedBankTransaction[];
    setSelectedTransactions: React.Dispatch<React.SetStateAction<ExtendedBankTransaction[]>>;
    transactionIdsFromUrlParams: number[];
    setTransactionIdsFromUrlParams: React.Dispatch<React.SetStateAction<number[]>>;
    selectedRowKeys: Key[];
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<Key[]>>;
} | undefined>(undefined);

export const useBankTransactionListDrawerContext = (usageName: string) => {
  const bankTransactionListDrawerContext = useContext(BankTransactionListDrawerContext);

  if (bankTransactionListDrawerContext === undefined) {
    throw new Error(`${usageName} must be used within a BankTransactionListContextProvider`);
  }

  return bankTransactionListDrawerContext;
};

const BankTransactionListContextProvider = ({ children }: any) => {
  const [bankTransactionListState, setBankTransactionListState] = useState(DEFAULT_DATA<ExtendedAllocationGroup[]>([]));
  const [filterState, setFilterState] = useState<TransactionFilter>(DEFAULT_FILTER_STATE);
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const defaultSortState: { field: string, order: 1 | -1 } = {
    field: 'bankBookingDate',
    order: -1,
  };
  const [sortState, setSortState] = useState(defaultSortState);

  const [allocationVisible, setAllocationVisible] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState<ExtendedBankTransaction[]>([]);
  const [transactionIdsFromUrlParams, setTransactionIdsFromUrlParams] = useState<number[]>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();

  const value = useMemo(() => ({
    bankTransactionListState,
    setBankTransactionListState,
    filterState,
    setFilterState,
    initialFilterUpdate,
    setInitialFilterUpdate,
    sortState,
    setSortState,
  }), [bankTransactionListState,
    setBankTransactionListState,
    filterState,
    setFilterState,
    initialFilterUpdate,
    setInitialFilterUpdate,
    sortState,
    setSortState]);

  const drawerContextValue = useMemo(() => ({
    allocationVisible,
    setAllocationVisible,
    selectedTransactions,
    setSelectedTransactions,
    transactionIdsFromUrlParams,
    setTransactionIdsFromUrlParams,
    selectedRowKeys,
    setSelectedRowKeys,
  }), [allocationVisible,
    setAllocationVisible,
    selectedTransactions,
    setSelectedTransactions,
    transactionIdsFromUrlParams,
    setTransactionIdsFromUrlParams,
    selectedRowKeys,
    setSelectedRowKeys]);


  return (
    <BankTransactionListContext.Provider value={value}>
        <BankTransactionListDrawerContext.Provider value={drawerContextValue}>
          {children}
        </BankTransactionListDrawerContext.Provider>
    </BankTransactionListContext.Provider>
  );
};

export default BankTransactionListContextProvider;
