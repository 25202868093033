import { getEnvVar } from 'lib/getEnvVar';

export const envString: string = getEnvVar('app.envString', 'local') as string;
export const autoDetectedIsMasterEnv: boolean = envString === 'staging'
  || envString === 'prod-replica'
  || envString === 'canary'
  || envString === 'production';
export const backendUrl: string = getEnvVar('app.backendUrl', 'http://localhost:9100') as string;
export const pdfProxyUrl: string = getEnvVar('app.pdfProxyUrl', 'https://pdf-proxy.app.impower.de') as string;
export const version: string = getEnvVar('app.version', `${new Date().getTime()}`) as string;
export const isMasterLikeEnv: boolean = getEnvVar('app.isMasterLikeEnv', `${autoDetectedIsMasterEnv}`) === 'true' as string;
export const isServiceWorkerEnabled: boolean = getEnvVar('app.isServiceWorkerEnabled', 'true') === ('true' as string);
export const PUBLIC_URL = new URL(process.env.PUBLIC_URL, window.location.href);
