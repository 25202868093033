import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Button from 'elements/Buttons/Button/Button';
import Icon from '@ant-design/icons';
import { Input } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { LanguageContext } from 'contexts/LanguageContext';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import DateInput from 'elements/Inputs/DateInput/DateInput';
import { ICONS } from 'components/icons';
import { PAGE_CONTENT_ID } from 'components/Layout/data';
import '../../../SearchBar.scss';
import { DATE_FORMAT, NUMBER_MATCHER } from 'lib/Utils';
import { FILTER_VALUE_DELIMITER } from 'services/SearchBarHooks/useSearchBar';
import { translations } from '../../../../../translations';
import { BreadcrumbInterface } from '../Breadcrumbs';
import {
  GlobalSearchContext, Page, SearchSuggestion,
} from '../../../services/GlobalSearchContext';
import './ActiveFilter.scss';
import { SearchBarDropdown } from '../../SearchBarDropdown/SearchBarDropdown';


// making it case insensitive to cover `administrationType`, `propertyAdministrationType` etc.
const ADMINISTRATION_TYPE_REGEX = new RegExp(/administrationType/i);


export const ActiveFilter = ({ filter, editingEnabled }: { filter: BreadcrumbInterface, editingEnabled: boolean }) => {
  const { tl } = useContext(LanguageContext);
  const {
    onSearchValueChanged,
    dropdownVisible,
    setDropdownVisible,
    filterDropdownVisible,
    setFilterDropdownVisible,
    currentlyEditedBreadcrumb,
    setCurrentlyEditedBreadcrumb,
    selectedSuggestionIndex,
    setSelectedSuggestionIndex,
  } = useContext(GlobalSearchContext);

  const [filterEditable, setFilterEditable] = useState(false);
  const [currentFilterNameInEditing, setCurrentFilterNameInEditing] = useState(false);
  const [filterString, setFilterString] = useState('');

  const {
    pages, breadcrumbs, removeBreadcrumb, replaceBreadcrumb,
  } = useContext(GlobalSearchContext);

  const removeFilter = () => {
    const { setFilter } = pages.filter((page: Page) => page.filterProps)[0].filterProps;
    let value;
    if (!filter.multiValue) {
      value = null;
    } else {
      value = breadcrumbs.filter((bc: BreadcrumbInterface) => bc.breadcrumbType === 'FILTER' && bc.key === filter.key && bc.matchingValue !== filter.matchingValue)
        .map((bc: BreadcrumbInterface) => bc.matchingValue);
    }
    setFilter(filter.key, value);
    setFilterEditable(false);
    removeBreadcrumb((breadcrumb: BreadcrumbInterface) => breadcrumb.key === filter.key && breadcrumb.matchingValue === filter.matchingValue);
    hideDropdown();
    setDropdownVisible(false);
  };

  const editBreadcrumb = () => {
    const { setFilter } = pages.filter((page: Page) => page.filterProps)[0].filterProps;

    const index = breadcrumbs.findIndex((bc: BreadcrumbInterface) => bc.key === filter.key);
    if (index !== -1) {
      const newBreadcrumb = breadcrumbs[index];
      let value;
      if (filter.type === 'date') {
        filter.translatedValue = filterString;
        const filterMoment = moment.utc(filterString, DATE_FORMAT);
        filter.matchingValue = filterMoment.toISOString();
        value = filter.matchingValue;
      } else if (filter.type === 'decimal') {
        filter.translatedValue = filterString;
        filter.matchingValue = filterString;
        value = parseFloat(filterString.replaceAll(',', '.'));
      } else {
        filter.translatedValue = filterString;
        filter.matchingValue = filterString;
        value = filterString;
      }

      setFilter(filter.key, value);
      replaceBreadcrumb(index, newBreadcrumb);
    }
    setFilterEditable(false);
  };

  const editEnumBreadcrumb = (filterCriteria: SearchSuggestion['filterProps']['matchingValue'], filterKey: string) => {
    const matchedBreadcrumbs = breadcrumbs.filter((bc: BreadcrumbInterface) => bc.key === filterKey);
    const identicalBreadcrumbs = matchedBreadcrumbs.filter((bc: BreadcrumbInterface) => bc.matchingValue === filterCriteria);

    if (identicalBreadcrumbs.length === 0) {
      const currentBreadcrumb = matchedBreadcrumbs.filter((bc: BreadcrumbInterface) => (bc.options?.findIndex((option: SelectOption) => option.value === bc.matchingValue)) === selectedSuggestionIndex);
      const index = breadcrumbs.indexOf(currentBreadcrumb[currentBreadcrumb.length - 1]);

      if (index !== -1) {
        const newBreadcrumb = breadcrumbs[index];

        const { setFilter } = pages.filter((page: Page) => page.filterProps)[0].filterProps;


        if (newBreadcrumb.multiValue) {
          const currentMatchedBreadcrumb = matchedBreadcrumbs.find(breadcrumb => breadcrumb === newBreadcrumb);
          currentMatchedBreadcrumb.matchingValue = filterCriteria;
          const newFilterValues = matchedBreadcrumbs.map(matchedBreadcrumb => matchedBreadcrumb.matchingValue);
          setFilter(newBreadcrumb.key, newFilterValues);
        } else {
          setFilter(newBreadcrumb.key, filterCriteria);
        }

        newBreadcrumb.matchingValue = filterCriteria;

        if (newBreadcrumb.type === 'enum') {
          newBreadcrumb.translatedValue = newBreadcrumb.options?.filter((so: SelectOption) => so.value === filterCriteria)
            .map((so: SelectOption) => so.label)[0];
          setFilterEditable(false);
        }
        replaceBreadcrumb(index, newBreadcrumb);
      }
    }

    hideDropdown();
    setDropdownVisible(false);
  };


  const showDropdown = () => {
    if (filterDropdownVisible) {
      setFilterDropdownVisible(false);
    }
    const index = filter.options?.findIndex((option: SelectOption) => option.value === filter.matchingValue);
    setSelectedSuggestionIndex(index);
    setFilterDropdownVisible(true);
    const pageContentElement = document.getElementById(PAGE_CONTENT_ID);
    if (pageContentElement) {
      pageContentElement.style.filter = 'blur(2.0rem)';
    }
    onSearchValueChanged(filter?.translatedValue ? filter.name : '');
    setCurrentlyEditedBreadcrumb(filter);
  };

  const hideDropdown = () => {
    setFilterDropdownVisible(false);
    const pageContentElement = document.getElementById(PAGE_CONTENT_ID);
    if (pageContentElement) {
      pageContentElement.style.filter = 'none';
    }
  };

  const filterValueValid = useMemo(() => {
    const filterValue = filterString;

    if (_.isEmpty(filterString) || !filter) {
      return false;
    }

    if (filter.matcher) {
      return filter.matcher.test(filterValue);
    }

    switch (filter.type) {
      case 'number':
        if (!NUMBER_MATCHER.test(filterValue)) {
          return false;
        }
        break;
      case 'date':
        if (!moment.utc(filterValue, DATE_FORMAT).isValid()) {
          return false;
        }
        break;
      default:
    }

    return true;
  }, [filterString]);

  useEffect(() => {
    onSearchValueChanged(filter?.translatedValue ? filter.name : '');
  }, [filter.translatedValue]);

  useEffect(() => {
    if (filter.type === 'date' && filterValueValid && filter.matchingValue !== filterString) {
      editBreadcrumb();
    }
  }, [filterString, filterValueValid]);

  useEffect(() => {
    if (dropdownVisible) {
      setFilterDropdownVisible(false);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (!filterDropdownVisible || currentlyEditedBreadcrumb !== filter) {
      setCurrentFilterNameInEditing(false);
    } else if (currentlyEditedBreadcrumb === filter) {
      setCurrentFilterNameInEditing(true);
    }
  }, [currentlyEditedBreadcrumb, filterDropdownVisible]);

  const onSelectSuggestion = (value: SearchSuggestion) => {
    if (value.filterProps?.type === 'enum') {
      editEnumBreadcrumb(value.filterProps?.matchingValue || '', value.filterProps?.key || '');
    } else {
      editBreadcrumb();
    }
    hideDropdown();
  };

  const enableEditMode = () => {
    if (editingEnabled) {
      if (filter.type === 'enum') {
        showDropdown();
        setCurrentFilterNameInEditing(true);
        if (dropdownVisible) {
          setDropdownVisible(false);
        }
      } else {
        setFilterString(String(filter.matchingValue));
        setFilterEditable(true);
      }
    }
  };

  const applyInputFieldFilter = () => {
    if (filterValueValid) {
      setFilterEditable(false);
      editBreadcrumb();
    }
  };

  return (
    <div>
      {
        filterEditable
          ? (
            <div className="ActiveFilter">
              {
                filter && filter.type === 'date'
                  ? (
                    <div className="ant-input-grayed-background">
                      <span className="FilterName">{filter.name + FILTER_VALUE_DELIMITER}</span>
                      <DateInput
                        onChange={(value) => {
                          setFilterString(value ? value.format(DATE_FORMAT) : '');
                        }}
                        label=""
                        value={filterString ? moment.utc(filterString) : undefined}
                        onKeyDown={(e) => { if (e.key === 'Escape') { applyInputFieldFilter(); } }}
                        hardMaxDate={filter.maxDate}
                        hardMinDate={filter.minDate}
                      />
                    </div>
                  )
                  : (
                    <div
                      className="ant-input-grayed-background"
                      onBlur={() => applyInputFieldFilter()}
                    >
                      <span className="FilterName">{filter.name + FILTER_VALUE_DELIMITER}</span>
                      <Input
                        className={`${!filterValueValid ? 'invalid' : ''}`}
                        onChange={e => setFilterString(e.target.value)}
                        value={filterString}
                        onPressEnter={() => applyInputFieldFilter()}
                        placeholder={tl(translations.searchBar.placeholder)}
                        onFocus={(e) => {
                          hideDropdown();
                          setDropdownVisible(false);
                          e.target.select();
                        }}
                        autoFocus
                      />
                    </div>

                  )
              }
            </div>
          )
          : (
            <Button
              className={`ActiveFilter ${filter.className}`}
              type="text"
              onClick={() => {
                enableEditMode();
              }}
            >
              <div>
                <span className={`${currentFilterNameInEditing ? 'edited-filterName' : ''}`}>
                  {
                    ADMINISTRATION_TYPE_REGEX.test(filter.key)
                      ? filter.translatedValue
                      : filter.name + FILTER_VALUE_DELIMITER + filter.translatedValue
                  }
                </span>
                {editingEnabled && (
                  <Icon
                    className="icon"
                    component={ICONS.breadcrumbDelete}
                    onClick={(event) => {
                      event.stopPropagation();
                      removeFilter();
                    }}
                  />
                )
                }
              </div>
            </Button>
          )
      }
      <SearchBarDropdown
        visible={filterDropdownVisible}
        onSelected={onSelectSuggestion}
        hideDropdown={hideDropdown}
      />
    </div>
  );
};
