import React, {useContext, useEffect, useState} from 'react';
import './FileUpload.scss';
import Icon from '@ant-design/icons';
import {
  DocumentCreateDtoOcrStateEnum,
  DocumentCreateDtoSourceTypeEnum,
  DocumentCreateDtoTagCodeEnum,
  DocumentLegacyControllerApi,
} from 'api/document';
import { ICONS } from '../../../components/icons';
import { extractDocumentIdFromUrl, extractFileName } from '../../../lib/Utils';
import { useFileUpload } from './UseFileUpload';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import InputProps from '../InputProps';
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';
import Button from '../../Buttons/Button/Button';
import { AuthContext } from '../../../contexts/AuthContext';

export interface fileUploadProps extends InputProps<string> {
  name: string
  buttonLabel: string
  descriptionLabel: string
  onDeleteFile?: () => void
  onDownload?: (filePath: string) => void,
  accept?: string,
  hideDeleteButton?: boolean,
  propertyId?: number,
  documentTag?: DocumentCreateDtoTagCodeEnum,
  unitContractId?: number,
  sourceType?: DocumentCreateDtoSourceTypeEnum,
  ocrState?: DocumentCreateDtoOcrStateEnum,
}

export default function FileUpload(props: fileUploadProps): JSX.Element {
  const {
    value, disabled, name, descriptionLabel, buttonLabel, onDeleteFile, accept, hideDeleteButton, propertyId, documentTag, ocrState,
    unitContractId, sourceType,
  } = props;

  const [documentName, setDocumentName] = useState('');

  const { documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document'));

  useEffect(() => {
    const setFileDisplayName = async () => {
      const fileName = extractFileName(value);
      if (fileName) {
        setDocumentName(fileName);
      } else {
        const documentId = extractDocumentIdFromUrl(value);
        if (documentId) {
          const response = await documentControllerApi.getDocumentByIdUsingGET({ documentId });
          setDocumentName(response.name);
        }
      }
    };
    setFileDisplayName()
      .catch(error => console.error('Error while setting file display name:', error));
  }, [value]);

  const fileUploader = useFileUpload('', sourceType, ocrState);
  const { tl } = useContext(LanguageContext);


  const onChange = (event: any) => {
    if (event.target.value === value) return;
    fileUploader.onChange(event, (url: string) => props.onChange(url), propertyId, documentTag, unitContractId);
  };

  const onDelete = () => {
    if (onDeleteFile) {
      onDeleteFile();
    } else {
      fileUploader.onDeleteFile();
      props.onChange('');
    }
  };


  const onDownload = (e: any) => {
    e.preventDefault();
    fileUploader.onDownload(value);
  };

  const required = !!props.required;

  const onClickUpload = () => {
    document.getElementById(`${name}-file-input`)!.click();
  };

  return (
    <div className="FileUpload">
      <div className="first-row">
        <label
          htmlFor={`${name}-file-input`}
          className="description-label"
        >
          {descriptionLabel}
          {required ? tl(translations.elements.fileUpload.required) : ' (optional)'}
        </label>
        <Button
          onClick={onClickUpload}
          className={`file-input-label ${disabled ? 'disabled' : ''}`}
        >
          <Icon component={ICONS.upload} />
          {buttonLabel}
        </Button>
        <input
          id={`${name}-file-input`}
          className="file-input"
          type="file"
          accept={accept || ''}
          value=""
          onChange={onChange}
          disabled={disabled || false}
        />
      </div>
      {fileUploader.uploading && <LoadingIndicator />}
      {!!value && (
        <div className="second-row">
          <Icon component={ICONS.file} />
          <div className="file-name">
            <a href={value} onClick={onDownload}>
              {documentName}
            </a>
          </div>
          {!hideDeleteButton
              && (
                <button className="delete-button" onClick={onDelete}>
                  <Icon component={ICONS.bin} />
                </button>
              )
          }
        </div>
      )}
    </div>
  );
}

FileUpload.defaultProps = {
  hideDeleteButton: false,
  sourceType: DocumentCreateDtoSourceTypeEnum.OTHER,
};
