import { LanguageContext } from 'contexts/LanguageContext';
import { uniqBy } from 'lodash';
import { DocumentDatasource } from 'pages/MessageSendingPage/interfaces';
import {
  useMessageSendingContext, useMessageSendingPropertyContext, useRecipientContext, useRecipientUpdaterContext,
} from 'pages/MessageSendingPage/services/MessageSendingContext';
import { useRecipientsSyncData } from 'pages/MessageSendingPage/services/useRecipientsSyncData';
import { useContext, useState } from 'react';

export const useRecipientCell = (record: DocumentDatasource) => {
  const { tl } = useContext(LanguageContext);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const recipientContext = useRecipientContext('useRecipientCell');
  const recipientUpdaterContext = useRecipientUpdaterContext('useRecipientCell');
  const messageSendingContext = useMessageSendingContext('useRecipientCell');
  const { documentRecipients } = recipientContext;
  const { setDocumentRecipients, setContracts } = recipientUpdaterContext;
  const { setDirty } = messageSendingContext;
  const propertyContext = useMessageSendingPropertyContext('useRecipientCell');
  const { properties, propertyConnections } = propertyContext;

  const recipientsForDocument = documentRecipients.filter(dr => dr.documentId === record.id);
  const numberOfRecipients = recipientsForDocument.length;

  const {
    loadContractSyncData, loadContactSyncData,
  } = useRecipientsSyncData();

  const onClick = () => {
    setModalVisible(true);
  };

  const onCancel = () => {
    setModalVisible(false);
  };

  const onApply = (newContractContacts) => {
    setDocumentRecipients(prev => [...prev].filter(dr => dr.documentId !== record.id).concat(newContractContacts.map(cc => ({ ...cc, documentId: record.id }))));
    setContracts((prev) => {
      const tempContracts = [...prev.data];
      tempContracts.push(...newContractContacts);

      return prev.load(uniqBy(tempContracts, 'unitContractId'));
    });

    loadContractSyncData(newContractContacts.map(c => c.unitContractId), [{ id: propertyConnections[0].activePortals[0].id, appName: propertyConnections[0].activePortals[0].appName, managementCompanyId: propertyConnections[0].managementCompanyId }]);
    loadContactSyncData(newContractContacts.map(c => c.contactId), [{ id: propertyConnections[0].activePortals[0].id, appName: propertyConnections[0].activePortals[0].appName, managementCompanyId: propertyConnections[0].managementCompanyId }]);

    setDirty(true);
    setModalVisible(false);
  };

  const selectedContractContactsForDocument = recipientsForDocument.map(dr => ({ contractId: dr.contractId, contactId: dr.contactId }));
  const allSelectedContractContacts = documentRecipients.map(dr => ({ contractId: dr.contractId, contactId: dr.contactId }));

  return {
    numberOfRecipients,
    onClick,
    modalVisible,
    propertyId: record.propertyId,
    propertyHrId: properties.data?.find(p => p.id === record?.propertyId)?.propertyHrId,
    selectedContractContactsForDocument,
    allSelectedContractContacts,
    onCancel,
    onApply,
  };
};
