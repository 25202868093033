import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Modal } from 'antd';
import {
  ContactLegacyControllerApi,
  EconomicPlanControllerApi,
  GetUnitContractsUsingGETContractTypesEnum,
  GetWkasPagedUsingGETOrderEnum,
  HouseMoneySettlementAggregationControllerApi,
  OwnersMeetingInvitationControllerApi,
  UnitContractControllerApi,
  UnitLegacyControllerApi,
  WkaControllerApi,
} from 'api/accounting/apis';
import {
  DocumentProjectionDtoSourceTypeEnum,
  DocumentProjectionDtoStateEnum,
} from 'api/document';
import {
  DocumentApi,
  GetDocumentsByFilterUsingGETOrderEnum,
  GetDocumentsByFilterUsingGETSourceTypeEnum,
} from 'api/public';
import FileSaver from 'file-saver';
import { buildDocumentRecipients } from 'lib/messageUtils';
import _ from 'lodash';
import moment from 'moment';
import { getEconomicPlanAccountBasedViewPath } from 'pages/EconomicPlan/routes';
import {
  getHouseMoneySettlementOverviewRoute,
} from 'pages/HouseMoneySettlement/routes';
import {
  MessageSendingInitializationStateType,
} from 'pages/MessageSendingPage/interfaces';
import {
  ownersMeetingInvitationTranslations,
} from 'pages/OwnersMeetingInvitation/OwnersMeetingInvitationEditing/translations/OwnersMeetingInvitationTranslations';
import {
  getSendRouteForOwnersMeetingInvitation,
} from 'pages/OwnersMeetingInvitation/routes';
import { getWkaEditingRoute } from 'pages/Wka/routes';
import { useHistory } from 'react-router';
import {
  useActiveContractsList,
} from 'services/UnitContractsList/useActiveContractsList';
import { useGetPropertyById } from 'services/useGetPropertyById';

import { extractSubject } from 'pages/OwnersMeetingInvitation/OwnersMeetingInvitationEditing/services/utilExtractSubjectFromTemplate';
import {
  ContactLegacyDto,
  ContactLegacyDtoDispatchPreferencesEnum,
  ContractProjectionDto,
  DocumentProjectionDto,
  EconomicPlanSimpleProjection,
  EconomicPlanSimpleProjectionStatusEnum,
  EconomicPlanSimpleProjectionTypeEnum,
  HouseMoneySettlementSimpleProjection,
  OwnersMeetingInvitationDto,
  OwnersMeetingInvitationDtoStatusEnum,
  PageOfEconomicPlanSimpleProjection,
  PageOfWkaProjectionDto,
  UnitContractProjectionDto,
  WkaProjectionDto,
} from '../api/accounting';
import backend, { endpointUrls } from '../backend_api';
import { OwnersMeetingInvitationFE } from '../data/ownersMeeting';
import DEFAULT_DATA from '../lib/data';
import { showNotification } from '../lib/Notification';
import {
  DATE_FORMAT,
  deleteKey,
  formatDate,
} from '../lib/Utils';
import { AuthContext } from './AuthContext';
import { LanguageContext } from './LanguageContext';
import {
  getSectionErrors,
} from './util/OwnersMeetingInvitationSectionFieldsConfiguration';

export const OwnersMeetingInvitationContext: any = React.createContext({});

const PAGE_SIZE = 30;

const MAX_PAGE_SIZE = 10000;

export default function OwnersMeetingInvitationProvider({ children }: any) {
  const { tl } = useContext(LanguageContext);
  const isDirty = useRef(false);
  const { apiConfiguration, publicApiConfiguration } = useContext(AuthContext);
  const houseMoneySettlementController = new HouseMoneySettlementAggregationControllerApi(apiConfiguration('accounting'));
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));

  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const unitControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));
  const ownersMeetingInvitationControllerApi = new OwnersMeetingInvitationControllerApi(apiConfiguration('accounting'));
  const wkaControllerApi = new WkaControllerApi(apiConfiguration('accounting'));
  const documentApi = new DocumentApi(publicApiConfiguration('public'));

  const defaultFilterState = {
    pageSize: PAGE_SIZE,
    sort: 'id',
    order: 'DESC',
  };

  const defaultAgendaItem = {
    template: 'individuell',
  };

  const defaultOwnersMeeting = {
    status: 'DRAFT',
    agendaItems: [defaultAgendaItem],
    numberOfAgendaItems: 1,
    meetingType: 'REGULAR',
  };

  const defaultUnitsData = {
    unitNr: 0,
    portalNr: 0,
    postalNr: 0,
    emailNr: 0,
    unitOptionsList: [],
  };

  const [ownersMeetingListState, setOwnersMeetingListState] = useState(DEFAULT_DATA<OwnersMeetingInvitationFE[]>([]));
  const [ownersMeeting, setOwnersMeeting] = useState(DEFAULT_DATA<OwnersMeetingInvitationFE>(defaultOwnersMeeting));
  const [unitsData, setUnitsData] = useState(defaultUnitsData);
  const [selectedOwnersMeetingId, setSelectedOwnersMeetingId] = useState(null);
  const [economicPlans, setEconomicPlans] = useState(DEFAULT_DATA<EconomicPlanSimpleProjection[]>([]));
  const [wkas, setWkas] = useState(DEFAULT_DATA<WkaProjectionDto[]>([]));
  const [houseMoneySettlementEconomicYears, setHouseMoneySettlementEconomicYears] = useState<HouseMoneySettlementSimpleProjection[]>([]);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [unitContracts, setUnitContracts] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>([]));

  const [filterState, setFilterState] = useState<any>(defaultFilterState);
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const [sort, setSort] = useState({
    field: 'date',
    order: -1,
  });
  const sortRef: any = useRef();
  sortRef.current = sort;
  const history = useHistory();
  const lastRequestTimestamp = useRef<number | null>(null);

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadOwnersMeetingList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [filterState, sort]);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (selectedOwnersMeetingId) {
      onLoadOwnersMeeting();
      onValidateOwnersMeeting();
    }
  }, [selectedOwnersMeetingId]);

  const selectedPropertyId = useMemo(() => {
    if (!ownersMeeting.data) return null;
    return ownersMeeting.data.propertyId;
  }, [ownersMeeting.data]);

  const { property } = useGetPropertyById(selectedPropertyId);

  useEffect(() => {
    if (!ownersMeeting.data) {
      return;
    }
    if (ownersMeeting.data.propertyId) {
      onLoadUnitData(ownersMeeting.data.propertyId);
      onLoadUnits(ownersMeeting.data.propertyId);
      onLoadEconomicPlans();
      onLoadHouseMoneySettlementEconomicYears();
      onLoadWkas();
    }
  }, [selectedPropertyId]);

  const setSortField = (field: string) => {
    const order = sortRef.current.field === field ? sortRef.current.order * -1 : 1;
    setSort({
      field,
      order,
    });
  };

  const setDirty = (value: boolean) => {
    isDirty.current = value;
  };

  const processChanges = (newValue: OwnersMeetingInvitationFE) => {
    setOwnersMeeting((prevState: any) => {
      const prevValue = prevState.data;
      if (!_.isEqual(prevValue.propertyId, newValue.propertyId)) {
        deleteKey(newValue, 'houseMoneySettlementEconomicYear');
        deleteKey(newValue, 'houseMoneySettlementId');
        deleteKey(newValue, 'economicPlanId');
        deleteKey(newValue, 'signingPersonId');

        if (newValue.units) {
          for (let i = 0; i < newValue.units.length; i++) {
            deleteKey(newValue, `unit[${i}].houseMoneySettlementDocument`);
            deleteKey(newValue, `unit[${i}].economicPlanDocument`);
          }
        }
      }
      newValue.name = extractSubject(newValue.coverLetterHtml, newValue.meetingType);
      return prevState.load(newValue);
    });
  };

  const updateOwnersMeeting = (value: OwnersMeetingInvitationFE) => {
    if (!ownersMeeting.data) return;
    processChanges(value);
  };

  const convertOwnersMeetingBEModel = () => {
    const BEModel = _.clone(ownersMeeting.data);
    BEModel.withCoverLetter = undefined;
    BEModel.withAgenda = undefined;

    if (!BEModel) return BEModel;
    if (BEModel.startTime) {
      // @ts-ignore
      BEModel.startTime = BEModel.startTime.format('HH:mm:ss');
    }
    if (BEModel.endTime) {
      // @ts-ignore
      BEModel.endTime = BEModel.endTime.format('HH:mm:ss');
    }

    if (BEModel?.coverLetterHtml?.includes('style="font-size: 1.6rem; letter-spacing: 0px;"')) {
      BEModel.coverLetterHtml = BEModel.coverLetterHtml.replaceAll('style="font-size: 1.6rem; letter-spacing: 0px;"', '');
    }

    // only save those documentTypes which were 'saved'
    if (BEModel.documentTypes) BEModel.documentTypes = BEModel.documentTypes.filter((documentType: any) => documentType.saved);
    return BEModel;
  };

  const convertOwnersMeetingToFEModel = (data: OwnersMeetingInvitationDto) => {
    const newOwnersMeeting: any = _.clone(data);
    if (data.startTime) newOwnersMeeting.startTime = moment(data.startTime, 'HH:mm');
    if (data.endTime) newOwnersMeeting.endTime = moment(data.endTime, 'HH:mm');
    newOwnersMeeting.numberOfAgendaItems = 1;
    newOwnersMeeting.numberOfDocumentTypes = data.documentTypes ? data.documentTypes.length : 1;
    // set additional documents 'saved' property (so it can't be edited
    if (newOwnersMeeting.documentTypes) {
      newOwnersMeeting.documentTypes.forEach((documentType: any) => {
        documentType.saved = true;
      });
    }
    // set to true for formSection context
    newOwnersMeeting.withCoverLetter = true;
    newOwnersMeeting.withAgenda = true;
    return newOwnersMeeting;
  };

  const onLoadOwnersMeetingList = (resetPage: boolean = false, sorting: any = sortRef.current) => {
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    setOwnersMeetingListState(ownersMeetingListState.startLoading());
    backend
      .get(`${endpointUrls.OWNERS_MEETING_INVITATION}`, {
        ...filterState,
        page: resetPage ? 0 : ownersMeetingListState.page,
        size: PAGE_SIZE,
        order: sorting.order > 0 ? 'ASC' : 'DESC',
        sort: sorting.field,
      })
      .then((response: any) => {
        // do nothing if this is a response for an older request
        if (currentTimestamp !== lastRequestTimestamp.current) return;

        const { content, last } = response;
        setOwnersMeetingListState(ownersMeetingListState.loadPaged(content, resetPage, last));
      })
      .catch(() => {
        setOwnersMeetingListState(ownersMeetingListState.failed());
        showNotification({
          key: 'loadOwnersMeetingListError',
          message: tl(ownersMeetingInvitationTranslations.notifications.loadOwnersMeetingListError.message),
          type: 'error',
        });
      });
  };

  const onLoadOwnersMeeting = () => {
    if (!selectedOwnersMeetingId) return;
    setOwnersMeeting(ownersMeeting.startLoading());
    backend
      .get(`${endpointUrls.OWNERS_MEETING_INVITATION}/${selectedOwnersMeetingId}`, {})
      .then((response: any) => {
        setOwnersMeeting((ownersM) => ownersM.load(convertOwnersMeetingToFEModel(response)));
      })
      .catch(() => {
        setOwnersMeeting(ownersMeeting.failed());
        showNotification({
          key: 'loadOwnersMeetingError',
          message: tl(ownersMeetingInvitationTranslations.notifications.loadOwnersMeetingError.message),
          description: tl(ownersMeetingInvitationTranslations.notifications.loadOwnersMeetingError.description),
          type: 'error',
        });
      });
  };

  const onLoadUnits = (propertyId: number) => {
    unitControllerApi
      .getSimpleUnitsUsingGET({ propertyId })
      .then((unitResponse) => {
        setOwnersMeeting((prev) => {
          const newOmi = {
            ...prev.data,
            units: prev.data?.units?.map(u => ({
              ...u,
              unitRank: unitResponse.find(ur => ur.id === u.unitId)?.unitRank ?? u.id,
            })) ?? [],
          } as OwnersMeetingInvitationFE;
          return prev.load(newOmi);
        });
      })
      .catch(() => {
        showNotification({
          key: 'loadUnitsError',
          message: tl(ownersMeetingInvitationTranslations.notifications.loadUnitsError),
          type: 'error',
        });
      });
  };

  const handleError = (err: any, sectionIndex: number) => {
    if (err.status === 422) {
      setIsValid(false);
    } else {
      setDirty(true);
      console.error(err);
      setOwnersMeeting(ownersMeeting.failed());
      showNotification({
        key: 'saveError',
        message: tl(ownersMeetingInvitationTranslations.notifications.saveError.message),
        type: 'error',
      });
      return;
    }

    if (err.errorCode) {
      showNotification({
        key: 'saveOwnerMeetingInvitationWithValidationErrors',
        message: tl(ownersMeetingInvitationTranslations.notifications.validationErrors.message),
        description: tl(ownersMeetingInvitationTranslations.notifications.error[err?.errorCode]),
        type: 'error',
      });
      setOwnersMeeting(ownersMeeting.failed());
    } else {
      // @ts-ignore
      const currentSectionErrors: any = getSectionErrors(sectionIndex, err);

      if (!_.isEmpty(currentSectionErrors)) {
        setOwnersMeeting((ownersM: any) => ownersM.load(convertOwnersMeetingToFEModel(err.savedEntity), currentSectionErrors, true));
        showNotification({
          key: 'saveOwnersMeetingWithValidationErrors',
          message: tl(ownersMeetingInvitationTranslations.notifications.validationErrors.message),
          type: 'warning',
        });
      } else {
        setOwnersMeeting((ownersM: any) => ownersM.load(convertOwnersMeetingToFEModel(err.savedEntity), {}, true));
        showNotification({
          key: 'saveOwnersMeetingSuccess',
          message: tl(ownersMeetingInvitationTranslations.notifications.saveSuccess.message),
          type: 'success',
        });
      }
    }
  };

  const onSaveOwnersMeeting = (savingSectionNumber?: number, ownersMeetingId?: any) => {
    setOwnersMeeting(ownersMeeting.startLoading());
    setDirty(false);

    const data = convertOwnersMeetingBEModel();

    let p;
    if (!ownersMeetingId) {
      p = backend.post(`${endpointUrls.OWNERS_MEETING_INVITATION}`, data);
    } else {
      p = backend.put(`${endpointUrls.OWNERS_MEETING_INVITATION}`, data);
    }
    p.then((response: any) => {
      setOwnersMeeting((ownersM: any) => ownersM.load(convertOwnersMeetingToFEModel(response), {}, true));
      setIsValid(true);
      if (!ownersMeetingId) {
        history.replace(`/owners-meeting/invitation/edit/${response.id}`, { openSection: 1 });
      }
      showNotification({
        key: 'saveOwnersMeetingSuccess',
        message: tl(ownersMeetingInvitationTranslations.notifications.saveSuccess.message),
        type: 'success',
      });
    });
    p.catch((err) => {
      handleError(err, savingSectionNumber);
    });
    return p;
  };

  // it's okay to only load the active ones as we always generate documents for the active contracts
  const { activeContractsList } = useActiveContractsList({ propertyId: ownersMeeting.data?.propertyId, contractTypes: [GetUnitContractsUsingGETContractTypesEnum.OWNER] });

  const markETVSent = () => {
    setOwnersMeeting(prev => prev.startLoading());
    ownersMeetingInvitationControllerApi.markInvitationAsSentAndCreateProtocolUsingPOST({ ownersMeetingInvitationId: ownersMeeting.data.id })
      .then((resp) => {
        setOwnersMeeting((ownersM: any) => ownersM.load({ ...ownersM.data, status: resp.status }));
        onLoadOwnersMeetingList(true);
        if (resp.status === OwnersMeetingInvitationDtoStatusEnum.ERROR) {
          showNotification({
            key: 'sendOut',
            message: tl(ownersMeetingInvitationTranslations.notifications.sendOutError.message),
            type: 'error',
          });
        }
      })
      .catch((error) => {
        setOwnersMeeting((prev) => prev.failed());
        console.error(error);
        showNotification({
          key: 'sendOut',
          message: tl(ownersMeetingInvitationTranslations.notifications.sendOutError.message),
          type: 'error',
        });
      });
  };

  const navigateToMessageSending = (documents: DocumentProjectionDto[]) => {
    const documentRecipients = documents?.filter(d => d.contractId !== undefined).map((doc) => {
      const contract = activeContractsList.data?.find(c => c.unitContractId === doc.contractId);
      return { ...buildDocumentRecipients(doc?.id, [contract as unknown as ContractProjectionDto]), unitRank: contract?.unitRank };
    })
      .sort((a, b) => a.unitRank - b.unitRank);

    const messageSendingNavigationState: MessageSendingInitializationStateType = {
      sourceType: 'OM_INVITE',
      sourceId: ownersMeeting.data?.id,
      properties: [{
        propertyId: ownersMeeting.data?.propertyId,
        documents: [...documentRecipients],
      }],
    };

    const sendUrl = getSendRouteForOwnersMeetingInvitation(ownersMeeting.data?.id);
    history.push(sendUrl, messageSendingNavigationState);
  };

  const generateEtvDocumentsAndNavigate = () => {
    setOwnersMeeting((prev) => prev.startLoading());

    ownersMeetingInvitationControllerApi
      .createInvitationDocumentsUsingPOST({ ownersMeetingInvitationId: ownersMeeting.data.id })
      .then((documents: DocumentProjectionDto[]) => {
        setOwnersMeeting((prev) => prev.finishLoading());
        navigateToMessageSending(documents);
      })
      .catch((error) => {
        setOwnersMeeting((prev) => prev.failed());
        console.error(error);
        showNotification({
          key: 'sendOut',
          message: tl(ownersMeetingInvitationTranslations.notifications.sendOutError.documentGenerationError),
          type: 'error',
        });
      });
  };

  const getUrlOfSource = (document: DocumentProjectionDto) => {
    switch (document.sourceType) {
      case DocumentProjectionDtoSourceTypeEnum.ECONOMIC_PLAN:
        return property?.propertyHrId && ownersMeeting.data?.economicPlanId ? getEconomicPlanAccountBasedViewPath({ propertyHrId: property?.propertyHrId, economicPlanId: ownersMeeting.data?.economicPlanId }) : '';
      case DocumentProjectionDtoSourceTypeEnum.HOUSE_MONEY_SETTLEMENT:
        return property?.propertyHrId ? getHouseMoneySettlementOverviewRoute({ propertyHrId: property?.propertyHrId }) : '';
      case DocumentProjectionDtoSourceTypeEnum.HEATING_COST_DISTRIBUTION:
        return ownersMeeting.data?.wkaIds ? getWkaEditingRoute(document.sourceId) : '';
      default:
        return '';
    }
  };

  const createDocumentPromise = (sourceId: number, sourceType: GetDocumentsByFilterUsingGETSourceTypeEnum) =>
    documentApi.getDocumentsByFilterUsingGET({
      sourceId,
      sourceType,
      size: MAX_PAGE_SIZE,
      sort: 'unitRank',
      order: GetDocumentsByFilterUsingGETOrderEnum.ASC,
    });

  const getDocumentPromises = () => {
    const docPromises = [];
    if (ownersMeeting.data?.economicPlanId !== undefined) {
      docPromises.push(createDocumentPromise(ownersMeeting.data?.economicPlanId, GetDocumentsByFilterUsingGETSourceTypeEnum.ECONOMIC_PLAN));
    }

    if (ownersMeeting.data?.houseMoneySettlementId !== undefined) {
      docPromises.push(createDocumentPromise(ownersMeeting.data?.houseMoneySettlementId, GetDocumentsByFilterUsingGETSourceTypeEnum.HOUSE_MONEY_SETTLEMENT));
    }

    if (ownersMeeting.data && Array.isArray(ownersMeeting.data.wkaIds) && ownersMeeting.data.wkaIds.length > 0) {
      docPromises.push(...ownersMeeting.data?.wkaIds.map((id) => createDocumentPromise(id, GetDocumentsByFilterUsingGETSourceTypeEnum.HEATING_COST_DISTRIBUTION)));
    }

    return docPromises;
  };

  const showMissingDocsModal = (missingDocs) => {
    Modal.confirm({
      title: tl(ownersMeetingInvitationTranslations.sendWarningModal.title),
      content: (
        <div>
          {missingDocs.map((d) => {
            const url = getUrlOfSource(d);
            return (
              <>
                <>
                  {d.sourceType === DocumentProjectionDtoSourceTypeEnum.HEATING_COST_DISTRIBUTION
                    ? tl(ownersMeetingInvitationTranslations.sendWarningModal.contentLine1)(url, d.name)
                    : tl(ownersMeetingInvitationTranslations.sendWarningModal.contentLine2)(d.documentType, url)}
                </>
                <ul>
                  {d.contracts.map((c) => (
                    <li>{c?.mailingContact?.name}</li>
                  ))}
                </ul>
              </>
            );
          })}
          <p>{tl(ownersMeetingInvitationTranslations.sendWarningModal.contentLine3)}</p>
        </div>
      ),
      okText: tl(ownersMeetingInvitationTranslations.sendWarningModal.send),
      cancelText: tl(ownersMeetingInvitationTranslations.sendWarningModal.cancel),
      okButtonProps: { className: 'Button', loading: ownersMeeting.loading },
      cancelButtonProps: { className: 'Button' },
      onOk: () => generateEtvDocumentsAndNavigate(),
      closable: true,
      width: 450,
      className: 'SendEtvWarningModal',
    });
  };

  const getDocumentTypesWithContractsWhoAreMissingDocuments = (documentResp) => {
    const missingDocs = [];
    documentResp.forEach((r) => {
      const generatingOrDraft = r.content?.filter((d) => [DocumentProjectionDtoStateEnum.DRAFT, DocumentProjectionDtoStateEnum.GENERATING].includes(d.state));
      if (!_.isEmpty(generatingOrDraft)) {
        showNotification({
          key: 'documentsStillGenerating',
          message: tl(ownersMeetingInvitationTranslations.notifications.documentsStillGenerating.title),
          description: tl(ownersMeetingInvitationTranslations.notifications.documentsStillGenerating.descripiton),
          type: 'warning',
        });

        return;
      }
      const wkaName = wkas.data?.find((wka) => wka.id === r.content?.[0]?.sourceId)?.title;
      const contractsWithMissingDoc = activeContractsList.data?.filter((uc) => r.content?.filter((d) => d.unitContractId === uc.unitContractId || d.unitId === uc.unitId)?.length === 0);
      if (!_.isEmpty(r.content) && !_.isEmpty(contractsWithMissingDoc)) {
        missingDocs.push({
          sourceId: r.content?.[0]?.sourceId,
          sourceType: r.content?.[0]?.sourceType,
          contracts: contractsWithMissingDoc,
          name: wkaName,
        });
      }
    });

    return missingDocs;
  };

  const onSendOutOwnersMeeting = () => {
    if (!ownersMeeting.data) return;

    const docPromises = getDocumentPromises();

    if (!_.isEmpty(docPromises)) {
      Promise.all(docPromises)
        .then((documentResp) => {
          const missingDocs = getDocumentTypesWithContractsWhoAreMissingDocuments(documentResp);

          if (!_.isEmpty(missingDocs)) {
            showMissingDocsModal(missingDocs);
          } else {
            generateEtvDocumentsAndNavigate();
          }
        })
        .catch((err) => {
          console.error(err);
          showNotification({
            key: 'sendOut',
            message: tl(ownersMeetingInvitationTranslations.notifications.sendOutError.documentGenerationError),
            type: 'error',
          });
        });
    } else {
      generateEtvDocumentsAndNavigate();
    }
  };

  function getUnitsData(unitList: UnitContractProjectionDto[], mailingContacts: ContactLegacyDto[]) {
    const returnValue: any = {
      unitNr: 0,
      portalNr: 0,
      postalNr: 0,
      emailNr: 0,
      unitOptionsList: [],
    };
    unitList.forEach((unit: any) => {
      returnValue.unitNr += 1;
      try {
        const item = {
          value: unit.unitId,
          label: `${unit.unitNrSharingDeclaration} ${unit.mailingContact.name}`,
        };
        returnValue.unitOptionsList.push(item);
        const contact = mailingContacts.find((mc) => mc.id === unit.mailingContact.contactId);
        if (contact?.dispatchPreferences?.includes(ContactLegacyDtoDispatchPreferencesEnum.PORTAL)) {
          returnValue.portalNr += 1;
        }
        if (contact?.dispatchPreferences?.includes(ContactLegacyDtoDispatchPreferencesEnum.EPOST)) {
          returnValue.postalNr += 1;
        }
      } catch (e) {
        console.error(e);
      }
    });
    return returnValue;
  }

  // Load unit contracts for shipping statistics
  const onLoadUnitData = (propertyId: number) => {
    setUnitContracts((prev) => prev.startLoading());
    unitContractControllerApi
      .getUnitContractsUsingGET({
        propertyId,
        atDate: formatDate(new Date(), 'YYYY-MM-DD'),
        contractTypes: ([GetUnitContractsUsingGETContractTypesEnum.OWNER] as unknown) as GetUnitContractsUsingGETContractTypesEnum,
      })
      .then((response: UnitContractProjectionDto[]) => {
        const activeContracts = response.filter((uc) => uc.unitContractId !== undefined);
        setUnitContracts((prevState) => prevState.load(activeContracts));
        contactControllerApi
          .getContactsUsingGET({ size: 2147483647, propertyId, role: 'OWNER' })
          .then((contactResponse) => {
            setUnitsData(getUnitsData(response, contactResponse.content));
          })
          .catch(() => {
            showNotification({
              key: 'loadOwnersMeetingError',
              message: tl(ownersMeetingInvitationTranslations.notifications.loadOwnersMeetingError.message),
              description: tl(ownersMeetingInvitationTranslations.notifications.loadOwnersMeetingError.description),
              type: 'error',
            });
          });
      })
      .catch(() => {
        setUnitContracts((prev) => prev.finishLoading());
        showNotification({
          key: 'loadOwnersMeetingError',
          message: tl(ownersMeetingInvitationTranslations.notifications.loadOwnersMeetingError.message),
          description: tl(ownersMeetingInvitationTranslations.notifications.loadOwnersMeetingError.description),
          type: 'error',
        });
      });
  };

  const saveUrlForUnit = (url: string, unitId: number, documentTypeName: string) => {
    if (!ownersMeeting) return;
    setOwnersMeeting((om: any) => {
      const newOwnersMeeting = _.cloneDeep(om.data);
      try {
        const unit = newOwnersMeeting.units.filter((u: any) => u.id === unitId)[0];
        const neededAdditionalDocuments = unit.additionalDocuments.filter((ad: any) => ad.typeName === documentTypeName);
        if (neededAdditionalDocuments.length === 0) {
          // no file saved yet
          unit.additionalDocuments.push({
            typeName: documentTypeName,
            url,
          });
        } else {
          // change the existing document
          neededAdditionalDocuments[0].url = url;
        }
        return om.load(newOwnersMeeting);
      } catch (e) {
        console.error(e);
        return om.failed();
      }
    });
  };

  const deleteUrlFromUnit = (unitId: number, documentTypeName: string) => {
    // clone to be able to delete (don't use the `clone` function, that breaks the date type)
    const newOwnersMeeting = _.clone(ownersMeeting.data);
    if (!newOwnersMeeting) return;
    try {
      const unit = newOwnersMeeting.units!.filter((u: any) => u.id === unitId)[0];
      // delete the document object
      unit.additionalDocuments = unit.additionalDocuments!.filter((ad: any) => ad.typeName !== documentTypeName);
      // update the object
      setOwnersMeeting((om) => om.load(newOwnersMeeting));
    } catch (e) {
      console.error(e);
    }
  };

  const onClearOwnersMeeting = () => {
    setOwnersMeeting(DEFAULT_DATA<any>(defaultOwnersMeeting));
    setSelectedOwnersMeetingId(null);
  };

  const clearFilter = () => {
    setFilterState(defaultFilterState);
  };

  const clearData = () => {
    setOwnersMeetingListState(DEFAULT_DATA<any>([]));
  };

  const downloadAllPdfs = () => {
    setDownloadInProgress(true);
    backend
      .getFileByPath(`${endpointUrls.OWNERS_MEETING_INVITATION}/${selectedOwnersMeetingId}/document`)
      .then((resp: any) => {
        if (!ownersMeeting.data) return;
        let fileName;
        try {
          fileName = `${formatDate(moment(), DATE_FORMAT)}_${ownersMeeting.data.property!.propertyIdInternal}_Eigentümerversammlung.zip`;
        } catch (e) {
          fileName = `${formatDate(moment(), DATE_FORMAT)}_Eigentümerversammlung.zip`;
        }
        const blob = new Blob([resp], { type: 'application/zip' });
        FileSaver.saveAs(blob, fileName);
      })
      .catch(() => {
        showNotification({
          key: 'fileDownloadError',
          message: tl(ownersMeetingInvitationTranslations.elements.fileUpload.downloadError.message),
          type: 'error',
        });
      })
      .finally(() => {
        setDownloadInProgress(false);
      });
  };

  const downloadPdfForUnit = () => {
    setDownloadInProgress(true);
    if (!ownersMeeting.data) return;
    const unitId = ownersMeeting.data.selectedUnitId;
    const unit = ownersMeeting.data.units!.filter((u: any) => u.unitId === unitId)[0];
    backend
      .getFileByPath(`${endpointUrls.OWNERS_MEETING_INVITATION}/${selectedOwnersMeetingId}/document/${unitId}`)
      .then((resp: any) => {
        if (!ownersMeeting.data) return;
        let fileName;
        try {
          fileName = `${formatDate(moment(), DATE_FORMAT)}_${ownersMeeting.data.property!.propertyIdInternal}_${unit.unitNrSharingDeclaration}_Eigentümerversammlung.pdf`;
        } catch (e) {
          fileName = `${formatDate(moment(), DATE_FORMAT)}_Eigentümerversammlung.pdf`;
        }
        const blob = new Blob([resp], { type: 'application/pdf' });
        FileSaver.saveAs(blob, fileName);
      })
      .catch(() => {
        showNotification({
          key: 'fileDownloadError',
          message: tl(ownersMeetingInvitationTranslations.elements.fileUpload.downloadError.message),
          type: 'error',
        });
      })
      .finally(() => {
        setDownloadInProgress(false);
      });
  };

  const onLoadEconomicPlans = () => {
    if (!ownersMeeting.data || !ownersMeeting.data.propertyId) return;
    economicPlanControllerApi
      .getAllUsingGET({ propertyId: ownersMeeting.data.propertyId })
      .then((response: PageOfEconomicPlanSimpleProjection) => {
        const statesForEpDocumeny = [EconomicPlanSimpleProjectionStatusEnum.PREPARED, EconomicPlanSimpleProjectionStatusEnum.DECIDED, EconomicPlanSimpleProjectionStatusEnum.ACTIVE];
        const fetchedEps: EconomicPlanSimpleProjection[] = response.content.filter((ep: EconomicPlanSimpleProjection) => ep.type === EconomicPlanSimpleProjectionTypeEnum.ACCOUNT_DISTRIBUTION && statesForEpDocumeny.includes(ep.status));
        setEconomicPlans((prev) => prev.load(fetchedEps));
      })
      .catch(() => {
        showNotification({
          key: 'loadEconomicPlanEconomicYearsError',
          message: tl(ownersMeetingInvitationTranslations.notifications.loadEconomicYearsError.message),
          type: 'error',
        });
      });
  };

  const onLoadWkas = () => {
    if (!ownersMeeting.data || !ownersMeeting.data.propertyId) return;
    wkaControllerApi
      .getWkasPagedUsingGET({
        propertyId: ownersMeeting.data.propertyId,
        order: GetWkasPagedUsingGETOrderEnum.DESC,
        sort: 'start_date',
        size: MAX_PAGE_SIZE,
      })
      .then((response: PageOfWkaProjectionDto) => {
        const fetchedWkas: WkaProjectionDto[] = response.content;
        setWkas((prev) => prev.load(fetchedWkas));
      })
      .catch(() => {
        showNotification({
          key: 'loadWkaError',
          message: tl(ownersMeetingInvitationTranslations.notifications.loadWkasError),
          type: 'error',
        });
      });
  };

  const onLoadHouseMoneySettlementEconomicYears = () => {
    if (!ownersMeeting.data || !ownersMeeting.data.propertyId) return;
    houseMoneySettlementController
      .getHouseMoneySettlementSimpleProjectionByPropertyIdUsingGET({ propertyId: ownersMeeting.data.propertyId })
      .then((response: any) => {
        setHouseMoneySettlementEconomicYears(response);
      })
      .catch(() => {
        showNotification({
          key: 'loadHouseMoneySettlementYearsError',
          message: tl(ownersMeetingInvitationTranslations.notifications.loadHouseMoneySettlementYearsError.message),
          type: 'error',
        });
      });
  };

  const onValidateOwnersMeeting = (): void => {
    backend
      .get(`${endpointUrls.OWNERS_MEETING_INVITATION}/${selectedOwnersMeetingId}/validate`, {})
      .then(() => {
        setIsValid(true);
      })
      .catch(() => {
        setIsValid(false);
      });
  };

  const onDeleteOwnersMeetingInvitation = (id: number, onSuccess?: Function) => {
    setOwnersMeetingListState((state) => state.startLoading());
    ownersMeetingInvitationControllerApi
      .deleteOwnersMeetingInvitationUsingDELETE({ ownersMeetingInvitationId: id })
      .then(() => {
        setOwnersMeetingListState((state) => {
          if (!state.data) return state;
          const tempOwnersMeetingInvitationList = state.data.filter((omi: OwnersMeetingInvitationFE) => omi.id !== id);
          return state.load(tempOwnersMeetingInvitationList);
        });
        showNotification({
          key: 'deleteOwnersMeetingInvitationSuccess',
          message: tl(ownersMeetingInvitationTranslations.notifications.deleteSuccess.message),
          type: 'success',
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        setOwnersMeetingListState((state) => state.failed());
        showNotification({
          key: 'deleteOwnersMeetingInvitationError',
          message: tl(ownersMeetingInvitationTranslations.notifications.deleteError.message),
          type: 'error',
        });
      });
  };

  const updateFilterState = (data: object) => {
    setFilterState({
      ...filterState,
      ...data,
    });
  };

  return (
    <OwnersMeetingInvitationContext.Provider
      value={{
        ownersMeetingListState,
        ownersMeeting,
        setOwnersMeeting,
        selectedOwnersMeetingId,
        setSelectedOwnersMeetingId,
        unitsData,
        economicPlans: economicPlans.data,
        houseMoneySettlementEconomicYears,
        wkas: wkas.data,
        isValid,
        downloadInProgress,
        filterState,
        unitContracts,
        setFilterState,
        clearFilter,
        clearData,
        onLoadOwnersMeetingList,
        onLoadReportForProperty: onLoadOwnersMeeting,
        saveUrlForUnit,
        deleteUrlFromUnit,
        updateOwnersMeeting,
        onLoadOwnersMeeting,
        onSaveOwnersMeeting,
        onSendOutOwnersMeeting,
        onClearOwnersMeeting,
        downloadAllPdfs,
        downloadPdfForUnit,
        onDeleteOwnersMeetingInvitation,
        updateFilterState,
        isDirty: isDirty.current,
        isDirtyRef: isDirty,
        setDirty,
        setSortField,
        sortField: sortRef.current.field,
        sortOrder: sortRef.current.order,
        markETVSent,
      }}
    >
      {children}
    </OwnersMeetingInvitationContext.Provider>
  );
}
