import { EconomicPlanControllerApi, EconomicPlanSimpleProjection, EconomicPlanSimpleProjectionStatusEnum, EconomicPlanSimpleProjectionTypeEnum, GetAllUsingGETOrderEnum, GetWkasPagedUsingGETOrderEnum, HouseMoneySettlementAggregationControllerApi, PropertyDisplayDtoAdministrationTypeEnum, WkaControllerApi } from 'api/accounting';
import { DocumentDtoSourceTypeEnum } from 'api/public';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { showNotification } from 'lib/Notification';
import { useContext, useEffect, useMemo, useState } from 'react';
import { calculateTokenValidityInSeconds, copyToClipboard, DATE_FORMAT, getCurrentEconomicYear, ISO_DATE_FORMAT, MAX_PAGE_SIZE } from 'lib/Utils';
import moment, { Moment } from 'moment';
import SelectOptionWithTwoLines from 'storybook-components/inputs/select/SelectOptionWithTwoLines/SelectOptionWithTwoLines';
import { Configuration, UserAccountControllerApi } from 'api/user';
import { Permissions } from 'lib/userPermissionUtils';
import { isEmpty, uniqBy } from 'lodash';
import * as config from 'config';
import { DocumentLegacyControllerApi, DocumentProjectionDto, FindDocumentsFilteredUsingGETSourceTypesEnum } from 'api/document';
import { GlobalFilter } from 'services/AuditingGlobalFilterStore/interfacesAuditingGlobalFilter';
import { accountsOverviewTranslations } from '../../translations';

export enum FinancialDataAccessOptions {
  UNLIMITED = 'UNLIMITED',
  LIMITED = 'LIMITED',
}

type SourceDocument = {
  sourceType: DocumentDtoSourceTypeEnum;
  sourceIds: number;
  value: string;
};

type SharedDocumentsOptions = {
  label: string;
  value: string;
  economicYearStart: Moment;
};

export const useModalShareAccountingData = (visible: boolean) => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration, documentApiConfiguration } = useContext(AuthContext);
  const { selectedProperty } = useContext(PropertyListContext);
  const houseMoneySettlementController = new HouseMoneySettlementAggregationControllerApi(apiConfiguration('accounting'));
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));
  const wkaControllerApi = new WkaControllerApi(apiConfiguration('accounting'));
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document'));
  const userAccountControllerApi = new UserAccountControllerApi((apiConfiguration('user') as unknown) as Configuration);
  const [linkExpirationDate, setLinkExpirationDate] = useState<Moment>();
  const [financialDataAccess, setFinancialDataAccess] = useState<FinancialDataAccessOptions>();
  const [timeframeForSharedData, setTimeframeForSharedData] = useState<[Moment, Moment]>();
  const [sourceDocuments, setSourceDocuments] = useState<SourceDocument[]>([]);
  const [sharedDocumentsOptions, setSharedDocumentsOptions] = useState<SharedDocumentsOptions[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState(false);

  const isSelectedPropertyWEG = selectedProperty?.data?.administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG;
  const economicYearRange = getCurrentEconomicYear(selectedProperty?.data?.economicYearStart);

  useEffect(() => {
    if (!visible) return;

    setFinancialDataAccess(isSelectedPropertyWEG ? FinancialDataAccessOptions.LIMITED : FinancialDataAccessOptions.UNLIMITED);
    const startOfLastYear = moment(economicYearRange?.economicYearStart).subtract(1, 'year');
    const endOfLastYear = moment(economicYearRange?.economicYearEnd).subtract(1, 'year');
    setTimeframeForSharedData([startOfLastYear, endOfLastYear]);
    setLinkExpirationDate(moment().add(1, 'month'));
  }, [visible]);

  useEffect(() => {
    if (financialDataAccess === FinancialDataAccessOptions.LIMITED && isSelectedPropertyWEG) {
      setSourceDocuments(getDefaultDocuments(economicYearRange?.economicYearStart));
    }
  }, [financialDataAccess, sharedDocumentsOptions]);

  useEffect(() => {
    if (financialDataAccess === FinancialDataAccessOptions.LIMITED && selectedProperty.data.id && isEmpty(sharedDocumentsOptions)) {
      loadAllDocuments();
    }
  }, [selectedProperty.data, financialDataAccess]);

  const getDefaultDocuments = (economicYearStart) => {
    const defaultEpSelected = sharedDocumentsOptions.filter((doc) => doc.value.includes(DocumentDtoSourceTypeEnum.ECONOMIC_PLAN.toString()) && doc.economicYearStart.isSame(moment(economicYearStart).add(1, 'year'), 'day'));

    const defaultHGA = sharedDocumentsOptions.filter((doc) => doc.value.includes(DocumentDtoSourceTypeEnum.HOUSE_MONEY_SETTLEMENT.toString()) && doc.economicYearStart.isSame(moment(economicYearStart).subtract(1, 'year'), 'day'));

    return [...defaultHGA, ...defaultEpSelected]
      .filter((doc) => doc)
      .map((doc) => {
        const [sourceType, sourceIds] = doc.value.split('-');
        return { sourceIds: parseInt(sourceIds, 10), sourceType, value: doc.value } as SourceDocument;
      });
  };
  const getApiCallForSourceType = (propertyId, sourceType) => {
    switch (sourceType) {
      case DocumentDtoSourceTypeEnum.ECONOMIC_PLAN:
        return economicPlanControllerApi.getAllUsingGET({ propertyId, sort: 'created', order: GetAllUsingGETOrderEnum.DESC });
      case DocumentDtoSourceTypeEnum.HEATING_COST_DISTRIBUTION:
        return wkaControllerApi.getWkasPagedUsingGET({
          propertyId,
          order: GetWkasPagedUsingGETOrderEnum.DESC,
          sort: 'start_date',
          size: MAX_PAGE_SIZE,
        });
      case DocumentDtoSourceTypeEnum.HOUSE_MONEY_SETTLEMENT:
        return houseMoneySettlementController.getHouseMoneySettlementSimpleProjectionByPropertyIdUsingGET({ propertyId });
      case DocumentDtoSourceTypeEnum.PROFIT_AND_LOSS:
      case DocumentDtoSourceTypeEnum.SPECIAL_CONTRIBUTION:
        return documentControllerApi.findDocumentsFilteredUsingGET({
          propertyId,
          sourceTypes: ([sourceType] as unknown) as FindDocumentsFilteredUsingGETSourceTypesEnum,
          size: MAX_PAGE_SIZE,
        });
      default:
        return Promise.resolve([]);
    }
  };

  const loadDocuments = (propertyId, sourceType) =>
    getApiCallForSourceType(propertyId, sourceType)
      .then((response) => {
        if (!response) return [];
        if (sourceType === DocumentDtoSourceTypeEnum.HOUSE_MONEY_SETTLEMENT) {
          return response
            .sort((a, b) => b.economicYear - a.economicYear)
            .map((doc) => ({
              value: `${sourceType}-${doc.id}`,
              label: `Hausgeldabrechnung ${doc.economicYear}`,
              economicYearStart: moment(`${doc.economicYear}-01-01`),
            }));
        }
        if (sourceType === DocumentDtoSourceTypeEnum.ECONOMIC_PLAN) {
          const statesForEpDocumeny = [EconomicPlanSimpleProjectionStatusEnum.PREPARED, EconomicPlanSimpleProjectionStatusEnum.DECIDED, EconomicPlanSimpleProjectionStatusEnum.ACTIVE];
          return response.content
            .filter((ep: EconomicPlanSimpleProjection) => ep.type === EconomicPlanSimpleProjectionTypeEnum.ACCOUNT_DISTRIBUTION && statesForEpDocumeny.includes(ep.status))
            .map((doc) => ({
              value: `${sourceType}-${doc.id}`,
              label: doc.title,
              economicYearStart: moment(doc.startDate),
            }));
        }

        if (sourceType === DocumentDtoSourceTypeEnum.HEATING_COST_DISTRIBUTION) {
          return response.content.map((doc) => ({
            value: `${sourceType}-${doc.id}`,
            label: doc.title,
          }));
        }

        const uniqDocuments = uniqBy(
          response.content.filter((doc) => doc.sourceId),
          'sourceId',
        ) as DocumentProjectionDto[];
        return uniqDocuments.map((doc) => ({ value: `${sourceType}-${doc?.sourceId}`, label: doc?.name }));
      })
      .catch(() => {
        showNotification({
          key: `load${sourceType}Error`,
          message: tl(accountsOverviewTranslations.notifications.error[sourceType]),
          type: 'error',
        });
        return [];
      });

  const loadAllDocuments = () => {
    if (!selectedProperty.data.id) return;

    const documentTypes = isSelectedPropertyWEG ? [DocumentDtoSourceTypeEnum.ECONOMIC_PLAN, DocumentDtoSourceTypeEnum.HOUSE_MONEY_SETTLEMENT, DocumentDtoSourceTypeEnum.HEATING_COST_DISTRIBUTION, DocumentDtoSourceTypeEnum.SPECIAL_CONTRIBUTION] : [DocumentDtoSourceTypeEnum.PROFIT_AND_LOSS];

    Promise.all(documentTypes.map((type) => loadDocuments(selectedProperty.data.id, type))).then((results) => {
      setSharedDocumentsOptions(results.flat());
    });
  };

  const selectedSourceDocuments = useMemo(() => sourceDocuments.map((doc) => doc.value), [sourceDocuments]);

  const generateFilter = () => {
    if (financialDataAccess === FinancialDataAccessOptions.LIMITED) {
      const groupedDocuments = Array.from(
        sourceDocuments
          .reduce((map, { sourceType, sourceIds }) => {
            if (!map.has(sourceType)) {
              map.set(sourceType, { sourceType, sourceIds: [] });
            }
            if (sourceIds) {
              map.get(sourceType).sourceIds.push(sourceIds);
            }
            return map;
          }, new Map())
          .values(),
      );

      const filter: GlobalFilter = {
        timeframe: {
          startDate: timeframeForSharedData[0].format(ISO_DATE_FORMAT),
          endDate: timeframeForSharedData[1].format(ISO_DATE_FORMAT),
        },
        sharedDocuments: [{ sourceType: DocumentDtoSourceTypeEnum.INVOICE }, ...groupedDocuments],
      };
      const jsonString = JSON.stringify(filter);

      const encodedGlobalFilter = btoa(jsonString);

      return `filters=${encodedGlobalFilter}`;
    }

    return undefined;
  };

  const onGenerateToken = () => {
    setLoading(true);
    return userAccountControllerApi
      .accessTokenUsingPOST({
        accessTokenCreateDto: {
          propertyIds: [selectedProperty.data.id],
          permissions: [Permissions.PERM_ACCOUNT_READ, Permissions.PERM_DOCUMENT_DOWNLOAD],
          tokenValidityInSeconds: calculateTokenValidityInSeconds(linkExpirationDate),
        },
      })
      .then((response) => {
        const filters = generateFilter();

        return `${config.PUBLIC_URL.origin}?authToken=${response.token}${filters ? `&${filters}` : ''}`;
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: tl(accountsOverviewTranslations.notifications.generateTokenError),
          key: 'generateTokenError',
          type: 'error',
        });
        return null;
      })
      .finally(() => setLoading(false));
  };

  const onCopyLink = () => {
    onGenerateToken()
      .then((link) => copyToClipboard(link, setIsCopied))
      .catch((err) => {
        console.error(err);
        showNotification({
          message: tl(accountsOverviewTranslations.notifications.copyLinkError),
          key: 'copyLinkError',
          type: 'error',
        });
      });
  };

  const onChangeSourceDocuments = (values) => {
    const selectedDocs = values.map((value) => {
      const [sourceType, sourceId] = value.split('-');
      return { sourceIds: sourceId, sourceType, value };
    });

    setSourceDocuments(selectedDocs);
  };

  const resetState = () => {
    setSharedDocumentsOptions([]);
    setLinkExpirationDate(undefined);
    setFinancialDataAccess(FinancialDataAccessOptions.UNLIMITED);
    setTimeframeForSharedData(undefined);
    setSourceDocuments([]);
  };

  const sendEmail = (shareUrl: string) => {
    const propertyName = selectedProperty?.data?.name;

    const subject = encodeURIComponent(`Überprüfung und Freigabe der Buchhaltungsunterlagen für ${propertyName}`);
    const body = encodeURIComponent(
      // newline may NOT be indented as we don't want indents in the email
      `Sehr geehrte Rechnungsprüfer,

hiermit erhalten Sie die Buchhaltungsdaten für das Objekt ${propertyName}

👉 Über den folgenden Link können Sie die Unterlagen direkt einsehen:

${shareUrl}

🔔 Wichtiger Hinweis: Der Link ist aus Sicherheitsgründen nur bis ${moment(linkExpirationDate).utc().format(DATE_FORMAT)} gültig. Bitte rufen Sie die Unterlagen zeitnah auf.

Für Rückfragen stehen wir Ihnen gerne zur Verfügung.

Mit freundlichen Grüßen
`,
    );

    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
    window.open(mailtoLink, '_blank');
  };

  const onGenerateTokenAndSendMail = () => {
    onGenerateToken()
      .then((link) => sendEmail(link))
      .catch((err) => {
        console.error(err);
        showNotification({
          message: tl(accountsOverviewTranslations.notifications.sendEmailError),
          key: 'sendEmailError',
          type: 'error',
        });
      });
  };

  const financialDataAccessOptions = Object.values(FinancialDataAccessOptions).map((value) => ({
    value: (value as unknown) as FinancialDataAccessOptions,
    label: tl(accountsOverviewTranslations.shareAccountingDataModal.financialDataAccessOptions[value]?.title),
    customBody: <SelectOptionWithTwoLines primaryText={tl(accountsOverviewTranslations.shareAccountingDataModal.financialDataAccessOptions[value]?.title)} secondaryText={tl(accountsOverviewTranslations.shareAccountingDataModal.financialDataAccessOptions[value]?.details)} />,
  }));

  const isLimitedAccess = financialDataAccess === FinancialDataAccessOptions.LIMITED;

  const areButtonsDisabled = isLimitedAccess ? !linkExpirationDate || isEmpty(timeframeForSharedData) : !linkExpirationDate;

  return {
    linkExpirationDate,
    setLinkExpirationDate,
    financialDataAccessOptions,
    financialDataAccess,
    setFinancialDataAccess,
    timeframeForSharedData,
    setTimeframeForSharedData,
    sharedDocumentsOptions,
    selectedSourceDocuments,
    onChangeSourceDocuments,
    onCopyLink,
    onGenerateTokenAndSendMail,
    isLimitedAccess,
    areButtonsDisabled,
    isCopied,
    loading,
    resetState,
    minDateLinkEpiration: moment().utc(),
    maxDateLinkEpiration: moment().utc().add(2, 'months'),
  };
};
