import './InvoiceList.scss';

import React, {
  useContext,
  useEffect,
  useMemo,
} from 'react';

import {
  BreadcrumbInterface,
} from 'components/Header/components/SearchBar/components/Breadcrumbs/Breadcrumbs';
import {
  GlobalSearchContext,
} from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { ICONS } from 'components/icons';
import { AuthContext } from 'contexts/AuthContext';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { InvoiceListContext } from 'contexts/InvoiceListContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { Action } from 'elements/SmartTable/data';
import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { DATE_FORMAT } from 'lib/Utils';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  DropdownButton,
} from 'storybook-components/DropdownButton/DropdownButton';

import {
  InvoiceLegacyDto,
  InvoiceLegacyProjectionStateEnum,
  PaymentDto,
} from '../../../api/accounting';
import {
  useGlobalSearch,
} from '../../../components/Header/components/SearchBar/services/useGlobalSearch';
import {
  DeletionModal,
} from '../../../elements/Modals/DeletionModal/DeletionModal';
import { useModal } from '../../../elements/Modals/useModal/useModal';
import useSiteMap from '../../../services/useSiteMap/useSiteMap';
import MainPageHeader
  from '../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import { invoiceListTranslations } from './translations';
import useInvoiceFilters from './UseInvoiceFilters';
import { useInvoiceListColumns } from './UseInvoiceListColumns';

export default function InvoiceList(): JSX.Element {
  const invoiceListContext: any = useContext(InvoiceListContext);
  const invoiceEditingContext = useContext(InvoiceEditingContext);
  const { breadcrumbs, addBreadcrumb } = useContext(GlobalSearchContext);
  const { name } = useContext(AuthContext);


  const { tl } = useContext(LanguageContext);
  const fileInputRef = React.createRef<HTMLInputElement>();
  const { counterpartContactId } = useParams<{ counterpartContactId: string }>();

  const history = useHistory();
  const { propertyHrId } = useParams<{ propertyHrId: string }>();


  useGlobalSearch({
    key: 'invoices',
    filterProps: {
      availableFilters: useInvoiceFilters(),
      setFilter: (key: string, value: string) => invoiceListContext.setInvoiceFilterState(
        (currentFilter: any) => {
          const newFilter = _.cloneDeep(currentFilter);
          if (value === undefined || value === null) {
            // @ts-ignore
            delete newFilter[key];
          } else {
            newFilter[key] = value;
          }
          return newFilter;
        },
      ),
    },
    queryParamAsFilter: {
      onSetDefaultFilterFromQueryParams: invoiceListContext.onSetDefaultFilterFromQueryParams,
      filterState: invoiceListContext.invoiceFilterState,
    },
  });

  const deletionModal = useModal({ onOk: invoiceListContext.onDeleteDraftInvoices });

  useEffect(() => () => {
    invoiceListContext.onClearInvoiceListAndFilter();
  }, []);

  useEffect(() => {
    if (fileInputRef.current && queryString.parse(history.location.search).upload) {
      fileInputRef.current.click();
      // trigger file upload only once
      // Replace allows to go back directly to prev path
      history.replace(history.location.pathname);
    }
  }, [fileInputRef]);

  useEffect(() => {
    if (propertyHrId) {
      invoiceListContext.setInvoiceFilterState((currentState: any) => ({
        ...currentState.data,
        propertyHrId,
      }));
    }
  }, [propertyHrId]);

  function setGlobalFilter(value: string) {
    const filter = {
      type: 'text' as const,
      name: tl(invoiceListTranslations.table.headers.businessPartnerName),
      key: 'counterpartName',
      pageKey: 'invoices',
      matchingValue: value,
    };

    const breadcrumb = {
      ...filter,
      breadcrumbType: 'FILTER' as const,
      translatedValue: value,
    };

    addBreadcrumb(breadcrumb);
  }


  useEffect(() => {
    if (smartTable.onUnselectAll) smartTable.onUnselectAll();
  }, [invoiceListContext.invoiceFilterState, invoiceListContext.invoiceSort]);


  // TODO: this might not be needed, as the counterpartContactId is now a query param, not a navigation state
  useEffect(() => {
    setTimeout(() => {
      const invoiceBreadcrumb = breadcrumbs.find((breadcrumb: BreadcrumbInterface) => breadcrumb.label === tl(invoiceListTranslations.title));
      const creditorBreadcrumb = breadcrumbs.find((breadcrumb: BreadcrumbInterface) => breadcrumb.key === 'counterpartContactId');
      if (counterpartContactId && invoiceBreadcrumb && !invoiceListContext.counterpartFilter && creditorBreadcrumb === undefined) {
        setGlobalFilter(counterpartContactId);
        invoiceListContext.setInvoiceFilterState((currentState: any) => ({
          ...currentState.data,
          counterpartContactId,
        }));
        invoiceListContext.setCounterpartFilter(true);
      }
    }, 0);
  }, [breadcrumbs]);


  const toTableData = (invoice: any): any => ({
    ...invoice,
    totalGross: invoice.totalGross,
    processingDate: invoice.invoiceDate ? moment(invoice.invoiceDate).format(DATE_FORMAT) : null,
    paymentTargetDate: invoice.paymentTargetDate ? moment(invoice.paymentTargetDate).format(DATE_FORMAT) : null,
    created: invoice.created ? moment(invoice.created).format(DATE_FORMAT) : null,
    key: invoice.invoiceHrId,
    status: invoice.state ? invoiceListContext.translateInvoiceStates(invoice.state) : null,
    statusRaw: invoice.state,
  });

  const actionsMenu: Action[] = [{
    label: tl(invoiceListTranslations.reject),
    onAction: (record: PaymentDto) => {
      const param = record.invoiceHrId ? [record.invoiceHrId] : [];
      invoiceListContext.onRejectInvoices(param);
    },
    onBatchAction: (records: PaymentDto[]) => {
      const params = records.map(record => record.invoiceHrId || '');
      invoiceListContext.onRejectInvoices(params);
    },
    actionSupported: () => false, // TODO PMP-8948 enable actions after switch to exchanges
  },
  {
    label: tl(invoiceListTranslations.delete),
    onAction: (record: InvoiceLegacyDto) => {
      const param = record.id ? [record.id] : [];
      deletionModal.showModal(param);
    },
    onBatchAction: (records: InvoiceLegacyDto[]) => {
      const params = records.map(record => record.id || '');
      deletionModal.showModal(params);
    },
    actionSupported: (record => record.state === InvoiceLegacyProjectionStateEnum.NEW),
  }];


  const tableData = useMemo(() => invoiceListContext.data.invoiceList.map(toTableData), [invoiceListContext.data.invoiceList]);
  const smartTable = useSmartTable(
    {
      tableName: 'invoiceList',
      columns: useInvoiceListColumns(),
      dataSource: tableData,
      propSort: {
        field: invoiceListContext.sortField,
        order: invoiceListContext.sortOrder,
        onSortChange: (dataKey: string) => invoiceListContext.setSortField(dataKey),
      },
      contentLoading: invoiceListContext.loading || invoiceEditingContext.loading,
      onRow: (record: any) => {
        const path = propertyHrId ? `/properties/${propertyHrId}/edit/invoice/edit/${record.invoiceHrId}` : `/invoice/edit/${record.invoiceHrId}`;
        return ({ onClick: () => history.push(path) });
      },
      infiniteScrollerProps: {
        hasMoreData: !invoiceListContext.data.lastPage,
        loadMoreData: invoiceListContext.onLoadInvoiceList,
      },
      supportBatchActions: false, // TODO PMP-8948 enable actions after switch to exchanges
      rowKey: 'id',
      actionsMenu,
    },
  );

  const uploadInvoice = (event: any): void => {
    if (event.target.files && event.target.files[0]) {
      invoiceEditingContext.startLoading();
      const fileReader = new FileReader();
      // extract name of file without extension
      const fileName = event.target.files[0].name.replace(/\.[^/.]+$/, '');
      fileReader.addEventListener('error', () => {
        invoiceEditingContext.onUploadError();
      });
      fileReader.addEventListener('load', (e: any) => {
        fetch(e.target.result)
          .then((resp) => {
            resp.blob()
              .then((blob) => {
                invoiceEditingContext.onUploadInvoice(blob, fileName);
              })
              .catch(() => {
                invoiceEditingContext.onUploadError();
              });
          })
          .catch(() => {
            invoiceEditingContext.onUploadError();
          });
      });
      fileReader.readAsDataURL(event.target.files[0]);
    }
  };

  const invoiceEmail = `rechnungen+${name}@impower.de`;
  const menuItems = [
    {
      key: 'UPLOAD',
      onClick: () => !!fileInputRef.current && fileInputRef.current.click(),
      label: tl(invoiceListTranslations.upload),
    },
    {
      key: 'MANUAL',
      onClick: () => history.push('/invoice/create'),
      label: tl(invoiceListTranslations.manual),
    },
    {
      key: 'EMAIL',
      onClick: () => window.open(`mailto:${invoiceEmail}?subject=${tl(invoiceListTranslations.email.subject)}&body=${tl(invoiceListTranslations.email.body)} ${encodeURIComponent(invoiceEmail)}`, '_blank'),
      label: tl(invoiceListTranslations.email.buttonLabel),
    },
  ];

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'invoices',
    navItems: subcategorySwitcherItems.accounting,
  };

  const headerRightSideComponent = (
    <>
      <DropdownButton
        dropdownClassName="dropdownButton"
        menuItems={menuItems}
        buttonLabel={tl(invoiceListTranslations.add)}
        icon={ICONS.triangleDown}
      />

      <input
        ref={fileInputRef}
        type="file"
        accept="application/pdf,.xml"
        className="invoice-input"
        onChange={uploadInvoice}
      />
    </>
  );

  return (
    <div className="InvoiceList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={headerRightSideComponent}

      />
      <SmartTable {...smartTable} />
      <DeletionModal {...deletionModal} />
    </div>
  );
}
