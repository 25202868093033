import { UnitContractProjectionDto } from "api/accounting";
import { ConnectionLegacyDto, GetSyncDataUsingGETResourceNameEnum, SyncDataApi, SyncDataDto, SyncDataDtoStateEnum } from "api/app";
import _ from "lodash";
import { isNil } from "lodash";
import { RecipientContractContact } from "../interfaces";
import { useSyncDataContext } from "./MessageSendingContext";
import { showNotification } from "lib/Notification";
import { MAX_PAGE_SIZE } from "lib/Utils";
import { messageSendingTranslations } from "../translations";
import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";
import { LanguageContext } from "contexts/LanguageContext";

const validateContractSyncData = (contract: UnitContractProjectionDto, contractsSyncData: SyncDataDto[]) => {
  const { unitContractId, contractGroupId } = contract;
  let contractSyncData;

  // for regular contracts and WEG tenant contracts linked to an SEV check own sync data
  if (isNil(contractGroupId) ||
    (!isNil(contractGroupId) && unitContractId === contractGroupId)) {
    contractSyncData = contractsSyncData.find(sd => sd.entityId === unitContractId);
  }

  // for SEV contracts, check sync data of linked WEG tenant contract
  if (!isNil(contractGroupId) && unitContractId !== contractGroupId) {
    contractSyncData = contractsSyncData.find(sd => sd.entityId === contractGroupId);
  }


  // not valid if no contractSyncData is found
  if (_.isEmpty(contractSyncData)) {
    return false;
  }

  // Check if contract is synced based on state and externalEntityId
  return [SyncDataDtoStateEnum.READY, SyncDataDtoStateEnum.SUCCESS].includes(contractSyncData.state) && !_.isEmpty(contractSyncData.externalEntityId);
};

const validateContactSyncData = (contractContact: RecipientContractContact, contactsSyncData: SyncDataDto[]): boolean => {
  const contactSyncData = contactsSyncData.find(cs => cs.entityId == contractContact.contactId);

  // not valid if no contactSyncData is found
  if (_.isEmpty(contactSyncData)) {
    return false;
  }

  // Check if contact is synced based on state and externalEntityId
  return [SyncDataDtoStateEnum.READY, SyncDataDtoStateEnum.SUCCESS].includes(contactSyncData.state) && !_.isEmpty(contactSyncData.externalEntityId);
};

export const useRecipientsSyncData = () => {

  const { tl } = useContext(LanguageContext);
  const syncDataContext = useSyncDataContext('useRecipientsSyncData');
  const {
    contractsSyncData, setContractsSyncData, contactsSyncData, setContactsSyncData,
  } = syncDataContext;

  const { appApiConfiguration } = useContext(AuthContext);
  const syncDataApi = new SyncDataApi(appApiConfiguration('app'));

  const loadContractSyncData = (contractIds: number[], connections?: ConnectionLegacyDto[]) => {
    if (_.isEmpty(connections)) {
      setContractsSyncData(prev => prev.load([]));
      return Promise.resolve({ connections });
    }

    setContractsSyncData((prev) => prev.startLoading());
    // load contract sync data
    syncDataApi.getSyncDataUsingGET({
      connectionId: connections[0].id,
      resourceName: GetSyncDataUsingGETResourceNameEnum.contracts,
      resourceIds: contractIds,
      size: MAX_PAGE_SIZE,
    })
      .then((resp) => {
        setContractsSyncData(prev => prev.load([...prev.data, ...resp.content]));
      })
      .catch((e) => {
        setContractsSyncData(prev => prev.failed());
        console.log(e);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.errorLoadingContractSyncData),
        });
      });
    return Promise.resolve({ connections });
  };

  const loadContactSyncData = (contactIds: number[], connections?: ConnectionLegacyDto[]) => {
    if (_.isEmpty(connections)) {
      setContactsSyncData(prev => prev.load([]));
      return Promise.resolve({ connections });
    }

    setContactsSyncData((prev) => prev.startLoading());
    // load contact sync data
    syncDataApi.getSyncDataUsingGET({
      connectionId: connections[0].id,
      resourceName: GetSyncDataUsingGETResourceNameEnum.contacts,
      resourceIds: contactIds,
      size: MAX_PAGE_SIZE,
    })
      .then((resp) => {
        setContactsSyncData(prev => prev.load([...prev.data, ...resp.content]));
      })
      .catch((e) => {
        console.log(e);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.errorLoadingContactSyncData),
        });
      });
    return Promise.resolve({ connections });
  };

  return {
    contractsSyncData,
    contactsSyncData,
    validateContractSyncData,
    validateContactSyncData,
    loadContractSyncData,
    loadContactSyncData,
  };
};