import {
  useContext, useEffect, useRef, useState,
} from 'react';
import moment from 'moment';
import { getAccountSheetPath } from 'pages/AccountSheet/routes';
import { DATE_FORMAT } from 'lib/Utils';
import { getOpenTransactionUrl } from 'pages/BankTransactions/routes';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../../elements/Translation/translations';
import { ContactListContext } from '../../../contexts/ContactListContext';
import DEFAULT_DATA from '../../../lib/data';
import { AuthContext } from '../../../contexts/AuthContext';
import {
  SliceOfPropertyDisplayDto, PropertyBankAccountProjectionDto, PropertyBankAccountProjectionDtoPropertyAccountTypesEnum, PropertyLegacyControllerApi, PropertyDisplayDto,
  AccountControllerApi,
  PropertyDisplayDtoAdministrationTypeEnum,
  PropertyDisplayDtoPropertyStateEnum,
} from '../../../api/accounting';
import { showNotification } from '../../../lib/Notification';

const PAGE_SIZE = 30;
export const DEFAULT_FILTER_BANK_ACCOUNT_LIST: any = {
  propertyStates: ['READY', 'OFFBOARDED'],
  excludeFields: ['buildings', 'units'],
  administrationTypes: ['WEG', 'MV'],
};

export interface BankAccountInfo {
  id: number;
  type: string;
  name: string;
  institute?: string;
  iban: string;
  bic: string;
  balance: number;
  balanceDate?: moment.Moment;
  transactionsUrl?: string;
  balanceDateString?: string;
  calculatedBalance?: number;
  accountingBalance?: number;
  accountCode?: string;
  accountUrl?: string;
}

export interface PropertyWithBankAccountInfo {
  propertyHrId: string;
  propertyIdInternal: string;
  propertyId: number;
  administrationType: PropertyDisplayDtoAdministrationTypeEnum;
  managementCompanyId?: number;
  name: string;
  propertyState: PropertyDisplayDtoPropertyStateEnum;
  supervisorName: string;
  administrator: string;
  houseMoneyBalance?: number;
  reserveMoneyBalance?: number;
  calculatedHouseMoneyBalance?: number;
  calculatedReserveMoneyBalance?: number;
  totalBalance?: number;
  totalBankBalance?: number;
  transactionsUrl: string,
  maxBankBalanceDate?: moment.Moment;
  maxBankBalanceDateString?: string;
  calculatedTotalBalance?: number;
  bankAccounts: BankAccountInfo[];
  accountCode?: string;
  accountUrl?: string;
  sumOfPropertyAccountBalances?: number;
}

export const useBankAccountList = () => {
  const contactListContext: any = useContext(ContactListContext);
  const { tl } = useContext(LanguageContext);

  const [bankAccountListState, setBankAccountListState] = useState(DEFAULT_DATA<PropertyWithBankAccountInfo[]>([]));
  const [filterState, setFilterState] = useState<any>({});
  const [bankAccountSortState, setBankAccountSortState] = useState({
    field: 'propertyHrId',
    order: 1,
  });
  const [initialSortUpdate, setInitialSortUpdate] = useState(true);
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const lastRequestTimestamp = useRef<number | null>(null);

  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    if (!initialSortUpdate) {
      onLoadBankAccountList(true);
    } else {
      setInitialSortUpdate(false);
    }
  }, [bankAccountSortState]);


  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadBankAccountList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [filterState]);

  function convertToBankAccount(property: PropertyDisplayDto) {
    let houseMoneyBalance: number = 0;
    let houseMoneyUndefined = true;
    let reserveMoneyUndefined = true;
    let reserveMoneyBalance: number = 0;
    let calculatedHouseMoneyBalance: number = 0;
    let calculatedReserveMoneyBalance: number = 0;
    let calculatedTotalBalance: number = 0;
    const bankAccounts: Array<BankAccountInfo> = [];
    if (!!property.supervisorContactId && !contactListContext.containsContact(property.supervisorContactId)) {
      contactListContext.onLoadContact(property.supervisorContactId);
    }
    property.bankAccounts?.forEach((bankAccount: PropertyBankAccountProjectionDto) => {
      const balanceDateMoment = bankAccount.balanceDate !== undefined ? moment(bankAccount.balanceDate) : undefined;
      bankAccounts.push({
        id: bankAccount.bankAccountId,
        type: bankAccount.propertyAccountTypes?.map(type => tl(translations.pages.bankAccount.table.headers[type])).join(', '),
        name: bankAccount.accountName,
        institute: bankAccount.accountInstitute,
        iban: bankAccount.iban,
        bic: bankAccount.bic,
        balance: bankAccount.balance,
        balanceDate: balanceDateMoment,
        balanceDateString: balanceDateMoment !== undefined ? balanceDateMoment.format(DATE_FORMAT) : undefined,
        calculatedBalance: bankAccount.calculatedBalance || undefined,
      });

      if (typeof bankAccount.balance !== 'undefined') {
        if (bankAccount.propertyAccountTypes.includes(PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.HOUSE)) {
          houseMoneyBalance += bankAccount.balance;
          houseMoneyUndefined = false;
        } else {
          reserveMoneyBalance += bankAccount.balance;
          reserveMoneyUndefined = false;
        }
      }
      if (bankAccount.calculatedBalance) {
        calculatedTotalBalance += bankAccount.calculatedBalance;
        if (bankAccount.propertyAccountTypes.includes(PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.HOUSE)) {
          calculatedHouseMoneyBalance += bankAccount.calculatedBalance;
        } else {
          calculatedReserveMoneyBalance += bankAccount.calculatedBalance;
        }
      }
    });

    const totalBankBalance = bankAccounts.reduce((acc, bankAccount) => acc + (bankAccount.balance || 0), 0);
    const maxBankBalanceDate: moment.Moment | undefined = bankAccounts
      .map(bankAccount => bankAccount.balanceDate)
      .filter(date => date !== undefined)
      .reduce((acc, curr) => {
        if (acc === undefined) return curr;
        return curr.isAfter(acc) ? curr : acc;
      }, undefined);

    const propertyWithBankingInfo: PropertyWithBankAccountInfo = {
      propertyHrId: property.propertyHrId,
      propertyIdInternal: property.propertyIdInternal,
      propertyId: property.id,
      administrationType: property.administrationType,
      managementCompanyId: property.managementCompany?.id,
      name: property.name,
      propertyState: property.propertyState,
      supervisorName: property.supervisorName,
      administrator: contactListContext.getName(property.supervisorContactId),
      houseMoneyBalance: !houseMoneyUndefined ? houseMoneyBalance : undefined,
      reserveMoneyBalance: !reserveMoneyUndefined ? reserveMoneyBalance : undefined,
      calculatedHouseMoneyBalance: calculatedHouseMoneyBalance || undefined,
      calculatedReserveMoneyBalance: calculatedReserveMoneyBalance || undefined,
      totalBalance: !houseMoneyUndefined || !reserveMoneyUndefined ? houseMoneyBalance + reserveMoneyBalance : undefined,
      totalBankBalance,
      transactionsUrl: getOpenTransactionUrl(property.propertyIdInternal),
      maxBankBalanceDate,
      maxBankBalanceDateString: maxBankBalanceDate !== undefined ? maxBankBalanceDate.format(DATE_FORMAT) : undefined,
      calculatedTotalBalance: calculatedTotalBalance || undefined,
      bankAccounts,
    };
    return propertyWithBankingInfo;
  }

  function convertToBankAccountList(properties?: PropertyDisplayDto[]) {
    return properties ? properties.map(property => convertToBankAccount(property)) : [];
  }

  function errorLoading() {
    setBankAccountListState({
      ...bankAccountListState.failed(),
      data: {
        ...bankAccountListState.data,
      },
      lastPage: true,
    });
    showNotification({
      key: 'loadBankAccountListError',
      message: tl(translations.notifications.bankAccountListContext.loadError.message),
      type: 'error',
    });
  }

  const onLoadAccountingAccountBalancesAsync = async (propertiesWithBankAccountInfo) => {
    const propertyHrIds = propertiesWithBankAccountInfo.map(bankAccount => bankAccount.propertyHrId);
    accountControllerApi.getExtendedAccountBalancesV2UsingGET({
      propertyHrIds,
      regex: '^27/[45]/.*',
      startDate: moment().format('YYYY-MM-DD'), // today
      endDate: moment().format('YYYY-MM-DD'), // today
    }).then((response) => {
      setBankAccountListState((old) => {
        const bankAccountsWithBalances = old.data.map((oldPrpWithBankAccount) => {
          if (oldPrpWithBankAccount.sumOfPropertyAccountBalances !== undefined) {
            return oldPrpWithBankAccount; // account balance already loaded in previous call
          }
          const accountsOfThisPrp = response.filter(account => account.propertyId === oldPrpWithBankAccount.propertyId);
          const sumOfPropertyAccountBalances = accountsOfThisPrp.reduce((acc, account) => acc + (account?.normalizedBalanceAtEndOfDateRange ?? 0), 0);
          const valueWithAddedAccountingBalances: PropertyWithBankAccountInfo = {
            ...oldPrpWithBankAccount,
            sumOfPropertyAccountBalances,
            accountCode: '27', // All 27 accounts of the property
            accountUrl: getAccountSheetPath(oldPrpWithBankAccount.propertyHrId, '27'), // All 27 accounts of the property
            bankAccounts: oldPrpWithBankAccount.bankAccounts.map((ba) => {
              const accountOfThisBankAccount = accountsOfThisPrp.find(acc => acc.accountName.endsWith(ba.iban));
              const accountingBalance = accountOfThisBankAccount?.normalizedBalanceAtEndOfDateRange ?? 0;
              const bankAccountWithAccountingBalance: BankAccountInfo = {
                ...ba,
                accountingBalance,
                transactionsUrl: getOpenTransactionUrl(oldPrpWithBankAccount.propertyIdInternal, ba.iban),
                accountCode: accountOfThisBankAccount?.accountCode,
                accountUrl: accountOfThisBankAccount
                  ? getAccountSheetPath(oldPrpWithBankAccount.propertyHrId, accountOfThisBankAccount.accountCode)
                  : undefined,
              };
              return bankAccountWithAccountingBalance;
            }),
          };
          return valueWithAddedAccountingBalances;
        });
        // Don't use the `old.load()` so we don't mess with the loading state. The loading is handled by the main request
        return {
          ...old,
          data: bankAccountsWithBalances,
        };
      });
    }).catch((error) => {
      // Show error notification but don't set the data to failed.
      console.error('Error loading accounting balances', error);
      showNotification({
        type: 'error',
        message: 'Could not load account balances for bank accounts',
      });
    });
  };

  const onLoadBankAccountList = async (resetPage: boolean = false, sort: any = bankAccountSortState) => {
    if (bankAccountListState.loading) {
      return;
    }
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    setBankAccountListState(bankAccountListState.startLoading());
    try {
      const response: SliceOfPropertyDisplayDto = await propertyControllerApi.findFilteredPropertiesWithBankAccountsUsingGET({
        ...filterState,
        page: resetPage ? 0 : bankAccountListState.page,
        size: PAGE_SIZE,
        order: sort.order > 0 ? 'ASC' : 'DESC',
        sort: sort.field,
      });
      const bankAccounts = convertToBankAccountList(response.content);

      // do nothing if this is a response for an older request
      if (currentTimestamp !== lastRequestTimestamp.current) return;

      setBankAccountListState(old => old.loadPaged(
        bankAccounts,
        resetPage,
        !!response.last,
      ));
      onLoadAccountingAccountBalancesAsync(bankAccounts);
    } catch (e) {
      errorLoading();
    }
  };


  const onClearBankAccountList = (): void => {
    setBankAccountListState(bankAccountListState.load([]));
  };

  const onClearFilterState = (): void => {
    setFilterState({});
  };

  const updateFilterState = (data: object) => {
    setFilterState((old: any) => ({
      ...old,
      ...data,
    }));
  };

  const setSortField = (field: string) => {
    setBankAccountSortState((old: any) => ({
      field,
      order: old.field === field ? old.order * (-1) : 1,
    }));
  };

  return {
    ...bankAccountListState,
    onLoadBankAccountList,
    onClearBankAccountList,
    onClearFilterState,
    setSortField,
    filterState,
    updateFilterState,
    sortField: bankAccountSortState.field,
    sortOrder: bankAccountSortState.order,
  };
};
