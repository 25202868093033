import {
  useContext,
  useState,
} from 'react';

import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import {
  OwnersMeetingProtocolContext,
} from 'pages/OwnersMeetingProtocol/services/OwnersMeetingProtocolContext';
import { usePrintCss } from 'services/print/usePrintCss';
import { usePrintHtml } from 'services/print/usePrintHtml';

import { AuthContext } from 'contexts/AuthContext';
import { TemplateControllerApi } from 'api/document';
import { getFooter } from 'services/print/utilFooterTemplate';
import { getSecondPageWatermarkConfig } from 'services/print/utilWatermarkConfig';
import { downloadByteArrayAsPdf } from '../../../../../../../lib/Utils';

import {
  translations as ownersMeetingProtocolTranslations,
} from '../../../../../translations';

import {
  translations as signatureListTranslations,
} from '../../../pages/OwnersMeetingProtocolAttendance/translations';

export const useCreateSignatureListDocument = () => {
  const ownersMeetingProtocolContext:any = useContext(OwnersMeetingProtocolContext);

  if (ownersMeetingProtocolContext === undefined) {
    throw new Error('useCreateSignatureListDocument must be used within a OwnersMeetingProtocolContextProvider');
  }
  const {
    ownersMeeting,
  } = ownersMeetingProtocolContext;
  const { tl } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);

  const { documentApiConfiguration } = useContext(AuthContext);

  const templateController = new TemplateControllerApi(documentApiConfiguration('document'));

  const { getPrintCss } = usePrintCss();
  const { getPrintHtml } = usePrintHtml();

  const handleError = () => {
    showNotification({
      type: 'error',
      message: tl(ownersMeetingProtocolTranslations.notifications.errorCreatingDocument),
    });
  };

  const downloadDocument = (documentTitle: string) => {
    setLoading(true);

    return getPrintCss()
      .then(css => templateController.renderPdfUsingPOSTRaw({
        templateRenderDto:
        {
          html: getPrintHtml(),
          css,
          displayHeaderFooter: true,
          useLegacyPdfRenderer: false,
          watermarkId: ownersMeeting?.data?.watermarkId,
          watermarkConfigDto: getSecondPageWatermarkConfig(),
          footerTemplate: getFooter({
            propertyName: ownersMeeting?.data?.propertyName,
            documentTitle: tl(signatureListTranslations.title),
          }),
        },
      }).then((resp: any) => resp?.raw?.blob())
        .then((blob: Blob) => {
          downloadByteArrayAsPdf(documentTitle, blob);
        })
        .catch(handleError)
        .finally(() => setLoading(false)));
  };

  return { downloadDocument, loadingDocument: loading };
};
