import { OwnersMeetingInvitationDtoMeetingTypeEnum } from 'api/accounting/models/OwnersMeetingInvitationDto';

export const extractSubject = (template: string, meetingType: OwnersMeetingInvitationDtoMeetingTypeEnum): string => {
  const element = document.createElement('html');
  element.innerHTML = template;
  const subjectElement = element.querySelector('#subject');
  if (subjectElement) {
    const translatedMeetingType = meetingType === OwnersMeetingInvitationDtoMeetingTypeEnum.REGULAR ? 'ordentlichen' : 'außerordentlichen';
    return (
      subjectElement.innerHTML
        // eslint-disable-next-line no-template-curly-in-string
        .replace('<span class="lowercase">[[${data.ownersMeetingType}]]n</span>', `${translatedMeetingType}`)
        .replace(/<.*?>/g, '')
    );
  }
  return '';
};
