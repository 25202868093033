import SelectInputWithLink from 'storybook-components/inputs/select/SelectorWithLink/SelectorWithLink';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { economicPlanTranslations } from 'pages/EconomicPlan/economicPlanTranslations';
import { useEconomicPlanWkaSelector } from './useEconomicPlanWkaSelector';


const EconomicPlanWkaSelector = () => {
  const { tl } = useContext(LanguageContext);
  const { values, onChange, isDisabled, options, dropdownRenderWithCreateNewWkaOption } = useEconomicPlanWkaSelector();


  return (
    <SelectInputWithLink
      showSearch
      label={tl(economicPlanTranslations.wka)}
      onChange={onChange}
      value={values}
      options={options}
      getPopupContainer={() => (document.getElementsByClassName('EconomicPlanData')?.[0] ?? document.body) as HTMLElement}
      dangerouslySetDisabled={isDisabled}
      hideClearIcon={isDisabled}
      allowMultiple
      onDropDownRender={dropdownRenderWithCreateNewWkaOption}
    />
  );
};

export default EconomicPlanWkaSelector;
