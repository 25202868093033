import React, { useContext, useEffect, useState } from 'react';
import './PostingListFilter.scss';
import { useParams } from 'react-router';
import { useAuditingGlobalFilterIsDateRangeLimited } from 'services/AuditingGlobalFilterStore/selectorsAuditingGlobalFilter';
import RangeInput from '../../../elements/Inputs/RangeInput/RangeInput';
import { PostingListContext } from '../../../contexts/PostingListContext';
import CheckboxInput from '../../../elements/Inputs/CheckboxInput/CheckboxInput';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from './translations';
import { AccountsBalanceContext } from '../../../contexts/AccountsBalanceContext';

export default function PostingListFilter(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const { onLoadPostings, sortOrder } = useContext(PostingListContext);
  const { filterState, setIncludeCorrectional, setAccountDateRange, resetAccountDateRange } = useContext(AccountsBalanceContext);
  const isDateRangeLimited = useAuditingGlobalFilterIsDateRangeLimited();
  const { propertyHrId, accountCode } = useParams<{
    propertyHrId: string;
    accountCode: string;
  }>();

  const decodedAccountCode = decodeURIComponent(accountCode);
  useEffect(() => () => resetAccountDateRange(), []);

  useEffect(() => {
    if (!initialFilterUpdate) {
      const page: number = 0;
      onLoadPostings(propertyHrId, decodedAccountCode, true, page);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [filterState, sortOrder]);

  const { accountDateRange, includeCorrectional } = filterState;

  return (
    <div className="PostingListFilter">
      <CheckboxInput value={includeCorrectional} onChange={setIncludeCorrectional} label={tl(translations.includeCorrectional)} />
      <RangeInput label="" className="input-small" onChange={setAccountDateRange} disabled={isDateRangeLimited} value={accountDateRange} />
    </div>
  );
}
