import SmartTable from 'elements/SmartTable/SmartTable';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { useMemo } from 'react';
import BankAccountTable from '../BankAccountTable/BankAccountTable';
import { useBankAccountListColumns } from '../UseBankAccountListColumns';
import { PropertyWithBankAccountInfo } from '../UseBankAccountList';

interface BankAccountListTableProps {
  bankAccountListContext: any
  showNewBankAccountListColumns: boolean
}

export const BankAccountListTable = ({ bankAccountListContext, showNewBankAccountListColumns }: BankAccountListTableProps) => {
  const tableData = useMemo(() => bankAccountListContext.data, [bankAccountListContext.data]);

  const smartTable = useSmartTable(
    {
      tableName: 'bankAccountList',
      /* smartTable does not care that columns here has a parameter and it sometimes changes.
      We force a re-mount by having a `key` on the BankAccountListTable which changes with the newBankAccountListColumns */
      columns: useBankAccountListColumns(showNewBankAccountListColumns),
      dataSource: tableData,
      propSort: {
        field: bankAccountListContext.sortField,
        order: bankAccountListContext.sortOrder,
        onSortChange: (dataKey: string) => bankAccountListContext.setSortField(dataKey),
      },
      contentLoading: bankAccountListContext.loading,
      infiniteScrollerProps: {
        hasMoreData: !bankAccountListContext.lastPage,
        loadMoreData: bankAccountListContext.onLoadBankAccountList,
      },
      expandedRowRender: (record: PropertyWithBankAccountInfo) => <BankAccountTable property={record} showNewBankAccountListColumns={showNewBankAccountListColumns} />,
      rowKey: 'propertyHrId',
      virtualize: false,
    },
  );

  return <SmartTable {...smartTable} />;
};
