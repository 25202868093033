import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import _ from 'lodash';
import moment from 'moment';
import {
  calculateLaborCostPercentage,
  calculateNetAmount,
} from 'pages/Invoice/InvoiceEditing/sections/invoiceBooking/invoiceBookingUtils';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { round2dec } from 'lib/Utils';
import {
  InvoiceEpo,
  InvoiceEpoPaymentTypeEnum,
  InvoiceLegacyControllerApi,
  InvoiceLegacyDto,
  InvoiceLegacyDtoStateEnum,
  InvoiceLegacyProjectionExchangeStateEnum,
  PropertyDisplayDto,
  PropertyDisplayDtoVatRelevanceEnum,
  PropertyLegacyControllerApi,
} from '../api/accounting';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import DEFAULT_DATA, { DefaultDataInterface } from '../lib/data';
import {
  NotificationObject,
  showNotification,
} from '../lib/Notification';
import {
  defaultInvoiceBooking,
  InvoiceBookingReducerActions,
  useInvoiceBookings,
} from '../pages/Invoice/InvoiceEditing/InvoiceEditorForm/hooks/useInvoiceBookings';
import { OverlayContext } from '../services/OverlayContext/OverlayContext';
import { AuthContext } from './AuthContext';
import {
  convertToBEModel,
  convertToFEModel,
  InvoiceBookingWithNet,
  processChanges,
} from './functions/InvoiceEditingFunctions';
import { InvoiceListContext } from './InvoiceListContext';
import { LanguageContext } from './LanguageContext';
import {
  getInvoiceSectionFieldsMap,
} from './util/InvoiceSectionFieldsConfiguration';

export const DUPLICATE_STATUS_CODE = 409;

interface FeInvoice extends Omit<InvoiceEpo, 'invoiceBookings' | 'invoiceDate' | 'paymentTargetDate' > {
  numberOfPaymentSplits?: number,
  executionDateType?: string,
  paymentTargetDays?: number,
  invoiceBookings?: InvoiceBookingWithNet[],
  servicePeriod?: moment.Moment[],
  invoiceDate?: moment.Moment, // input accepts moments
  paymentTargetDate?: moment.Moment, // input accepts moments
  totalNet?: number,
};

interface InvoiceState {
  invoice: FeInvoice,
}

interface InvoiceEditingContextType {
  data: InvoiceState,
  saved: boolean,
  loading: boolean,
  loaded: boolean,
  error: boolean,
  validationErrors: any,
  page: number,
  lastPage: boolean,
  load: (loadedData: InvoiceState, validationErrors?: any, saved?: boolean) => DefaultDataInterface<InvoiceState>,
  setInvoiceState: React.Dispatch<React.SetStateAction<DefaultDataInterface<InvoiceState>>>,
  invoiceBookingValidationErrors: ValidationErrors[],
  approvable: boolean,
  updateInvoiceState: (data: InvoiceState, validationErrors?: any, saved?: boolean) => void,
  onUploadInvoice: (file: Blob, fileName: string) => void,
  onUploadError: () => void,
  onSaveInvoice: (invoiceHrId: string | null, savingSectionNumber: number) => void,
  onApproveInvoice: (invoiceHrId: string) => Promise<any>,
  clearInvoice: () => void,
  onLoadInvoiceByInvoiceId: (id: number, redirectPath: string, redirectPathSearchParam?: string) => void,
  startLoading: (loaded?: boolean) => DefaultDataInterface<InvoiceState>,
  failed: (validationErrors?: any) => DefaultDataInterface<InvoiceState>,
  isDirty: boolean,
  setDirty: React.Dispatch<React.SetStateAction<boolean>>,
  invoiceBookings: InvoiceBookingWithNet[],
  changeInvoiceBookings: React.Dispatch<InvoiceBookingReducerActions>,
  laborCostTypes: { label: string, value: string }[],
  similarInvoices: SimilarInvoicesType,
  onDuplicateCheck: () => Promise<Response>,
  editingBookings: boolean,
  setEditingBookings: React.Dispatch<React.SetStateAction<boolean>>,
  onOcrReady: () => void,
  property: DefaultDataInterface<PropertyDisplayDto>,
  partiallyVatRelevantProperty: boolean,
  vatEligibilityShare: number,
  setVatEligibilityShare: React.Dispatch<React.SetStateAction<number>>,
  setApprovable: React.Dispatch<React.SetStateAction<boolean>>,
  setProperty: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertyDisplayDto>>>,
}


export const InvoiceEditingContext = React.createContext<InvoiceEditingContextType | undefined>(undefined);

interface ValidationError {
  validationState: undefined | 'error',
  validationMessage: undefined | string,
}

export interface ValidationErrors {
  [key: string]: ValidationError
}

export interface InvoiceInfo {
  state: string;
  invoiceNumber: string;
}
export interface SimilarInvoicesType {
  hrId: Record<string, InvoiceInfo>
}

export default function InvoiceEditingProvider({ children }: any) {
  const defaultContext = {
    invoice: {
      // default value = current date
      processingDate: moment(),
    },
  };
  const { tl } = useContext(LanguageContext);

  const [invoiceState, setInvoiceState] = useState(DEFAULT_DATA<InvoiceState>(defaultContext));
  const [approvable, setApprovable] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [editingBookings, setEditingBookings] = useState(false);
  const [similarInvoices, setSimilarInvoices] = useState<SimilarInvoicesType>(undefined);
  const [invoiceBookingValidationErrors, setInvoiceBookingValidationErrors] = useState<ValidationErrors[]>([]);
  const [property, setProperty] = useState<DefaultDataInterface<PropertyDisplayDto>>(DEFAULT_DATA(undefined));

  const history = useHistory();
  const { goBack } = useContext(OverlayContext);
  const [vatEligibilityShare, setVatEligibilityShare] = useState<number | undefined>();
  const partiallyVatRelevantProperty = property?.data?.vatRelevance === PropertyDisplayDtoVatRelevanceEnum.PARTIALLY_RELEVANT;
  const [invoiceBookings, changeInvoiceBookings, laborCostTypes] = useInvoiceBookings({
    total: invoiceState.data.invoice.totalGross,
    setDirty,
    propertyVatEligibilityShare: vatEligibilityShare,
  });

  const { apiConfiguration } = useContext(AuthContext);
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  const { addInvoiceToList }: any = useContext(InvoiceListContext);

  useEffect(() => {
    /**
     * Duplicate check
     */
    let timer: any;
    if (isDirty) {
      timer = setTimeout(() => {
        onDuplicateCheck();
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [invoiceState]);

  const validationWarningNotification: NotificationObject = {
    message: tl(translations.notifications.invoiceEditingContext.updateValidationError.message),
    description: tl(translations.notifications.invoiceEditingContext.updateValidationError.description),
    type: 'warning',
  };

  const updateInvoiceState = (data: InvoiceState, validationErrors?: any, saved?: boolean) => {
    setInvoiceState((prevState: DefaultDataInterface<InvoiceState>) => {
      const tempInvoice = processChanges(data.invoice, _.cloneDeep(prevState.data.invoice), property.data);
      return prevState.load(
        {
          invoice: {
            ...tempInvoice,
          },
        },
        validationErrors,
        saved,
      );
    });
  };

  const clearInvoice = () => {
    changeInvoiceBookings({ type: 'setDefault', value: { ...defaultInvoiceBooking, vatEligibilityPercentage: undefined, vatEligibilityAmount: undefined } });
    setDirty(false);
    setInvoiceState(DEFAULT_DATA<InvoiceState>(defaultContext));
    setSimilarInvoices(undefined);
  };


  const onLoadInvoiceByInvoiceId = (id: number, redirectPath: string, redirectPathSearchParam?: string): void => {
    setInvoiceState(invoiceState.startLoading());
    invoiceControllerApi.getInvoiceByIdUsingGET({ invoiceId: id })
      .then((response: InvoiceLegacyDto) => {
        if (redirectPath) {
          history.push(`${redirectPath}/${response.invoiceHrId}?${redirectPathSearchParam ?? ''}`);
        }
      })
      .catch((err) => {
        console.error(err);
        setInvoiceState(invoiceState.failed());
        showNotification({
          key: 'loadInvoiceError',
          message: tl(translations.notifications.invoiceEditingContext.loadError.message),
          description: tl(translations.notifications.invoiceEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onUploadInvoice = (file: Blob, fileName: string): void => {
    setInvoiceState(invoiceState.startLoading());
    backend.postFileWithName(`${endpointUrls.INVOICE}/upload`, file, fileName, {})
      .then((response: any) => {
        updateInvoiceState({ invoice: response });
        history.push(`/invoice/edit/${response.invoiceHrId}`);
        addInvoiceToList({ ...response });
      })
      .catch(() => {
        setInvoiceState(invoiceState.failed());
        onUploadError();
      });
  };

  const onUploadError = (): void => {
    showNotification({
      key: 'uploadInvoiceError',
      message: tl(translations.notifications.invoiceEditingContext.uploadError.message),
      description: tl(translations.notifications.invoiceEditingContext.uploadError.description),
      type: 'error',
    });
  };

  const invoiceBookingsAreValid = (): boolean => getInvoiceBookingValidations().every(bookingValidationError => _.isEmpty(bookingValidationError));

  const getInvoiceBookingValidations = () => invoiceBookings.map((ib) => {
    const bookingValidationErros: ValidationErrors = {};
    if (ib.vatAmount && Math.abs(ib.vatAmount) > Math.abs(ib.netAmount)) {
      bookingValidationErros.vatAmount = {
        validationState: 'error',
        validationMessage: tl(translations.validations.tooBig),
      };
    }

    if (ib.laborCost && Math.abs(ib.laborCost) > Math.abs(ib.amount)) {
      bookingValidationErros.laborCostGross = {
        validationState: 'error',
        validationMessage: tl(translations.validations.tooBig),
      };
    }

    const bookingLaborCostNet = ib.laborCost && ib.vatPercentage ? calculateNetAmount(ib.laborCost, ib.vatPercentage) : 0;
    if (bookingLaborCostNet > Math.abs(round2dec(ib.netAmount))) {
      bookingValidationErros.laborCostNet = {
        validationState: 'error',
        validationMessage: tl(translations.validations.tooBig),
      };
    }

    if (ib.vatPercentage > 100) {
      bookingValidationErros.vatPercentage = {
        validationState: 'error',
        validationMessage: tl(translations.validations.tooBig),
      };
    }
    if (ib.vatPercentage < 0) {
      bookingValidationErros.vatPercentage = {
        validationState: 'error',
        validationMessage: tl(translations.validations.tooSmall),
      };
    }

    const laborCostPercentage = ib.amount === 0 ? 0 : calculateLaborCostPercentage(ib.laborCost, ib.amount);
    if (laborCostPercentage > 100) {
      bookingValidationErros.laborCostPercentage = {
        validationState: 'error',
        validationMessage: tl(translations.validations.tooBig),
      };
    }

    if ((ib.laborCost || laborCostPercentage) && !ib.laborCostType) {
      bookingValidationErros.laborCostType = {
        validationState: 'error',
        validationMessage: tl(translations.validations.mandatory),
      };
    }

    return bookingValidationErros;
  });

  const onUpdateInvoice = (savingSectionNumber: number, invoiceData?: FeInvoice) => {
    setInvoiceState(invoiceState.startLoading());
    let requestData = {
      ...convertToBEModel(invoiceState.data.invoice, invoiceBookings),
    };

    if (invoiceData) {
      requestData = { ...invoiceData };
    }

    backend.put(`${endpointUrls.INVOICE}/${requestData.id}`, requestData)
      .then((response: any) => {
        updateInvoiceState({
          invoice: convertToFEModel(response),
        }, {}, true);
        setInvoiceBookingValidationErrors([]);
        changeInvoiceBookings({ type: 'setDefault', value: response.invoiceBookings });
        setDirty(false);
        setApprovable(true);
        setEditingBookings(false);
        addInvoiceToList(convertToFEModel(response));
        showNotification({
          message: tl(translations.notifications.invoiceEditingContext.updateSuccess.message),
          type: 'success',
        });
      }).catch((json) => {
        setApprovable(false);
        setDirty(false);
        console.error('error json', json);
        if (json.title === 'Validation error') {
          if (json.paymentStatement === 'tooLong') {
            showNotification({
              key: 'statementTooLong',
              message: `${tl(translations.notifications.invoiceEditingContext.invalidPaymentStatement)}`,
              type: 'error',
            });
          }
          if (json.status === 409 && json.invoice === 'hasDuplicate') {
            showNotification({
              key: 'duplicateInvoice',
              message: `${tl(translations.notifications.invoiceEditingContext.duplicateInvoiceError.message)} ${json.duplicateInvoice}`,
              type: 'error',
            });
          } else {
            let currentSectionErrors: any = {};
            const currentSection = getInvoiceSectionFieldsMap(savingSectionNumber, json.entity);
            Object.keys(json).forEach((key: any) => {
              if (currentSection && currentSection.includes(key)) {
                currentSectionErrors[key] = json[key];
              }
            });
            if (savingSectionNumber === 3 && !invoiceBookingsAreValid()) {
              const bookingErrors = getInvoiceBookingValidations();
              setInvoiceBookingValidationErrors(bookingErrors);
              currentSectionErrors = { ...currentSectionErrors, invoiceBookings: [...(currentSectionErrors.invoiceBookings ? currentSectionErrors.invoiceBookings : []), ...bookingErrors] };
            }
            if (!_.isEmpty(currentSectionErrors)) {
              if (currentSectionErrors.paymentType === 'invalidPaymentTypeWithoutCounterBankAccount') {
                showNotification({
                  key: 'paymentTypeValidationError',
                  message: tl(translations.notifications.invoiceEditingContext.invalidPaymentTypeWithoutCounterpartIban),
                  type: 'error',
                });
              }
              showNotification(validationWarningNotification);
              updateInvoiceState({
                invoice: convertToFEModel(json.entity),
              }, currentSectionErrors, true);
            } else {
              updateInvoiceState({
                invoice: convertToFEModel(json.entity),
              }, {}, true);
              setInvoiceBookingValidationErrors([]);
              showNotification({
                message: tl(translations.notifications.invoiceEditingContext.updateSuccess.message),
                type: 'success',
              });
            }
            changeInvoiceBookings({ type: 'setDefault', value: json.entity.invoiceBookings });
            addInvoiceToList(convertToFEModel(json.entity));
          }
        } else {
          setInvoiceState(invoiceState.failed());
          showNotification({
            key: 'updateInvoiceError',
            message: tl(translations.notifications.invoiceEditingContext.updateError.message),
            description: tl(translations.notifications.invoiceEditingContext.updateError.description),
            type: 'error',
          });
        }
      });
  };

  const onCreateInvoice = (savingSectionNumber: number) => {
    setInvoiceState(invoiceState.startLoading());
    const invoiceCreationData: FeInvoice = {
      ...invoiceState.data.invoice,
    };

    backend.post(`${endpointUrls.INVOICE}`, { ...invoiceCreationData })
      .then((resp) => {
        const invoice = convertToFEModel(resp);

        onUpdateInvoice(savingSectionNumber, {
          ...invoiceState.data.invoice,
          id: invoice.id,
          invoiceHrId: invoice.invoiceHrId,
          propertyId: invoice.propertyId,
        });
      }).catch((json) => {
        if (json.title === 'Validation error') {
          const invoice = convertToFEModel(json.entity);
          onUpdateInvoice(savingSectionNumber, {
            ...invoiceState.data.invoice,
            id: invoice.id,
            invoiceHrId: invoice.invoiceHrId,
            propertyId: invoice.propertyId,
          });
        } else {
          setInvoiceState(invoiceState.failed());
          showNotification({
            key: 'createInvoiceError',
            message: tl(translations.notifications.invoiceEditingContext.createError.message),
            type: 'error',
          });
        }
      });
  };

  const onSaveInvoice = (invoiceHrId: string | null, savingSectionNumber: number): void => {
    if (invoiceHrId) {
      onUpdateInvoice(savingSectionNumber);
    } else {
      onCreateInvoice(savingSectionNumber);
    }
  };

  const onApproveInvoice = (invoiceHrId: string): Promise<any> => backend.post(`${endpointUrls.INVOICE}/${invoiceHrId}/approve`, null)
    .then(() => {
      setInvoiceState(prev => prev.finishLoading());
      return propertyControllerApi.getPropertyDisplayByIdUsingGET({
        propertyId: invoiceState.data.invoice?.propertyId,
      });
    })
    .then((propertyInfo) => {
      addInvoiceToList({
        ...invoiceState.data.invoice,
        state: InvoiceLegacyDtoStateEnum.APPROVED,
        exchangeState: invoiceState.data.invoice?.paymentType !== InvoiceEpoPaymentTypeEnum.EXISTING ? InvoiceLegacyProjectionExchangeStateEnum.BOOKED : undefined,
        propertyName: propertyInfo.name,
        propertyIdInternal: propertyInfo.propertyIdInternal,
      });
      goBack();
      showNotification({
        key: 'invoiceApproveSuccess',
        message: tl(translations.notifications.invoiceEditingContext.invoiceApproveSuccess.message),
        type: 'success',
      });
      return Promise.resolve();
    })
    .catch((error) => {
      if (error.title === 'Validation error') {
        if (error.status === 409 && error.invoice === 'hasDuplicate') {
          showNotification({
            key: 'duplicateInvoice',
            message: `${tl(translations.notifications.invoiceEditingContext.duplicateInvoiceError.message)} ${error.duplicateInvoice}`,
            type: 'error',
          });
        } else {
          showNotification({
            key: 'updateInvoiceError',
            message: tl(translations.notifications.invoiceEditingContext.invoiceApproveError.validationError.message),
            description: tl(translations.notifications.invoiceEditingContext.invoiceApproveError.validationError.description),
            type: 'error',
          });
        }
      } else {
        console.error(error);
        showNotification({
          key: 'updateInvoiceError',
          message: tl(translations.notifications.invoiceEditingContext.invoiceApproveError.generalError.message),
          description: tl(translations.notifications.invoiceEditingContext.invoiceApproveError.generalError.description),
          type: 'error',
        });
      }
      return Promise.reject(error);
    });

  const onDuplicateCheck = () => {
    const p = backend.post(`${endpointUrls.INVOICE}/duplicate-check`, convertToBEModel(invoiceState.data.invoice, invoiceBookings));
    p.then(() => {
      setSimilarInvoices(undefined);
    })
      .catch((response) => {
        if (response.status === DUPLICATE_STATUS_CODE) {
          setSimilarInvoices(response.duplicateInvoices);
        }
      });
    return p;
  };


  const onOcrReady = () => {
    invoiceControllerApi.getInvoiceUsingGET({ invoiceHrId: invoiceState.data?.invoice?.invoiceHrId })
      .then((response: any) => {
        // do not overwrite user inputs
        setInvoiceState((prevState: any) => prevState.load({ ...prevState.data, invoice: applyNonConflictingChanges(prevState.data?.invoice, response) }));
      })
      .catch((err) => {
        console.error(err);
        setInvoiceState(prev => prev.failed());
        showNotification({
          key: 'loadInvoiceError',
          message: tl(translations.notifications.invoiceEditingContext.loadError.message),
          description: tl(translations.notifications.invoiceEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const applyNonConflictingChanges = (oldInvoice, newInvoice) => {
    const tempInvoice = { ...oldInvoice };
    if (!tempInvoice.propertyId) {
      tempInvoice.propertyId = newInvoice.propertyId;
      tempInvoice.propertyHrId = newInvoice.propertyHrId;
      tempInvoice.propertyIdInternal = newInvoice.propertyIdInternal;
      tempInvoice.propertyName = newInvoice.propertyName;
      tempInvoice.propertyIban = newInvoice.propertyIban;
      tempInvoice.propertyBic = newInvoice.propertyBic;
      tempInvoice.bankAccountId = newInvoice.bankAccountId;
    }

    if (!tempInvoice.counterpartContactId) {
      tempInvoice.counterpartContactId = newInvoice.counterpartContactId;
      tempInvoice.counterpartName = newInvoice.counterpartName;
      tempInvoice.counterpartIban = newInvoice.counterpartIban;
      tempInvoice.counterpartBic = newInvoice.counterpartBic;
    }

    if (!tempInvoice.invoiceDate) {
      tempInvoice.invoiceDate = newInvoice.invoiceDate;
      tempInvoice.paymentTargetDate = newInvoice.paymentTargetDate;
    }

    if (!tempInvoice.totalGross || tempInvoice.totalGross === 0) {
      tempInvoice.totalGross = newInvoice.totalGross;
      changeInvoiceBookings({ type: 'setDefault', value: newInvoice.invoiceBookings });
    }

    if (!tempInvoice.invoiceNumber) {
      tempInvoice.invoiceNumber = newInvoice.invoiceNumber;
    }

    tempInvoice.documentUrl = newInvoice.documentUrl;

    if (!_.isEmpty(tempInvoice.invoicePayments) && !tempInvoice.invoicePayments[0].statement) {
      tempInvoice.invoicePayments[0].statement = newInvoice.invoiceNumber;
    }
    return convertToFEModel(tempInvoice);
  };

  return (
    <InvoiceEditingContext.Provider value={{
      ...invoiceState,
      setInvoiceState,
      invoiceBookingValidationErrors,
      approvable,
      updateInvoiceState,
      onUploadInvoice,
      onUploadError,
      onSaveInvoice,
      onApproveInvoice,
      clearInvoice,
      onLoadInvoiceByInvoiceId,
      startLoading: invoiceState.startLoading,
      failed: invoiceState.failed,
      isDirty,
      setDirty,
      invoiceBookings,
      changeInvoiceBookings,
      laborCostTypes,
      similarInvoices,
      onDuplicateCheck,
      editingBookings,
      setEditingBookings,
      onOcrReady,
      property,
      partiallyVatRelevantProperty,
      vatEligibilityShare,
      setVatEligibilityShare,
      setApprovable,
      setProperty,
    }}
    >
      {children}
    </InvoiceEditingContext.Provider>
  );
}


InvoiceEditingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
