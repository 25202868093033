import React, {
  ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import Icon from '@ant-design/icons';
import Button from 'elements/Buttons/Button/Button';
import Heading from 'storybook-components/typography/Heading/Heading';
import { translations } from '../../Translation/translations';
import './ExpandableCardsList.scss';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ICONS } from '../../../components/icons';
import { NavigationElement, SimpleSidebar } from '../SimpleSidebar/SimpleSidebar';
import { ExpandableCard } from '../ExpandableCard/ExpandableCard';
import { LoadingScreen } from 'storybook-components/feedback/LoadingScreen/LoadingScreen';


interface ExpandableCardProps {
  bordered?: boolean,
  loading?: boolean,
  title: string,
  children: ReactNode | ReactNode[],
  category: string,
  navigationTitle: string,
  summary?: ReactNode,
  summaryOnBottom?: boolean,
  navigationSubElements?: NavigationElement[],
  hideSummaryWhenOpen?: boolean,
}

interface ExpandableCardGroup {
  groupName: string,
  groupTitle?: string,
  expandableCards: ExpandableCardProps[]
}

interface ExpandableCardsListProps {
  expandableCardGroups: ExpandableCardGroup[],
  headerChildren?: ReactNode | ReactNode[],
  loading?: boolean,
  loadingMessage?: string,
}

export function ExpandableCardsList(props: ExpandableCardsListProps) {
  const {
    expandableCardGroups, headerChildren, loading, loadingMessage,
  } = props;

  const { tl } = useContext(LanguageContext);
  const defaultExpandedCards: string[] = [];
  const [expandedCards, setExpandedCards] = useState(defaultExpandedCards);
  const emptyStringArray: string[] = [];
  const [categories, setCategories] = useState(emptyStringArray);

  const expandableCards: ExpandableCardProps[] = useMemo(() => expandableCardGroups.flatMap(groups => groups.expandableCards), expandableCardGroups);

  useEffect(() => {
    const tempCategories = expandableCards.map((card: any) => card.category);
    setCategories(tempCategories);
  }, [expandableCardGroups]);

  const navigationElementGroups: any = useMemo(() => expandableCardGroups.map(group => ({
    groupName: group.groupName,
    elements: group.expandableCards.map((card: ExpandableCardProps) => ({
      name: card.navigationTitle,
      elementId: `${card.category}`,
      subElements: card.navigationSubElements,
    })),
  })), expandableCardGroups);

  const expandAll = (): void => {
    if (expandedCards.length > 0) {
      setExpandedCards([]);
    } else {
      setExpandedCards(categories);
    }
  };

  const expandCard = (category: string): void => {
    const tempExpandedCards = expandedCards.slice();
    if (!tempExpandedCards.includes(category)) {
      tempExpandedCards.push(category);
      setExpandedCards(tempExpandedCards);
    }
  };

  const onCardToggled = (category: string, open: boolean): void => {
    const tempExpandedCards = expandedCards.slice();
    if (tempExpandedCards.includes(category) && !open) {
      const index = expandedCards.indexOf(category);
      tempExpandedCards.splice(index, 1);
    } else if (!tempExpandedCards.includes(category) && open) {
      tempExpandedCards.push(category);
    }
    setExpandedCards(tempExpandedCards);
  };

  const cardTitle = (title: string) => (
    <div className="overview-card-title">
      {title}
    </div>
  );

  const titleWithSummary = (title: string, summary: any) => (
    <div className="overview-header">
      {cardTitle(title)}
      {summary}
    </div>
  );

  const isExpandable = expandedCards.length === 0;

  return (
    <div className="ExpandableCardsList">
      <div className="card-list-container">
        <div className="header-row">
          {headerChildren}
          <Button className="expand-button" type="ghost" onClick={expandAll} icon={isExpandable ? <Icon component={ICONS.expand} /> : <Icon component={ICONS.collapse} />}>
            {isExpandable ? tl(translations.pages.accountsOverview.expandAll) : tl(translations.pages.accountsOverview.collapseAll)}
          </Button>
        </div>
        <div className="card-list">
          {loading ? (
            <LoadingScreen title={loadingMessage} />
          ) : (
            expandableCardGroups.map((group: ExpandableCardGroup) => (
              <div className="group" key={`group-${group.groupName}`}>
                {group.groupTitle && (
                  <Heading level={3} className="group-title">
                    {group.groupTitle}
                  </Heading>
                )}
                {group.expandableCards.map((card: any) => (
                  <div className="overview-card" id={card.category} key={card.category}>
                    <ExpandableCard onToggled={onCardToggled} cardOpen={expandedCards.includes(card.category)} category={card.category} title={!card.summaryOnBottom ? titleWithSummary(card.title, card.summary) : cardTitle(card.title)} summary={card.summaryOnBottom && card.summary} hideSummaryWhenOpen={card.hideSummaryWhenOpen}>
                      {card.children}
                    </ExpandableCard>
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
      </div>
      <div className="navigation-bar">
        <SimpleSidebar groups={navigationElementGroups} expandElement={expandCard} />
      </div>
    </div>
  );
}

ExpandableCardsList.defaultProps = {
  loading: false,
  loadingMessage: '',
  headerChildren: undefined,
};
