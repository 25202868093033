import React from 'react';
import { useIndividualTenantCostsManualValuesTotals, useOpsCostPrintMode } from 'pages/OpsCostReport/OpsCostReportEditing/services/stores/opsCostReportManualAmountsStore/selectorsOpsCostReportManualAmounts';
import ExpenseTableSummary, { ExpenseTableSummaryProps } from '../../components/ExpenseTableSummary';
import { ReturnTypeOfIndividualTenantCostsHook } from '../IndividualTenantCostsSection';


interface IndividualTenantCostsTableSummaryProps extends Omit<ExpenseTableSummaryProps, 'totalSummaryData'> {
  contractId: number;
  getTotal: ReturnTypeOfIndividualTenantCostsHook['getTotal']
}


const IndividualTenantCostsTableSummary = (props: IndividualTenantCostsTableSummaryProps) => {
  const { getTotal, contractId, ...restProps } = props;

  /* if we move this dependency any higher up in the tree, then the entire table will re-render on every keystroke */
  const summaryFromManualAmounts = useIndividualTenantCostsManualValuesTotals(contractId);
  const { includingManual: totalSummaryDataWithManualAmounts } = getTotal(summaryFromManualAmounts);
  const printMode = useOpsCostPrintMode();

  return (
    <ExpenseTableSummary
      {...restProps}
      totalSummaryData={totalSummaryDataWithManualAmounts}
      addPlaceholderLastColumn={!printMode}
    />
  );
};

export default IndividualTenantCostsTableSummary;
