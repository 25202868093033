import StandingOrderOverview from './StandingOrderOverview/StandingOrderOverview';
import { RouteDefinition } from '../../components/RenderRoutes';
import InvoiceEditingRouter from '../Invoice/InvoiceEditing/InvoiceEditingRouter';

export const STANDING_ORDER_PATH = '/standing-orders';
export const STANDING_ORDER_INVOICE_PATH = `${STANDING_ORDER_PATH}/invoice/:invoiceHrId`;

export const getStandingOrderInvoicePath = (invoiceHrId: string) => STANDING_ORDER_INVOICE_PATH.replace(':invoiceHrId', invoiceHrId);


export const StandingOrderRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: STANDING_ORDER_PATH,
      component: StandingOrderOverview,
      subRoutes: [
        {
          path: STANDING_ORDER_INVOICE_PATH,
          component: InvoiceEditingRouter,
          type: 'focus',
        },
      ],
    },
    {
      path: '*/:propertyHrId/standing-orders',
      component: StandingOrderOverview,
      type: 'overlay',
      width: '90%',
      subRoutes: [
        {
          path: '*/:propertyHrId/standing-orders/:standingOrderId/invoice/:invoiceHrId',
          component: InvoiceEditingRouter,
          type: 'focus',
        },
        {
          path: '*/:propertyHrId/standing-orders/invoice/:invoiceHrId',
          component: InvoiceEditingRouter,
          type: 'focus',
        },
      ],
    },
  ],
};
