import './InputNumber.scss';

import React from 'react';

import { Form } from 'antd';
import {
  digitNumberFormatter,
  numberInputParser,
} from 'lib/Utils';

import InputProps from '../InputProps';
import { getLabelForInput } from '../utilGetLabelForInput';
import InputNumber from './InputNumber';

interface numberInputProps extends InputProps<number> {
  // eslint-disable-next-line react/require-default-props
  min?: number
  // eslint-disable-next-line react/require-default-props
  max?: number
  // eslint-disable-next-line react/require-default-props
  precision?: 1|2|3|4|5|6,
  required?: boolean,
  controls?: boolean,
  suffix?: string,
  align?: 'left' | 'right',
}

const DECIMAL_SEPARATOR = ',';
export default function NumberInput(props: numberInputProps): JSX.Element {
  const {
    className,
    inputClassName,
    label,
    min,
    max,
    precision,
    value,
    disabled,
    required,
    validationState,
    validationMessage,
    showPlaceholderWhenDisabled,
    autoFocus,
    infoText,
    controls,
    suffix,
    align,
  } = props;
  const onChange = (v: number | undefined) => {
    // @ts-ignore
    if (v === '') return;
    if (typeof v === 'undefined') props.onChange(0);
    else props.onChange(v);
  };
  let { placeholder } = props;
  if (disabled && !showPlaceholderWhenDisabled) {
    placeholder = '';
  }
  const parser = (val: any) => {
    if (precision) return numberInputParser(val, precision);
    return parseFloat(val.replace(/\D/g, ''));
  };


  return (
    <div className={`NumberInput ${className}`}>
      <Form.Item
        label={getLabelForInput(label, infoText, required)}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <InputNumber
          className={`Input InputNumber ${inputClassName}`}
          precision={precision || 0}
          min={min}
          decimalSeparator={DECIMAL_SEPARATOR}
          max={max}
          value={value}
          onChange={onChange}
          parser={parser}
          formatter={val => digitNumberFormatter(val, precision)}
          placeholder={placeholder}
          disabled={disabled || false}
          onFocus={e => e.target.select()}
          autoFocus={autoFocus}
          controls={controls}
          suffix={suffix}
          align={align}
        />
      </Form.Item>
    </div>
  );
}

NumberInput.defaultProps = {
  required: false,
  suffix: undefined,
  align: 'left',
};
