import { RouteDefinition } from 'components/RenderRoutes';
import { lazy } from 'react';
import DocumentList from './DocumentList/DocumentList';
import { DocumentDtoSourceTypeEnum } from 'api/public';

const DocumentEditor = lazy(() => import('pages/Document/DocumentEditor/DocumentEditor'));

const DOCUMENT_PATH = '/documents';

export const DOCUMENT_EDITOR_PATH = `${DOCUMENT_PATH}/:documentDbId`;
export const DOCUMENT_EDITOR_PATH_BY_SOURCE = '/documents/view';

export const getDocumentEditorRoute = (documentDbId: string | number) => DOCUMENT_EDITOR_PATH.replace(':documentDbId', `${documentDbId}`);

export const getDocumentListRoute = (queryParams) => {
  const { sourceType, timeframe, ...params } = queryParams || {};

  const queryParamsValue = new URLSearchParams();

  Object.entries({ sourceType, ...params }).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      queryParamsValue.append(key, String(value));
    }
  });

  const route = `${DOCUMENT_PATH}${queryParamsValue.toString() ? `?${queryParamsValue.toString()}` : ''}`;

  // queryParamsValue.toString() can't be empty if `sourceType` is defined
  if (sourceType === DocumentDtoSourceTypeEnum.INVOICE.toString() && timeframe?.startDate) {
    return `${route}&minIssuedDate=${timeframe?.startDate}&maxIssuedDate=${timeframe?.endDate}`;
  }

  return route;
};

export const DocumentRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: DOCUMENT_PATH,
      component: DocumentList,
      strict: true,
      subRoutes: [
        {
          path: [DOCUMENT_EDITOR_PATH, `*${DOCUMENT_EDITOR_PATH_BY_SOURCE}`],
          component: DocumentEditor,
          type: 'overlay',
        },
      ],
    },
  ],
};
