import {
  useContext, useEffect, useMemo, useRef,
} from 'react';
import usePropertyOptions from 'services/usePropertyOptions';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { isEqual, isNil } from 'lodash';
import {DocumentCreateDtoSourceTypeEnum, DocumentLegacyControllerApi} from 'api/document';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import defaultSection from './useDefaultSection';
import { PropertyListContext } from '../../../../../contexts/PropertyListContext';
import { translations } from '../../../translations';
import usePropertyManagementCompanyEmployeeOptions from '../../../../../services/usePropertyManagementCompanyEmployeeOptions';
import useWatermarkOptions from '../../../../../services/useWatermarkOptions';
import useMoreForm from '../../../../../elements/FormElements/FormSection/UseMoreForm';
import { translations as generalTranslations } from '../../../../../elements/Translation/translations';
import {extractDocumentIdFromUrl, extractFileName} from '../../../../../lib/Utils';
import { SelectOption } from '../../../../../elements/Inputs/SelectInput/SelectInput';
import { PropertyDisplayDtoAdministrationTypeEnum, SerialLetterPropertyDto } from '../../../../../api/accounting/models';
import { SerialLetterDtoShippingStatusEnum } from '../../../../../api/accounting/models/SerialLetterDto';
import {AuthContext} from "../../../../../contexts/AuthContext";

const NUMBER_OF_ATTACHMENTS_KEY = 'nrOfAttachments';

export default function useLetterDataSection({
  index, serialLetter, onChange, setDirty,
}: any) {
  const { tl } = useContext(LanguageContext);
  const { propertyOptions, searchForProperty, onLoadProperty } = usePropertyOptions(true);
  const { setSelectedDisplayPropertyIdList } = useContext(PropertyListContext);
  const isSent = !serialLetter.data || (serialLetter.data.shippingStatus
    && serialLetter.data.shippingStatus !== `${SerialLetterDtoShippingStatusEnum.DRAFT}`
    // allow editing also in error state so users may retry sending
     && serialLetter.data.shippingStatus !== `${SerialLetterDtoShippingStatusEnum.ERROR}`
  );

  const propertyOptionsExceptSEVs = useMemo(() => propertyOptions.filter(option => [
    PropertyDisplayDtoAdministrationTypeEnum.WEG,
    PropertyDisplayDtoAdministrationTypeEnum.MV,
  ].includes(option?.administrationType)),
  [propertyOptions]);

  const { managementCompanyEmployees } = usePropertyManagementCompanyEmployeeOptions(true);
  const { watermarkOptions, defaultWatermarkId } = useWatermarkOptions();
  const { checkPropertyValidity } = useCheckPropertyValidity();
  const { goBack } = useContext(OverlayContext);
  const prevParsedProperties = useRef([]);

  const { documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document'));

  // if signing person id changes, update the name in the object (name and id)
  useEffect(() => {
    if (serialLetter.data.signingPersonId) {
      const signingPerson: SelectOption[] | undefined = managementCompanyEmployees.filter((employee: SelectOption) => employee.value === serialLetter.data.signingPersonId);
      if (signingPerson && signingPerson.length > 0) {
        onChange(signingPerson[0].label, 'signingPersonName');
      }
    }
  }, [serialLetter.data.signingPersonId]);

  useEffect(() => {
    if (watermarkOptions && serialLetter.data.watermarkId && !watermarkOptions.find(w => w?.value === serialLetter.data.watermarkId)) {
      serialLetter.data.watermarkId = undefined;
    }
  }, [watermarkOptions]);

  useEffect(() => {
    if (defaultWatermarkId && !serialLetter?.data?.id) {
      onChange(defaultWatermarkId, 'watermarkId');
    }
  }, [defaultWatermarkId]);

  useEffect(() => {
    if (serialLetter.data.properties) {
     const filteredProperties = serialLetter.data.properties.filter(p => !isNil(p));
     const newPrpHrIds = filteredProperties.map(({ propertyHrId }: { propertyHrId: string | undefined }) => propertyHrId);

      if (!isEqual(newPrpHrIds, prevParsedProperties.current)) {
        newPrpHrIds.forEach((propertyHrId: string) => {
          if (propertyHrId !== undefined) {
            checkPropertyValidity({ propertyHrId, onCancel: () => { goBack(); } });
          }
        });
        prevParsedProperties.current = newPrpHrIds;
      }
      setSelectedDisplayPropertyIdList(filteredProperties.map((prp: SerialLetterPropertyDto) => prp.propertyId));
    }
  }, [serialLetter.data.properties]);

  const attachmentInputs = useMoreForm({
    value: serialLetter.data,
    key: NUMBER_OF_ATTACHMENTS_KEY,
    content: (idx: number) => ([[
      {
        type: 'file',
        key: `attachments[${idx}].url`,
        props: {
          descriptionLabel: tl(translations.editPage.sections.letterDataSection.attachments.selectDocument),
          buttonLabel: tl(generalTranslations.elements.fileUpload.buttonLabel),
          name: `attachment${idx}`,
          disabled: isSent,
          hideDeleteButton: isSent,
          sourceType: DocumentCreateDtoSourceTypeEnum.SERIAL_LETTER,
          onDeleteFile: () => {
            onChange(null, `attachments[${idx}]`);
            onChange(serialLetter.data[NUMBER_OF_ATTACHMENTS_KEY] - 1, NUMBER_OF_ATTACHMENTS_KEY);
            setDirty(true);
          },
          accept: '.pdf',
          onChange: async (value: any) => {
            let fileName = extractFileName(value);
            if (!fileName) {
              const documentId = extractDocumentIdFromUrl(value);
              if (documentId) {
                const { name: documentName } = await documentControllerApi.getDocumentByIdUsingGET({ documentId });
                fileName = documentName;
              }
            }
            onChange(value, `attachments[${idx}].url`);
            onChange(fileName, `attachments[${idx}].name`);
            setDirty(true);
          },
        },
      },
    ]]),
  });

  const onSelectProperty = (propertyId: number, idx: number) => {
    const property = propertyOptionsExceptSEVs.find(option => option.value === propertyId)!;
    if (property) {
      checkPropertyValidity({
        propertyHrId: property.propertyHrId,
        onCancel: () => {
          goBack();
        },
      });
    }
    onChange(propertyId, `properties[${idx}].propertyId`);
  };


  const availablePropertyOptions = useMemo(() => {
    const selectedProperties = serialLetter.data.properties || [];
    const selectedPropertyIds = selectedProperties.map((prp: SerialLetterPropertyDto) => prp?.propertyId);
    return propertyOptionsExceptSEVs.filter(option => !selectedPropertyIds.includes(option.value));
  }, [propertyOptionsExceptSEVs, serialLetter.data.properties]);

  const propertySelectorInput = (idx: number) => {
    let options: any[] = [];
    try {
      const selectedOption = propertyOptionsExceptSEVs.filter(option => option.value === serialLetter.data.properties[idx].propertyId);
      options = selectedOption.concat(availablePropertyOptions);
    } catch (e) {
      options = availablePropertyOptions;
    }
    return [[
      {
        type: 'smartSearch',
        key: `properties[${idx}].propertyId`,
        props: {
          required: true,
          label: `${tl(translations.editPage.sections.letterDataSection.propertySubsection.property)} ${idx + 1}`,
          searchFunction: searchForProperty,
          getOneFunction: onLoadProperty,
          options,
          onChange: (value:number) => { onSelectProperty(value, idx); },
          disabled: isSent,
        },
      },
      {
        type: 'deleteButton',
        minWidth: '7.0rem',
        maxWidth: '7.0rem',
        props: {
          dataKey: `properties[${idx}]`,
          nrKey: 'numberOfProperties',
          disabled: idx === 0 || isSent,
        },
      },
    ]];
  };

  const propertySelectors = useMoreForm({
    value: serialLetter.data,
    key: 'numberOfProperties',
    content: propertySelectorInput,
  });

  return {
    ...defaultSection(index, index, serialLetter),
    sectionTitle: tl(translations.editPage.sections.letterDataSection.title),
    sectionId: 'LetterDataSection',
    content: [
      {
        sectionId: 'propertyAndSender',
        title: '',
        content: [
          ...propertySelectors,
          [{
            type: 'addButton',
            key: 'numberOfProperties',
            props: {
              label: tl(translations.editPage.sections.letterDataSection.propertySubsection.addMore),
              disabled: isSent,
            },
          }],
        ],
      }, {
        sectionId: 'letterData',
        title: '',
        content: [
          [
            {
              type: 'select',
              key: 'signingPersonId',
              props: {
                label: tl(translations.editPage.sections.letterDataSection.letterSubsection.signingPerson),
                options: managementCompanyEmployees,
                disabled: isSent,
                showSearch: true,
              },
            },
            {},
          ],
          [
            {
              type: 'select',
              key: 'template',
              props: {
                label: tl(translations.editPage.sections.letterDataSection.letterSubsection.template),
                options: [],
                disabled: true, // TODO: enable it when template selection/ creation is implemented
              },
            },
            {
              type: 'select',
              key: 'watermarkId',
              props: {
                label: tl(translations.editPage.sections.letterDataSection.letterSubsection.watermark),
                options: watermarkOptions,
                disabled: isSent,
                allowClear: true,
              },
            },
          ],
          [
            {
              type: 'text',
              key: 'subject',
              props: {
                label: tl(translations.editPage.sections.letterDataSection.letterSubsection.subject),
                required: true,
                disabled: isSent,
              },
            },
          ],
          [
            {
              type: 'richTextEditor',
              key: 'body',
              props: {
                label: tl(translations.editPage.sections.letterDataSection.letterSubsection.body),
                required: true,
                disabled: isSent,
              },
            },
          ],
        ],
      },
      {
        sectionId: 'attachemnts',
        content: [
          ...attachmentInputs,
          [
            {
              type: 'addButton',
              key: NUMBER_OF_ATTACHMENTS_KEY,
              props: {
                label: tl(translations.editPage.sections.letterDataSection.attachments.addDocument),
                disabled: isSent,
              },
            },
          ],
        ],
      },
    ],
  };
}
