import { GetDocumentsByFilterUsingGETRequest } from 'api/public';
import { Filter } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { isNil } from 'lodash';
import { useContext } from 'react';
import { usePropertyAdministrationTypeFilterOption } from 'services/usePropertyAdministrationTypeFilterOption/usePropertyAdministrationTypeFilterOption';
import { isAuditingUser } from 'lib/userPermissionUtils';
import { translations } from '../translations';
import { DocumentListContext } from './DocumentListContext';

export const useDocumentsListFilters = () => {
  const { tl } = useContext(LanguageContext);
  const documentsListContext = useContext(DocumentListContext);

  if (documentsListContext === undefined) {
    throw new Error('useDocumentsListFilters must be used within DocumentListContextProvider');
  }

  const { filterState, setFilterState } = documentsListContext;

  const administrationTypeFilterOption = usePropertyAdministrationTypeFilterOption();

  const onChangeFilterState = (key: keyof GetDocumentsByFilterUsingGETRequest, value: any[keyof GetDocumentsByFilterUsingGETRequest]) => {
    setFilterState((prev) => {
      if (isNil(value)) {
        return ({
          ...prev,
          [key]: undefined,
        });
      }
      return ({
        ...prev,
        [key]: value,
      });
    });
  };

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setFilterState(prev => ({
      ...prev,
      ...searchParams,
    }));
  };

  const filters: Filter[] = [
    {
      type: 'text',
      name: tl(translations.filter.title),
      key: 'name',
    },
    {
      type: 'text',
      name: tl(translations.filter.tagName),
      key: 'tagName',
    },
    ...(!isAuditingUser()
      ? ([
          {
            type: 'text',
            name: tl(translations.filter.propertyName),
            key: 'propertyName',
          },
          {
            type: 'text',
            name: tl(translations.filter.propertyIdInternal),
            key: 'propertyIdInternal',
          },
          administrationTypeFilterOption,
          {
            type: 'text',
            name: tl(translations.filter.administrator),
            key: 'administrator',
          },
          {
            type: 'text',
            name: tl(translations.filter.accountant),
            key: 'accountant',
          },
        ] as Filter[])
      : []),
    {
      type: 'text',
      name: tl(translations.filter.contract),
      key: 'contractName',
    },
    {
      type: 'date',
      name: tl(translations.filter.date),
      key: 'issuedDate',
    },
    {
      type: 'date',
      name: tl(translations.filter.minDate),
      key: 'minIssuedDate',
    },
    {
      type: 'date',
      name: tl(translations.filter.maxDate),
      key: 'maxIssuedDate',
    },
    {
      type: 'text',
      name: tl(translations.filter.contactId),
      key: 'contactId',
      visibleInSearchDropdown: false,
    },
    {
      type: 'text',
      name: tl(translations.filter.createdBy),
      key: 'createdBy',
    },
  ];

  return {
    filters,
    onSetDefaultFilterFromQueryParams,
    filterState,
    onChangeFilterState,
  };
};
