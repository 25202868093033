import React, { useContext, useEffect } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Alert } from 'antd';
import { PrimarySectionWrapper } from 'storybook-components/sections/PrimarySectionWrapper/PrimarySectionWrapper';
import { translations } from './translations';
import { loadCsv } from '../services/loadCsv';
import { useImportContactsCsv } from './useContactsImportCsv';
import { FileUploadButton } from '../components/FileUploadButton/FileUploadButton';
import { showNotification } from '../../../../lib/Notification';
import { ContactImportDto } from './interfaces';


export const ContactsImportSection = ({ onLoaded }: { onLoaded: (data: ContactImportDto[]) => void }) => {
  const { tl } = useContext(LanguageContext);
  const { validateContactRows, contacts, contactsValidationIssues, numberOfValidatedContacts } = useImportContactsCsv();
  useEffect(() => { onLoaded(contacts); }, [contacts]);
  return (

    <PrimarySectionWrapper
      sectionNumber={1}
      sectionTitle={tl(translations.title)}
      sectionId="hms-close-year-account-selection"
    >
      <div className="row">
        <div className="col-xs-12">
          <FileUploadButton
            label={tl(translations.upload)}
            accept="text/csv"
            onChange={async event => {
              const files = event.target.files;
              if (files.length > 0) {
                try {
                  for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const reader = new FileReader();
                    let header;
                    let lineBuffer = [];

                    reader.onload = async (e) => {
                      let content = e.target.result as string;

                      // Split the content into lines
                      const lines = content.split('\n');
                      header = lines[0];
                      // Process lines in chunks of 8
                      for (let j = 1; j < lines.length; j++) {
                        lineBuffer.push(lines[j]);
                        if (lineBuffer.length === 5) {
                          // Create a new DataTransfer object for the current chunk
                          const dataTransfer = new DataTransfer();
                          const blob = new Blob([header + '\n' + lineBuffer.join('\n')], { type: 'text/csv' });
                          dataTransfer.items.add(new File([blob], file.name));

                          const fileList = dataTransfer.files;
                          const res = await loadCsv(fileList, 0);
                          await validateContactRows(res, j - lineBuffer.length);

                          // Clear the buffer
                          lineBuffer = [];
                          await new Promise(resolve => setTimeout(resolve, 500));
                        }
                      }

                      if (lineBuffer.length > 0) {
                        const dataTransfer = new DataTransfer();
                        const blob = new Blob([header + '\n' + lineBuffer.join('\n')], { type: 'text/csv' });
                        dataTransfer.items.add(new File([blob], file.name));

                        const fileList = dataTransfer.files;
                        const res = await loadCsv(fileList, 0);
                        await validateContactRows(res, 0);
                      }
                    };


                    reader.readAsText(file); // Read the file as text
                  }
                } catch (err) {
                  showNotification({ message: tl(translations.validations.errors) });
                  console.error('Error while loading csv', err);
                }
              }
            }}
          />
        </div>
      </div>
      {numberOfValidatedContacts === 0 && (<Alert message={tl(translations.description)} />)}
      {numberOfValidatedContacts !== 0 && (contacts.length > 0
        ? <Alert message={`${contacts.length} ${tl(translations.validations.success)}. To create: ${contacts.filter(c => !c.id).length} To update: ${contacts.filter(c => c.id).length}`} type="success" />
        : <Alert message={`${numberOfValidatedContacts} ${tl(translations.counter)}`} />
      )}
      {contactsValidationIssues.length > 0 && (
        <Alert
          message={tl(translations.validations.errors)}
          type="error"
          description={(
            <ul>
              {' '}
              {
                contactsValidationIssues.map(issue => (
                  <li key={issue.row + issue.message}>
                    {`${issue.row + 1}: ${issue.message}`}
                  </li>
                ))
              }
            </ul>
          )}
        />

      )}

    </PrimarySectionWrapper>
  );
};
