export const translations = {
  title: { en: 'Contacts', de: 'Kontakte' },
  description: { en: 'List of contacts to upload', de: 'Liste der Kontakten zum hochladen. Die Liste sollte keine schon bereits existierende Kontakte beinhalten um Duplikate zu vermeiden.' },
  upload: { en: 'Upload contacts', de: 'Kontakte hochladen' },
  counter: { en: 'uploaded contacts', de: 'hochgeladene Kontakte' },
  validations: {
    success: { en: 'Contacts have been verified and ready for import.', de: 'Kontakte wurden geprüft und sind bereit zum Importieren' },
    errors: { en: 'Issues found in the CSV', de: 'Es wurden Fehlern gefunden' },
    issues: {
      person: { en: 'Invalid person; first- / lastnames shall be specified ', de: 'Ungültige Personenkontakt. Vor- und Nachname müssen definiert werden.' },
      company: { en: 'Invalid company: company name shall be specified', de: 'Ungültige Firmenkontakt: Die Firmenname soll definiert werden.' },
      title: { en: 'Invalid title. Supported values are <empty>, Prof., Prof. Dr. or Dr..', de: 'Ungültige Titel. Die erlaubte Werte sind: <lehr>, Prof., Prof. Dr. or Dr.' },
      country: { en: 'Invalid country code. Only ISO values are supported.', de: 'Nur 2 Buchstaben / ISO Landreferenzen dürfen verwendet werden. z.B.: DE für Deutschland' },
      city: { en: 'Invalid city', de: 'Ungültiges Ort' },
      street: { en: 'Invalid street', de: 'Ungültige Straße' },
      number: { en: 'Invalid number', de: 'Ungültiges Hausnummer' },
      iban: { en: 'Invalid iban', de: 'Ungültige IBAN' },
      bicIdentificationError: { en: 'BIC could not be automatically filled out.', de: 'BIC konnte nicht automatisch ausgefüllt werden.' },
      accountInstituteIdentificationError: { en: 'Could not identify accountInstitute.', de: 'Ungültiges Bank.' },
      bicPattern: { en: 'Invalid BIC pattern', de: 'Ungültiges BIC-Format.' },
      accountHolderName: { en: 'Invalid accountHolderName', de: 'Unglültige Kontoinhaber' },
    },
  },
};
