import {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { AuthContext } from 'contexts/AuthContext';
import FileSaver from 'file-saver';
import { nanoid } from 'nanoid';

import {
  GetTransactionImportsPagedUsingGETOrderEnum,
  PageOfTransactionImportBatchProjectionDto,
  TransactionImportControllerApi,
  TransactionImportDto,
  TransactionImportDtoStatusEnum,
} from 'api/accounting';
import backend, { endpointUrls } from 'backend_api';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import {extractDocumentIdFromUrl, extractFileName} from 'lib/Utils';
import {
  Order,
  useSort,
} from 'services/useSort';
import { translations } from '../../translations';
import {DocumentLegacyControllerApi} from "../../../../api/document";

export interface TransactionImport extends TransactionImportDto {
  statusTranslated: string,
  rowId?: string,
  children?: Array<TransactionImportDto>,
  totalNrOfFiles?: number,
}

interface TransactionImportsState {
  transactionImports: TransactionImport[],
  page: number
  lastPage: boolean
  loading: boolean
}

interface TransactionImportsStateAction {
  type: 'START_LOADING' | 'STOP_LOADING' | 'FAILED' | 'SET' | 'APPEND' | 'CLEAR'
  data?: PageOfTransactionImportBatchProjectionDto
}

export const useTransactionImports = () => {
  const { tl } = useContext(LanguageContext);
  const [blobs, setBlobs] = useState<Blob[]>([]);
  const [nrOfFiles, setNrOfFiles] = useState(0);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const { apiConfiguration } = useContext(AuthContext);
  const transactionImportControllerApi = new TransactionImportControllerApi(apiConfiguration('accounting'));

  const { documentApiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document'));

  const initialState: TransactionImportsState = {
    transactionImports: [],
    page: 0,
    lastPage: false,
    loading: false,
  };

  const toTransactionImport = (dto: TransactionImportDto): TransactionImport => ({
    ...dto,
    statusTranslated: tl(translations.status[dto.status!]),
    // @ts-ignore
    rowId: `batch-child-${dto.batchId}-${nanoid()}`,
  });

  const hasFailedImport = (imports: TransactionImportDto[]) => {
    try {
      return imports.filter(imp => imp.status === TransactionImportDtoStatusEnum.FAILED).length > 0;
    } catch (e) {
      return false;
    }
  };

  function generateBatchesList(batchId: string, failedImports: any, processedImports: any) {
    const failedTxs = {
      statusTranslated: tl(translations.status.FAILED),
      status: TransactionImportDtoStatusEnum.FAILED,
      totalNrOfFiles: failedImports.length,
      children: failedImports,
      rowId: `batch-group-child-0-${batchId}-${nanoid()}`,
    };
    const children = [
      failedTxs,
    ];
    if (processedImports.length > 0) {
      const processedTxs = {
        statusTranslated: tl(translations.status.PROCESSED),
        status: TransactionImportDtoStatusEnum.PROCESSED,
        totalNrOfFiles: processedImports.length,
        children: processedImports,
        rowId: `batch-group-child-1-${batchId}-${nanoid()}`,
      };
      children.push(processedTxs);
    }
    return children;
  }

  const toBatches = (batch: any) => {
    let batchStatus = 'PROCESSED';
    if (!batch.transactionImports || (batch.transactionImports && batch.totalNrOfFiles !== batch.transactionImports.length)) {
      batchStatus = 'IN_PROGRESS';
    } else {
      // do not show files until import is not completely done
      const batchHasFailedImports = hasFailedImport(batch.transactionImports);
      if (batchHasFailedImports) {
        const failedImports = batch.transactionImports ? batch.transactionImports.filter((imp: TransactionImportDto) => imp.status === TransactionImportDtoStatusEnum.FAILED).map(toTransactionImport) : [];
        const processedImports = batch.transactionImports ? batch.transactionImports.filter((imp: TransactionImportDto) => imp.status === TransactionImportDtoStatusEnum.PROCESSED).map(toTransactionImport) : [];
        const importList = generateBatchesList(batch.batchId, failedImports, processedImports);

        if (processedImports.length === 0) {
          batchStatus = 'FAILED';
        } else {
          batchStatus = 'WARNING';
        }

        return ({
          ...batch,
          children: importList,
          statusTranslated: tl(translations.status[batchStatus]),
          status: batchStatus,
          rowId: `batch-${batch.batchId}-${nanoid()}`,
        });
      }
    }
    return ({
      ...batch,
      children: batch.transactionImports ? batch.transactionImports.map(toTransactionImport) : [],
      statusTranslated: tl(translations.status[batchStatus]),
      status: batchStatus,
      rowId: `batch-${batch.batchId}-${nanoid()}`,
    });
  };

  const reducer = (currentState: TransactionImportsState, action: TransactionImportsStateAction): TransactionImportsState => {
    switch (action.type) {
      case 'START_LOADING':
        return {
          ...currentState,
          loading: true,
        };
      case 'FAILED':
        return {
          ...currentState,
          loading: false,
          lastPage: true,
        };
      case 'STOP_LOADING':
        return {
          ...currentState,
          loading: false,
        };
      case 'SET':
        return {
          ...initialState,
          page: 1,
          transactionImports: action.data!.content!.map(toBatches),
        };
      case 'APPEND':
        return {
          transactionImports: currentState.transactionImports.concat(action.data!.content!.map(toBatches)),
          page: currentState.page + 1,
          lastPage: action.data!.last!,
          loading: false,
        };
      case 'CLEAR':
        return initialState;
      default:
        return currentState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { field, order, setSortField } = useSort({ initialField: 'created', initialOrder: Order.DESC });

  useEffect(() => {
    loadTransactions(true);
  }, [field, order]);

  const loadTransactions = async (resetPage?: boolean) => {
    dispatch({ type: 'START_LOADING' });
    transactionImportControllerApi.getTransactionImportsPagedUsingGET({
      page: resetPage ? 0 : state.page,
      size: 30,
      sort: field,
      order: Order[order] as unknown as GetTransactionImportsPagedUsingGETOrderEnum,
    }).then((response: PageOfTransactionImportBatchProjectionDto) => {
      const dispatchType = resetPage ? 'SET' : 'APPEND';
      // @ts-ignore
      dispatch({ type: dispatchType, data: response });
    })
      .catch(() => {
        dispatch({ type: 'FAILED' });
        showNotification({
          key: 'loadTransactionImportsError',
          message: tl(translations.notifications.transactionImportLoadError.message),
          type: 'error',
        });
      });
  };

  const download = (url: string) => {
    backend.getFile(url)
      .then(async (resp: any) => {
        const blob = new Blob([resp]);
        let fileName = extractFileName(url);
        if (!fileName) {
          const documentId = extractDocumentIdFromUrl(url);
          if (documentId) {
            const { name: documentName } = await documentControllerApi.getDocumentByIdUsingGET({ documentId });
            fileName = documentName;
          }
        }
        if (!fileName.includes('.xml')) {
          fileName += (fileName.substr(-1) === '.') ? 'xml' : '.xml';
        }
        FileSaver.saveAs(blob, fileName);
      })
      .catch(() => {
        showNotification({
          key: 'fileDownloadError',
          message: tl(translations.notifications.downloadError.message),
          type: 'error',
        });
      });
  };

  const showUploadError = () => {
    dispatch({ type: 'STOP_LOADING' });
    showNotification({
      key: 'uploadImportFileError',
      message: tl(translations.notifications.uploadError.message),
      type: 'error',
    });
  };

  const uploadABlob = (blob: Blob) => {
    setBlobs((tempBlobs) => {
      const newBlobs = tempBlobs.slice();
      newBlobs.push(blob);
      return newBlobs;
    });
  };

  useEffect(() => {
    if (nrOfFiles !== 0 && blobs.length === nrOfFiles) {
      backend.postFiles(`${endpointUrls.TRANSACTION_IMPORT}/match`, blobs, fileNames, {})
        .then((response: any) => {
          setFileNames([]);
          setBlobs([]);
          dispatch({ type: 'CLEAR' });
          const uploadedFileNr = response.totalNrOfFiles || 0;
          showNotification({
            key: 'uploadImportAlreadyUploaded',
            message: ` ${uploadedFileNr} ${tl(translations.notifications.uploadSuccess.message)}`,
            type: 'success',
          });
        })
        .catch(() => {
          setFileNames([]);
          setBlobs([]);
          showUploadError();
        });
    }
  }, [blobs]);


  const upload = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setNrOfFiles(event.target.files.length);
      dispatch({ type: 'START_LOADING' });
      Array.prototype.forEach.call(event.target.files, (file: File) => {
        setFileNames((names: string[]) => {
          const newNames = names.slice();
          newNames.push(file.name);
          return newNames;
        });
        uploadABlob(file);
      });
    }
  };

  return {
    ...state,
    load: loadTransactions,
    download,
    upload,
    sortField: field,
    sortOrder: order,
    setSortField,
  };
};
