import React from 'react';
import './Dashboard.scss';
import LanguageSwitcher from 'elements/LanguageSwitcher/LanguageSwitcher';
import { getEnvVar } from 'lib/getEnvVar';
import AnnouncementAlert from 'storybook-components/AnnouncementAlert/AnnouncementAlert';
import { isAuditingUser } from 'lib/userPermissionUtils';
import { isMasterLikeEnv } from '../../config';
import { NavigationTiles } from './components/NavigationTiles';
import { useGlobalSearch } from '../../components/Header/components/SearchBar/services/useGlobalSearch';
import { HelpButton } from '../../components/HelpButton/HelpButton';
import { useDashboard } from './useDashboard';
import { useAlertOnDashboard } from './services/useAlertOnDashboard';

export const Dashboard: React.FC = () => {
  const { navigationSections } = useDashboard();

  useGlobalSearch({
    key: 'dashboard',
    filterProps: {
      availableFilters: [],
      setFilter: () => {},
    },
  });

  return (
    <div className="Dashboard page">
      <div className="container">
        {navigationSections.map((section, index) => (
          <div key={section.id} className="dashboard-section">
            <div className="dashboard-header-row">
              <span className="salutation">{section.salutation}</span>
              {section.showLanguageSwitcher && (getEnvVar('app.showLanguageSelector', `${!isMasterLikeEnv}`) as string) === 'true' && (
                <span className="language-switcher-wrapper">
                  <LanguageSwitcher />
                </span>
              )}
            </div>
            {index === 0 && <AnnouncementAlert {...useAlertOnDashboard()} />}
            <NavigationTiles tileItems={section.items} />
          </div>
        ))}
      </div>
      { !isAuditingUser() && <HelpButton /> }
    </div>
  );
};
