import { useContext } from 'react';

import { useStore } from 'zustand';
import { AuditingGlobalFiltersStore } from './interfacesAuditingGlobalFilter';
import { AuditingGlobalFilterContext } from './storeAuditingGlobalFilter';
import { isEmpty } from 'lodash';

// ! do not export !
const useAuditingGlobalFilterStore = <T>(
  selector: (state: AuditingGlobalFiltersStore) => T,
): T => {
  const store = useContext(AuditingGlobalFilterContext);

  if (!store) {
    throw new Error('Missing AuditingGlobalFilterStoreProvider.');
  }

  return useStore(store, selector);
};

export const useAuditingGlobalFilterActions = () => useAuditingGlobalFilterStore((state) => state.actions);

export const useAuditingGlobalFilters = () => useAuditingGlobalFilterStore((state) => {
  if (!isEmpty(state.globalFilters?.sharedDocuments)) {
    return state.globalFilters;
  }

  return JSON.parse(localStorage.getItem('globalFilters')) || {};
});

export const useAuditingGlobalFilterLoadedData = () => useAuditingGlobalFilterStore((state) => state.loadedData);

export const useAuditingGlobalFilterIsDateRangeLimited = () =>
  useAuditingGlobalFilterStore((state) => {
    const globalFilter = !isEmpty(state.globalFilters.timeframe?.startDate) ? state.globalFilters : JSON.parse(localStorage.getItem('globalFilters')) || {};

    if (isEmpty(globalFilter.timeframe?.startDate) || isEmpty(globalFilter.timeframe?.endDate)) {
      return false;
    }

    return true;
  });

export const useAuditingGlobalFilterIsDocumentListLimited = () =>
  useAuditingGlobalFilterStore((state) => {
    const globalFilter = !isEmpty(state.globalFilters?.sharedDocuments) ? state.globalFilters : JSON.parse(localStorage.getItem('globalFilters')) || {};

    if (isEmpty(globalFilter.sharedDocuments)) {
      return false;
    }

    return true;
  });
