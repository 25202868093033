import { useContext } from 'react';

import {
  AllocationControllerApi,
  AllocationDto,
  ExchangeControllerApi,
  ExchangeDto,
  ExchangeDtoStateEnum,
  ExchangeDtoTypeEnum,
  ExtendedExchangeProjection,
  ExtendedExchangeProjectionStateEnum,
  ExtendedExchangeProjectionTypeEnum,
  GetAllExchangesFilteredUsingGETTypesEnum,
  GetAllocationsUsingGETStatesEnum,
  ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import {
  Dictionary,
  groupBy,
  isEmpty,
} from 'lodash';
import { nanoid } from 'nanoid';
import { ExtendedBankTransaction } from 'pages/BankTransactions/interfaces';

import { translations } from '../../../../translations';
import {
  AllocationAmount,
  ExchangeListAndPreviousAllocationValuesContext,
  ServiceCompanyAllocationUpdatersContext,
  ServiceCompanyAllocationValues,
  SetPostingTextsContext,
} from './ServiceCompanyAllocationContext';
import {
  useServiceCompanyAllocationProposals,
} from './useServiceCompanyAllocationProposals';

const getComparisonScore = (exchange: ServiceCompanyAllocationValues, txAmount: number, propertyIban: string, counterpartIban: string) => {
  let score = 0;

  if (exchange.type === ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED) score += 100;

  if (exchange.previousAllocationAmount !== undefined && Math.abs(exchange.previousAllocationAmount) > 0) score += 10;

  if (exchange.allocationAmount === txAmount) score += 1;

  if (exchange.propertyIban === propertyIban) score += 2;

  if (exchange.counterpartIban === counterpartIban) score += 2;

  return score;
};


const compareExchangesFn = (
  a: ServiceCompanyAllocationValues,
  b: ServiceCompanyAllocationValues,
  txAmount: number,
  propertyIban: string,
  counterpartIban: string,
) => (
  getComparisonScore(b, txAmount, propertyIban, counterpartIban)
    - getComparisonScore(a, txAmount, propertyIban, counterpartIban)
);


// Exchanges from allocationDto does not contain extra info such as AccountNames
const getAccounts = (exchangeFromAllocation: ExchangeDto | undefined, extendedExchangeList: ExtendedExchangeProjection[]) => {
  const extendedExchange = extendedExchangeList.find(xc => xc.id === exchangeFromAllocation!.id);
  return extendedExchange?.counterpartInstruction?.[0]?.categorisationInstructions?.map(cti => ({
    code: cti.accountCode!,
    name: cti.accountName!,
  }));
};


const getVatPercentageForUnallocatedExchanges = (xc: ExtendedExchangeProjection) => (
  xc.counterpartInstruction.map(
    cpi => cpi.categorisationInstructions.map(cti => cti.vatPercentage),
  ).flat(2)
);


const getVatPercentageForAllocatedExchanges = (allocDtos: AllocationDto[]) => {
  if (allocDtos[0]?.exchange?.type === ExchangeDtoTypeEnum.TRANSACTION_BASED) {
    return (
      allocDtos[0].exchange
        ?.counterpartInstruction?.[0]
        ?.categorisationInstructions?.[0]
        .vatPercentage
    );
  }

  return (
    allocDtos[0]?.exchange?.counterpartInstruction?.[0]?.categorisationInstructions?.map(cti => cti.vatPercentage)
  );
};

const getVatEligibilityForUnallocatedExchanges = (xc: ExtendedExchangeProjection, key) => (
  xc.counterpartInstruction.map(
    cpi => cpi.categorisationInstructions.map(cti => cti[key]),
  ).flat(2)
);


const getVatEligibilityForAllocatedExchanges = (allocDtos: AllocationDto[], key) => {
  if (allocDtos[0]?.exchange?.type === ExchangeDtoTypeEnum.TRANSACTION_BASED) {
    return (
      allocDtos[0].exchange
        ?.counterpartInstruction?.[0]
        ?.categorisationInstructions?.[0][key]
    );
  }

  return (
    allocDtos[0]?.exchange?.counterpartInstruction?.[0]?.categorisationInstructions?.map(cti => cti[key])
  );
};


export const useInitializeExchangeList = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const { tl } = useContext(LanguageContext);
  const exchangeListAndPreviousAllocationValuesContext = useContext(ExchangeListAndPreviousAllocationValuesContext);
  const updatersContext = useContext(ServiceCompanyAllocationUpdatersContext);
  const setPostingTexts = useContext(SetPostingTextsContext);
  const exchangeControllerApi = new ExchangeControllerApi(apiConfiguration('accounting'));
  const allocationControllerApi = new AllocationControllerApi(apiConfiguration('accounting'));

  if (exchangeListAndPreviousAllocationValuesContext === undefined || updatersContext === undefined || setPostingTexts === undefined) {
    throw new Error('useInitializeExchangeList must be used within a ServiceCompanyAllocationContextProvider');
  }

  const { setExchangeList, setPreviousAllocationValues } = exchangeListAndPreviousAllocationValuesContext;
  const {
    setAllocationAmounts,
    setAllocationLaborCostValues,
    setAllocationVatEligibilityValues,
    setSelectInputValues,
  } = updatersContext;

  const { fetchProposals } = useServiceCompanyAllocationProposals();

  const initialize = (propertyHrIds: string[], bankTransactions: ExtendedBankTransaction[]) => {
    if (bankTransactions.length === 0 || isEmpty(propertyHrIds)) return;
    setExchangeList(prev => prev.startLoading());
    setAllocationAmounts([]);
    setAllocationLaborCostValues([]);
    setSelectInputValues([]);
    setAllocationVatEligibilityValues([]);

    const exchangesPromise = exchangeControllerApi.getAllExchangesFilteredUsingGET({
      propertyHrIds,
      states: [
        ExchangeDtoStateEnum.PAID,
        ExchangeDtoStateEnum.PAYING,
        ExchangeDtoStateEnum.BOOKED,
      ],
      types: [GetAllExchangesFilteredUsingGETTypesEnum.TRANSACTION_BASED, GetAllExchangesFilteredUsingGETTypesEnum.INVOICE] as unknown as GetAllExchangesFilteredUsingGETTypesEnum,
      ...(bankTransactions[0].amount! > 0 ? { minAmount: 0 } : { maxAmount: 0 }),
    });

    const bankTransaction = bankTransactions[0];
    const transactionIds = bankTransactions.map(tx => tx.bankTransactionId!);
    const allocationsPromise = allocationControllerApi.getAllocationsUsingGET({ transactionIds, states: [GetAllocationsUsingGETStatesEnum.ALLOCATED] as unknown as GetAllocationsUsingGETStatesEnum });

    Promise.allSettled([exchangesPromise, allocationsPromise])
      .then(([exchangesPromiseResult, allocationsPromiseResult]) => {
        if (exchangesPromiseResult.status === 'rejected' || allocationsPromiseResult.status === 'rejected') {
          showNotification({
            type: 'error',
            message: tl(translations.notifications.loadPreviousAllocationsError.message),
          });
          setExchangeList(prev => prev.failed());
          return;
        }

        const invoiceAndTxBasedAllocations = allocationsPromiseResult.value.filter(a => a.exchange?.type === ExchangeDtoTypeEnum.INVOICE
           || a.exchange?.type === ExchangeDtoTypeEnum.TRANSACTION_BASED);
        
        // group allocations by transaction id
        const groupByTransactions = groupBy(invoiceAndTxBasedAllocations, allocDto => allocDto.transaction.id);
        
        const getAllocationsGroupedByExchangeData = (groupByTransactions) => {
          let result: Dictionary<AllocationDto[]> = {};

          // iterate over each transaction
          Object.values(groupByTransactions).forEach((allocations: AllocationDto[]) => {
            let counter = {};

            allocations.forEach((allocDto) => {
              const baseKey = `${
                allocDto.amount}#${
                allocDto.exchange?.serviceCompanyId}#${
                allocDto.exchange?.counterpartInstruction?.[0].categorisationInstructions?.[0].accountCode}#${
                allocDto.exchange?.counterpartInstruction?.[0].categorisationInstructions?.[0].laborCost}#${
                allocDto.exchange?.counterpartInstruction?.[0].categorisationInstructions?.[0].laborCostType}${
                allocDto.exchange?.counterpartInstruction?.[0].categorisationInstructions?.[0].vatPercentage}${
                allocDto.exchange?.counterpartInstruction?.[0].categorisationInstructions?.[0].vatEligibilityPercentage}${
                allocDto.exchange?.type === ExchangeDtoTypeEnum.INVOICE ? `#${allocDto.exchange.id}` : ''}`;
              if (!counter[baseKey]) {
                counter[baseKey] = 0;
              }
              const uniqueKey = counter[baseKey] > 0 ? `${baseKey}#${counter[baseKey]}` : `${baseKey}#0`;
              counter[baseKey]++;

              if (!result[uniqueKey]) {
                result[uniqueKey] = [];
              }
              result[uniqueKey].push(allocDto);
            });
          });

          return result;
        };
        const allocationsGroupedByExchangeData = getAllocationsGroupedByExchangeData(groupByTransactions);

        const allocatedExchanges = Object.values(allocationsGroupedByExchangeData).map(
          (allocDtos) => {
            const key = nanoid();
            const postingTexts = allocDtos.map(allocDto => ({
              key,
              transactionId: allocDto.transaction?.id,
              postingText: allocDto.exchange?.counterpartInstruction?.[0].categorisationInstructions?.[0].bookingText ?? '',
              previousPostingText: allocDto.exchange?.counterpartInstruction?.[0].categorisationInstructions?.[0].bookingText ?? '',
              exchangeId: allocDto.exchange?.id,
              allocationId: allocDto.id,
              counterpartAccount: allocDto.exchange?.counterpartInstruction?.[0].counterpartCode,
            }));

            setPostingTexts(prev => [...prev, ...postingTexts]);

            const matchingExchange = exchangesPromiseResult.value.find(xc => xc.id === allocDtos[0].exchange?.id);

            const prevAmountsSum = allocDtos.filter(alloc => alloc.exchange?.id === matchingExchange?.id)
              .reduce((prevAmountsSumAcc, alloc) => (prevAmountsSumAcc + alloc.amount!), 0);

            const exchangeIds = allocDtos.map(alloc => alloc.exchange!.id!);

            const planIds = allocDtos.map(alloc => alloc.exchange!.planId!);


            const alloc: ServiceCompanyAllocationValues = {
              exchangeIds,
              planIds,
              exchangeAmount: allocDtos[0].exchange?.amount!,
              exchangeRemainingAmount: matchingExchange?.remainingAmount!,
              previousAllocationAmount: prevAmountsSum,
              allocationAmount: prevAmountsSum,
              key,
              accounts: getAccounts(allocDtos[0].exchange, exchangesPromiseResult.value),
              counterpartName: matchingExchange?.companyName?.trim() || matchingExchange?.ownerName?.trim() || '',
              counterpartContactId: allocDtos[0].exchange?.unitContractId ?? allocDtos[0].exchange?.serviceCompanyId,
              laborCost: allocDtos[0].exchange?.counterpartInstruction?.[0]?.categorisationInstructions?.[0].laborCost,
              laborCostType: allocDtos[0].exchange?.counterpartInstruction?.[0]?.categorisationInstructions?.[0].laborCostType as unknown as ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
              vatPercentage: getVatPercentageForAllocatedExchanges(allocDtos),
              vatEligibilityPercentage: getVatEligibilityForAllocatedExchanges(allocDtos, 'vatEligibilityPercentage'),
              vatEligibilityAmount: getVatEligibilityForAllocatedExchanges(allocDtos, 'vatEligibilityAmount'),
              date: allocDtos[0].exchange?.debtDate,
              type: allocDtos[0].exchange?.type! as unknown as ExtendedExchangeProjectionTypeEnum,
              purpose: allocDtos[0].exchange?.name,
              propertyIban: matchingExchange?.propertyIban,
              counterpartIban: matchingExchange?.counterpartIban,
              propertyHrId: allocDtos[0].exchange?.propertyHrId,
              propertyIdInternal: matchingExchange?.propertyIdInternal,
              propertyName: matchingExchange?.propertyName,
            };

            return alloc;
          },
        );


        const unallocatedExchanges = exchangesPromiseResult.value
          .reduce((exchangesAcc, xc) => {
            if (
              xc.state === ExtendedExchangeProjectionStateEnum.PAID
            || ![
              ExtendedExchangeProjectionTypeEnum.TRANSACTION_BASED,
              ExtendedExchangeProjectionTypeEnum.INVOICE,
            ].includes(xc.type!)
            ) {
              return exchangesAcc;
            }

            const prevAmountsSum = invoiceAndTxBasedAllocations.filter(alloc => alloc.exchange?.id === xc.id)
              .reduce((prevAmountsSumAcc, alloc) => (prevAmountsSumAcc + alloc.amount!), 0);

            const key = nanoid();
            const allocation: ServiceCompanyAllocationValues = {
              key,
              exchangeIds: [xc.id!],
              planIds: [xc.planId!],
              exchangeAmount: xc.amount!,
              exchangeRemainingAmount: xc.remainingAmount!,
              previousAllocationAmount: prevAmountsSum,
              allocationAmount: prevAmountsSum,
              accounts: xc.counterpartInstruction?.[0]?.categorisationInstructions?.map(cti => ({
                code: cti.accountCode!,
                name: cti.accountName!,
              })),
              counterpartName: xc.companyName?.trim() || xc.ownerName?.trim() || '',
              counterpartContactId: xc.unitContractId ?? xc.serviceCompanyId,
              laborCost: xc.counterpartInstruction?.[0]?.categorisationInstructions?.[0].laborCost,
              laborCostType: xc.counterpartInstruction?.[0]?.categorisationInstructions?.[0].laborCostType as unknown as ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum,
              vatPercentage: getVatPercentageForUnallocatedExchanges(xc),
              vatEligibilityPercentage: getVatEligibilityForUnallocatedExchanges(xc, 'vatEligibilityPercentage'),
              vatEligibilityAmount: getVatEligibilityForUnallocatedExchanges(xc, 'vatEligibilityAmount'),
              date: xc.debtDate,
              type: xc.type,
              purpose: xc.name,
              propertyIban: xc.propertyIban,
              counterpartIban: xc.counterpartIban,
              propertyHrId: xc.propertyHrId,
              propertyIdInternal: xc.propertyIdInternal,
              propertyName: xc.propertyName,
            };

            exchangesAcc.push(allocation);
            return exchangesAcc;
          }, [] as ServiceCompanyAllocationValues[]);


        const parsedExchangesIds = Object.values(allocationsGroupedByExchangeData).map(alloc => alloc.map(a => a.exchange?.id)).flat(2);
        const allXcs = [...allocatedExchanges, ...unallocatedExchanges.filter(xc => !parsedExchangesIds.some(xcId => xc.exchangeIds?.includes(xcId!)))];

        setAllocationAmounts(
          allXcs.map((xc): AllocationAmount => ({
            key: xc.key,
            currentAmount: xc.allocationAmount ? Math.abs(xc.allocationAmount) : undefined,
            previousAmount: xc.previousAllocationAmount,
            exchangeAmount: xc.exchangeAmount,
            exchangeIds: xc.exchangeIds,
            exchangePurpose: xc.purpose,
            exchangeType: xc.type,
          })),
        );
        setSelectInputValues(
          allXcs.map(xc => ({
            key: xc.key,
            accountCode: xc.accounts?.map(acc => acc.code),
            counterpartContactId: xc.counterpartContactId,
            previousAccountCode: xc.accounts?.map(acc => acc.code),
            previousCounterpartContactId: xc.counterpartContactId,
            vatPercentage: xc.vatPercentage as number,
            previousVatPercentage: xc.vatPercentage as number,
            propertyHrId: xc.propertyHrId,
            propertyIdInternal: xc.propertyIdInternal,
            propertyName: xc.propertyName,
          })),
        );
        setAllocationLaborCostValues(
          allXcs.map(xc => ({
            key: xc.key,
            laborCostAmount: xc.laborCost,
            previousLaborCostAmount: xc.laborCost,
            laborCostType: xc.laborCostType,
            previousLaborCostType: xc.laborCostType,
          })),
        );

        setAllocationVatEligibilityValues(
          allXcs.map(xc => ({
            key: xc.key,
            vatEligibilityPercentage: xc.vatEligibilityPercentage as number,
            previousVatEligibilityPercentage: xc.vatEligibilityPercentage as number,
          })),
        );

        setPreviousAllocationValues(allXcs);

        setExchangeList(prev => prev.load(allXcs.sort((a, b) => compareExchangesFn(a, b, bankTransaction.amount!,
          bankTransaction.propertyIban!, bankTransaction.counterpartIban!))));

        // fetch proposals only after initialization, so they don't overwrite each other
        fetchProposals(bankTransactions);
      });
  };

  return {
    initialize,
  };
};
