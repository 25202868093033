import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useInvoiceIdList } from 'pages/AccountSheet/services/useInvoiceIdList';
import { InvoiceLegacyDto, PostingControllerApi, PostingItemDetailsDto } from 'api/accounting';
import { Order, useSort } from 'services/useSort';
import _ from 'lodash';
import { LanguageContext } from './LanguageContext';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { InvoiceListContext } from './InvoiceListContext';
import { NotificationObject, showNotification } from '../lib/Notification';
import { AuthContext } from './AuthContext';
import { AccountListContext } from './AccountListContext';
import { AccountsBalanceContext } from './AccountsBalanceContext';
import { formatToEuro } from '../lib/Utils';

const INITIAL_SORT_STATE = { initialField: 'postDate', initialOrder: Order.DESC };

export const PostingListContext: any = React.createContext({});

export interface BalanceData {
  startBalance: number
  endBalance: number | null
}

export default function PostingListProvider({ children }: any) {
  const { tl } = useContext(LanguageContext);
  const { laborCostTypes } = translations.pages.accountSheet.table;

  // TODO: Need to move to hook, as context is modified once we access an invoice and we lose the data
  const {
    translateInvoiceStates,
  } = useContext(InvoiceListContext);
  const { apiConfiguration } = useContext(AuthContext);

  const { selectedAccountNormalityMultiplier } = useContext(AccountListContext);
  const { getInitialAccountBalances, filterState } = useContext(AccountsBalanceContext);

  const { invoiceList, onLoadInvoicesByIds } = useInvoiceIdList();

  const [postingListState, setPostingListState] = useState(DEFAULT_DATA<PostingItemDetailsDto[]>([]));
  const [balanceData, setBalanceData] = useState<BalanceData>({ startBalance: 0, endBalance: null });

  const postingControllerApi = new PostingControllerApi(apiConfiguration('accounting'));


  const transformForFE = (content: Array<PostingItemDetailsDto>) => {
    if (!content || !Array.isArray(content)) return [];

    // Assuming all posting items belong to the same root account.
    // If this will not be true in the future we will have to move normalityMultiplier calculation inside the map function
    // Hopefully until that happens we will do all the normalization in the BE (already done by the public api GET posting-items)
    const isAccountDebitNormal = Math.sign(content[0]?.amount || 0) === Math.sign(content[0]?.balancedAmount || 0);
    const normalityMultiplier = isAccountDebitNormal ? 1 : -1;

    return content.map((posting: PostingItemDetailsDto) => {
      const invoice: InvoiceLegacyDto = invoiceList[posting.exchangePlanId || 0];


      // TODO: Type definitions
      let postingListItem: any = {
        exchangeId: posting.exchangeId,
        allocationId: posting.allocationId,
        transactionId: posting.transactionId,
        accountCode: posting.accountCode,
        postDate: posting.postingDate,
        invoiceAmount: invoice ? invoice.totalGross : posting.amount,
        bookingText: posting.bookingText,
        postingId: posting.postingId,
        runningBalance: posting.runningBalance,
        laborCost: posting.laborCost,
        laborCostType: posting.laborCostType,
        balancedAmount: posting.balancedAmount,
        vatAmount: posting.vatAmount * normalityMultiplier,
        vatPercentage: posting.vatPercentage,
        vatEligibilityAmount: posting.vatEligibilityAmount * normalityMultiplier,
        vatEligibilityPercentage: posting.vatEligibilityPercentage,
        accountingEventType: posting.accountingEventType,
        credit: '',
        debit: '',
        propertyId: posting.propertyId,
        invoiceDate: invoice ? invoice.invoiceDate : '',
        invoiceId: invoice ? invoice.id : undefined,
        postingDate: posting.postingDate,
        state: invoice ? translateInvoiceStates(invoice.state) : '',
        invoiceHrId: invoice && invoice.invoiceHrId ? invoice.invoiceHrId : '',
        invoiceInternalRefNr: invoice && invoice.refNr ? invoice.refNr : '',
        creditor: invoice ? invoice.counterpartName : '',
        rowKey: posting.postingItemId,
        counterAccounts: posting.counterAccounts,
      };
      if (posting!.amount && posting.amount > 0) {
        postingListItem.debit = Math.abs(posting.amount);
      } else {
        postingListItem.credit = Math.abs(posting.amount || 0);
      }

      return postingListItem;
    });
  };

  const onLoadPostings = (propertyHrId: string, accountCode: string, resetPage: boolean = false) => {
    if (!propertyHrId || !accountCode) return;
    if (!accountCode || (filterState.dateRange![0] === undefined && filterState.dateRange![1] === undefined)) return;
    setPostingListState(listState => listState.startLoading());
    let fromDate = filterState.dateRange![0] ? filterState.dateRange[0].format('YYYY-MM-DD') : null;
    let toDate = filterState.dateRange![1] ? filterState.dateRange[1].format('YYYY-MM-DD') : null;
    if (filterState.accountDateRange) {
      fromDate = filterState.accountDateRange[0] ? filterState.accountDateRange[0].format('YYYY-MM-DD') : null;
      toDate = filterState.accountDateRange[1] ? filterState.accountDateRange[1].format('YYYY-MM-DD') : null;
    }

    const filter = {
      includeCorrectional: filterState.includeCorrectional,
      fromDate,
      toDate,
    };

    function loadInvoices(postingItems: Array<any>) {
      onLoadInvoicesByIds(postingItems.filter(posting => posting.exchangePlanType === 'INVOICE').map(p => p.exchangePlanId));
    }

    const data = {
      accountCode,
      propertyHrId,
      ...filter,
      sort: [
        { field, order: Order[order] },
      ],
    };
    backend.getWithMultipleSort(`${endpointUrls.POSTING}/accounts`, data)
      .then((response: any) => {
        loadInvoices(response.postingItemDetails);
        setBalanceData(() => ({ startBalance: response.startBalance, endBalance: response.endBalance }));
        setPostingListState(listState => listState.load(response.postingItemDetails));
      })
      .catch((e) => {
        console.error('Failed to load postings', e);
        setPostingListState(listState => listState.failed());
        showNotification({
          key: 'loadPostingsError',
          message: tl(translations.notifications.postingListContext.loadError.message),
          type: 'error',
        });
      });
  };

  const onDeletePosting = async (id: number) => {
    try {
      await postingControllerApi.deletePostingUsingDELETE({ postingId: id });
      const deleteSuccessMessage: NotificationObject = {
        key: 'deletePostingSuccess',
        message: tl(translations.notifications.postingListContext.deleteSuccess.message),
        type: 'success',
      };
      showNotification(deleteSuccessMessage);
      getInitialAccountBalances();
      removeReversedOrDeletedPostingFromList(id);
    } catch (ex) {
      console.error('Failed to delete postings', ex);
      const deleteErrorMessage: NotificationObject = {
        key: 'deletePostingError',
        message: tl(translations.notifications.postingListContext.deleteError.message),
        type: 'error',
      };
      showNotification(deleteErrorMessage);
    }
  };

  const onRevertPosting = async (id: number) => {
    try {
      await postingControllerApi.revertPostingUsingPOST({ postingId: id });
      const revertSuccessMessage: NotificationObject = {
        key: 'revertPostingSuccess',
        message: tl(translations.notifications.postingListContext.revertSuccess.message),
        type: 'success',
      };
      showNotification(revertSuccessMessage);
      getInitialAccountBalances();
      removeReversedOrDeletedPostingFromList(id);
    } catch (ex) {
      console.error('Failed to revert postings', ex);
      const revertErrorMessage: NotificationObject = {
        key: 'revertPostingError',
        message: tl(translations.notifications.postingListContext.revertError.message),
        type: 'error',
      };
      showNotification(revertErrorMessage);
    }
  };

  const removeReversedOrDeletedPostingFromList = (id: number) => {
    setPostingListState((oldList) => {
      const newList: PostingItemDetailsDto[] = _.cloneDeep(oldList.data) as PostingItemDetailsDto[];
      const deletedIdx = _.findIndex(newList, (p: PostingItemDetailsDto) => p.postingId === id);
      // remove deleted item from list
      if (deletedIdx !== -1) {
        const removedAmount = newList[deletedIdx].amount;
        newList.splice(deletedIdx, 1);
        if (newList.length === 0) {
          setBalanceData(oldBalance => ({ ...oldBalance, endBalance: 0 }));
          // update the running balance of the rest
        } else if (Order[order] === 'ASC') {
          for (let i = deletedIdx; i < newList.length; i += 1) {
            newList[i]!.runningBalance! -= (removedAmount! * selectedAccountNormalityMultiplier);
          }
          setBalanceData(oldBalance => ({ ...oldBalance, endBalance: newList[newList.length - 1].runningBalance || null }));
        } else {
          for (let i = 0; i < deletedIdx; i += 1) {
            newList[i]!.runningBalance! -= (removedAmount! * selectedAccountNormalityMultiplier);
          }
          // update end balance
          setBalanceData(oldBalance => ({ ...oldBalance, endBalance: newList[0].runningBalance || null }));
        }
      }
      return oldList.load(newList);
    });
  };

  const removePostingsRelatedToExchange = (xcId: number) => {
    postingListState!.data!.filter((p: PostingItemDetailsDto) => p.exchangeId === xcId)
      .forEach((p: PostingItemDetailsDto) => removeReversedOrDeletedPostingFromList(p.postingId!));
    getInitialAccountBalances();
  };

  const { field, order, setSortField } = useSort(INITIAL_SORT_STATE);

  const frontendData = useMemo(() => transformForFE(postingListState.data as PostingItemDetailsDto[]), [invoiceList, postingListState.data, order]);


  return (
    <PostingListContext.Provider value={{
      ...postingListState,
      data: frontendData,
      onLoadPostings,
      balanceData,
      setSortField,
      sortField: field,
      sortOrder: order,
      onDeletePosting,
      onRevertPosting,
      removePostingsRelatedToExchange,
    }}
    >
      {children}
    </PostingListContext.Provider>
  );
}

PostingListProvider.propType = {
  children: PropTypes.node.isRequired,
};
