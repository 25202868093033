import { RouteDefinition } from 'components/RenderRoutes';
import { DISTRIBUTION_KEY_DETAILS_COMPONENT } from 'pages/DistributionKeys/routes';
import AccountSheet from './AccountSheet';
import InvoiceEditingRouter from '../Invoice/InvoiceEditing/InvoiceEditingRouter';

export const getAccountSheetPath = (propertyHrId: string, accountCode: string): string => `account-balances/${propertyHrId}/account/${encodeURIComponent(accountCode)}`;

export const AccountSheetRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '*/:propertyHrId/account/:accountCode',
      component: AccountSheet,
      type: 'overlay',
      subRoutes: [
        {
          path: '*/:propertyHrId/account/:accountCode/invoice/edit/:invoiceHrId',
          component: InvoiceEditingRouter,
          type: 'focus',
        },
        {
          path: '*/:propertyHrId/account/:accountCode/:economicYear/distribution-keys-details',
          component: DISTRIBUTION_KEY_DETAILS_COMPONENT,
          type: 'overlay',
        },
      ],
    },
  ],
};
