import {
  ContactLegacyControllerApi,
  ContactLegacyDto,
  ContactLegacyDtoTypeEnum,
  InvoiceLegacyControllerApi,
  InvoiceLegacyProjection, PropertyDisplayDto,
  PropertyLegacyControllerApi,
} from 'api/accounting';
import { ContactSimpleDto, ContractApi, ContractDto, DocumentApi, DocumentDto, DocumentDtoSourceTypeEnum } from 'api/public';
import { LanguageContext } from 'contexts/LanguageContext';
import FileSaver from 'file-saver';
import { showNotification } from 'lib/Notification';
import { DATE_FORMAT, formatDate } from 'lib/Utils';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { useDeleteDocumentModal } from 'pages/Document/useDeleteDocumentModal';
import { getEconomicPlanViewPath } from 'pages/EconomicPlan/routes';
import { getHouseMoneySettlementOverviewRoute } from 'pages/HouseMoneySettlement/routes';
import { getInvoiceEditingRoute } from 'pages/Invoice/routes';
import { getOpsCostReportViewPath } from 'pages/OpsCostReport/routes';
import { getProfitAndLossReportViewRoute } from 'pages/ProfitAndLossReport/routes';
import { getSpecialContributionEditingRoute } from 'pages/SpecialContribution/routes';
import { getWkaEditingRoute } from 'pages/Wka/routes';
import { useContext, useEffect, useRef } from 'react';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { DocumentLegacyControllerApi, DocumentProjectionDtoSourceTypeEnum, FindDocumentsFilteredUsingGETOrderEnum, FindDocumentsFilteredUsingGETSourceTypesEnum, PageOfDocumentProjectionDto } from 'api/document';
import { useLocation } from 'react-router';
import { AuthContext } from '../../../../contexts/AuthContext';
import { translations } from '../translations';
import { DocumentListContext } from './DocumentListContext';
import { isAuditingUser } from 'lib/userPermissionUtils';
import { GlobalSearchContext } from 'components/Header/components/SearchBar/services/GlobalSearchContext';
import { useAuditingGlobalFilterIsDocumentListLimited } from 'services/AuditingGlobalFilterStore/selectorsAuditingGlobalFilter';

export default function useDocumentList() {
  const { tl } = useContext(LanguageContext);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const documentListContext = useContext(DocumentListContext);
  const { documentList, setDocumentList, filterState, sortState } = documentListContext;
  const { overlays } = useContext(OverlayContext);
  const { publicApiConfiguration, documentApiConfiguration } = useContext(AuthContext);
  const { setHideGlobalSearch } = useContext(GlobalSearchContext);
  const documentApi = new DocumentApi(publicApiConfiguration('public'));
  const documentControllerApi = new DocumentLegacyControllerApi(documentApiConfiguration('document'));
  const contractApi = new ContractApi(publicApiConfiguration('public'));
  const { apiConfiguration } = useContext(AuthContext);
  const propertyApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const invoiceApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));
  const contactApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);

  const { showModal } = useDeleteDocumentModal();

  const isDocumentListLimited = useAuditingGlobalFilterIsDocumentListLimited();

  useEffect(() => {
    if (overlays.length === 1) {
      onLoadDocumentList(true);
    }
  }, [sortState, filterState, overlays]);

  useEffect(() => {
    if (!isAuditingUser()) {
      return;
    }

    if (isDocumentListLimited) {
      setHideGlobalSearch(true);
    }

    return () => {
      setHideGlobalSearch(false);
    };
  }, [isDocumentListLimited]);

  const onDownloadSingleDocument = (id: number) => {
    documentApi
      .downloadUsingGETRaw({ documentId: id })
      .then((documentByteArr: any) => {
        documentByteArr?.raw?.blob().then((blob: Blob) => {
          let fileName;
          const contentDispositionHeader = documentByteArr.raw.headers.get('Content-Disposition');
          if (!isNil(contentDispositionHeader)) {
            const name = contentDispositionHeader.split('filename="');
            if (name.length > 1) {
              fileName = decodeURIComponent(name[1].slice(0, name[1].length - 1));
            }
          }
          if (!isNil(fileName)) {
            FileSaver.saveAs(blob, fileName);
          } else if (blob.type === 'application/pdf') {
            FileSaver.saveAs(blob, 'file.pdf');
          } else if (blob.type === 'text/xml') {
            FileSaver.saveAs(blob, 'file.xml');
          }
        });
      })
      .catch((error) => {
        console.log({ error });
        showNotification({
          key: 'downloadDocumentsError',
          message: tl(translations.notification.downloadDocuments.failure.description),
          type: 'error',
        });
      });
  };

  const onDownloadZip = (documentIds: number[]) => {
    documentApi
      .downloadDocumentsByFilterUsingGETRaw({ documentIds })
      .then((documentByteArr: any) => {
        documentByteArr?.raw?.blob().then((blob: Blob) => FileSaver.saveAs(blob, `${formatDate(moment(), DATE_FORMAT)}_Dokumente.zip`));
      })
      .catch((error) => {
        console.log({ error });
        showNotification({
          key: 'downloadDocumentsError',
          message: tl(translations.notification.downloadDocuments.failure.description),
          type: 'error',
        });
      });
  };

  const onDeleteDocument = (documentId: number) => {
    const onProceed = () => {
      setDocumentList((prev) => prev.startLoading());
      documentApi
        .deleteDocumentUsingDELETE({ documentId })
        .then(() => {
          onLoadDocumentList(true);
          showNotification({
            key: 'successDocumentDelete',
            message: tl(translations.notification.deleteDocument.success.message),
            type: 'success',
          });
        })
        .catch((err) => {
          console.error(err);
          setDocumentList((prev) => prev.failed());
          showNotification({
            key: 'deleteDocumentError',
            message: tl(translations.notification.deleteDocument.failure.message),
            type: 'error',
          });
        });
    };

    showModal(onProceed);
  };

  const getSortField = (sourceType: string, sortField: keyof DocumentDto) => {
    if (!isAuditingUser() || !sourceType) {
      return sortField;
    }
    if (sourceType === DocumentDtoSourceTypeEnum.INVOICE.toString()) {
      return 'issuedDate';
    }

    return 'unitId';
  };

  const onLoadDocumentList = (resetPage: boolean = false) => {
    abortController.current?.abort();
    abortController.current = new AbortController();
    const { signal } = abortController.current;
    const propertyId = urlSearchParams.get('propertyId');
    const sourceType = urlSearchParams.get('sourceType');
    const sourceIds = (urlSearchParams.get('sourceIds') || '')
      .split(',')
      .map((id) => parseInt(id, 10))
      .filter((id) => !isNaN(id));

    setDocumentList((prev) => prev.startLoading());
    documentControllerApi
      .findDocumentsFilteredUsingGET(
        {
          page: resetPage ? 0 : documentList.page,
          size: 30,
          sort: getSortField(sourceType, sortState.field),
          ...filterState,
          issuedDate: filterState.issuedDate ? moment(filterState.issuedDate).format('YYYY-MM-DD') : undefined,
          minIssuedDate: filterState.minIssuedDate ? moment(filterState.minIssuedDate).format('YYYY-MM-DD') : undefined,
          maxIssuedDate: filterState.maxIssuedDate ? moment(filterState.maxIssuedDate).format('YYYY-MM-DD') : undefined,
          order: sortState.order > 0 ? FindDocumentsFilteredUsingGETOrderEnum.ASC : FindDocumentsFilteredUsingGETOrderEnum.DESC,
          sourceTypes: sourceType ? (([sourceType] as undefined) as FindDocumentsFilteredUsingGETSourceTypesEnum) : (([FindDocumentsFilteredUsingGETSourceTypesEnum.OTHER, FindDocumentsFilteredUsingGETSourceTypesEnum.BANK_ORDER, FindDocumentsFilteredUsingGETSourceTypesEnum.ECONOMIC_PLAN, FindDocumentsFilteredUsingGETSourceTypesEnum.HEATING_COST_DISTRIBUTION, FindDocumentsFilteredUsingGETSourceTypesEnum.HOUSE_MONEY_SETTLEMENT, FindDocumentsFilteredUsingGETSourceTypesEnum.INVOICE, FindDocumentsFilteredUsingGETSourceTypesEnum.OPS_COST_REPORT, FindDocumentsFilteredUsingGETSourceTypesEnum.PROFIT_AND_LOSS, FindDocumentsFilteredUsingGETSourceTypesEnum.SPECIAL_CONTRIBUTION] as unknown) as FindDocumentsFilteredUsingGETSourceTypesEnum),
          propertyId: parseInt(propertyId, 10) || undefined,
          sourceIds: !isEmpty(sourceIds) ? sourceIds : undefined,
        },
        { signal },
      )
      .then((documentsResponse: PageOfDocumentProjectionDto) => {
        // We are doing this filtering for uniqueness in order to avoid
        // multiple appearences of the same document in the list.
        // Such a case might happen on loading a document twice thanks to parallel editing of the document
        // and the default sorting, which takes the last updated documents first.
        let uniqueNewDocuments;
        if (resetPage) {
          uniqueNewDocuments = documentsResponse.content;
        } else {
          const currentDocIds = documentList.data?.map((d) => d.id) || [];
          uniqueNewDocuments = documentsResponse.content.filter((doc) => !currentDocIds.includes(doc.id));
        }
        const propertyIds: number[] = uniqueNewDocuments
          .filter((doc) => !isNil(doc.propertyId))
          .map((doc) => doc.propertyId)
          .filter((value, index, array) => array.indexOf(value) === index);

        const invoiceIds: number[] = uniqueNewDocuments
          .filter((doc) => !isNil(doc.sourceId) && !isNil(doc.sourceType) && doc.sourceType === DocumentDtoSourceTypeEnum.INVOICE)
          .map((doc) => doc.sourceId)
          .filter((value, index, array) => array.indexOf(value) === index);

        const contactIds: number[] = uniqueNewDocuments
          .filter((doc) => !isNil(doc.contactId))
          .map((doc) => doc.contactId)
          .filter((value, index, array) => array.indexOf(value) === index);

        const contractIds: number[] = uniqueNewDocuments
          .filter((doc) => !isNil(doc.contractId))
          .map((doc) => doc.contractId)
          .filter((value, index, array) => array.indexOf(value) === index);

        const promises = [];
        promises.push(
          propertyApi.findFilteredPropertiesUsingGET(
            {
              size: 30,
              propertyIds,
            },
            { signal },
          ),
        );
        promises.push(
          invoiceApi.findFilteredInvoicesUsingGET(
            {
              size: 30,
              invoiceIds,
            },
            { signal },
          ),
        );
        promises.push(contractApi.getContractsByFilterUsingGET({ size: 30, contractIds }, { signal }));

        Promise.all(promises).then(([properties, invoices, contracts]) => {
          const contractContactIds = contracts.content
            .flatMap((contract) => contract.contacts)
            .filter((contact) => !isNil(contact))
            .map((contact) => contact.id);
          contactApi.searchContactsUsingGET({ size: 100, contactIds: contactIds.concat(contractContactIds) }, { signal }).then((contacts) => {
            const documentEntries = documentsResponse.content.map((doc) => {
              const propertyHrId = getPropertyImpowerHrId(properties.content, doc.propertyId);
              const invoiceHrId = !isNil(doc.sourceType) && doc.sourceType === DocumentProjectionDtoSourceTypeEnum.INVOICE ? getInvoiceHrId(invoices.content, doc.sourceId) : undefined;
              const contract: ContractDto = contracts.content.find((c) => c.id === doc?.contractId);
              const contractContact: ContactLegacyDto = getContractContact(contract?.name, contract?.contacts, contacts.content);
              const documentContact: ContactLegacyDto = contacts.content.find((con) => con.id === doc?.contactId);
              return {
                ...doc,
                propertyImpowerHrId: propertyHrId,
                sourceProcessUrl: getSourceProcessUrl(doc.id, doc.sourceType, doc.sourceId, invoiceHrId, propertyHrId),
                contactType: documentContact?.type,
                contactName: getContactName(documentContact),
                unitIdOfContract: contract?.unitId,
                contractContactId: contractContact?.id,
                contractName: contract?.name,
                contractContactType: contractContact?.type,
              };
            });
            setDocumentList((prev) => prev.loadPaged(documentEntries, resetPage, documentsResponse.last));
          });
        });
      })
      .catch((error) => {
        if (signal.aborted) return;
        setDocumentList((prev) => prev.failed());
        console.log({ error });
        showNotification({
          key: 'loadDocumentListError',
          message: tl(translations.notification.loadDocumentsList.failure.description),
          type: 'error',
        });
      });
  };

  const getPropertyImpowerHrId = (properties: PropertyDisplayDto[], propertyId: number) => {
    if (isNil(propertyId)) return undefined;
    const property: PropertyDisplayDto = properties.find((prp) => prp.id === propertyId);
    return property?.propertyHrId;
  };

  const getInvoiceHrId = (invoices: InvoiceLegacyProjection[], invoiceId: number) => {
    if (isNil(invoiceId)) return undefined;
    const invoice: InvoiceLegacyProjection = invoices.find((inv) => inv.id === invoiceId);
    return invoice?.invoiceHrId;
  };

  const getContractContact = (contractName: string, contractContacts: ContactSimpleDto[], allContacts: ContactLegacyDto[]) => {
    const contractContact: ContactSimpleDto = contractContacts?.find((c) => contractName?.includes(c.name));
    return allContacts?.find((c) => c.id === contractContact?.id);
  };

  const getContactName = (contact: ContactLegacyDto) => {
    if (isNil(contact)) return undefined;
    if (contact?.type === ContactLegacyDtoTypeEnum.PERSON) {
      return contact.firstName.concat(' ', contact.lastName);
    }
    if (contact?.type === ContactLegacyDtoTypeEnum.COMPANY) {
      return contact.companyName;
    }
    return undefined;
  };

  const getSourceProcessUrl = (docId: number, sourceType?: DocumentProjectionDtoSourceTypeEnum, sourceId?: number, invoiceHrId?: string, propertyHrId?: string) => {
    switch (sourceType) {
      case DocumentProjectionDtoSourceTypeEnum.INVOICE:
        return invoiceHrId ? getInvoiceEditingRoute({ invoiceHrId }) : '';
      case DocumentProjectionDtoSourceTypeEnum.ECONOMIC_PLAN:
        return propertyHrId && sourceId ? getEconomicPlanViewPath({ economicPlanId: sourceId }) : '';
      case DocumentProjectionDtoSourceTypeEnum.HOUSE_MONEY_SETTLEMENT:
        return propertyHrId ? getHouseMoneySettlementOverviewRoute({ propertyHrId }) : '';
      case DocumentProjectionDtoSourceTypeEnum.PROFIT_AND_LOSS:
        return getProfitAndLossReportViewRoute({ documentDbId: docId });
      case DocumentProjectionDtoSourceTypeEnum.OPS_COST_REPORT:
        return sourceId ? getOpsCostReportViewPath(sourceId) : '';
      case DocumentProjectionDtoSourceTypeEnum.HEATING_COST_DISTRIBUTION:
        return sourceId ? getWkaEditingRoute(sourceId) : '';
      case DocumentProjectionDtoSourceTypeEnum.SPECIAL_CONTRIBUTION:
        return sourceId
          ? getSpecialContributionEditingRoute({
              specialContributionId: sourceId,
            })
          : '';
      default:
        console.error(`Unable to create a navigation url for document of type ${sourceType}`);
        return '';
    }
  };

  return {
    documentList,
    onLoadDocumentList,
    onDownloadSingleDocument,
    onDownloadZip,
    onDeleteDocument,
  };
}
