import { RouteDefinition } from 'components/RenderRoutes';
import AccountBalances from './AccountBalances/AccountBalances';
import { AccountsOverview } from './AccountsOverview/AccountsOverview';
import AccountBooking from './AccountBooking/AccountBooking';

export const ACCOUNT_BALANCES_PATH = '/account-balances';

export const ACCOUNT_BALANCES_PROPERTY_PATH = '/account-balances/:propertyHrId';
export const ACCOUNT_SHEET_FOR_PROPERTY = '/account-sheet/property/:propertyHrId'
export const ACCOUNT_BALANCES_PROPERTY_CREATE_BOOKING_PATH = '/account-balances/:propertyHrId/booking/create';

export const getPropertyAccountBalancePath = (propertyHrId: string, accountCode?: string, startDate?: string, endDate?: string) => {
  let url = `${ACCOUNT_BALANCES_PATH}/${propertyHrId}`;

  if (accountCode) {
    url += `/account/${encodeURIComponent(accountCode)}`;
  }

  if (startDate) {
    url += `?startDate=${startDate}`;
  }

  if (endDate) {
    if (url.includes('?')) {
      url += '&';
    } else {
      url += '?';
    }
    url += `endDate=${endDate}`;
  }
  return url;
};

type AccountSheetParams = {
  propertyHrId: string;
  startDate?: string;
  endDate?: string;
  [key: string]: string | undefined;
};

export const getAccountSheetRoute = (queryParams: AccountSheetParams) => {
  const { propertyHrId, ...params } = queryParams;

  const queryParamsValue = new URLSearchParams();

  Object.entries({ ...params }).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      queryParamsValue.append(key, String(value));
    }
  });

  return `${ACCOUNT_SHEET_FOR_PROPERTY.replace(':propertyHrId', propertyHrId)}${
    queryParamsValue.toString() ? `?${queryParamsValue.toString()}` : ''
  }`;
};


export const AccountBalancesRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: ACCOUNT_BALANCES_PATH,
      component: AccountBalances,
      subRoutes: [
        {
          path: [ACCOUNT_BALANCES_PROPERTY_PATH, ACCOUNT_SHEET_FOR_PROPERTY],
          component: AccountsOverview,
          type: 'overlay',
          subRoutes: [
            {
              path: ACCOUNT_BALANCES_PROPERTY_CREATE_BOOKING_PATH,
              component: AccountBooking,
              type: 'overlay',
            },
          ],
        },
      ],
    },
  ],
};
