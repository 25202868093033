import React from 'react';
import { translations } from './translations';
import './MainDetails.scss';

interface MainDetailsProps {
  details: { [key: string]: string | undefined },
  tl: (key: string) => string;
}

export const MainDetails: React.FC<MainDetailsProps> = ({ details, tl }) => (
  <section>
    <dl className="main-details">
      {Object.keys(details).map((key) => {
        const value = details[key];
        return (
          <React.Fragment key={key}>
            <dt>{`${tl(translations[key]) || key}:`}</dt>
            <dd>{value}</dd>
          </React.Fragment>
        );
      })}
    </dl>
  </section>
);
