import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TemplateDto } from 'api/document';
import _ from 'lodash';
import { useLocation } from 'react-router';

import backend, { endpointUrls } from '../../../../../../../backend_api';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import {
  OwnersMeetingInvitationContext,
} from '../../../../../../../contexts/OwnersMeetingInvitationContext';
import {
  OwnersMeetingInvitationFE,
} from '../../../../../../../data/ownersMeeting';
import DEFAULT_DATA, {
  DefaultDataInterface,
} from '../../../../../../../lib/data';
import { showNotification } from '../../../../../../../lib/Notification';
import {
  ownersMeetingInvitationTranslations,
} from '../../../../translations/OwnersMeetingInvitationTranslations';

const coverLetterTemplates = [
  'ownersMeetingCoverLetter',
  'conveningOwnersMeetingCoverLetter',
  'oneManOwnersMeetingCoverLetter',
  'retryOwnersMeetingCoverLetter',
  'shortNoticeOwnersMeetingCoverLetter',
];

interface UseCoverLetterTemplatesProps {
  setDirty: Function
} 

export default function useCoverLetterTemplates(props: UseCoverLetterTemplatesProps) {
  const { setDirty } = props;
  const { tl } = useContext(LanguageContext);
  const { ownersMeeting, updateOwnersMeeting, setOwnersMeeting } = useContext(OwnersMeetingInvitationContext);
  const location = useLocation();

  const [selectedCoverLetterTemplateName, setSelectedCoverLetterTemplateName] = useState<string>();
  const [selectedCoverLetterTemplate, setSelectedCoverLetterTemplate] = useState(DEFAULT_DATA<TemplateDto>(null));

  const coverLetterTemplateOptions = useMemo(() => coverLetterTemplates.map((template: string) => ({
    value: template,
    label: tl(ownersMeetingInvitationTranslations.editPage.sections.shippingSection.coverLetter.templates[template]),
  })), []);

  useEffect(() => {
    if (location.pathname.includes('create')) {
      setSelectedCoverLetterTemplateName(coverLetterTemplates[0]);
    }
  }, []);

  useEffect(() => {
    if (selectedCoverLetterTemplate.data) {
      const newOwnersMeeting = _.cloneDeep(ownersMeeting.data);
      newOwnersMeeting.coverLetterHtml = selectedCoverLetterTemplate.data.htmlText;
      newOwnersMeeting.coverLetterCss = selectedCoverLetterTemplate.data.cssText;
      updateOwnersMeeting(newOwnersMeeting);
    }
  }, [selectedCoverLetterTemplate, ownersMeeting.data.meetingType]);

  useEffect(() => {
    if (selectedCoverLetterTemplateName) {
      setDirty(true);
      onLoadCoverLetterTemplate(selectedCoverLetterTemplateName);
    }
  }, [selectedCoverLetterTemplateName]);

  const onChange = (selector: string, value: string) => {
    setOwnersMeeting((oldOwnersMeeting: DefaultDataInterface<OwnersMeetingInvitationFE>) => {
      const newOwnersMeeting = _.cloneDeep(oldOwnersMeeting.data);
      if (newOwnersMeeting?.coverLetterHtml) {
        // parse template
        const templateBodyTag = document.createElement('body');
        // @ts-ignore
        // eslint-disable-next-line prefer-destructuring
        templateBodyTag.innerHTML = /<body.*>([\s\S]*)<\/body>/.exec(newOwnersMeeting.coverLetterHtml)[0];

        // find element to change
        const elementToChange = templateBodyTag.querySelector(selector);
        if (elementToChange) {
          // set content of element
          elementToChange.innerHTML = value;

          newOwnersMeeting.coverLetterHtml = newOwnersMeeting.coverLetterHtml
            .replace(/(<body.*>)([\s\S]*)(<\/body>)/, `$1${templateBodyTag.innerHTML}$3`);
          if (selector !== '#body' && oldOwnersMeeting.data?.coverLetterHtml !== newOwnersMeeting.coverLetterHtml) {
            setDirty(true);
          }
        }
      }

      if (selector === '#subject') {
        newOwnersMeeting.name = value;
      }

      return oldOwnersMeeting.load(newOwnersMeeting!);
    });
  };

  const onChangeSubject = (value: string) => {
    onChange('#subject', value);
  };

  const onChangeBody = (value: string) => {
    onChange('#body', value);
  };

  const extractBody = (template: string): string => {
    const element = document.createElement('html');
    element.innerHTML = template;
    const bodyElement = element.querySelector('#body');
    if (bodyElement) {
      return bodyElement.innerHTML;
    }
    return '';
  };

  const onLoadCoverLetterTemplate = (templateName: string): void => {
    setSelectedCoverLetterTemplate((state) => state.startLoading());
    backend
      .get(`${endpointUrls.TEMPLATES}`, { templateName })
      .then((response: any) => {
        setSelectedCoverLetterTemplate((state) => state.load(response[0]));
      })
      .catch((error) => {
        console.error(error);
        setSelectedCoverLetterTemplate((state) => state.failed());
        showNotification({
          key: 'loadCoverLetterTemplateError',
          message: tl(ownersMeetingInvitationTranslations.notifications.coverLetterloadError.message),
          type: 'error',
        });
      });
  };

  const body = useMemo(() => extractBody(ownersMeeting.data.coverLetterHtml), [ownersMeeting.data.coverLetterHtml]);

  return {
    coverLetterTemplateOptions,
    selectedCoverLetterTemplateName,
    setSelectedCoverLetterTemplateName,
    subject: ownersMeeting.data.name,
    body,
    onChangeSubject,
    onChangeBody,
  };
}
