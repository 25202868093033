import { UnitContractProjectionDtoUnitTypeEnum } from 'api/accounting';

export const accountDistributionTranslations = {
  adminitrator: {
    en: 'Adminitrator',
    de: 'Objektbetreuer',
  },
  headerButtons: {
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    copy: {
      en: 'Copy',
      de: 'Kopieren',
    },
    save: {
      en: 'Save',
      de: 'Speichern',
    },
    disabledTooltip: {
      en: 'Account distribution is invalid.',
      de: 'Verteilerschlüssel ist ungültig.',
    },
  },
  definition: {
    distributionName: {
      MEA: {
        en: 'Miteigentumsanteil (MEA)',
        de: 'Miteigentumsanteil (MEA)',
      },
      UNITS: {
        en: 'Einheiten',
        de: 'Einheiten',
      },
      PERSONS: {
        en: 'Personen',
        de: 'Personen',
      },
      HEATING_AREA: {
        en: 'Heizfläche',
        de: 'Heizfläche',
      },
      AREA: {
        en: 'Fläche',
        de: 'Fläche',
      },
      INDIVIDUAL: {
        en: 'Individual',
        de: 'Individuell',
      },
      DISTRIBUTION_VALUE: {
        en: 'Distribution value',
        de: 'Verteilungswert',
      },
      HEATING_COST: {
        en: 'Heating cost',
        de: 'lt. Wärmekostenabrechnung',
      },
      DIRECT_COST: {
        en: 'Direct cost',
        de: 'Direkte Kosten',
      },
      UNDEFINED: {
        en: 'Undefined',
        de: 'Nicht definiert',
      },
    },
    title: {
      en: 'Definition',
      de: 'Definition',
    },
    warningMessage: {
      en: 'The DK set in the HGA cannot be used for Ops Cost DK because they are individual settings. Please set a custom DK or change the DK in the HGA.',
      de: 'Dieser Verteilerschlüssel der HGA kann für die BKA nicht genutzt werden, da dies individuelle Einstellungen sind. Bitte stellen Sie einen benutzerdefinierten Verteilerschlüssel ein oder ändern Sie diesen in der HGA ab',
    },
    accountDetails: {
      en: 'Account Details',
      de: 'Konto Details',
    },
    account: {
      en: 'Account',
      de: 'Konto',
    },
    economicYear: {
      en: 'Economic Year',
      de: 'Wirtschaftsjahr',
    },
    distribution: {
      en: 'Distribution',
      de: 'Verteilung',
    },
    applicable: {
      en: 'Transferability',
      de: 'Umlagefähigkeit',
    },
    accountSpecificationTooltip: {
      en: 'The settings for WKA and transferable costs come from HGA and EP. Changes can be made there. WKAs can be selected in the Ops-Cost.',
      de: 'Die Einstellungen für die WKA und die der umlegbaren Kosten kommen aus den Verteilerschlüsseln der HGA und dem der WP. Änderungen können dort vorgenommen werden. WKAs werden in der BKA ausgewählt.',
    },
    distributionSetTooltip: {
      en: 'The distribution keys are automatically pre-filled from the input for the HGA. Changes specific for the Ops-Cost Report can be done here.',
      de: 'Die Verteilerschlüssel für die BKA werden automatisch mit denen der HGA vorausgefüllt. Sie können sie hier jederzeit abändern.',
    },
    transferable: {
      infoText: {
        en: 'How the costs will be reported in the Housemoney Settlement. WKAs can be selected in the Ops-Cost.',
        de: 'Bestimmt die Darstellung der Kosten in der Hausgeldabrechnung. WKAs werden in der BKA ausgewählt.',
      },
      transferable: {
        label: {
          en: 'Transferable',
          de: 'Umlagefähig',
        },
        subLabel: {
          en: 'Costs may be transferred to tenants.',
          de: 'Kosten können auf Mieter umgelegt werden.',
        },
      },
      notTransferable: {
        label: {
          en: 'Not transferable',
          de: 'Nicht umlagefähig',
        },
        subLabel: {
          en: 'Costs are covered by the owners.',
          de: 'Kosten werden vom Eigentümer getragen.',
        },
      },
      wka: {
        label: {
          en: 'According to Heatingcost Settlement',
          de: 'lt. Wärmekostenabrechnung',
        },
        subLabel: {
          en: 'Costs are divided according to the Heatingcost Settlement.',
          de: 'Kosten werden gemäß Wärmekostenabrechnung aufgeteilt.',
        },
      },
    },
    distributionSet: {
      en: 'Distribution set',
      de: 'Verteilerschlüssel',
    },
    heatingCenter: {
      en: 'Heating center',
      de: 'Heizzentrale',
    },
    unitSet: {
      en: 'Unit set',
      de: 'Einheiten-Set',
    },
    allUnits: {
      en: 'All units',
      de: 'Alle Einheiten',
    },
    createNew: {
      en: 'Create new ',
      de: 'Neu erstellen',
    },
    createNewWka: {
      en: 'Create new WKA',
      de: 'Neue WKA erstellen',
    },
    createNewUnitSet: {
      en: 'Create new unit set ',
      de: 'Neues Einheiten-Set erstellen',
    },
    directCosts: {
      en: 'Direct costs ',
      de: 'Direkte Kosten',
    },
    wkaNotSupported: {
      en: "Creating WKA's for SEV's with WEG connection is currently not supported.",
      de: 'Das Erstellen von WKA für SEV mit WEG-Verbindung wird derzeit nicht unterstützt.',
    },
    distributionKeyForOpsCost: {
      en: 'Distribution Key for Ops Cost',
      de: 'Verteilerschlüssel für BKA',
    },
    fromHGA: {
      en: 'From HGA',
      de: 'Von HGA',
    },
    custom: {
      en: 'Custom',
      de: 'Benutzerdefiniert',
    },
  },
  values: {
    errorDistributionValueOutsideDateRange: {
      modalTitle: {
        en: 'Are you sure you want to delete this distribution value?',
        de: 'Sind Sie sicher, dass Sie diesen Verteilungswert löschen möchten?',
      },
      modalYes: {
        en: 'Yes',
        de: 'Ja',
      },
      modalCancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      alertTitle: {
        en: 'The following contracts have distribution values outside of the date range of the selected economic year. Please delete them.',
        de: 'Die folgenden Verträge haben Verteilungswerte außerhalb des ausgewählten Wirtschaftsjahres. Bitte löschen Sie diese.',
      },
      notificationSuccess: {
        en: 'Successfully deleted the distribution value.',
        de: 'Verteilungswert erfolgreich gelöscht.',
      },
      notificationError: {
        en: 'Failed to delete the distribution value.',
        de: 'Verteilungswert konnte nicht gelöscht werden.',
      },
    },
    warningMessageCustomPersonDS: {
      en: 'Values set here are only valid for the Ops-Cost Report.',
      de: 'Hier eingestellte Personenwerte gelten nur für die Betriebskostenabrechnung.',
    },
    searchCheckboxLabel: {
      en: 'Only active contracts',
      de: 'Nur aktive Verträge',
    },
    title: {
      en: 'Values',
      de: 'Werte',
    },
    headers: {
      contract: {
        en: 'Contract',
        de: 'Vertrag',
      },
      startDate: {
        en: 'Start date',
        de: 'Startdatum',
      },
      endDate: {
        en: 'End date',
        de: 'Enddatum',
      },
      value: {
        en: 'Value',
        de: 'Wert',
      },
    },
    unitTypes: {
      [UnitContractProjectionDtoUnitTypeEnum.APARTMENT]: {
        en: 'Apartment',
        de: 'Wohnung',
      },
      [UnitContractProjectionDtoUnitTypeEnum.COMMERCIAL]: {
        en: 'Commercial',
        de: 'Gewerbeeinheit',
      },
      [UnitContractProjectionDtoUnitTypeEnum.PARKING]: {
        en: 'Parking',
        de: 'Parkmöglichkeit',
      },
      [UnitContractProjectionDtoUnitTypeEnum.OTHER]: {
        en: 'Other',
        de: 'Sonstiges',
      },
    },
    emptyTable: {
      en: 'Make sure you have a Distribution Key selected and active contracts for the selected Economic Year',
      de: 'Stellen Sie sicher, dass Sie einen Verteilerschlüssel ausgewählt haben und es aktive Verträge im ausgewählten Wirtschaftsjahr gibt.',
    },
    totalAllTenantContracts: {
      en: 'Total of all tenant contracts',
      de: 'Gesamt aller Mietverträge',
    },
    totalDistributed: {
      en: 'Total distributed',
      de: 'Gesamt verteilt',
    },
    totalAllContracts: {
      en: 'Total of all contracts',
      de: 'Gesamt aller Verträge',
    },
    totalAllUnits: {
      en: 'Total of all units',
      de: 'Gesamt aller Einheiten',
    },
    totalLatestContractsPerUnit: {
      en: 'Total of latest value per unit',
      de: 'Gesamt der aktuellsten Werte pro Einheit',
    },
    totalDirectCost: {
      en: 'Total distributed',
      de: 'Gesamt verteilt',
    },
    accountBalance: {
      en: 'Account balance in selected economic year',
      de: 'Kontensaldo im ausgewählten Wirtschaftsjahr',
    },
  },
  copyModal: {
    title: {
      en: 'Copy Distribution Key',
      de: 'Verteilerschlüssel kopieren',
    },
    destinationAccounts: {
      en: 'Destination accounts',
      de: 'Zu den Konten',
    },
    destinationYears: {
      en: 'Destination years',
      de: 'Zu den Jahren',
    },
    selectPlaceholder: {
      en: 'Select...',
      de: 'Wählen...',
    },
    source: {
      en: 'Source',
      de: 'Von',
    },
    target: {
      en: 'Target',
      de: 'Anwenden auf',
    },
    warningMessage: {
      en: 'In case you already created Distribution Keys for the year you are copying in, they will be overwritten. Are you sure you want to continue?',
      de: 'Falls für das Jahr bereits Verteilerschlüssel hinterlegt wurden, in welches Sie die Verteilerschlüssel kopieren, werden diese überschrieben. Möchten Sie dennoch fortfahren?',
    },
    copyButton: {
      en: 'Create a copy',
      de: 'Kopie erstellen',
    },
  },
  saveModal: {
    title: {
      en: 'Changes on Direct Costs',
      de: 'Änderungen an den Direkten Kosten',
    },
    message: {
      en: ['You are about to change the direct costs for a timerange a report has already been generated for.', 'In order to receive an updated version, you need to re-create them.', 'Are you sure you want to change the direct cost values?'],
      de: ['Sie sind dabei, die direkten Kosten für einen Zeitraum zu verändern, für welchen bereits ein Bericht erstellt wurde.', 'Um eine aktualisierte Version zu erhalten, müssen Sie den Bericht neu erstellen.', 'Sind Sie sicher, dass Sie die direkten Kosten aktualisieren möchten?'],
    },
    okText: {
      en: 'Change direct costs',
      de: 'Direkte Kosten ändern',
    },
    cancelText: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
  },
  notifications: {
    loadError: {
      en: 'Failed to load account distribution.',
      de: 'Fehler beim Laden der Verteilerschlüssel.',
    },
    distributionSetLoadError: {
      en: 'Failed to load distribution sets.',
      de: 'Verteilerschlüssel konnten nicht geladen werden.',
    },
    unitSetLoadError: {
      en: 'Failed to load unit sets.',
      de: 'Einheitensets konnten nicht geladen werden.',
    },
    saveSuccess: {
      en: 'Account distribution saved successfully.',
      de: 'Verteilerschlüssel erfolgreich gespeichert.',
    },
    saveError: {
      en: 'Account distribution could not be saved.',
      de: 'Verteilerschlüssel kann nicht gespeichert werden.',
    },
    copySuccess: {
      en: 'Distribution keys copied successfully.',
      de: 'Verteilerschlüssel erfolgreich kopiert.',
    },
    copyError: {
      en: 'Distribution key copy failed.',
      de: 'Verteilerschlüssel kopieren fehlgeschlagen.',
    },
    loadAccountsError: {
      en: 'Failed to load accounts.',
      de: 'Konten konnten nicht geladen werden.',
    },
    validationError: {
      en: 'Account distribution might be invalid.',
      de: 'Verteilerschlüssel könnte ungültig sein.',
    },
    loadBalanceError: {
      en: 'Failed to load account balance.',
      de: 'Kontensaldo konnte nicht geladen werden.',
    },
    loadWkaError: {
      en: 'Failed to load WKA',
      de: 'WKA konnte nicht geladen werden',
    },
  },
};
